export enum FormatTypes {
    dd_mm_yyyy = 'dd-MM-yyyy',
    yyyy_mm_dd = 'yyyy-MM-dd',
    yyyy_MM_dd_HH_mm = 'yyyy-MM-dd HH:mm',
    yyyy_MM_dd_HH_mm_ss = 'yyyy-MM-dd HH:mm:ss',
    yyyy_MM_dd_HH_mm_ss_SSS = 'yyyy-MM-dd HH:mm:ss.SSS',
    mm_dd_yy = 'MM/dd/yy',
    DD_MM_YY = 'dd/MM/yy',
    YY_MM_DD = 'yy/MM/dd',
    Month_D_Yr = 'MMMM d, yyyy',
    m_d_yy = 'M/d/yy',
    d_m_yy = 'd/M/yy',
    yy_m_d = 'yy/M/d',
    MMDDYY = "MMddyy",
    DDMMYY = 'ddMMyy',
    YYMMDD = 'yyMMdd',
    MonDDYY = 'MMMddyy',
    DDMonYY = 'ddMMMyy',
    YYMonDD = 'yyMMMdd',
    D_Month_Yr = 'd MMMM, yyyy',
    Yr_Month_D = 'yyyy, MMMM d',
    Mon__DD__YYYY = 'MMM-dd-yyyy',
    DD__Mon__YYYY = 'dd-MMM-yyyy',
    YYYY__Mon__DD = 'yyyy-MMM-dd',
    Mon_DD_YYYY = 'MMM dd, yyyy',
    DD_Mon_YYYY = 'dd MMM, yyyy',
    YYYY_Mon_DD = 'yyyy, MMM dd',
    yyyy_mm = 'yyyy-MM',
    dd = 'dd',
    mm = 'MM',
    yyyy = 'yyyy',
    MMMM_yyyy = 'MMMM yyyy',
    MMMM = 'MMMM_yyyy',
    mm_dd_yyyy = 'MM/dd/yyyy'

    // Custom = 'Custom'
}

export class DateFormatAttributesConstants {

    static readonly DATE_TYPES = [
        { id: FormatTypes.dd_mm_yyyy, name: FormatTypes.dd_mm_yyyy },
        { id: FormatTypes.yyyy_mm_dd, name: FormatTypes.yyyy_mm_dd },
        { id: FormatTypes.yyyy_MM_dd_HH_mm, name: FormatTypes.yyyy_MM_dd_HH_mm },
        { id: FormatTypes.yyyy_MM_dd_HH_mm_ss, name: FormatTypes.yyyy_MM_dd_HH_mm_ss },
        { id: FormatTypes.yyyy_MM_dd_HH_mm_ss_SSS, name: FormatTypes.yyyy_MM_dd_HH_mm_ss_SSS},
        { id: FormatTypes.mm_dd_yy, name: FormatTypes.mm_dd_yy },
        { id: FormatTypes.DD_MM_YY, name: FormatTypes.DD_MM_YY },
        { id: FormatTypes.YY_MM_DD, name: FormatTypes.YY_MM_DD },
        { id: FormatTypes.Month_D_Yr, name: FormatTypes.Month_D_Yr },
        { id: FormatTypes.m_d_yy, name: FormatTypes.m_d_yy },
        { id: FormatTypes.d_m_yy, name: FormatTypes.d_m_yy },
        { id: FormatTypes.yy_m_d, name: FormatTypes.yy_m_d },
        { id: FormatTypes.MMDDYY, name: FormatTypes.MMDDYY },
        { id: FormatTypes.DDMMYY, name: FormatTypes.DDMMYY },
        { id: FormatTypes.YYMMDD, name: FormatTypes.YYMMDD },
        { id: FormatTypes.MonDDYY, name: FormatTypes.MonDDYY },
        { id: FormatTypes.DDMonYY, name: FormatTypes.DDMonYY },
        { id: FormatTypes.YYMonDD, name: FormatTypes.YYMonDD },
        { id: FormatTypes.D_Month_Yr, name: FormatTypes.D_Month_Yr },
        { id: FormatTypes.Yr_Month_D, name: FormatTypes.Yr_Month_D },
        { id: FormatTypes.Mon__DD__YYYY, name: FormatTypes.Mon__DD__YYYY },
        { id: FormatTypes.DD__Mon__YYYY, name: FormatTypes.DD__Mon__YYYY },
        { id: FormatTypes.YYYY__Mon__DD, name: FormatTypes.YYYY__Mon__DD },
        { id: FormatTypes.Mon_DD_YYYY, name: FormatTypes.Mon_DD_YYYY },
        { id: FormatTypes.DD_Mon_YYYY, name: FormatTypes.DD_Mon_YYYY },
        { id: FormatTypes.YYYY_Mon_DD, name: FormatTypes.YYYY_Mon_DD },
        { id: FormatTypes.dd, name: FormatTypes.dd },
        { id: FormatTypes.mm, name: FormatTypes.mm },
        { id: FormatTypes.yyyy, name: FormatTypes.yyyy },
        { id: FormatTypes.MMMM_yyyy, name: FormatTypes.MMMM_yyyy },
        { id: FormatTypes.MMMM, name: FormatTypes.MMMM }

    ]
}
export class DateTimeEnum {
    static readonly PRESENTATION = 'presentation';
    static readonly DATE_FORMAT = 'Date Format';
}