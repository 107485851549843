import { AbstractControl, ValidatorFn } from "@angular/forms";
import { CFSDataTypes, FormFieldOption } from "../../../../../../contants";
import { EntitySchemaFieldContainer } from "../../../../../../schema-models";
import { FormRenderService } from "../shared/service/form-render-service";

export class FormValidator {
  constructor(private formRenderService: FormRenderService) { }

  uniqueValidation(schemaFieldContainer: EntitySchemaFieldContainer): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string | number } | null => {
      const isFieldStringType = schemaFieldContainer.entitySchemaField.fieldType === CFSDataTypes.STRING_VALUE;
      let controlValue = this.getValueBasedOnType(control.value, isFieldStringType);
      let uniqueValuesList = this.getDistinctValues(schemaFieldContainer, isFieldStringType);

      const caseSensitivity = this.getCaseSensitivity(schemaFieldContainer);
      if(isFieldStringType && !caseSensitivity) {
        controlValue = (controlValue as string)?.toLowerCase();
        uniqueValuesList = uniqueValuesList.map((value:string) => value.toLowerCase());
      }
      const isUnique = !uniqueValuesList.includes(controlValue);
      return isUnique ? null : { 'uniqueValidation': control.value };
    };
  }

  private getValueBasedOnType(value: string | number, isFieldStringType: boolean): string | number {
    return isFieldStringType ? (value as string)?.trim() : Number(value);
  }

  private getCaseSensitivity(schemaFieldContainer : EntitySchemaFieldContainer){
    return schemaFieldContainer.advancedSchemaFieldSetting.attributes[FormFieldOption.CASE_SENSITIVE] ? JSON.parse(schemaFieldContainer.advancedSchemaFieldSetting.attributes[FormFieldOption.CASE_SENSITIVE]) : false;
  }

  private getDistinctValues(schemaFieldContainer: EntitySchemaFieldContainer & { distinctValues?: string[] }, isFieldStringType: boolean): (string | number)[] {
    if (schemaFieldContainer.distinctValues) {
      const { distinctValues, fieldName } = schemaFieldContainer;
      const formData = this.formRenderService.getData();
      const currentValue = this.getValueBasedOnType(formData ? formData[fieldName] : null, isFieldStringType);
      const convertedValues = isFieldStringType ? distinctValues.map((item:string) => item.trim()) : distinctValues.map(Number);
      return convertedValues.filter(value => value !== currentValue);
    } else {
      return [];
    }
  }

}
