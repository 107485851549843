import { RtOption, RtNone, RtSome } from "../../../utils/option-helper";
import { AttributeGroup } from "../../constants/attribute-group-constant";
import { AttributeRegistryConstant } from "../../constants/attribute-registry-constants";
import { CSSPropertyNameConstants } from "../../constants/css-property-name-constants";
import { Units } from "../../controls/css-grid/enums/grid-enums";
import { Attribute, AttributeType, MobiAttribute } from "../core/attribute";
import { UsableAttributeValue } from "../core/attribute-instance";
import { IClassInstanceHelper } from "../core/class-instance-helper";

export class DimensionNoAutoHeightModel implements IClassInstanceHelper {
  constructor(public widthContainer: WidthContainer, public heightContainer: HeightContainer) { }

  private resolveUnit(unit: Units) {
    switch (unit) {
      case Units.PX:
        return 'Units.PX';
      case Units.AUTO:
        return 'Units.AUTO';
      case Units.PERCENTAGE:
        return 'Units.PERCENTAGE';
      case Units.FR:
        return 'Units.FR';
      default:
        return 'Units.AUTO';
    }
  }

  generateTSCode(): string {
    return ` new DimensionNoAutoHeightModel(
            new WidthContainer(${this.widthContainer.width}, ${this.resolveUnit(this.widthContainer.unit)}),
            new HeightContainer(${this.heightContainer.height}, ${this.resolveUnit(this.heightContainer.unit)}));
    `;
  }
  static parse(json: string) {
    const dimension: DimensionNoAutoHeightModel = JSON.parse(json);
    const widthDimension = dimension.widthContainer;
    const heightDimension = dimension.heightContainer;
    const widthContainer = new WidthContainer(widthDimension.width, widthDimension.unit);
    const heightContainer = new HeightContainer(heightDimension.height, heightDimension.unit);
    return new DimensionNoAutoHeightModel(widthContainer, heightContainer);
  }

  buildUsableValues() {
    return [
      ...this.widthContainer.buildWidthValues(CSSPropertyNameConstants.WIDTH),
      ...this.heightContainer.buildHeightValues(CSSPropertyNameConstants.HEIGHT),
    ];
  }

  buildMobiUsableValues(width, height) {
    return [
      ...this.widthContainer.buildWidthValues(width),
      ...this.heightContainer.buildHeightValues(height),
    ];
  }
}

export class WidthContainer {
  constructor(public width: number, public unit: Units) { }

  buildWidthValues(property: string): UsableAttributeValue<string>[] {
    let width = `${this.width}${this.unit}`;
    if(this.unit == Units.AUTO){
      width = this.unit;
    }
    return [
      new UsableAttributeValue(property, width, AttributeType.CSS),
    ];
  }
}

export class HeightContainer {
  constructor(public height: number, public unit: Units) { }

  buildHeightValues(property: string): UsableAttributeValue<string>[] {
    let height = `${this.height}${this.unit}`;
    if(this.unit == Units.AUTO){
      height = this.unit;
    }
    return [
      new UsableAttributeValue(property, height, AttributeType.CSS),
    ];
  }
}

export class DimensionNoAutoHeightAttribute extends Attribute<DimensionNoAutoHeightModel, string> implements MobiAttribute<DimensionNoAutoHeightModel, string>{

  constructor(private defaultDimensionNoAutoHeightModel: RtOption<DimensionNoAutoHeightModel> = RtNone(),
    public name: string = AttributeRegistryConstant.DIMENSIONS_NO_AUTOHEIGHT,
    public cssWidthAttributeName: RtOption<string> = RtNone(),
    public cssHeightAttributeName: RtOption<string> = RtNone()) {
    super(name, AttributeGroup.APPERANCE, 'string', AttributeType.CSS, RtSome(() => this.getDefaultDimension()));
  }

  clone(): Attribute<unknown, unknown> {
    return new DimensionNoAutoHeightAttribute(this.defaultDimensionNoAutoHeightModel, this.name);
  }
  generateTSCode(): string {

    return `
      const DimensionNoAutoHeightModel = ${this.model.generateTSCode()};
      const name = ${this.name};
      new DimensionNoAutoHeightAttribute(RtSome(DimensionNoAutoHeightModel),name);
      `;
  }
  getDefaultDimension(): any {
    if (this.defaultDimensionNoAutoHeightModel.isDefined) {
      return this.defaultDimensionNoAutoHeightModel.get;
    }
    const widthContainer = new WidthContainer(100, Units.PX);
    const heightContainer = new HeightContainer(50, Units.PX);
    return new DimensionNoAutoHeightModel(widthContainer, heightContainer);
  }
  parseModel(jsonValue: string): DimensionNoAutoHeightModel {
    return DimensionNoAutoHeightModel.parse(jsonValue);
  }
  buildUsableValue(resolvedValueOpt: RtOption<DimensionNoAutoHeightModel>): UsableAttributeValue<string>[] {
    if (resolvedValueOpt.isDefined) {
      const container = resolvedValueOpt.get;
      return container.buildUsableValues();
    } else {
      return [];
    }
  }

  buildMobiUsableValue(resolvedValue: RtOption<DimensionNoAutoHeightModel>): UsableAttributeValue<string>[] {
    const cssWidthAttributeName = this.cssWidthAttributeName.map(p => p).getOrElse(() => CSSPropertyNameConstants.WIDTH);
    const cssHeightAttributeName = this.cssHeightAttributeName.map(p => p).getOrElse(() => CSSPropertyNameConstants.HEIGHT);
    if (resolvedValue.isDefined) {
      const container = resolvedValue.get;
      return container.buildMobiUsableValues(cssWidthAttributeName, cssHeightAttributeName);
    } else {
      return [];
    }
  }

}
