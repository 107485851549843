import { RtOption, RtNone, RtSome } from '../../../utils/option-helper';
import { AttributeGroup } from '../../constants/attribute-group-constant';
import { AttributeRegistryConstant } from '../../constants/attribute-registry-constants';
import { BorderStyleConstant } from '../border/border-style-constants';
import { ColorContainerV3 } from '../color/color-attributeV3';
import { Attribute, AttributeType, MobiAttribute } from '../core/attribute';
import { UsableAttributeValue } from '../core/attribute-instance';
import { IClassInstanceHelper } from '../core/class-instance-helper';

export class TypographyConfigurationModel implements IClassInstanceHelper {
  constructor(
    public font_weight: string,
    public font_style: string,
    public text_decoration: string,
    public text_align: string,
    public word_break: string,
    public font_family: string,
    public color: RtOption<ColorContainerV3>,
    public font_size: number,
    public text_transform: string,
    public writing_mode?: string,
    public font_size_unit = 'px',
  ) { }

  generateTSCode(): string {
    return `
      const typographyConfigModel = new TypographyConfigurationModel('${this.font_weight}',
      '${this.font_style}',
      '${this.text_decoration}',
      '${this.text_align}',
      '${this.word_break}',
      '${this.font_family}',
      '${this.color}',
      '${this.font_size}',
      '${this.text_transform}',
      '${this.writing_mode}',
      '${this.font_size_unit}',
      )`;
  }

  private static parseColorValue(jsonValue: string) {
    const colorContainer = ColorContainerV3.parse(jsonValue);
    return colorContainer;
  }

  static parse(jsonString: string): TypographyConfigurationModel {
    const configValue: TypographyConfigurationModel = JSON.parse(jsonString);
    const colorValue = RtOption.parse(configValue.color).isDefined
      ? RtOption.parse(configValue.color).get
      : RtNone();
    const color: RtOption<ColorContainerV3> = configValue.color
      ? RtSome(this.parseColorValue(JSON.stringify(colorValue)))
      : RtNone();
    configValue.color = RtOption.parse(configValue.color);
    return new TypographyConfigurationModel(
      configValue.font_weight,
      configValue.font_style,
      configValue.text_decoration,
      configValue.text_align,
      configValue.word_break,
      configValue.font_family,
      color,
      configValue.font_size,
      configValue.text_transform,
      configValue.writing_mode,
      configValue.font_size_unit
    );
  }

  buildUsableValues(): UsableAttributeValue<string>[] {
    const colorCode = this.color.map(
      (c) => c.usableCSSValue
    ).getOrElseUndefined;
    return [new UsableAttributeValue('font-weight', `${this.font_weight}`, AttributeType.CSS),
    new UsableAttributeValue('font-style', `${this.font_style}`, AttributeType.CSS),
    new UsableAttributeValue('text-decoration', `${this.text_decoration}`, AttributeType.CSS),
    new UsableAttributeValue('text-align', `${this.text_align}`, AttributeType.CSS),
    new UsableAttributeValue('word-break', `${this.word_break}`, AttributeType.CSS),
    new UsableAttributeValue('font-family', `${this.font_family}`, AttributeType.CSS),
    new UsableAttributeValue('color', `${colorCode}`, AttributeType.CSS),
    new UsableAttributeValue('font-size', `${this.font_size}${this.font_size_unit}`, AttributeType.CSS),
    new UsableAttributeValue('text-transform', `${this.text_transform}`, AttributeType.CSS),
    new UsableAttributeValue('writing-mode', `${this.writing_mode}`, AttributeType.CSS)
    ];
  }
}
export class TypographyAttributes
  extends Attribute<TypographyConfigurationModel, string>
  implements MobiAttribute<TypographyConfigurationModel, string> {
  constructor(
    public typographyConfig: RtOption<TypographyConfigurationModel> = RtNone()) {
    super(AttributeRegistryConstant.TYPOGRAPHY, AttributeGroup.TYPOGRAPHY, 'string', AttributeType.CSS, RtSome(() => this.getDefaultValue()));
  }

  clone(): Attribute<unknown, unknown> {
    return new TypographyAttributes(this.typographyConfig);
  }

  generateTSCode(): string {
    return `
    ${this.model.generateTSCode()}
      new TypographyAttributes(typographyConfigurationModel)
    `;
  }

  static defaultTypographyConfiguration() {
    const font_weight = BorderStyleConstant.FONT_WEIGHT[0];
    const font_style = BorderStyleConstant.FONT_STYLES[0];
    const text_decoration = 'none';
    const text_align = null; // BorderStyleConstant.FONT_STYLES[0]
    const word_break = BorderStyleConstant.WORD_BREAK_LIST[0];
    const font_family = 'Roboto, sans-serif';
    // const color = RtSome(
    //   ColorContainerV3.solidColorTheme(
    //     ThemeColorType.PRIMARY,
    //     ThemePalleteKeys.DARKER_CONTRAST
    //   )
    // );
    const rgba = { "r": 0, "g": 0, "b": 0, "a": 1 }
    const color = RtSome(
      ColorContainerV3.solidCustomColor(rgba)
    );
    const font_size = 13;
    const text_transform = BorderStyleConstant.TEXT_TRANSFORM_LIST[4];
    const writing_mode = BorderStyleConstant.WRITING_MODE[0];
    const font_size_unit = 'px';

    return new TypographyConfigurationModel(
      font_weight,
      font_style,
      text_decoration,
      text_align,
      word_break,
      font_family,
      color,
      font_size,
      text_transform,
      writing_mode,
      font_size_unit
    );
  }
  private getDefaultValue(): any {
    if (this.typographyConfig.isDefined) {
      return this.typographyConfig.get;
    } else {
      return TypographyAttributes.defaultTypographyConfiguration();
    }
  }

  parseModel(jsonValue: string): TypographyConfigurationModel {
    return TypographyConfigurationModel.parse(jsonValue);
  }
  buildUsableValue(
    resolvedValueOpt: RtOption<TypographyConfigurationModel>
  ): UsableAttributeValue<string>[] {
    if (resolvedValueOpt.isDefined) {
      const container = resolvedValueOpt.get;
      return container.buildUsableValues();
    } else {
      return [];
    }
  }

  buildMobiUsableValue(
    resolvedValue: RtOption<TypographyConfigurationModel>
  ): UsableAttributeValue<string>[] {
    if (resolvedValue.isDefined) {
      const container = resolvedValue.get;
      return container.buildUsableValues();
    } else {
      return [];
    }
  }
}
