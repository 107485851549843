<div class="peek-window-container mat-mdc-card" [id]="containerId" cdkDrag>
  @if(peekWindowOpenStatus) {
  <div cdkDragHandle>
    <mat-icon class="drag-icon">drag_handle</mat-icon>
  </div>
  }

  <ng-template denHost></ng-template>

  @if(peekWindowOpenStatus) {
  <div class="close-icon">
    <mat-icon (click)="closePeekWindow(containerId)">clear </mat-icon>
  </div>
  }
</div>