import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, NgZone } from '@angular/core';
import { HttpStatusCode, HttpStatusMessages } from '../../constants/http-constants';
import { LoaderServiceProvider } from '../../providers/abstract/loader-service-provider';
import { ToastServiceProvider } from '../../providers/abstract/toast-service-provider';
import { LogService } from '../../log-service/log.service';

@Injectable()
export class ErrorsInterceptor implements ErrorHandler {
    httpStatusMessages: string = 'Http Response Error';

    private httpErrorStatusMap: Map<number, string> = new Map();

    // https://stackoverflow.com/a/69311309
    constructor(@Inject(Injector) protected injector: Injector,
    ) {
        this.registerStatusCode();
    }

    handleError(error: Error | HttpErrorResponse): void {
        const zone = this.injector.get(NgZone);
        const loaderService = this.injector.get(LoaderServiceProvider);
        const toastService = this.injector.get(ToastServiceProvider);
        const logService: LogService = this.injector.get(LogService);
        loaderService.loaderSubjectCounter.next(0);
        if (error instanceof HttpErrorResponse) {
            zone.run(() => {
                if (error.message && error.error.message) {
                    if (error.status !== HttpStatusCode.EXPECTATION_FAILED) {
                        toastService.showError(error.error.message);
                    }
                    loaderService.decrementLoaderCount();
                    logService.error(error.error?.message, error.error);

                } else {
                    if (error.status !== HttpStatusCode.EXPECTATION_FAILED) {
                        this.getExcpetionByStatusCode(error.status);
                        toastService.showError(error.message || this.httpStatusMessages);
                        logService.error(error.message || this.httpStatusMessages, error.error);
                    }
                    loaderService.decrementLoaderCount();
                    logService.error(error.error?.message, error.error);

                }
            });
        } else {
            const errorStack = typeof error === 'string' ? error : error?.stack || error?.message;
            logService.error(null, errorStack);
        }
    }

    private registerStatusCode() {
        this.httpErrorStatusMap.set(HttpStatusCode.CONFLICT, HttpStatusMessages.CONFLICT);
        this.httpErrorStatusMap.set(HttpStatusCode.FORBIDDEN, HttpStatusMessages.FORBIDDEN);
        this.httpErrorStatusMap.set(HttpStatusCode.UNAUTHORIZED, HttpStatusMessages.UNAUTHORIZED);
        this.httpErrorStatusMap.set(HttpStatusCode.NOT_FOUND, HttpStatusMessages.NOT_FOUND);
        this.httpErrorStatusMap.set(HttpStatusCode.LOCKED, HttpStatusMessages.LOCKED);
        this.httpErrorStatusMap.set(HttpStatusCode.FAILED_DEPENDENCY, HttpStatusMessages.FAILED_DEPENDENCY);
        this.httpErrorStatusMap.set(HttpStatusCode.BAD_REQUEST, HttpStatusMessages.BAD_REQUEST);
        this.httpErrorStatusMap.set(HttpStatusCode.INTERNAL_SERVER_ERROR, HttpStatusMessages.INTERNAL_SERVER_ERROR);
        this.httpErrorStatusMap.set(HttpStatusCode.SERVICE_UNAVAILABLE, HttpStatusMessages.SERVICE_UNAVAILABLE);
        this.httpErrorStatusMap.set(HttpStatusCode.BAD_GATEWAY, HttpStatusMessages.BAD_GATEWAY);
        this.httpErrorStatusMap.set(HttpStatusCode.NOT_ACCEPTABLE, HttpStatusMessages.NOT_ACCEPTABLE);
        this.httpErrorStatusMap.set(HttpStatusCode.METHOD_NOT_ALLOWED, HttpStatusMessages.METHOD_NOT_ALLOWED);
        this.httpErrorStatusMap.set(HttpStatusCode.CONNECTION_FAILED, HttpStatusMessages.CONNECTION_FAILED);
        this.httpErrorStatusMap.set(HttpStatusCode.REQUEST_TIMEOUT, HttpStatusMessages.REQUEST_TIMEOUT);
        this.httpErrorStatusMap.set(HttpStatusCode.NO_CONTENT, HttpStatusMessages.NO_CONTENT);
        this.httpErrorStatusMap.set(HttpStatusCode.PRECONDITION_FAILED, HttpStatusMessages.PRECONDITION_FAILED);
    }

    protected getExcpetionByStatusCode(statusCode: number) {
        this.httpErrorStatusMap.get(statusCode);
    }
}
