import { Type } from '@angular/core';
import { FilterOperator, FilterProperty, GlobalBaseEntity } from './base.models';

export class IODateFormat {
    static inputFormat: string = 'yyyy-MM-ddTHH:mm:ss+05:30';
    static outputFormat: string = 'yyyy-MM-dd HH:mm:ss';
}
export class CrudModel<T> {
    crudType: number;
    crudInstance: T;
}
export class UniqueValidator {
    value: string;
    propertyName?: string;
    filterProperties?: FilterProperty[];
    filterOperator?: FilterOperator;
    url?: string;
}
export class FeatureGroup {
    public id: number;
    public name: string;
    public featureGroupIdentifier: string;
    public entityName?: string;
    public operationType?: string;
    public moduleId?: number;
    public enabled?: boolean;
    public parentFeatureGroupIdentifier?: string;
    public featureGroupDependencies?: string[];
}

export enum ModeConstants {
    MAIN = 'MAIN',
    FLEX = 'FLEX'
}

export class FlexContentInput<T> {
    title: string;
    component: Object;
    showPanel: boolean;
    inputs: {
        data: T,
        [property: string]: any;
    };
    outputs?: {
        [property: string]: any;
    };
    constructor() { }
}
export class NavigationComponentModel {
    id: number;
    name: string;
    component: Type<any>;
    path: string;
}

export class TableProperty {
    columnDetails: ColumnDetailsData[];
    dataSource: any;
    actionList?: ActionListData[];
}
export class ColumnDetailsData {
    displayName: string;
    propertyName: string;
    hasObject: boolean;
    objPropertyName?: string;
    imageConfig?: ImageConfig;
    isDate?: boolean;
    outputDateFormat?: string = 'yyyy-MM-dd';

}
export class ActionListData {
    name: string;
    icon: string;
    permissionConstant?: string;
    callbackFn: (element: any) => void;
}
export class FormFieldDateInput {
    propertyName: string;
    formatType: string;
}

export class MultiSelectType {
    name?: string;
    label?: string;
    isDisable?: boolean;
}

export class NavChildList {
    name: string;
    icon: string;
    link: string;
    isSelect?: boolean;
    authorize?: string;
    queryParams?: object;
    isAuthorized?: boolean;
    children?: NavList[];
}
export class NavList extends NavChildList {
    isParentSelect?: boolean;
    isChildSelect?: boolean;
    children?: NavList[] = [];
}
export class HeaderSetting {
    constructor(public name: string,
        public icon: string,
        public callBack: () => void) { }
}

export interface FsDocumentElement extends HTMLElement {
    msRequestFullscreen?: () => void;
    mozRequestFullScreen?: () => void;
    webkitRequestFullscreen?: () => void;
    exitFullscreen?: () => void;
    fullscreenElement: () => void;
}

export class Device extends GlobalBaseEntity {
    public name: string;
    public deviceType: string;
    public description?: string;
    public labels: Array<string>;
    public sortOrder?: number;
    public label: string;
    public modifiedDate: string;
    constructor() {
        super();
    }
}

export class MapLocators {
    lattitude: number;
    longitude: number;
}


export class ImageConfig {
    imageURL?: string;
    documentURL?: string;
    defaultImage?: string;
}
