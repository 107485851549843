import { RtNone, RtOption } from "../../../../../../utils/option-helper";
import { EventAction, EventActionCondition, EventActionType } from "../../event-store";
import { CommonEventType } from "../../event-types";


export class PageVariables implements EventAction {

  id: string;

  actionType: EventActionType;

  eventType: CommonEventType;

  containers: PageVariablesContainer[];

  eventCondition: EventActionCondition;

  constructor() { }

}




export class PageVariablesContainer {

  //control instance to apply

  controlInstanceId: string;
  controlInstanceName?: string;
  variableName: string;
  bindingProperty: string;
  expression: string;
  dataBindingType: BindingType;

  constructor(controlInstanceId: string, variableName: string, dataBindingType: BindingType, controlInstanceName?: string, bindingProperty?: string, expression?: string,) {
    this.controlInstanceId = controlInstanceId;
    this.variableName = variableName;
    this.controlInstanceName = controlInstanceName;
    this.bindingProperty = bindingProperty;
    this.expression = expression;
    this.dataBindingType = dataBindingType;
  }
}

export enum BindingType {
  DATA_BINDING = "data_binding",
  EXPRESSION = "expression"
}
export enum VariableType {
  USE_EXISTING = "Use existing",
  CREATE_NEW = "Create new"
}