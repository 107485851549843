
export enum BackendActionResponseEnumConstants {

   	 	DataViewItem = "DataViewItem",
 	 	DataViewTimeSeriesItemContainer = "DataViewTimeSeriesItemContainer",
 	 	MultiDataViewContainer = "MultiDataViewContainer",
 	 	MultiDataViewTimeSeriesItem = "MultiDataViewTimeSeriesItem",
 	 	TwoDMultiDataViewContainer = "TwoDMultiDataViewContainer",
 	 	TwoDMultiDataViewTimeSeriesContainer = "TwoDMultiDataViewTimeSeriesContainer",

}

export enum DerivedTypeEnumConstants {

   	 	Api = "Api",
 	 	MongoQuery = "MongoQuery",
 	 	ReferenceData = "ReferenceData",

}

export enum DeleteStrategyConstants {

   	 	CASCADE_DELETE = "CASCADE_DELETE",
 	 	FK_CONSTRAINT = "FK_CONSTRAINT",

}

export enum BackendActionSupportEnumConstants {

   	 	FilterCase = "FilterCase",
 	 	Multiple = "Multiple",
 	 	Single = "Single",

}

export enum DevumNotificationTypeEnumConstants {

   	 	Apps = "Apps",
 	 	Email = "Email",
 	 	SMS = "SMS",
 	 	WhatsApp = "WhatsApp",

}

export enum FilterOperatorEnumConstants {

   	 	BETWEEN = "BETWEEN",
 	 	CONTAINS = "CONTAINS",
 	 	CONTAINS_IGNORE_CASE = "CONTAINS_IGNORE_CASE",
 	 	ENDS_WITH = "ENDS_WITH",
 	 	ENDS_WITH_IGNORE_CASE = "ENDS_WITH_IGNORE_CASE",
 	 	EQUALS = "EQUALS",
 	 	EQUALS_IGNORE_CASE = "EQUALS_IGNORE_CASE",
 	 	EXP_EQUALS = "EXP_EQUALS",
 	 	EXP_GREATER_THAN = "EXP_GREATER_THAN",
 	 	EXP_GREATER_THAN_EQUALS = "EXP_GREATER_THAN_EQUALS",
 	 	EXP_IN = "EXP_IN",
 	 	EXP_LESS_THAN = "EXP_LESS_THAN",
 	 	EXP_LESS_THAN_EQUALS = "EXP_LESS_THAN_EQUALS",
 	 	EXP_NOT_EQUAL = "EXP_NOT_EQUAL",
 	 	EXP_NOT_IN = "EXP_NOT_IN",
 	 	GEO_INTERSECTS = "GEO_INTERSECTS",
 	 	GEO_WITHIN = "GEO_WITHIN",
 	 	GREATER_THAN = "GREATER_THAN",
 	 	GREATER_THAN_EQUALS = "GREATER_THAN_EQUALS",
 	 	IN = "IN",
 	 	IS_EMPTY = "IS_EMPTY",
 	 	IS_NOT_NULL = "IS_NOT_NULL",
 	 	IS_NULL = "IS_NULL",
 	 	IS_PRESENT = "IS_PRESENT",
 	 	LESS_THAN = "LESS_THAN",
 	 	LESS_THAN_EQUALS = "LESS_THAN_EQUALS",
 	 	NOT_CONTAINS = "NOT_CONTAINS",
 	 	NOT_CONTAINS_IGNORE_CASE = "NOT_CONTAINS_IGNORE_CASE",
 	 	NOT_EQUAL = "NOT_EQUAL",
 	 	NOT_EQUAL_IGNORE_CASE = "NOT_EQUAL_IGNORE_CASE",
 	 	NOT_IN = "NOT_IN",
 	 	RANGE = "RANGE",
 	 	STARTS_WITH = "STARTS_WITH",
 	 	STARTS_WITH_IGNORE_CASE = "STARTS_WITH_IGNORE_CASE",

}

export enum TemplateDeploymentExecutionStageConstants {

   	 	AllDbOperationSuccess = "AllDbOperationSuccess",
 	 	ConfirmAppCreationGoingToStart = "ConfirmAppCreationGoingToStart",
 	 	DataModellerDBOperation = "DataModellerDBOperation",
 	 	FailedDBOperation = "FailedDBOperation",
 	 	FluentServiceDBOperation = "FluentServiceDBOperation",
 	 	GeneralDBOperation = "GeneralDBOperation",
 	 	MViewDBOperation = "MViewDBOperation",
 	 	PageDBOperation = "PageDBOperation",
 	 	ProcessModellerDBOperation = "ProcessModellerDBOperation",
 	 	TemplateComparison = "TemplateComparison",
 	 	TemplateDeployedSuccessfully = "TemplateDeployedSuccessfully",
 	 	TemplateDeploymentUnSuccessful = "TemplateDeploymentUnSuccessful",
 	 	TemplateUpload = "TemplateUpload",

}

export enum LocalUserStatusEnumConstants {

   	 	ARCHIVED = "ARCHIVED",
 	 	COMPROMISED = "COMPROMISED",
 	 	CONFIRMED = "CONFIRMED",
 	 	RESET_REQUIRED = "RESET_REQUIRED",
 	 	UNCONFIRMED = "UNCONFIRMED",
 	 	UNKNOWN = "UNKNOWN",

}

export enum ReducerEnumConstants {

   	 	Average = "Average",
 	 	Concat = "Concat",
 	 	Distinct = "Distinct",
 	 	Expression = "Expression",
 	 	Head = "Head",
 	 	Last = "Last",
 	 	Max = "Max",
 	 	Min = "Min",
 	 	Percentage = "Percentage",
 	 	Summation = "Summation",

}

export enum TimeDurationTypeEnumConstants {

   	 	Days = "Days",
 	 	Hours = "Hours",
 	 	MilliSeconds = "MilliSeconds",
 	 	Minutes = "Minutes",
 	 	Seconds = "Seconds",

}

export enum TestCaseModeConstants {

   	 	SIMULATION = "SIMULATION",
 	 	TESTING = "TESTING",

}

export enum SpatialDataFormatEnumConstants {

   	 	BlockModel = "BlockModel",
 	 	Contour = "Contour",
 	 	General = "General",
 	 	Route = "Route",

}

export enum AppArtifactTypeEnumConstants {

   	 	DataModeller = "DataModeller",
 	 	FORM = "FORM",
 	 	FluentService = "FluentService",
 	 	GENERAL = "GENERAL",
 	 	MView = "MView",
 	 	PAGE = "PAGE",
 	 	ProcessModeller = "ProcessModeller",

}

export enum ProcessInstanceStateEnumConstants {

   	 	ACTIVE = "ACTIVE",
 	 	CANCELLED = "CANCELLED",
 	 	COMPLETED = "COMPLETED",
 	 	FAILED = "FAILED",

}

export enum TaskPriorityEnumConstants {

   	 	High = "High",
 	 	Low = "Low",
 	 	Medium = "Medium",

}

export enum TemplateAndDeploymentStatusEnumConstants {

   	 	Completed = "Completed",
 	 	Failed = "Failed",
 	 	InProgress = "InProgress",

}

export enum FileGroupConstants {

   	 	app = "app",
 	 	client = "client",
 	 	private = "private",
 	 	public = "public",

}

export enum CharvakaFunctionTypeEnumConstants {

   	 	ASYNC = "ASYNC",
 	 	DevumStream = "DevumStream",
 	 	REACTIVE = "REACTIVE",
 	 	ReactiveStream = "ReactiveStream",

}

export enum ArtifactGroupConstants {

   	 	APP = "APP",
 	 	DATA_MODELLER = "DATA_MODELLER",
 	 	DYNAMIC_FORM = "DYNAMIC_FORM",
 	 	FLUENT_SERVICE = "FLUENT_SERVICE",
 	 	MICROSERVICE = "MICROSERVICE",
 	 	MISCELLANEOUS = "MISCELLANEOUS",
 	 	MOBILE_APP = "MOBILE_APP",
 	 	MVIEW = "MVIEW",
 	 	NOT_PROVIDED = "NOT_PROVIDED",
 	 	PAGE = "PAGE",
 	 	PROCESS = "PROCESS",
 	 	REPORT = "REPORT",
 	 	SITE = "SITE",
 	 	USER_ACCOUNT = "USER_ACCOUNT",

}

export enum BuildStatusEnumConstants {

   	 	Cancelled = "Cancelled",
 	 	Completed = "Completed",
 	 	Created = "Created",
 	 	Failed = "Failed",
 	 	InProgress = "InProgress",
 	 	Queued = "Queued",

}

export enum ArtifactStatusTypeEnumConstants {

   	 	INFO = "INFO",
 	 	SHOW_STOPPER = "SHOW_STOPPER",
 	 	WARN = "WARN",

}

export enum SchemaTypeEnumConstants {

   	 	MView = "MView",
 	 	MView_Params = "MView_Params",
 	 	Master = "Master",
 	 	Reference = "Reference",
 	 	Transaction = "Transaction",

}

export enum DevumNotificationThresholdTypeConstants {

   	 	Daily = "Daily",
 	 	Fifteen_Minutes = "Fifteen Minutes",
 	 	One_Hour = "One Hour",
 	 	Six_Hours = "Six Hours",
 	 	Thirty_Minutes = "Thirty Minutes",
 	 	Twelve_Hours = "Twelve Hours",
 	 	Two_Hours = "Two Hours",

}

export enum BackendActionTypeEnumConstants {

   	 	Form = "Form",
 	 	MView = "MView",

}

export enum BeforeOrAfterConstants {

   	 	After = "After",
 	 	Before = "Before",

}

export enum UsageEvaluationConstants {

   	 	NOT_PROVIDED = "NOT_PROVIDED",
 	 	PROVIDED = "PROVIDED",

}

export enum TemplateComparisonStatusEnumConstants {

   	 	Failed = "Failed",
 	 	InProgress = "InProgress",
 	 	ReadyToCompare = "ReadyToCompare",

}

export enum AppDeploymentTypeConstants {

   	 	EXISTING = "EXISTING",
 	 	NEW = "NEW",

}

export enum CharvakaMViewTypeConstants {

   	 	All_Tasks = "All Tasks",
 	 	Process_Specific = "Process Specific",
 	 	Task_Specific = "Task Specific",

}

export enum MergeTemplateCategoryEnumConstants {

   	 	COMMON_FEATURES = "COMMON_FEATURES",
 	 	FEATURES_EXISTING_BUT_NOT_IN_UPLOADED = "FEATURES_EXISTING_BUT_NOT_IN_UPLOADED",
 	 	FEATURES_EXISTING_IN_TEMPLATE_FILE = "FEATURES_EXISTING_IN_TEMPLATE_FILE",

}

export enum MongoRepoFunctionsEnumConstants {

   	 	aggregate = "aggregate",
 	 	find = "find",
 	 	findOne = "findOne",
 	 	projection = "projection",
 	 	result = "result",

}

export enum StatusEnumConstants {

   	 	FAILED = "FAILED",
 	 	SUCCESS = "SUCCESS",

}

export enum ControlConditionTypeEnumConstants {

   	 	All = "All",
 	 	Any = "Any",

}

export enum MongoDbOperationTypeEnumConstants {

   	 	deleteById = "deleteById",
 	 	deleteByQuery = "deleteByQuery",
 	 	deleteMany = "deleteMany",
 	 	deleteManyWithLimitAndSort = "deleteManyWithLimitAndSort",
 	 	disableByQuery = "disableByQuery",
 	 	enableByQuery = "enableByQuery",
 	 	insert = "insert",
 	 	insertMany = "insertMany",
 	 	setById = "setById",
 	 	setMany = "setMany",
 	 	updateById = "updateById",
 	 	updateMany = "updateMany",

}

export enum SchemaBehaviourTypeEnumConstants {

   	 	Non_Process = "Non-Process",
 	 	Process = "Process",

}

export enum DevumNotificationSeverityEnumConstants {

   	 	High = "High",
 	 	Low = "Low",
 	 	Medium = "Medium",

}

export enum PgTableEnumConstants {

   	 	AdvancedEntitySchemaSetting = "AdvancedEntitySchemaSetting",
 	 	AdvancedSchemaFieldSetting = "AdvancedSchemaFieldSetting",
 	 	BoundedContext = "BoundedContext",
 	 	BoundedContextSchemaMapping = "BoundedContextSchemaMapping",
 	 	CharvakaFluentFunctionComposition = "CharvakaFluentFunctionComposition",
 	 	CharvakaFluentService = "CharvakaFluentService",
 	 	CharvakaProcess = "CharvakaProcess",
 	 	CharvakaProcessDefinition = "CharvakaProcessDefinition",
 	 	CharvakaProcessSpatialConfiguration = "CharvakaProcessSpatialConfiguration",
 	 	ComposerGroup = "ComposerGroup",
 	 	ControlInstance = "ControlInstance",
 	 	DataSourceServiceInstance = "DataSourceServiceInstance",
 	 	EntityFieldCondition = "EntityFieldCondition",
 	 	EntityFieldConditionOperator = "EntityFieldConditionOperator",
 	 	EntitySchema = "EntitySchema",
 	 	EntitySchemaField = "EntitySchemaField",
 	 	EntitySchemaFieldFilter = "EntitySchemaFieldFilter",
 	 	EntitySchemaPrecis = "EntitySchemaPrecis",
 	 	EntitySchemaPrecisMapping = "EntitySchemaPrecisMapping",
 	 	Help = "Help",
 	 	HelpCategory = "HelpCategory",
 	 	MViewParamsMapping = "MViewParamsMapping",
 	 	MViewTransactionDateMapping = "MViewTransactionDateMapping",
 	 	MaterializedViewDefinition = "MaterializedViewDefinition",
 	 	ModelConfiguration = "ModelConfiguration",
 	 	Page = "Page",
 	 	PageParameter = "PageParameter",
 	 	QuickStyleTemplate = "QuickStyleTemplate",

}

export enum OperationCrudTypeEnumConstants {

   	 	Delete = "Delete",
 	 	Insert = "Insert",
 	 	Move = "Move",
 	 	Update = "Update",

}

export enum SpatialCoordinateSystemEnumConstants {

   	 	UTM_system = "UTM system",
 	 	WGS_system = "WGS system",

}

export enum EnvironmentTypeConstants {

   	 	DEV = "DEV",
 	 	PROD = "PROD",
 	 	QA = "QA",
 	 	UAT = "UAT",
 	 	shared = "shared",

}

export enum SubscriptionPlanTypeEnumConstants {

   	 	Enterprise = "Enterprise",
 	 	Free = "Free",
 	 	Premium = "Premium",
 	 	Professional = "Professional",
 	 	Trail = "Trail",

}

export enum PeriodConstants {

   	 	Daily = "Daily",
 	 	Hourly = "Hourly",
 	 	Monthly = "Monthly",
 	 	NoPeriod = "NoPeriod",
 	 	Quarterly = "Quarterly",
 	 	Weekly = "Weekly",
 	 	Yearly = "Yearly",

}

export enum ControlTypeEnumConstants {

   	 	API = "API",
 	 	CHECK_BOX = "CHECK_BOX",
 	 	CHECK_BOX_LIST = "CHECK_BOX_LIST",
 	 	COLOR_PICKER = "COLOR_PICKER",
 	 	DATE_PICKER = "DATE_PICKER",
 	 	DATE_TIME_PICKER = "DATE_TIME_PICKER",
 	 	FILE_UPLOAD = "FILE_UPLOAD",
 	 	FOREIGN_KEY = "FOREIGN_KEY",
 	 	GEO_FENCE = "GEO_FENCE",
 	 	GEO_LOCATION = "GEO_LOCATION",
 	 	LOCATION3D = "LOCATION3D",
 	 	ORIENTATION = "ORIENTATION",
 	 	QUESTIONNAIRE = "QUESTIONNAIRE",
 	 	RADIO_BUTTON_LIST = "RADIO_BUTTON_LIST",
 	 	RESOURCE_BROWSER = "RESOURCE_BROWSER",
 	 	RESOURCE_BROWSER_3D = "RESOURCE_BROWSER_3D",
 	 	RICH_TEXT = "RICH_TEXT",
 	 	SCALE = "SCALE",
 	 	STATIC_SELECT = "STATIC_SELECT",
 	 	TEXT_AREA = "TEXT_AREA",
 	 	TEXT_BOX = "TEXT_BOX",
 	 	TIME_PICKER = "TIME_PICKER",

}

export enum CharvakaFunctionGroupEnumConstants {

   	 	Cache = "Cache",
 	 	Collection = "Collection",
 	 	Conditional = "Conditional",
 	 	Conversion = "Conversion",
 	 	Custom = "Custom",
 	 	DB = "DB",
 	 	Date = "Date",
 	 	Exception = "Exception",
 	 	Integration = "Integration",
 	 	Logger = "Logger",
 	 	Math = "Math",
 	 	Notification = "Notification",
 	 	Pub_sub = "Pub-sub",
 	 	Reactive = "Reactive",
 	 	Reactive_Streaming = "Reactive Streaming",
 	 	String = "String",
 	 	Types = "Types",
 	 	Util = "Util",

}

export enum SpatialFileEnumConstants {

   	 	CSV_file = "CSV file",
 	 	DXF_file = "DXF file",
 	 	Shape_file = "Shape file",

}

export enum WebSocketStrategyConstants {

   	 	Aggregated = "Aggregated",
 	 	IndividualItem = "IndividualItem",

}

export enum ReactiveServiceStatusEnumsConstants {

   	 	NA = "NA",
 	 	Start = "Start",
 	 	Stop = "Stop",

}

export enum RestMethodTypeConstants {

   	 	DELETE = "DELETE",
 	 	FILTER_WITH_PAGINATION = "FILTER_WITH_PAGINATION",
 	 	FORM_VIEW = "FORM_VIEW",
 	 	GET = "GET",
 	 	GET_ALL = "GET_ALL",
 	 	M_VIEW = "M_VIEW",
 	 	POST = "POST",
 	 	POST_FILTER = "POST_FILTER",
 	 	SELF_FILTER = "SELF_FILTER",

}

export enum NotificationTypeEnumConstants {

   	 	AdminRoleLogged = "AdminRoleLogged",
 	 	Assigned = "Assigned",
 	 	Available = "Available",
 	 	Claimed = "Claimed",
 	 	Completed = "Completed",
 	 	Deleted = "Deleted",
 	 	InitiatorRoleLogged = "InitiatorRoleLogged",
 	 	Notification_Logged = "Notification_Logged",
 	 	Overdue = "Overdue",
 	 	ProcessDefinitionDeleted = "ProcessDefinitionDeleted",

}

export enum DataSourceOwnerTypeEnumConstants {

   	 	control_instance = "control_instance",
 	 	dialog_button = "dialog_button",
 	 	event_action = "event_action",

}

export enum UsageSeverityConstants {

   	 	DEPENDENCY = "DEPENDENCY",
 	 	FK_DEPENDENCY = "FK_DEPENDENCY",
 	 	INFO = "INFO",
 	 	WARNING = "WARNING",

}

export enum ActualEntityStateTypeConstants {

   	 	DELETE = "DELETE",
 	 	INSERT = "INSERT",
 	 	UPDATE = "UPDATE",

}

export enum ComparisonResultTypeConstants {

   	 	DELETED = "DELETED",
 	 	NEW = "NEW",
 	 	UNCHANGED = "UNCHANGED",
 	 	UPDATED = "UPDATED",

}

export enum TemplateTypeConstants {

   	 	client_template = "client_template",
 	 	devum_distributed_template = "devum_distributed_template",

}

export enum SchemaFieldCategoryEnumConstants {

   	 	FormField = "FormField",
 	 	KpiInField = "KpiInField",
 	 	KpiOutField = "KpiOutField",
 	 	ProcessField = "ProcessField",
 	 	TaskField = "TaskField",

}

export enum UserOperationTypeConstants {

   	 	deleted = "deleted",
 	 	disabled = "disabled",
 	 	discarded = "discarded",
 	 	dynamic = "dynamic",
 	 	enabled = "enabled",
 	 	insert_updated = "insert_updated",
 	 	inserted = "inserted",
 	 	published = "published",
 	 	updated = "updated",

}

export enum ReportParamsTypeEnumConstants {

   	 	Date = "Date",
 	 	DateRange = "DateRange",
 	 	None = "None",

}

export enum ProcessExecutionStatusEnumConstants {

   	 	FAILED = "FAILED",
 	 	RETRYING = "RETRYING",
 	 	SUCCESS = "SUCCESS",

}

export enum ClientTypeEnumConstants {

   	 	api = "api",
 	 	user = "user",

}

export enum TableTypeEnumConstants {

   	 	Dynamic = "Dynamic",
 	 	Static = "Static",

}

export enum DevumFeaturesEnumConstants {

   	 	Access_to_Enterprise_Templates = "Access to Enterprise Templates",
 	 	Access_to_Premium_Templates = "Access to Premium Templates",
 	 	Access_to_template_library = "Access to template library",
 	 	Amazon_single_Sign_on = "Amazon single Sign-on",
 	 	App_deployment = "App deployment",
 	 	Apple_single_sign_on = "Apple single sign-on",
 	 	Application_health_monitoring = "Application health monitoring",
 	 	Apps = "Apps",
 	 	Automated_Database_backups = "Automated Database backups",
 	 	Automated_core_service_updates = "Automated core service updates",
 	 	Built_in_Microservices = "Built-in Microservices",
 	 	Create_own_templates = "Create own templates",
 	 	Custom_microservices = "Custom microservices",
 	 	Dedicated_account_manager = "Dedicated account manager",
 	 	Devum_Objects = "Devum Objects",
 	 	Domain_modeller = "Domain modeller",
 	 	Domain_service_builder = "Domain service builder",
 	 	Dynamic_Dev_users_configuration = "Dynamic Dev users configuration",
 	 	Enterprise_SAML = "Enterprise SAML",
 	 	Environments = "Environments",
 	 	Facebook_single_Sign_on = "Facebook single Sign-on",
 	 	Google_single_Sign_on = "Google single Sign-on",
 	 	Load_balancing = "Load balancing",
 	 	Log_monitoring = "Log monitoring",
 	 	Login_UI_customization = "Login UI customization",
 	 	Microsoft_Azure_AD = "Microsoft Azure AD",
 	 	Multi_factor_authentication = "Multi-factor authentication",
 	 	Node_configuration = "Node configuration",
 	 	Number_of_Nodes = "Number of Nodes",
 	 	Page_builder = "Page builder",
 	 	Real_time_MViews = "Real-time MViews",
 	 	Report_builder = "Report builder",
 	 	Resource_adjustment = "Resource adjustment",
 	 	Support_plan = "Support plan",
 	 	Users = "Users",

}

export enum DisposalTypeConstants {

   	 	Delete = "Delete",
 	 	DisableDelete = "DisableDelete",

}

export enum DatasourceCrudTypeConstants {

   	 	DELETE = "DELETE",
 	 	INSERT = "INSERT",
 	 	UPDATE = "UPDATE",

}

export enum IdentityProviderTypeEnumConstants {

   	 	Facebook = "Facebook",
 	 	Google = "Google",
 	 	LoginWithAmazon = "LoginWithAmazon",
 	 	SAML = "SAML",

}

export enum AuditLogLevelEnumConstants {

   	 	Advanced = "Advanced",
 	 	Basic = "Basic",
 	 	None = "None",

}

export enum ConditionalOperatorEnumConstants {

   	 	AND = "AND",
 	 	OR = "OR",

}

export enum ReactoreExceptionEnumConstants {

   	 	AWSException = "AWSException",
 	 	AppCodeException = "AppCodeException",
 	 	AuthenticationFailedException = "AuthenticationFailedException",
 	 	DatabaseException = "DatabaseException",
 	 	DependencyAvailabilityException = "DependencyAvailabilityException",
 	 	DuplicateEntityException = "DuplicateEntityException",
 	 	DuplicateNameException = "DuplicateNameException",
 	 	FileEmptyException = "FileEmptyException",
 	 	FunctionNotAvailableException = "FunctionNotAvailableException",
 	 	GenericException = "GenericException",
 	 	InvalidInputException = "InvalidInputException",
 	 	NoSuchEntityException = "NoSuchEntityException",
 	 	NoSuchMaterializedViewException = "NoSuchMaterializedViewException",
 	 	OperationNotAllowedException = "OperationNotAllowedException",
 	 	ReactoreFTPException = "ReactoreFTPException",
 	 	RequestAcceptedException = "RequestAcceptedException",
 	 	ServiceNotImplementedException = "ServiceNotImplementedException",
 	 	SimilarEntityException = "SimilarEntityException",
 	 	StopTimerException = "StopTimerException",
 	 	SubscriptionExpiredException = "SubscriptionExpiredException",
 	 	TargetNotAvailableException = "TargetNotAvailableException",
 	 	ValidationException = "ValidationException",

}

export enum OperatingSystemTypeEnumConstants {

   	 	Android = "Android",
 	 	IOS = "IOS",
 	 	Windows = "Windows",

}

export enum HealthCheckupScrutinizeTypeEnumConstants {

   	 	ScrutinizeTheAvoidDbCallsIfInsideMapFunction = "ScrutinizeTheAvoidDbCallsIfInsideMapFunction",
 	 	ScrutinizeTheDataItemBuilderFunc = "ScrutinizeTheDataItemBuilderFunc",
 	 	ScrutinizeTheEntityPrePostServiceForInsert = "ScrutinizeTheEntityPrePostServiceForInsert",
 	 	ScrutinizeTheFindAllFunc = "ScrutinizeTheFindAllFunc",
 	 	ScrutinizeTheMongoIndex = "ScrutinizeTheMongoIndex",
 	 	ScrutinizeThePublishDataItemKpiFuncIfItContainsInsideMapFunc = "ScrutinizeThePublishDataItemKpiFuncIfItContainsInsideMapFunc",
 	 	ScrutinizeTheRedundantAvgFunc = "ScrutinizeTheRedundantAvgFunc",
 	 	ScrutinizeTheRedundantGroupingFunc = "ScrutinizeTheRedundantGroupingFunc",
 	 	ScrutinizeTheRedundantMaxFunc = "ScrutinizeTheRedundantMaxFunc",
 	 	ScrutinizeTheRedundantSumFunc = "ScrutinizeTheRedundantSumFunc",
 	 	ScrutinizeTheReturnTypeFunc = "ScrutinizeTheReturnTypeFunc",
 	 	ScrutinizeTheTransactionCaching = "ScrutinizeTheTransactionCaching",

}

export enum TaskStatusEnumConstants {

   	 	Cancelled = "Cancelled",
 	 	Completed = "Completed",
 	 	Failed = "Failed",
 	 	Overdue = "Overdue",
 	 	Pending = "Pending",
 	 	Unclaimed = "Unclaimed",

}

export enum GroupAndReduceByConstants {

   	 	by_date = "by_date",
 	 	by_fk = "by_fk",
 	 	reduce_all = "reduce_all",

}

export enum FixedRoleEnumConstants {

   	 	App_Admin = "App_Admin",
 	 	App_Committer = "App_Committer",
 	 	App_Developer = "App_Developer",
 	 	App_Ops_Manager = "App_Ops_Manager",
 	 	Authenticated_User = "Authenticated_User",
 	 	Super_Admin = "Super_Admin",
 	 	Trial_Admin = "Trial_Admin",

}

export enum TemplateUploadStatusEnumConstants {

   	 	Completed = "Completed",
 	 	Failed = "Failed",
 	 	InProgress = "InProgress",

}

export enum HealthCheckupCategoryEnumConstants {

   	 	EXCEPTION_MAY_OCCUR = "EXCEPTION_MAY_OCCUR",
 	 	MISSING_FIELDS = "MISSING_FIELDS",
 	 	PERFORMANCE = "PERFORMANCE",

}

export enum FluentServiceUtilizerTypeConstants {

   	 	BackendActionMethod = "BackendActionMethod",
 	 	BasicFluentService = "BasicFluentService",
 	 	DevumStreamingService = "DevumStreamingService",
 	 	EntityPrePostProcessor = "EntityPrePostProcessor",
 	 	FluentServiceTask = "FluentServiceTask",
 	 	FormField = "FormField",
 	 	MaterializedViewBuilder = "MaterializedViewBuilder",
 	 	PostUserActionProcessor = "PostUserActionProcessor",
 	 	ReactiveFluentService = "ReactiveFluentService",
 	 	RestResponderCFSBridgeService = "RestResponderCFSBridgeService",
 	 	TimerFluentService = "TimerFluentService",

}

export enum HealthCheckupErrorCategoryEnumConstants {

   	 	DATABASE_OPERATIONS = "DATABASE_OPERATIONS",
 	 	DATA_TYPE_MISMATCH = "DATA_TYPE_MISMATCH",
 	 	HEAD_ELEMENT = "HEAD_ELEMENT",
 	 	MISSING_REQUIRED_FIELDS = "MISSING_REQUIRED_FIELDS",
 	 	WRONG_CACHING_APPLIED = "WRONG_CACHING_APPLIED",

}

export enum PageTypeEnumConstants {

   	 	Mobile = "Mobile",
 	 	Report = "Report",
 	 	Web = "Web",

}
