import { RtNone, RtOption, RtSome } from "../../../utils/option-helper";
import { AttributeGroup } from "../../constants/attribute-group-constant";
import { AttributeRegistryConstant } from "../../constants/attribute-registry-constants";
import { Attribute, AttributeType, MobiAttribute } from "../../public_api";
import { UsableAttributeValue } from "../core/attribute-instance";
import { IClassInstanceHelper } from "../core/class-instance-helper";
import { PermissionGroup, OperationTypes } from "../../models/enums";

export class PermissionModel implements IClassInstanceHelper {
  constructor(public group: PermissionGroup, public entityId: string, public operationType: OperationTypes) { }


  generateTSCode(): string {
    return ''
  }

  static parse(jsonString: string): PermissionModel {
    const configValue: PermissionModel = JSON.parse(jsonString);
    return new PermissionModel(configValue.group, configValue.entityId, configValue.operationType)
  }
}

export class PermissionAttribute extends Attribute<PermissionModel, PermissionModel> implements MobiAttribute<PermissionModel, PermissionModel> {

  constructor(public defaultPermissionModel: RtOption<PermissionModel> = RtNone()) {
    super(AttributeRegistryConstant.PERMISSION, AttributeGroup.CONFIGURATION, "object", AttributeType.CONFIGURATION, RtSome(() => this.getDefaultValue()));
  }

  buildMobiUsableValue(resolvedValue: RtOption<PermissionModel>): UsableAttributeValue<PermissionModel>[] {
    return this.buildUsableValue(resolvedValue);
  }
  generateTSCode(): string {
    return "";
  }
  clone(): Attribute<unknown, unknown> {
    return new PermissionAttribute();
  }
  parseModel(jsonValue: string): PermissionModel {
    return PermissionModel.parse(jsonValue);
  }
  buildUsableValue(resolvedValue: RtOption<PermissionModel>): UsableAttributeValue<PermissionModel>[] {
    if (resolvedValue.isDefined) {
      const usableAttributeValue = new UsableAttributeValue(this.name, resolvedValue.get, this.attributeType);
      return [usableAttributeValue];
    } else {
      return [];
    }
  }

  getDefaultValue(): any {
    if (this.defaultPermissionModel.isDefined) {
      return this.defaultPermissionModel.get;
    }
    return new PermissionModel(null, null, null);
  }

  // static asPermisssionAttribute(attributeInstances: AttributeInstance[]): FormFieldsAttribute {
  //   const attributeInstance = attributeInstances.find(attr => attr.name === AttributeRegistryConstant.PERMISSION);
  //   const formFieldConfig = attributeInstance?.value ? JSON.parse(attributeInstance.value) : null;
  //   const formFieldsAttribute = new PermissionAttribute();
  //   formFieldsAttribute.applyModel(formFieldConfig);
  //   return formFieldsAttribute;
  // }

}
