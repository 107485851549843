import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { TemplateDownloadHelper } from 'projects/den-web/src/app/helpers/template-download-helper';
import { HttpHeaderConstants } from '../../constants/constant';
import { HttpRequestTypes } from '../../constants/http-constants';
import { OfflineConstants } from '../../constants/mobile/mobile-offline-constant';
import { AdvancedFilter, GlobalBaseEntity } from '../../models/base.models';
import { SessionStorageProvider } from '../../providers/abstract/session-storage-provider';
import { IdentityGenerator } from '../../utils/identity-generator';
import { OrganizationDate } from '../../utils/org-date-helper';
import { AppEntity } from './../../models/base.models';


export class HttpRequestInterceptor {

    private static sessionStorageService: SessionStorageProvider;

    static checkForTrackableEntity(element: any, operation: string) {
        if (element instanceof Object) {
            if (this.isBaseEntity(element)) {
                this.prepareEntity(element, operation);
            } else {
                for (const i in element) {
                    if (element.hasOwnProperty(i)) {
                        this.checkForTrackableEntity(element[i], operation);
                    }
                }
            }
        }
    }

    static intercept(element: any, operation: string) {
        if (element && (operation === HttpRequestTypes.POST || operation === HttpRequestTypes.PUT)) {
            this.cleanUpEntity(element);
            if (this.isBaseEntity(element)) {
                this.prepareEntity(element, operation);
                return element;
            } else if (element.constructor === Array) {
                for (let i = 0; i < element.length; i++) {
                    this.checkForTrackableEntity(element[i], operation);
                }
            } else {
                for (const propertyName in element) {
                    if (element.hasOwnProperty(propertyName) && element[propertyName] instanceof Object) {
                        if (this.isBaseEntity(element[propertyName])) {
                            this.prepareEntity(element[propertyName], operation);
                        } else if (element[propertyName].constructor === Array) {
                            for (let i = 0; i < element[propertyName].length; i++) {
                                if (element[propertyName][i] instanceof Object) {
                                    if (this.isBaseEntity(element[propertyName][i])) {
                                        this.prepareEntity(element[propertyName][i], operation);
                                    } else {
                                        for (const property in element[propertyName][i]) {
                                            if (element[propertyName][i].hasOwnProperty(property) && element[propertyName][i][property] instanceof Object) {
                                                if (this.isBaseEntity(element[propertyName][i][property])) {
                                                    this.prepareEntity(element[propertyName][i][property], operation);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return element;
    }

    static cleanUpEntity(entity: any) {
        delete entity.$$hashKey;
        delete entity.$edit1;
        if (entity instanceof AdvancedFilter) {
            if (typeof entity.pageProperties.pageSize === 'string') {
                entity.pageProperties.pageSize = parseInt(entity.pageProperties.pageSize, 10);
            }
        }

    }
    // TODO verify Transaction entity is required or not
    static prepareEntity(element: any, operation: string) {
        if (operation === HttpRequestTypes.POST) {
            if (!this.isFromOffline(element)) {
                this.prepareEntityForPost(element);
            }
            if (this.isSlnEntity(element)) {
                element.identifier = IdentityGenerator.guid();
            }
        } else if (operation === HttpRequestTypes.PUT && !this.isFromOffline(element)) {
            this.prepareEntityForPut(element);
        }

        if (this.isTransactionalEntity(element)) {
            element.identifier = IdentityGenerator.guid();
            if (element.transactionDate) {
                element.transactionDate = OrganizationDate.convertDateToISOString(element.transactionDate)
            } else {
                element.transactionDate = OrganizationDate.currentDateToISOString();
            }
        } else if (this.isMutableTransactionalEntity(element)) {
            element.identifier = IdentityGenerator.guid();
            if (element.transactionDate) {
                element.transactionDate = OrganizationDate.convertDateToISOString(element.transactionDate)
            } else {
                element.transactionDate = OrganizationDate.currentDateToISOString();
            }
        }
    }

    static isFromOffline(entity: any) {
        const offlineAttributes = entity[OfflineConstants.ATTRIBUTES];
        return offlineAttributes ? offlineAttributes[OfflineConstants.IS_FROM_OFFLINE] : false;
    }

    static prepareEntityForPost(element: { id: string; transactionDate?: any; createdBy?: any; createDate?: any; modifiedDate?: any; siteCode?: string; appCode?: string; isGlobalEntity?: boolean }) {
        const userAccountId = this.sessionStorageService.getUserName();
        element.createdBy = userAccountId;
        element.createDate = element.createDate ? OrganizationDate.convertDateToISOString(element.createDate) : OrganizationDate.currentDateToISOString();
        element.modifiedDate = OrganizationDate.currentDateToISOString();


        if (element.hasOwnProperty('id') && (element.id === '' || element.id == null || element.id as unknown as number == 0)) {
            element.id = IdentityGenerator.guid();
        }

        if(element.isGlobalEntity){
            element.siteCode = null;
        }
        else if (!element.siteCode) {
            element.siteCode = this.sessionStorageService.getSiteCode();
        }
        if (element instanceof AppEntity) {
            element.appCode = this.sessionStorageService.getAppCode();
        }
    }

    static prepareEntityForPut(element: { identifier?: string; transactionDate?: any; createDate?: any; siteId?: any; modifiedDate?: any; appCode?: string; }) {
        element.createDate = OrganizationDate.convertDateToISOString(element.createDate)
        if (!element.siteId) {
            element.siteId = this.sessionStorageService.getSiteCode();
        }
        element.modifiedDate = OrganizationDate.currentDateToISOString();
        if (element instanceof AppEntity) {
            element.appCode = this.sessionStorageService.getAppCode();
        }
    }

    static isBaseEntity(entity: { hasOwnProperty: { (arg0: string): boolean; (arg0: string): boolean; (arg0: string): boolean; }; }) {
        // site_id check is removed from the type check; In few cases (solution specific entity), siteIds is available. instead of siteId;
        // should handle the case by introducing SlnAggregateRootEntity, SlnEntity and SlnTransactionEntity;
        // Need to get the clarification from BE on the same.
        return (entity instanceof GlobalBaseEntity) ||
            (entity.hasOwnProperty('createDate') && entity.hasOwnProperty('createdBy'));
        // (entity.hasOwnProperty('createDate') && entity.hasOwnProperty('createdBy') && entity.hasOwnProperty(HttpHeaderConstants.SITE_ID));
    }

    static isTransactionalEntity(entity: { identifier?: string; transactionDate?: any; hasOwnProperty?: any; }) {
        return (entity.hasOwnProperty('identifier') && entity.hasOwnProperty('transactionDate'));
    }

    static isMutableTransactionalEntity(entity: { identifier?: string; transactionDate?: any; hasOwnProperty?: any; }) {
        return (entity.hasOwnProperty('identifier') && entity.hasOwnProperty('transactionDate') && entity.hasOwnProperty('modifiedDate'));
    }

    static isSlnEntity(entity: { identifier?: string; hasOwnProperty?: any; }) {
        return (entity instanceof GlobalBaseEntity) && (entity.hasOwnProperty('identifier'));
    }

    static setHttpHeaders<T>(req: HttpRequest<T>, baseUrl: string, sessionStorageService: SessionStorageProvider): HttpRequest<T> {
        let headers = new HttpHeaders(), apiKey: string, appCode: string, siteCode: string, identifierType: string, domainURL: string, orgCode: string;
        this.sessionStorageService = sessionStorageService;
        if (req.responseType !== 'blob') {
            apiKey = this.sessionStorageService.getApiKey();
            siteCode = this.sessionStorageService.getSiteCode();
            appCode = this.sessionStorageService.getAppCode() || null;
            identifierType = this.sessionStorageService.getIdentifierType();
            domainURL = "www.devum.com";
            orgCode = this.sessionStorageService.getOrgCode();
            headers = headers.append(HttpHeaderConstants.IP_ADDRESS, sessionStorage.getItem(HttpHeaderConstants.IP_ADDRESS) || '');
            headers = headers.append(HttpHeaderConstants.GEO_LOCATION, sessionStorage.getItem(HttpHeaderConstants.GEO_LOCATION) || '');
        } else {
            headers = req.context.get(TemplateDownloadHelper.SKIP_HEADER_MODIFICATION)
                ? req.headers
                : headers.append('Cache-Control', 'no-cache');
        }
        if (apiKey && siteCode) {
            headers = headers.append(HttpHeaderConstants.SITE_ID, siteCode);
            headers = headers.append(HttpHeaderConstants.API_KEY, apiKey);
        } else if (apiKey) {
            headers = headers.append(HttpHeaderConstants.API_KEY, apiKey);
        } else if (siteCode) {
            headers = headers.append(HttpHeaderConstants.SITE_ID, siteCode);
        }
        if (appCode) {
            headers = headers.append(HttpHeaderConstants.APP_CODE, appCode);
        }
        if (identifierType) {
            headers = headers.append(HttpHeaderConstants.IDENTIFIER_TYPE, identifierType);
        }
        if (domainURL) {
            headers = headers.append(HttpHeaderConstants.DOMAIN_URL, domainURL);
        }
        if (orgCode) {
            headers = headers.append(HttpHeaderConstants.ORG_CODE, orgCode);
        }
        // If the apiKey is not available, then the user is not logged-in;
        // System will take it to LOGIN page again.
        if (!apiKey && req.responseType !== 'blob') {
            location.href = '';
            return req;
        }
        let url = '';

        // todo: url is appending twice.
        if (!req.url.match(/^(http:\/\/|https:\/\/|ws:\/\/|wss:\/\/)/)) {
            url = baseUrl + req.url;
        } else {
            url = req.url;
        }
        const body = this.intercept(req.body, req.method);
        const apiReq = req.clone({ url: url, headers: headers, body: body });

        return apiReq;
    }
}
