import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ComponentRef, Input, NgModule, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { DenPeekWindowComponent,DenPeekWindowModule } from '../den-peek-window/den-peek-window.component';
import { DenPeekWindowService, PeekWindowParams } from '../../services/control-assistant/den-peek-window.service';
import { ControlDescriptor } from '../../controls/core/control-descriptor';
import { ControlInstanceWrapper } from '../../controls/core/control-instance-drafts-models';
import { ControlAssistantMenuOption } from '../../controls/core/control-companion/control-assistant/control-assistant-menu-provider';


@Component({
  selector: 'den-w-control-assistant-context-menu',
  templateUrl: './control-assistant-context-menu.component.html',
  styleUrls: ['./control-assistant-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DenPeekWindowService]
})
export class ControlAssistantContextMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() controlInstance: ControlInstanceWrapper;
  @Input() controlDescriptor: ControlDescriptor;
  @Input() controlId: string;
  @Input() menuItems: ControlAssistantMenuOption[];
  @Input() subMenuItems: ControlAssistantMenuOption[];
  @Input() event: MouseEvent;
  @ViewChild(MatMenuTrigger, { static: false }) contextMenu: MatMenuTrigger;
  #componentRef: ComponentRef<DenPeekWindowComponent> | undefined;

  constructor(private denPeekWindowService: DenPeekWindowService) { }

  ngAfterViewInit(): void {
    this.contextMenu.openMenu();
  }

  ngOnInit(): void {
  }

  onMenuSelect(event: MouseEvent, menuItem: ControlAssistantMenuOption) {
    event.stopPropagation();
    if (menuItem.component.isDefined) {
      this.componentLoader(menuItem.component.get, event.clientX, event.clientY);
    } else if (menuItem.callBackFunc.isDefined) {
      const func = menuItem.callBackFunc.get;
      func(event);
    }
    this.contextMenu.closeMenu();
  }
  private componentLoader(componentRef: () => Promise<any>, positionX: number, positionY: number) {
    const data : PeekWindowParams = { controlInstanceId : this.controlInstance.id , controlId : this.controlId,  positionX, positionY };
    this.denPeekWindowService.openDenPeekWindow(componentRef, data);
  }

  ngOnDestroy(): void {
    this.#componentRef?.destroy();
  }
}

@NgModule({
  imports: [CommonModule, MatMenuModule, DenPeekWindowModule],
  declarations: [ControlAssistantContextMenuComponent],
  exports: [ControlAssistantContextMenuComponent],
})
export class ControlAssistantContextMenuModule {

}
