import { Injectable, OnDestroy } from "@angular/core";
import { EventService } from "../../../../../../../../src/lib/providers/event-service/event.service";
import { Observable, Subscription, lastValueFrom } from "rxjs";
import { ModalDataContainer } from "../../../../../../../../contants";
import { ConfirmationDialogComponent } from "../../../../../../../../src/lib/components-library/confirmation-dialog/confirmation-dialog.component";
import { ModalService } from "../../../../../../../../src/lib/modal-service/modalservice";
import { NavigationService } from "../../../../../../page-builder/devum-app/providers/navigation-service";
import { PageStateManager } from "../../../../../services/page/page-state-manager";
import { AppEventTopics, DataSourceParamEvent } from "../../app-event-topics";
import { ApplyActionCommand, PageEventActionStrategy } from "../../event-models";
import { NavigateAction, NavigateType } from "./navigation-action-models";

@Injectable()
export class NavigationProviderStrategy implements PageEventActionStrategy<NavigateAction>, OnDestroy {
  private queryParamSubscription: Subscription[] = [];
  private navigationParamsMap: Map<string, { value: any, isRequired: boolean }> = new Map<string, { value: any, isRequired: boolean }>();

  constructor(private navigationService: NavigationService, private modalService: ModalService, private eventService: EventService) {
  }

  applyActions(cmds: ApplyActionCommand<NavigateAction>[]): Promise<void> {
    const cmd$ = new Observable<void>((observer) => {
      if (PageStateManager.isInRuntimeMode()) {
        cmds.map((cmd) => {
          this.navigateToPage(cmd);
        })
      } else {
        //DO Nothing as navigation is not allowed in design mode
      }
      observer.next();
    })

    return lastValueFrom(cmd$);
  }
  navigateToPage(cmd: ApplyActionCommand<NavigateAction>) {
    const popupAction = cmd.action.actionConfirmationDialog;
    if (popupAction?.isConfirmed) {
      const dialogCmd: ModalDataContainer = {
        message: popupAction.message, headerMessage: popupAction.header,
        confirmBtnLabel: popupAction.successButtonCaption, cancelBtnLabel: popupAction.cancelButtonCaption
      };
      this.modalService.invoke(ConfirmationDialogComponent, dialogCmd).subscribe(isExecute => {
        if (isExecute) {
          const navAction = cmd.action;
          this.executeNavigationCmd(cmd);
        }
      });
    } else {
      this.executeNavigationCmd(cmd);
    }

  }

  private executeNavigationCmd(cmd: ApplyActionCommand<NavigateAction>) {
    const navAction = cmd.action;
    this.navigationParamsMap = new Map()
    cmd.action.queryParams?.forEach((value, param) => {
      this.navigationParamsMap.set(param, { isRequired: value.isRequired, value: null });
    })
    if (this.navigationParamsMap.size > 0) {
      this.subscribeForNavigationParams(cmd, navAction);
      setTimeout(() => {
        if (this.isAllParamsAreResolved()) {
          this.navigateToInternalOrExternalPage(navAction, cmd);
        }
      }, 100);
    } else {
      this.navigateToInternalOrExternalPage(navAction, cmd);
    }
  }

  private navigateToInternalOrExternalPage(navAction: NavigateAction, cmd: ApplyActionCommand<NavigateAction>) {
    if (navAction.navigateType === NavigateType.PAGE) {
      this.navigateToInternalPage(cmd);
    }
    else if (navAction.navigateType === NavigateType.EXTERNAL_LINK) {
      this.navigateToExternalPage(cmd);
    }
    else {
      throw new Error(`Navigation type not supported: ${navAction.navigateType}`);
    }
  }

  private subscribeForNavigationParams(cmd: ApplyActionCommand<NavigateAction>, navAction: NavigateAction) {
    cmd.action.queryParams.forEach((_, param) => {
      const topic = AppEventTopics.buildDataSourceParamTopic(`navigationAction`, param);
      this.queryParamSubscription.push(this.eventService.subscribe(topic, (data: DataSourceParamEvent) => {
        const dsResultValue = data.value.find(dsrv => dsrv.fieldName === data.binding.fieldName);
        this.navigationParamsMap.set(data.binding.param, { isRequired: _.isRequired, value: dsResultValue?.value });
      }, true))
    })
  }
  private isAllParamsAreResolved() {
    let allParamsResolved = true;
    this.navigationParamsMap.forEach((value, _key) => {
      if (value.isRequired && !value) {
        allParamsResolved = false;
      }
    });
    return allParamsResolved;
  }


  private navigateToInternalPage(cmd: ApplyActionCommand<NavigateAction>) {
    const navAction: NavigateAction = cmd.action;
    const params: Map<string, string | number> = this.constructParams(navAction);
    const navigateTo = `/app/${navAction.navigateTo}`;
    this.navigationService.navigate(navigateTo, params);
  }

  private navigateToExternalPage(cmd: ApplyActionCommand<NavigateAction>) {
    const navAction: NavigateAction = cmd.action;
    const params: Map<string, string | number> = this.constructParams(navAction);
    const urlWithParams = params.size > 0 ? navAction.navigateTo + this.getQueryParams(params) : navAction.navigateTo;
    window.open(urlWithParams, "_blank");
  }

  private constructParams(navAction: NavigateAction) {
    const hasQueryParams = navAction.queryParams && navAction.queryParams.size > 0;
    const params: Map<string, string | number> = new Map();
    if (hasQueryParams) {
      console.error("TODO: Vijay: NavigationProviderStrategy: Resolve param data from global producers along with control data. Currently only considering current control data");
      navAction.queryParams.forEach((_, key) => {
        const fieldValue = this.navigationParamsMap.get(key);
        params.set(key, fieldValue?.value);
      });
    }
    return params;
  }

  getQueryParams(params: Map<string, string | number>): string {
    return params ? '?' + [...params].map(([key, value]) => `${key}=${value}`).join('&') : '';
  }
  ngOnDestroy(): void {
    this.queryParamSubscription.forEach(param => param.unsubscribe());
  }
}
