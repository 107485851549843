import { DataSourcesModeller } from "projects/den-web/src/app/page-builder/components/pb-data-source/data-source-result-wrapper";
import { ControlInstanceWrapper } from "../page-builder/controls/core/control-instance-drafts-models";
import { DataSourceServiceInstanceWrapper } from "../page-builder/controls/core/data-source-draft-models";

export class DataSourceTypes {
  static FORMS = "Forms";
  static MView = "MView";
  static Process = "Process";
  static formView = "formView";
  static UserTaskAssignments = "UserTaskAssignments"
}

export class DatasourceSelector {
  existingControlDataSources: DataSourceServiceInstanceWrapper[];
  dataSourcesModeller: DataSourcesModeller;
  ctrlInstance: ControlInstanceWrapper
  constructor() { }
}