import { ElementRef, Renderer2 } from "@angular/core";
import { RtOption } from "../../../utils/option-helper";
import { AttributeType } from "../../attributes/core/attribute";
import { UsableAttributeValue } from "../../attributes/core/attribute-instance";
import { CSSPropertyNameConstants } from "projects/den-core/page-builder";

export class AngularContainerControlHelper {
  private static SELECTOR_ATTRIBUTES = ['height', 'width', 'min-height', 'min-width', 'max-height', 'max-width',
    'margin-left', 'margin-right', 'margin-top', 'margin-bottom', 'position', 'left', 'right', 'top', 'bottom', 'grid-area'];
  private static OVERRIDE_SELECTOR_ATTRIBUTES = ['margin-left', 'margin-right', 'margin-top', 'margin-bottom', 'position', 'left', 'right', 'top', 'bottom', 'width', 'height'];


  static updateHeightIfAutoHeightIsEnabled(el: ElementRef, renderer: Renderer2, cssAttributeValues: UsableAttributeValue<unknown>[]) {
    const autoHeightAttribute = cssAttributeValues.find(attr => attr.name === 'bufferHeight');
    if (autoHeightAttribute && typeof autoHeightAttribute.value === 'string' && autoHeightAttribute.value !== 'null') {
      const updatedDynamicHeightInPx = this.setDynamicHeight(el, autoHeightAttribute.value);
      const heightAttribute = cssAttributeValues.find(attr => attr.name === 'height');
      if (heightAttribute) {
        heightAttribute.value = updatedDynamicHeightInPx;
      } else {
        const heightInstance = new UsableAttributeValue<unknown>('height', updatedDynamicHeightInPx, AttributeType.CSS);
        cssAttributeValues.push(heightInstance);
      }
    } else if (autoHeightAttribute && autoHeightAttribute.value === 'null') {
      if (el.nativeElement.children.length > 0) {
        // renderer.removeStyle(el.nativeElement.firstChild, 'overflow-x');
        // renderer.removeStyle(el.nativeElement.firstChild, 'height');//Height not binding for container control.
      }
      // renderer.removeStyle(el.nativeElement, 'height');
      const heightAttribute = cssAttributeValues.find(attr => attr.name === 'height');
      if (heightAttribute) {
        el.nativeElement.style.height = heightAttribute.value;
        el.nativeElement.firstChild.style.height = '100%';
      }
      cssAttributeValues = cssAttributeValues.filter(attr => attr.name !== 'bufferHeight');
    }
  }

  static applyStylesToSelector(el: ElementRef, renderer: Renderer2, attributeValues: UsableAttributeValue<unknown>[], aditionalAttributes: string[] = []) {
    if (!attributeValues) { return; }
    // remove attributes from selector;
    attributeValues.forEach(sa => renderer.removeStyle(el.nativeElement, sa.name));
    const allSelectorAttributes = [...this.SELECTOR_ATTRIBUTES, ...aditionalAttributes];
    // add attributes to selector;
    attributeValues.forEach(attr => {
      if (allSelectorAttributes.indexOf(attr.name) > -1) {
        renderer.setStyle(el.nativeElement, attr.name, attr.value);
      }
    });
  }

  static updateStylesForSelf(styles: RtOption<Object>, cssAttributeValues: UsableAttributeValue<unknown>[]): Object {
    if (styles.isDefined) {
      return this.updateCssAttributeValue(styles.get, cssAttributeValues);
    } else {
      const copyAttributesValues = JSON.parse(JSON.stringify(cssAttributeValues));
      const styleArray = copyAttributesValues.filter(a => {
        if (this.OVERRIDE_SELECTOR_ATTRIBUTES.indexOf(a.name) > -1) { return; }
        return a;
      });
      return this.convertToJsonObj(styleArray);
    }
  }

  private static updateCssAttributeValue(styles: Object, cssAttributeValues: UsableAttributeValue<unknown>[]) {
    cssAttributeValues.forEach((attr: UsableAttributeValue<string>) => {
      if (this.OVERRIDE_SELECTOR_ATTRIBUTES.indexOf(attr.name) > -1) { return; }
      if (this.shouldRemoveStyle(attr.value)) {
        delete styles[attr.name];
      } else {
        styles[attr.name] = attr.value;
      }
    });
    AngularContainerControlHelper.updateBgImageForLinearAndRadialColors(styles);
    return styles;
  }

  private static shouldRemoveStyle(value: any): boolean {
    return value === null || value === 'null';
  }


  public static convertToJsonObj(attributes: UsableAttributeValue<unknown>[]) {
    const obj = {};
    attributes.forEach((attr: UsableAttributeValue<unknown>) => {
      // const keyValue = attr.split(':');
      obj[attr.name] = attr.value;
    });
    AngularContainerControlHelper.updateBgImageForLinearAndRadialColors(obj);
    return obj;
  }


  private static updateBgImageForLinearAndRadialColors(obj: {}) {
    const bgImage = obj[CSSPropertyNameConstants.BACKGROUND_IMAGE];
    if (!bgImage || (bgImage && !bgImage.startsWith('url('))) {
      const bgColor = obj[CSSPropertyNameConstants.BACKGROUND_COLOR];
      if (bgColor && (bgColor.startsWith('linear-gradient') || bgColor.startsWith('radial-gradient'))) {
        obj[CSSPropertyNameConstants.BACKGROUND_IMAGE] = bgColor;
        obj[CSSPropertyNameConstants.BACKGROUND_COLOR] = 'transparent';
      }
    }
  }

  private static setDynamicHeight(el: ElementRef<any>, bufferHeight: string): string {
    const updatedHeightValueInPX = this.calculateDynamicHeight(el, bufferHeight);
    el.nativeElement.firstChild.style.height = updatedHeightValueInPX;
    el.nativeElement.firstChild.style.overflowX = 'scroll';
    return updatedHeightValueInPX;
  }

  private static calculateDynamicHeight(el: ElementRef<any>, bufferHeight: string): any {
    return (window.innerHeight - el.nativeElement.getBoundingClientRect().top - parseInt(bufferHeight, 10)) + 'px';
  }
}
