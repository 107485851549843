export enum SchemaControlType {
  TEXT_BOX = "TEXT_BOX",
  FOREIGN_KEY = "FOREIGN_KEY",
  DATE_TIME_PICKER = "DATE_TIME_PICKER",
  MULTI_DATE_PICKER = "MULTI_DATE_PICKER",
  DATE_PICKER = "DATE_PICKER",
  FILE_UPLOAD = "FILE_UPLOAD",
  STATIC_SELECT = "STATIC_SELECT",
  TIME_PICKER = "TIME_PICKER",
  RADIO_BUTTON_LIST = "RADIO_BUTTON_LIST",
  CHECK_BOX_LIST = "CHECK_BOX_LIST",
  CHECK_BOX = "CHECK_BOX",
  GEO_FENCE = "GEO_FENCE",
  GEO_LOCATION = "GEO_LOCATION",
  LOCATION3D = "LOCATION3D",
  SCALE = "SCALE",
  ORIENTATION = "ORIENTATION",
  COLOR_PICKER = "COLOR_PICKER",
  TEXT_AREA = "TEXT_AREA",
  RICH_TEXT = "RICH_TEXT",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  API = "API",
  RESOURCE_BROWSER = 'RESOURCE_BROWSER',
  RESOURCE_BROWSER_3D = 'RESOURCE_BROWSER_3D',
  FORM_ACTION_BUTTON = "FORM_ACTION_BUTTON",
  SEARCHABLE_CONTROL = "SEARCHABLE_CONTROL",
  SIGNATURE_PAD = "SIGNATURE_PAD",
  YEAR_MONTH_PICKER = "YEAR_MONTH_PICKER"
}
export enum SchemaSubControlType{
  RADIO_BUTTONS = "RADIO_BUTTONDS",
  BUTTONS = "BUTTONS",
  BUTTONS_LIST = "BUTTONS_LIST"
}
