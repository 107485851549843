<rt-modal-dialog>

  <div class="modal-header rt-mb-0">
    <h3 class="sub-title rt-mb-0" mat-dialog-title>Please select the parent control to {{data.childData.controlName}}
    </h3>
  </div>

  <div class="modal-content">

    <div class="row rt-mb-20" *ngIf="enableEnvironmentControl">
      <div class="row col-md-12">
        <mat-card class="rt-border rt-mr-5 rt-mb-5 rt-py-10 rt-px-15 rt-cursor-pointer rt-show"
          [ngClass]="{'rt-selected' : environmentControl.isSelected}"
          *ngFor="let environmentControl of environmentControls"
          (click)="onEnvironmentControlSelect(environmentControl)">
          <div class="rt-mr-5 rt-relative rt-top-5 rt-text-center">
            <img src="../../../../../../../assets/svg/threejs/palette/{{environmentControl.icon}}.svg"
              class="rt-dimension-18 rt-bold-2x">
          </div>
          <div>
            {{environmentControl.controlName}}
          </div>
        </mat-card>
      </div>
    </div>

    <div class="row rt-mx-15" *ngIf="enableRealTimeORFixedLayer">
      Please select layer type to proceed
    </div>

    <div class="row rt-mx-15" *ngIf="enableRealTimeORFixedLayer">


      <div class="col-md-5 rt-py-10 rt-mr-20 rt-border rt-cursor-pointer" [ngClass]="{'rt-selected' : isRealTime}"
        (click)="onClose(true)">
        Real-time (Moving object like vehicles, people)
      </div>

      <div class="col-md-5 rt-py-10 rt-border rt-cursor-pointer" [ngClass]="{'rt-selected' : isRealTime==false}"
        (click)="onClose(false)">
        Fixed (Fixed object like sensors,locations)
      </div>

    </div>

    <div class="row rt-mx-15" *ngIf="isRealTime==false">
      <mat-form-field>
        <mat-select [(ngModel)]="schemaField" placeholder="Select SchemaField">
          <mat-option *ngFor="let schemaField of schemaFields" [value]='schemaField'>{{schemaField.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>


  <div class="modal-actions">
    <div class="row rt-justify-end">
      <button mat-raised-button class="rt-mr-5" color="primary" [mat-dialog-close]="false"
        (click)="onProceedClick()">PROCEED
      </button>
      <button mat-raised-button class="rt-mr-5" [mat-dialog-close]="false">Cancel</button>

    </div>
  </div>

</rt-modal-dialog>