import { ViewContainerRef } from "@angular/core";
import { IDsResult } from "../../../data-source/data-source-result-entities";
import { ControlInstanceWrapper } from "../control-instance-drafts-models";
import { IControl } from "../control-model";

export class CompanionRequestCommand<D extends IDsResult, C extends IControl<D>> {
  constructor(public control: C, public viewContainerRef: ViewContainerRef, public data: any) {

  }
}

export class CompanionDataChangeCommand {
  constructor(public controlInstance: ControlInstanceWrapper, public data: any) {

  }
}

export class DestroyCompanionCommand<D extends IDsResult, C extends IControl<D>> {
  constructor(public control: C) {

  }
}
