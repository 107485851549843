
import { RtOption } from "../../../../../utils/option-helper";
import { DsResultArray, DsResult } from "../../../../../page-builder/data-source/data-source-result-entities";
import { SharedHelper } from "../../../../../page-builder/helpers/shared-helper";

export class SortingManager {

    public sort(dataSource: RtOption<DsResultArray>, sortField: RtOption<string>): RtOption<DsResultArray> {
        if (!dataSource || !sortField) {
            throw new Error('Invalid input for sort() method');
        }
        const sortedData = dataSource?.get.results.sort(
            this.sortArrayByPropertyName(sortField.get));

        return new RtOption(new DsResultArray(dataSource.get.dsName, sortedData, sortedData.length, dataSource.get.fks));
    }

    private sortArrayByPropertyName(key: string | number, order = 'asc') {

        const result = (a: DsResult, b: DsResult) => {

            const dsResultValue1 = a.data.find(r => r.fieldName === key);
            const dsResultValue2 = b.data.find(r => r.fieldName === key);

            if (!dsResultValue1?.value ||
                !dsResultValue2?.value) {
                return 0;
            }

            let varA = SharedHelper.isIsoDate(dsResultValue1.value) || SharedHelper.isDate((dsResultValue1.value)) ?
                new Date(dsResultValue1.value) : dsResultValue1.value;
            let varB = SharedHelper.isIsoDate(dsResultValue2.value) || SharedHelper.isDate((dsResultValue2.value)) ?
                new Date(dsResultValue2.value) : dsResultValue2.value;

            varA = (typeof dsResultValue1.value === 'string') ?
                dsResultValue1.value.toUpperCase() : dsResultValue1.value;
            varB = (typeof dsResultValue2.value === 'string') ?
                dsResultValue2.value.toUpperCase() : dsResultValue2.value;

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ?
                    (comparison * -1) : comparison
            );
        };
        return result;
    }
}
