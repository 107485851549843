import { Inject, Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { DynamicReaderApi } from '../../base-apis/dynamic-reader-api';
import { ConfigService } from '../../config/config-service';
import { BaseDependencyProperty, DependencyResolvedData, DynamicDependencyProperty } from '../../models/base.models';


@Injectable()
export class FormDependencyService {

    baseUrl: string;

    constructor(
        @Inject(ConfigService) configService: ConfigService,
    ) {
        this.baseUrl = configService.getConfig().serverURL;
    }

    checkDependenciesAndResolveForDynamicEntity<T>(entities: T, dependencies: DynamicDependencyProperty[], parentEntityName?: string): Observable<T> {
        return new Observable(observer => {
            if (!dependencies || dependencies.length === 0) {
                observer.next(entities);
                return;
            } else {
                this.getDependenciesDataForDynamicEntity(dependencies, parentEntityName).subscribe({
                    next: (dependenciesData) => {
                        this.resolveDependencies(entities, dependenciesData);
                        observer.next(entities);
                    }, error: () => observer.next(entities)
                });
            }
        });
    }

    private resolveDependencies<T>(entities: T, dependenciesData: DependencyResolvedData<T>[]) {
        if (entities instanceof Array) {
            entities.forEach(entity => {
                this.recursivelyPopulateDependenciesData(entity, dependenciesData);
            });
        } else {
            this.recursivelyPopulateDependenciesData(entities, dependenciesData);
        }

        return entities;
    }

    private isDependency<T>(i: string, dependenciesData: Array<DependencyResolvedData<T>>) {
        return dependenciesData.find((item) => (item.key === i));
    }

    private recursivelyPopulateDependenciesData<T>(entity: any, dependenciesData: Array<DependencyResolvedData<T>>) {
        for (const i in entity) {
            if (entity.hasOwnProperty(i)) {
                if (this.isDependency(i, dependenciesData)) {
                    if (entity[i] instanceof Array) {
                        const propertyArrayData: T[] = [];
                        entity[i].forEach((nestedEntity: any) => {
                            propertyArrayData.push(this.getValueForDependency(dependenciesData, i, nestedEntity)[0]);
                        });
                        entity[this.getObjectPropertyName(i)] = propertyArrayData;
                    } else {
                        entity[this.getObjectPropertyName(i)] = this.getValueForDependency(dependenciesData, i, entity[i])[0];
                    }
                } else if (entity[i] instanceof Array) {
                    entity[i].forEach((nestedEntity: any) => {
                        this.recursivelyPopulateDependenciesData(nestedEntity, dependenciesData);
                    });
                } else if (entity[i] instanceof Object) {
                    this.recursivelyPopulateDependenciesData(entity[i], dependenciesData);
                }
            }
        }
    }

    private getDependenciesDataForDynamicEntity<T>(dependencies: DynamicDependencyProperty[], parentEntityName: string): Observable<DependencyResolvedData<T>[]> {
        return new Observable(observer => {
            const uniqueDependencies: DynamicDependencyProperty[] = this.getUniqueListBy(dependencies, 'schemaName');
            const dependenciesData: DependencyResolvedData<T>[] = [];
            this.getUniqueDependenciesData<T>(uniqueDependencies, parentEntityName)
                .subscribe((resolvedDependent: DependencyResolvedData<T>[]) => {
                    dependencies.forEach(dep => {
                        dependenciesData.push({
                            key: dep.idPropertyName,
                            value: resolvedDependent.find(res => res.key === dep.schemaName).value || [],
                            api: dep.api
                        });
                    });
                    observer.next(dependenciesData);
                });
        });
    }

    private getUniqueListBy<T extends { [key: string]: any; }>(arr: T[], key: string) {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    }

    private getUniqueDependenciesData<T>(dependencies: BaseDependencyProperty[] | DynamicDependencyProperty[], parentEntityName: string): Observable<DependencyResolvedData<T>[]> {
        return new Observable(observer => {
            const dependencyData: Observable<any>[] = new Array();
            dependencies.forEach((dep: BaseDependencyProperty) => {
                dependencyData.push(this.getDependencyResolver(dep));
            });
            zip(...dependencyData).subscribe((data: DependencyResolvedData<T>[]) => observer.next(data));
        });
    }

    private getDependencyResolver<T>(dep: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        if (dep instanceof DynamicDependencyProperty) {
            if (dep.api instanceof DynamicReaderApi) {
                return this.getDynamicReaderApiData(dep);
            } else {
                throw new Error(`Dependency API is not defined`);
            }
        } else {
            throw new Error(`Invalid dependency property type, should be of BaseDependencyProperty`);
        }
    }

    private getDynamicReaderApiData<T>(dep: DynamicDependencyProperty): Observable<DependencyResolvedData<T>> {
        return new Observable(observer => {
            if (dep.api instanceof DynamicReaderApi) {
                // DynamicFormsReaderApi.
                dep.api.getCollectionList<T>(dep.schemaName, [])
                    .subscribe({
                        next: (d: T[]) => observer.next({ key: dep.schemaName, value: d, api: dep.api }),
                        error: () => observer.next({ key: dep.schemaName, value: [], api: dep.api })
                    });
            }
        });
    }



    protected getValueForDependency<T extends { name?: string; id?: string; }>(dependenciesData: Array<DependencyResolvedData<T>>, key: string, value: any) {
        if (!dependenciesData || !key) { throw new Error('Invalid input parameters.'); }

        if (!value) { return []; }

        const res = dependenciesData.find((item) => (item.key === key)).value || [];

        if (res.length === 0) {
            return [];
        }
        return res.filter(item => item.id == value);
    }

    protected getObjectPropertyName(propertyName: string): string {
        return propertyName.substring(0, propertyName.length - 2);
    }
}
