
import { UomBinding } from "../../../uom/uom-binding";
import { RtSome } from "../../../utils/option-helper";
import { DsResultValue } from "../../data-source/data-source-result-entities";

/** Instance */
export enum BindingType {
  FORM = 1,
  KPI = 2,
}

export enum RenderType {
  SINGLE = 1,
  MULTI = 2
}

export enum OrientationType {
  HORIZONTAL = 1,
  VERTICAL = 2
}


export class ControlPropertyDefinitionValue {
  constructor(
    public controlAttributeName: string,   // Label --> value //Image --> image
    public value?: any,  // Default Value ---> Name
    public dsPropertyName?: string | string[], // ---> Bounded property from Data Source e.g. EmployeeName  //Image --> ImageUrl
    public uomBinding?: UomBinding, // if the field is of type 'UOM', then uomBinding binding may contain the conversion strategy
    public displayBindingType?: DisplayBindingType,
    public cssTemplate?: string,
    //public dsName?: string // Data Source name --> Vehicles, Trips etc
  ) { }

  static parse(controlPropertyDefinitionValue: ControlPropertyDefinitionValue) {
    const uomBinding = controlPropertyDefinitionValue.uomBinding ? UomBinding.parse(controlPropertyDefinitionValue.uomBinding) : undefined;
    const reConstructedObj = new ControlPropertyDefinitionValue(controlPropertyDefinitionValue.controlAttributeName,
      controlPropertyDefinitionValue.value,
      controlPropertyDefinitionValue.dsPropertyName,
      uomBinding,
      controlPropertyDefinitionValue.displayBindingType,
      controlPropertyDefinitionValue.cssTemplate
    );
    return reConstructedObj;
  }
}

export class DataSourceHelper {
  static getValueForBinding(controlPropertyDefinitionValue: ControlPropertyDefinitionValue, dsResultValue: DsResultValue) {
    const displayBindingType = RtSome(controlPropertyDefinitionValue.displayBindingType).map(d => d).getOrElseV2(DisplayBindingType.VALUE);
    if (displayBindingType === DisplayBindingType.VALUE) {
      return dsResultValue.referenceData.isDefined ? dsResultValue.referenceData.get : dsResultValue.value;
    } else if (displayBindingType === DisplayBindingType.UOM) {
      return dsResultValue.uom.isDefined ? dsResultValue.uom.get : "";
    } else if (displayBindingType === DisplayBindingType.DISPLAY_VALUE) {
      return dsResultValue.displayValue;
    }
  }
}

export enum DisplayBindingType {
  VALUE = "value",
  DISPLAY_VALUE = "display_value",
  UOM = "uom",
}
