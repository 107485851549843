export class CSSPropertyNameConstants {
  static WIDTH = "width";
  static HEIGHT = "height";
  static MIN_WIDTH = "min-width";
  static MIN_HEIGHT = "min-height";
  static MAX_WIDTH = "max-width";
  static Max_HEIGHT = "max-height";
  static GRID_TEMPLATE_COLUMNS = "grid-template-columns";
  static GRID_TEMPLATE_ROWS = "grid-template-rows";
  static GRID_TEMPLATE_AREAS = "grid-template-areas";
  static COLUMN_GAP = "column-gap";
  static ROW_GAP = "row-gap";
  static GRID_AREA = "grid-area";
  static OPACITY = "opacity";
  static FLOAT = "float";
  static OVERFLOW = "overflow";
  static TEXT_OVERFLOW = "text-overflow";
  static WHITE_SPACE = "white-space";
  static BOX_SHADOW = "box-shadow";
  static BORDER_RADIUS = "border-radius";
  static ZINDEX = "z-index";
  static CURSOR = "cursor";
  static POSITION = "position";
  static FONT_SIZE = "font-size";
  static DISPLAY = "display";
  static COLOR = "color";
  static BACKGROUND_IMAGE = "background-image";
  static BACKGROUND_COLOR = "background-color";
  static BACKGROUND_REPEAT = "background-repeat";
  static BACKGROUND_POSITION = "background-position";
  static BACKGROUND_SIZE = "background-size";
  static BACKGROUND_ATTACHMENT = "background-attachment";
  static BACKGROUND_CLIP = "background-clip";
  static BACKGROUND_BLEND_MODE = "background-blend-mode";
  static MARGIN_LEFT = "margin-left";
  static MARGIN_RIGHT = "margin-right";
  static MARGIN_TOP = "margin-top";
  static MARGIN_BOTTOM = "margin-bottom";
  static POINTER_EVENTS = "pointer-events";

  static PADDING_LEFT = "padding-left";
  static PADDING_RIGHT = "padding-right";
  static PADDING_TOP = "padding-top";
  static PADDING_BOTTOM = "padding-bottom";

  static BORDER_LEFT = "border-left";
  static BORDER_RIGHT = "border-right";
  static BORDER_TOP = "border-top";
  static BORDER_BOTTOM = "border-bottom";
  static MENU_BORDER_RADIUS = 'Menu Border Radius';

  static CHART_DIMENSION = 'Chart Dimension';
  static TRANSFORM = 'transform';
  static readonly ION_BACKGROUND_COLOR = '--ion-background-color';
  static readonly ION_TEXT_COLOR = '--ion-text-color';
  static FILL = 'fill';
  static BADGESIZE = 'badge size';
  static BADGEFONTSIZE = 'badge font size';
  static ORDER = 'order';
  static ICON_TOGGLE_COLOR = 'toggle-color'
  static ICON_SIZE = 'icon-size';
  static ICON_FILL_COLOR = 'icon-fill-color';
  static FLEX_DIRECTION = 'flex-direction';
  static FLEX = 'flex';
  static ROW = 'row';
  static COLUMN = 'column';
  static TOOLTIP_WIDTH = 'tooltip width';
  static ICON_FONT_COLOR = 'icon-font-color';
  static PROGRESS_LINE_COLOR = 'progress-line-color';
  static CHECK_BOX_COLOR = 'Check Box Color';
  static GROUP_HEADER_WIDTH = 'group-header-width';
  static STEP_ICON_WIDTH = 'Step Icon Width';
  static STEP_LABEL_WIDTH = 'Step Label Width';
  static STEP_NODE_FONT_COLOR = 'Step Node Font Color'
}
