import { Injector } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { CrudType } from '../constants/constant';
import { LogService } from '../log-service/log.service';
import { AdvancedFilter, DependencyProperty, GlobalBaseEntity } from '../models/base.models';
import { ForeignKeyEntityResolverProvider } from '../providers/abstract/foreign-key-entity-Provider';
import { HttpClientProvider } from '../providers/abstract/http-client-provider';
import { LoaderServiceProvider } from '../providers/abstract/loader-service-provider';
import { LocalStorageProvider } from '../providers/abstract/local-storage-provider';
import { HttpGetRequestOptions } from '../providers/http/angular-client-provider';
import { PageRefreshService } from '../providers/page-refresh/page-refresh.service';
import { BaseApiHelper } from './base-api-helper';

export interface IDevumBaseReaderApi {
  get<T>(url: string, options: HttpGetRequestOptions): Observable<T>;
  getById<T extends GlobalBaseEntity>(id: string, dependencies?: Array<DependencyProperty>): Observable<T>;
  getList<T>(dependencies?: Array<DependencyProperty>, includeDisabled?: boolean): Observable<T>;
  getPreciseList<T>(includeDisabled?: boolean): Observable<T>;
  getWithUrl<T>(url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
  getListWithUrl<T>(url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
  getByFilter<T>(advancedFilter: AdvancedFilter, dependencies?: Array<DependencyProperty>): Observable<T>;
  getByFilterWithUrl<T>(advancedFilter: AdvancedFilter, url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
  getByCustomFilter<T>(data: any, dependencies?: Array<DependencyProperty>): Observable<T>;
  getByCustomFilterWithUrl<T>(data: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
  searchByLabel<T>(data: { label: string; }, dependencies?: Array<DependencyProperty>): Observable<T[]>;
}

export class DenBaseReaderApi implements IDevumBaseReaderApi {

  isCacheable: boolean;
  protected baseUrl: string;
  protected baseUrlForCache: string;
  http: HttpClientProvider;
  public loaderService: LoaderServiceProvider;
  public indexedDbService: LocalStorageProvider;
  public dependencyService: ForeignKeyEntityResolverProvider;
  public pageRefresh = this.injector.get(PageRefreshService);
  private logService = this.injector.get(LogService);
  public deleteSelectionPublisher = new Subject();
  entity = new Subject();
  public name: string;
  private readonly INCLUDE_DISABLED_PARAMS_OPTIONS = { params: { includeDisabled: true } } 

  constructor(public injector: Injector, url: string, entityName: string, isCacheable: boolean) {
    this.baseUrl = url + entityName;
    this.name = entityName;
    this.baseUrlForCache = url;
    this.isCacheable = isCacheable;
    this.initProviders(url);
  }

  private initProviders(Url: string) {
    this.http = this.injector.get(HttpClientProvider);
    this.loaderService = this.injector.get(LoaderServiceProvider);
    this.indexedDbService = this.injector.get(LocalStorageProvider);
    this.dependencyService = this.injector.get(ForeignKeyEntityResolverProvider);
    this.dependencyService.baseUrl = Url;
  }

  getEntity() {
    return this.entity.asObservable();
  }

  getSelectedAndDeleteEntityAsObservable() {
    return this.deleteSelectionPublisher.asObservable();
  }

  getList<T>(dependencies?: Array<DependencyProperty>, includeDisabled?: boolean): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      if (this.isCacheable) {
        this.loaderService.incrementLoaderCount();
        return this.indexedDbService.get(this.name).subscribe({
          next: (data: any) => {
            this.loaderService.decrementLoaderCount();
            if (data) {
              this.getResolvedData(JSON.parse(JSON.stringify(data)), observer, dependencies, includeDisabled);
            } else {
              this.loaderService.incrementLoaderCount();
              this.get<T>('', this.INCLUDE_DISABLED_PARAMS_OPTIONS )
                .subscribe({
                  next: (data: T) => {
                    this.indexedDbService.set(this.name, JSON.parse(JSON.stringify(data))).subscribe();
                    this.getResolvedData(data, observer, dependencies, includeDisabled);
                    this.loaderService.decrementLoaderCount();
                  },
                  error: (error) => {
                    observer.error(error);
                    this.loaderService.decrementLoaderCount();
                  }
                });
            }
          },
          error: (error) => {
            observer.error(error);
            this.loaderService.decrementLoaderCount();
          }
        });
      } else {
        this.loaderService.incrementLoaderCount();
        return this.get<T>('', this.INCLUDE_DISABLED_PARAMS_OPTIONS )
          .subscribe({
            next:
              (data: T) => {
                this.getResolvedData(data, observer, dependencies, includeDisabled);
                this.loaderService.decrementLoaderCount();
              },
            error: (error) => {
              observer.error(error);
              this.loaderService.decrementLoaderCount();
            }
          });
      }
    });
  }

  getPreciseList<T>(includeDisabled: boolean = false): Observable<T> {
    //TODO: nag to revisit
    //@ts-ignore
    return new Observable((observer: Observer<T>) => {
      if (this.isCacheable) {
        this.loaderService.incrementLoaderCount();
        return this.indexedDbService.get(this.name + '/precis').subscribe({
          next: (data: any) => {
            this.loaderService.decrementLoaderCount();
            if (data) {
              if (!includeDisabled) {
                data = BaseApiHelper.filterIsDisabledItems(JSON.parse(JSON.stringify(data)), false);
              }
              observer.next(JSON.parse(JSON.stringify(data)));
            } else {
              this.loaderService.incrementLoaderCount();
              this.get<T>('/precis', { params: { includeDisabled } })
                .subscribe({
                  next: (data: T) => {
                    this.loaderService.decrementLoaderCount();
                    this.indexedDbService.set(this.name + '/precis', JSON.parse(JSON.stringify(data))).subscribe();
                    if (!includeDisabled) {
                      data = BaseApiHelper.filterIsDisabledItems(data, false);
                    }
                    observer.next(data);
                  },
                  error: (error: ErrorEvent) => {
                    observer.error(error);
                    this.loaderService.decrementLoaderCount();
                  }
                });
            }
          }
        });
      } else {
        this.loaderService.incrementLoaderCount();
        this.get<T>('/precis', { params: { includeDisabled } })
          .subscribe({
            next: (data: T) => {
              this.loaderService.decrementLoaderCount();
              if (!includeDisabled) {
                data = BaseApiHelper.filterIsDisabledItems(data, false);
              }
              observer.next(data);
            }, error: (error: ErrorEvent) => {
              observer.error(error);
              this.loaderService.decrementLoaderCount();
            }
          });
      }
    });
  }

  getWithUrl<T>(url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      this.loaderService.incrementLoaderCount();
      return this.get<T>(url)
        .subscribe({
          next: (data: T) => {
            this.loaderService.decrementLoaderCount();
            this.loaderService.incrementLoaderCount();
            this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
              (data: T) => {
                observer.next(data);
                this.loaderService.decrementLoaderCount();
              }
            );
          }, error: (error: ErrorEvent) => {
            observer.error(error);
            this.loaderService.decrementLoaderCount();
          }
        });
    });
  }

  getByFilter<T>(advancedFilter: AdvancedFilter, dependencies?: Array<DependencyProperty>): Observable<T> {
    return this.getByFilterWithUrl(advancedFilter, '/filter', dependencies);
  }

  getByFilterWithUrl<T>(advancedFilter: AdvancedFilter, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
    return this.getByAnyFilterType(advancedFilter, url, dependencies);
  }

  getByCustomFilter<T>(data: any, dependencies?: Array<DependencyProperty>): Observable<T> {
    return this.getByAnyFilterType(data, '/filter', dependencies);
  }

  getByCustomFilterWithUrl<T>(data: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
    return this.getByAnyFilterType(data, url, dependencies);
  }

  getByAnyFilterType<T>(payload: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      this.loaderService.incrementLoaderCount();
      return this.post<T>(url, payload)
        .subscribe({
          next: (data: T) => {
            this.loaderService.decrementLoaderCount();
            this.loaderService.incrementLoaderCount();
            this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
              (dependencyData: T) => {
                observer.next(dependencyData);
                this.loaderService.decrementLoaderCount();
                // if (isMultipleDelete === true) {
                //     this.publishOnDeleteMultipleEntities(isMultipleDelete, payload.ids);
                // }
              });
            // observer.next(data);
          },
          error: (error: ErrorEvent) => {
            observer.error(error);
            this.loaderService.decrementLoaderCount();
          }
        });
    });
  }



  get<T>(url: string, options?: HttpGetRequestOptions): Observable<T> {
    const constructedUrl = this.baseUrl + this.constructUrl(url);
    return this.http.get<T>(constructedUrl, options);
  }

  getListWithUrl<T>(url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      this.loaderService.incrementLoaderCount();
      return this.get<T>(url)
        .subscribe({
          next: (data: T) => {
            this.loaderService.decrementLoaderCount();
            this.loaderService.incrementLoaderCount();
            this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
              (data: T) => {
                observer.next(data);
                this.loaderService.decrementLoaderCount();
              }
            );
          },
          error: (error: ErrorEvent) => {
            observer.error(error);
            this.loaderService.decrementLoaderCount();
          }
        });
    });
  }

  getById<T extends GlobalBaseEntity>(id: string, dependencies?: Array<DependencyProperty>): Observable<T> {
    //TODO: nag to revisit
    //@ts-ignore
    return new Observable((observer: Observer<T>) => {
      if (typeof id === 'string') {
        id = id.replace('/', '');
        // name = parseInt(name, 10);
      }
      if (this.isCacheable) {
        this.loaderService.incrementLoaderCount();
        return this.indexedDbService.get(this.name).subscribe({
          next: (response: any) => {
            this.loaderService.decrementLoaderCount();
            if (response) {
              let data = JSON.parse(JSON.stringify(response));
              const result = data.find((item: { id: string; }) => item.id === id);
              if (result) {
                this.loaderService.incrementLoaderCount();
                this.dependencyService.checkDependenciesAndResolve(result, dependencies).subscribe(
                  (dependencyData: T) => {
                    observer.next(dependencyData);
                    this.loaderService.decrementLoaderCount();
                  }
                );
              } else {
                this.getByIdFromDbForNonTransactionalEntity<T>(id, observer, dependencies);
              }
            } else {
              this.getByIdFromDbForNonTransactionalEntity<T>(id, observer, dependencies);
            }
          }
        });
      } else {
        this.getByIdFromDbForTransactionalEntity<T>(id, observer, dependencies);
      }
    });
  }

  searchByLabel<T>(data: { label: string; }, dependencies?: Array<DependencyProperty>): Observable<T[]> {
    return this.getByAnyFilterType(data, 'searchByLabel', dependencies);
  }

  removeEntityFromCache() {
    return new Promise((resolve, reject) => {
      let getListUrl = this.name;
      let getPreciseListUrl = this.name + '/precis';
      return Promise.all([this.checkAndRemoveFromCache(getListUrl), this.checkAndRemoveFromCache(getPreciseListUrl)]).then(resolve, reject);
    });
  }

  constructUrl(url: string | any) {
    if (!url) {
      return '';
    }
    if (!url.startsWith('/')) {
      return '/'.concat(url);
    } else {
      return url;
    }
  }

  private checkAndRemoveFromCache(name: string) {
    return new Promise((resolve, reject) => {
      this.indexedDbService.get(name)
        .subscribe({
          next: (data) => {
            if (data) {
              this.indexedDbService.remove(name).subscribe();
            }
            resolve('success');
          },
          error: (error: ErrorEvent) => reject(error.message)
        });
    });
  }

  private getResolvedData<T>(data: T, observer: Observer<T>, dependencies?: Array<DependencyProperty>, includeDisabled?: boolean) {
    if (!includeDisabled) {
      data = BaseApiHelper.filterIsDisabledItems<T>(data, false);
    } else {
      observer.next(data);
    }
    this.loaderService.incrementLoaderCount();
    this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
      (data: T) => {
        observer.next(data);
        this.loaderService.decrementLoaderCount();
      }
    );
  }

  private getByIdFromDbForTransactionalEntity<T>(id: string, observer: Observer<T>, dependencies?: Array<DependencyProperty>) {
    this.loaderService.incrementLoaderCount();
    this.get<T[]>('/' + id, this.INCLUDE_DISABLED_PARAMS_OPTIONS )
      .subscribe({
        next: (data: T[]) => {
          this.loaderService.decrementLoaderCount();
          if (data) {
            this.loaderService.incrementLoaderCount();
            this.dependencyService.checkDependenciesAndResolveForSingleEntity(data, dependencies).subscribe(
              (response: any) => {
                observer.next(response);
                this.loaderService.decrementLoaderCount();
              }
            );
          }
        },
        error: (error: ErrorEvent) => {
          observer.error(error);
          this.loaderService.decrementLoaderCount();
        }
      });
  }

  private getByIdFromDbForNonTransactionalEntity<T extends GlobalBaseEntity & { id: string }>(id: string, observer: Observer<T>, dependencies?: Array<DependencyProperty>) {
    this.loaderService.incrementLoaderCount();
    this.get<T[]>('', this.INCLUDE_DISABLED_PARAMS_OPTIONS )
      .subscribe({
        next: (data: T[]) => {
          this.loaderService.decrementLoaderCount();
          if (this.isCacheable) {
            this.indexedDbService.set(this.name, JSON.parse(JSON.stringify(data))).subscribe();
          }
          const dataById = data.find(entity => entity.id === id);
          if (dataById) {
            this.loaderService.incrementLoaderCount();
            this.dependencyService.checkDependenciesAndResolve(dataById, dependencies).subscribe(
              (response: T) => {
                observer.next(response);
                this.loaderService.decrementLoaderCount();
              }
            );
          }
        },
        error: (error: ErrorEvent) => {
          observer.error(error);
          this.loaderService.decrementLoaderCount();
        }
      });
  }

  private post<T>(url: string, entity: T | T[]): Observable<any> {
    const constructedUrl = this.baseUrl + this.constructUrl(url);

    return new Observable((observer) => {
      this.http.post<T>(constructedUrl, entity).subscribe(res => {
        // if (this.isCacheable) {
        //     this.removeEntityFromCache();
        // }
        observer.next(res);
      }, (error: ErrorEvent) => observer.error(error));
    });
  }

  public validate(data: any) {
    let message: boolean;
    if (data.results && data.results.length > 0) {
      message = true;
    } else {
      message = false;
    }
    return message;
  }

  // closeFlexContent() {
  //     document.body.classList.remove('flex-content');
  //     document.body.classList.remove('minified');
  //     document.body.classList.remove('flex-dock-left');
  // }

  notifyObservers(responseData?: any) {
    // TODO: How the pageRefresh can be handled. -- Prem
    this.pageRefresh.setActionInstance(null, CrudType.REFRESH);
    this.entity.next(responseData);
    console.warn('TO DO Implement notify Observers in individual module base-api.ts');
    this.logService.warn('TO DO Implement notify Observers in individual module base-api.ts',null)
  }
}