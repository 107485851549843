import { Injectable, OnDestroy } from "@angular/core";
import { SubSink } from "subsink";
import { DataSourceServiceInstanceWithFilter, ReceivedParamBuffer } from "../../../data-source/data-source";
import { DsResultArray, IDsResult } from "../../../data-source/data-source-result-entities";
import { DataSourceServiceInstanceWrapper } from "../data-source-draft-models";
import { DataSourceExecutorServiceHelper } from "./data-source-instance-executor.service";
import { PaginationAndFilterData } from "./pagination-and-filter/pagination-and-filter.service";
import { DataSourceParamEvent } from "../event/app-event-topics";
import { RtNone, RtOption, RtSome } from "../../../../utils/option-helper";
import { FilterOperator } from "../../../../../../base-models";

type ControlDataSourceData = {
  dataSources: DataSourceServiceInstanceWrapper[], dataSourceParamBuffers: Map<string, ReceivedParamBuffer[]>, resultClassName: string,
  hasDataSourceControlEvents: boolean, paginationAndFilterData: Map<string, PaginationAndFilterData>
};

@Injectable()
export class DataSourceParamBufferService implements OnDestroy {

  private subscriptions = new SubSink();
  private controlInstanceParamBuffer: Map<string, ControlDataSourceData> = new Map();
  private oldParamBuffer: Map<string, ReceivedParamBuffer[]> = new Map();

  constructor() { }

  setControlDataSourceData(controlInstanceId: string, resultClassName: string, dataSources: DataSourceServiceInstanceWrapper[]) {
    const controlDataSourceData: ControlDataSourceData = {
      dataSources: dataSources, dataSourceParamBuffers: new Map(),
      resultClassName: resultClassName, hasDataSourceControlEvents: false, paginationAndFilterData: new Map()
    };
    this.updateDefaultPagination(controlInstanceId, dataSources, controlDataSourceData);
    this.controlInstanceParamBuffer.set(controlInstanceId, controlDataSourceData);
  }
  private updateDefaultPagination(controlInstanceId: string, dataSources: DataSourceServiceInstanceWrapper[], controlDataSourceData: ControlDataSourceData) {
    dataSources.forEach(ds => {
      if (ds.method?.defaultPaginationSettings) {
        const paginationAndFilterData: PaginationAndFilterData = { controlInstanceId: controlInstanceId, dsName: ds.dsName, pagination: ds.method.defaultPaginationSettings }
        controlDataSourceData.paginationAndFilterData.set(ds.dsName, paginationAndFilterData);
      }
    })
  }
  getControlInstanceParamBuffer(controlInstanceId: string) {
    const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    return controlDataSourceData?.dataSourceParamBuffers;
  }

  getDataSourceInstances(controlInstanceId: string): DataSourceServiceInstanceWrapper[] {
    const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    return controlDataSourceData.dataSources;
  }

  getDataSourceInstanceByDsName(controlInstanceId: string, dsName: string): RtOption<DataSourceServiceInstanceWrapper> {
    const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    const dataSourceServiceInstance = controlDataSourceData.dataSources.find(ds => ds.dsName === dsName);
    return RtSome(dataSourceServiceInstance);
  }
  getSecondaryDataSourceInstances(controlInstanceId: string): DataSourceServiceInstanceWrapper[] {
    const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    const secondaryDataSourceInstances = controlDataSourceData.dataSources.filter(dataSource => !dataSource.isMaster);
    return secondaryDataSourceInstances;
  }

  getResultClassName(controlInstanceId: string): string {
    const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    return controlDataSourceData.resultClassName;
  }

  getDataSourceParamBuffers(controlInstanceId: string, dsName: string): ReceivedParamBuffer[] {
    const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    const paramBuffer = controlDataSourceData.dataSourceParamBuffers.get(dsName);
    return paramBuffer;
  }
  getOldDataSourceParamBuffers(controlInstanceId: string, dsName: string): ReceivedParamBuffer[] {
    return this.oldParamBuffer.get(`${controlInstanceId}_${dsName}`);
  }
  setOldDataSourceParamBuffers(controlInstanceId: string, dsName: string, receivedParamBuffer: ReceivedParamBuffer[]): void {
    this.oldParamBuffer.set(`${controlInstanceId}_${dsName}`, receivedParamBuffer);
  }
  setDataSourcePaginationAndFilterData(controlInstanceId: string, dsName: string, paginationAndFilterData: PaginationAndFilterData) {
    const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    controlDataSourceData.paginationAndFilterData.set(dsName, paginationAndFilterData);
  }
  getDataSourcePaginationAndFilterData(controlInstanceId: string, dsName: string): PaginationAndFilterData {
    const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    const paginationAndFilterData = controlDataSourceData.paginationAndFilterData.get(dsName);
    return paginationAndFilterData;
  }

  private hasAnyDataSourceControlEvents(controlInstanceId: string) {
    const controlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    return controlDataSourceData.hasDataSourceControlEvents;
  }

  hasNoDataSourceControlEvents(controlInstanceId: string) {
    return !this.hasAnyDataSourceControlEvents(controlInstanceId);
  }

  updateParamBufferUsingDataSourceParamEventAndReturnControlInstanceId(dataSourceParamEvent: DataSourceParamEvent): RtOption<string> {
    const dataSourceName = dataSourceParamEvent.binding.datasourceName;
    const controlDataSourceDataOpt: RtOption<[string, ControlDataSourceData]> = this.getControlDataSourceDataByDataSourceName(dataSourceName);
    if (controlDataSourceDataOpt.isDefined) {
      const controlDataSourceData = controlDataSourceDataOpt.get[1];
      const paramFromBuffer = controlDataSourceData.dataSourceParamBuffers.get(dataSourceName)?.find(p => p.param.name === dataSourceParamEvent.binding.param);
      if (paramFromBuffer) {
        if (paramFromBuffer.param.isListParam || paramFromBuffer.param.operator === FilterOperator.IN || paramFromBuffer.param.isArray) {
          const value = dataSourceParamEvent.value?.map(dsResultValue => dsResultValue.originalValue).filter(v => v !== null);
          if (value.length > 0) {
            paramFromBuffer.applyValue(RtSome(value), dataSourceParamEvent.paramSelectorOpt);
          } else {
            paramFromBuffer.applyValue(RtSome(paramFromBuffer.param.isOptional ? null : value), dataSourceParamEvent.paramSelectorOpt);
          }
        } else {
          paramFromBuffer.applyValue(RtSome(dataSourceParamEvent.value[0]?.originalValue));
        }
      }
      return RtSome(controlDataSourceDataOpt.get[0]);
    } else {
      return RtNone();
    }
  }
  resetParamBufferOnControlDestroy(dsName: string, paramName: string) {
    const controlDataSourceDataOpt: RtOption<[string, ControlDataSourceData]> = this.getControlDataSourceDataByDataSourceName(dsName);
    if (controlDataSourceDataOpt.isDefined) {
      const controlDataSourceData = controlDataSourceDataOpt.get[1];
      const paramFromBuffer = controlDataSourceData.dataSourceParamBuffers.get(dsName)?.find(p => p.param.name === paramName);
      if (paramFromBuffer) {
        paramFromBuffer.applyValue(RtNone());
      }
    }
  }

  getControlDataSourceDataByDataSourceName(dataSourceName: string): RtOption<[string, ControlDataSourceData]> {
    const result: [string, ControlDataSourceData] = [...this.controlInstanceParamBuffer].find(([controlInstanceId, controlDataSourceData]: [string, ControlDataSourceData]) =>
      controlDataSourceData.dataSources.some((ds) => ds.dsName === dataSourceName));
    if (RtSome(result).isDefined) {
      return RtSome(result);
    } else {
      return RtNone();
    }
  }

  areAllParamsFulfilled(controlInstanceId: string, dsName: string): boolean {
    const controlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    const params: ReceivedParamBuffer[] = controlDataSourceData.dataSourceParamBuffers.get(dsName);
    return params.every(p => p.isFulfilled());
  }

  canUpdateSecondaryDataSourceParams(controlInstanceId: string, dsr: DataSourceServiceInstanceWithFilter, result: RtOption<IDsResult>) {
    const isTheCurrentDataSourceMaster = dsr.datasourceInstance.isMaster;
    const secondaryDataSourceInstances = this.getSecondaryDataSourceInstances(controlInstanceId);
    return isTheCurrentDataSourceMaster && secondaryDataSourceInstances.length > 0 && result.isDefined;
  }

  getMasterJoinKeyValues(controlInstanceId: string, dsr: DataSourceServiceInstanceWithFilter, result: RtOption<IDsResult>) {
    const masterJoinKey = dsr.datasourceInstance.joinKey;
    if (masterJoinKey) {
      const controlDataSourceData: ControlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
      if (controlDataSourceData.resultClassName === DsResultArray.name) {
        const masterJoinKeyDsResultValues = result.map(m => (m as DsResultArray).results.map(dsResult => dsResult.data.find(dsResultValue => dsResultValue.fieldName === masterJoinKey))).getOrElse(() => []);
        const masterKeyValues = masterJoinKeyDsResultValues
          .filter(dsResultValue => dsResultValue !== undefined)        // Remove the records where the master join key is not present.
          .map(dsResultValue => dsResultValue.value)
          .filter(v => v !== null)                                     // Remove the records where the master join key value is null.
        return [...new Set(masterKeyValues)];                          // Remove the duplicate values.
      }
    }
    return [];
  }

  createParamBufferUsingBoundParams(parentData: RtOption<any>, controlInstanceId: string): void {

    this.applyParamsIfSharedByParent(parentData, controlInstanceId);
    const controlDataSourceData = this.controlInstanceParamBuffer.get(controlInstanceId);
    const dataSourceInstances = this.getDataSourceInstances(controlInstanceId);
    dataSourceInstances.forEach(dsInstance => {
      const boundParams = dsInstance?.method?.params || [];
      const receivedParamBuff = boundParams.map(p => new ReceivedParamBuffer(p));
      controlDataSourceData.dataSourceParamBuffers.set(dsInstance.dsName, receivedParamBuff);
    })
  }

  resolveParamsValuesWithDataSourceAttributeValues(controlInstanceId: string, siteCode: string): void {
    const dataSourceInstances = this.getDataSourceInstances(controlInstanceId);
    // TODO nag should enable below code to pass default values for startTs and EndTs when those params are optional
    // dataSourceInstances.forEach(selDS => {
    //   const dateRangeOpt = DataSourceExecutorServiceHelper.getTheDateRangeForPeriod(PeriodConstant.DAILY);
    //   if (dateRangeOpt.isDefined) {
    //     const dateRange = dateRangeOpt.get;
    //     selDS?.method?.params.forEach(p => {
    //       if (p.name && (p.name.toLowerCase() === 'startts' || p.name.toLowerCase() === 'endts') && p.isOptional) {
    //         p.defaultValue = dateRange[p.name.toLowerCase()];
    //       }
    //     });
    //   }
    //   const siteParam = selDS?.method?.params.find(p => p.name && (p.name.toLowerCase() === 'siteid') && p.isOptional);
    //   if (siteParam) {
    //     siteParam.defaultValue = siteCode;
    //   }
    // })
  }

  applyParamsIfSharedByParent(parentData: RtOption<any>, controlInstanceId: string): void {
    const dataSourceInstances = this.getDataSourceInstances(controlInstanceId);
    dataSourceInstances.forEach(selDS => {
      DataSourceExecutorServiceHelper.applyParamsIfSharedByParent(parentData, selDS);
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

