import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'rt-modal-dialog',
  templateUrl: './modal-dialog.component.html',
})
export class ModalDialogComponent implements OnInit, OnDestroy {
  @Input() modalMaxMinButtons?: boolean;
  private readonly maxIcon = 'svg-icons-maximize';
  private readonly minIcon = 'svg-icons-minimize';
  private readonly maxDialogClass = 'maximize-modal-dialog-wrapper';

  constructor(public elementRef: ElementRef) { }

  ngOnInit() {
    if (this.modalMaxMinButtons === undefined) {
      this.modalMaxMinButtons = false;
    } else {
      this.modalMaxMinButtons = true;
    }
  }

  modalMaximize(event) {
    const dialogOverlayPaneList = document.querySelectorAll(".cdk-overlay-pane.modal-dialog-wrapper");
    if (dialogOverlayPaneList && dialogOverlayPaneList.length > 0) {
      dialogOverlayPaneList.forEach(node => {
        const maximizeIconEnableDialog = node.querySelector('rt-modal-dialog .enable-minmax-icon');
        // if maximize
        if ((this.elementRef.nativeElement?.parentElement.isEqualNode(maximizeIconEnableDialog?.parentElement?.parentElement) && (event.target.className.indexOf(this.maxIcon) > 0))) {
          event.target.classList.remove(this.maxIcon); // removed max icon
          event.target.classList.add(this.minIcon);   //  toggle with min icon
          node.classList.add(this.maxDialogClass);
        } else if ((this.elementRef.nativeElement?.parentElement.isEqualNode(maximizeIconEnableDialog?.parentElement?.parentElement))) {
          event.target.classList.remove(this.minIcon); // removed min icon
          event.target.classList.add(this.maxIcon); //  toggle with max icon
          node.classList.remove(this.maxDialogClass);
        }
      });
    }
  }

  ngOnDestroy() { }
}
