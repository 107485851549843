import { Injectable } from "@angular/core";
import { PageService } from "../../../services/page-service";
import { DsResult, DsResultArray, IDsResult } from "../../../data-source/data-source-result-entities";
import { DsResultStateManager } from "./ds-result/ds-result-state-manager";
import { FilteringManager } from "../list-view-control/filtering-manager/filtering-manager";
import { AbstractDSStateManager } from "./abstract-ds-state-manager";
import { DsResultArrayStateManager } from "./ds-result-array/ds-result-array-state-manager";
import { EventService } from "../../../../providers/event-service/event.service";
import { CategoryEnum, LogData, LogService } from "../../../../log-service/log.service";


@Injectable()
export class DsStateManagerResolver {

    constructor(private eventService: EventService, private pageService: PageService, private filteringManager: FilteringManager, private logService: LogService) {
    }

    resolveDsStateManager<D extends IDsResult>(dsResultType: string): AbstractDSStateManager<D> {

        if (dsResultType === DsResultArray.name) {
            return new DsResultArrayStateManager(this.eventService, this.pageService,
                this.filteringManager, this.logService) as unknown as AbstractDSStateManager<D>;
        } else if (dsResultType === DsResult.name) {
            return new DsResultStateManager(this.eventService, this.pageService,
                this.filteringManager, this.logService) as unknown as AbstractDSStateManager<D>;
        }
        this.logService.warn(`DsStateManagerResolver: resolveDsStateManager: DSResultType: ${dsResultType} did not match either DsResultArray or DsResult`, null, CategoryEnum.PageBuilder, new LogData(null,this.pageService.pageName))
        throw new Error(`DsStateManagerResolver: resolveDsStateManager: DSResultType: ${dsResultType} did not match either DsResultArray or DsResult`);
    }
}
