import { GlobalPositionStrategy, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef, Injectable } from "@angular/core";
import { DenPeekWindowComponent } from "../../../../../src/lib/page-builder/control-assistant/den-peek-window/den-peek-window.component";
import { EventStore } from "../../controls/core/event/event-store";
import { RtSome } from "../../../utils/option-helper";

export type PeekWindowParams = {
  controlId : string;
  controlInstanceId : string;
  positionX: number;
  positionY: number;
  width?: number;
}

@Injectable()
export class DenPeekWindowService {
  private overlayRef: OverlayRef;
  _eventStore: EventStore;

  constructor(private overlay: Overlay) {
  }

  openDenPeekWindow(componentRef: () => Promise<any>, data : PeekWindowParams) {
    this.overlayRef?.detach();
    const defaultWidth = (document.documentElement.clientWidth * 70) / 100;
    if (data.width && (data.positionX + data.width >= window.innerWidth)) {
      data.positionX = data.positionX - data.width;
    }
    let positionStrategy: GlobalPositionStrategy;
    if (RtSome(data.positionX).isDefined && RtSome(data.positionY).isDefined) {
      positionStrategy = this.overlay.position().global().left(data.positionX + 'px').top(data.positionY + 'px');
    } else {
      positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    }

    this.overlayRef = this.overlay.create({
      height: '400px',
      width: data.width ? `${data.width}px` : `${defaultWidth}px`,
      positionStrategy: positionStrategy
    });

    const denPeekWindow = new ComponentPortal(DenPeekWindowComponent);
    const denpeekWindowComponentRef: ComponentRef<DenPeekWindowComponent> = this.overlayRef.attach(denPeekWindow);
    denpeekWindowComponentRef.instance.componentRef = componentRef;
    denpeekWindowComponentRef.instance.positionX = data.positionX;
    denpeekWindowComponentRef.instance.positionY = data.positionY;
    denpeekWindowComponentRef.instance.controlInstanceId = data.controlInstanceId;
    denpeekWindowComponentRef.instance.controlId = data.controlId;
    const closeSub = denpeekWindowComponentRef.instance.onClose.subscribe(() => {
      denpeekWindowComponentRef.destroy();
      this.overlayRef?.detach();
      closeSub?.unsubscribe();
    })
    return denpeekWindowComponentRef;
  }

  closeDenPeekWindow() {
    this.overlayRef?.detach();
  }

}
