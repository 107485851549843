import { AdvancedFilter, FilterOperator, FilterProperty, SortingField, SortingOperator, SortingProperty } from '../../../../models/base.models';
import { throwError } from 'rxjs';
import { BoundParam, DataSourceMethodInstance, ReceivedParamBuffer } from '../../../data-source/data-source';
import { OrganizationDate } from '../../../../utils/org-date-helper';


export class DataSourceQueryHelper {

  static defaultPageNumber = 1;
  static defaultPageSize = 10;
  static pageSizeOptions: number[] = [5, 10, 15];

  static isDateTime(dateTime: string) {
    const _regExp = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/;
    return _regExp.test(dateTime);
  }

  static updateFilterPropertiesBasedOnParamType(method: DataSourceMethodInstance, paramBuffer: ReceivedParamBuffer[], filter: AdvancedFilter): AdvancedFilter {
    method.params.forEach(p => {
      const buffer = paramBuffer.find(buf => buf.param.name !== null && buf.param.name === p.name);
      const previousObj = this.loadPerviousFilterProperties(filter, p);
      if (this.isValueList(buffer) || p.operator === FilterOperator.IN) {
        filter = this.constructValueListFilterProperties(previousObj, buffer, filter, p);
      } else if (this.isDateRange(buffer)) {
        filter = this.constructDateRangeFilterProperties(buffer, filter, p);
      } else if (this.isDateTimeValue(buffer)) {
        filter = this.constructDateTimeFilterProperties(buffer, filter, p);
      } else if (this.isValue(buffer)) {
        filter = this.constructValue1FilterProperties(previousObj, filter, p, buffer);
      } else if (!buffer) {
        filter = this.constructDefaultValueFilterProperties(filter, p);
      } else {
        throwError('Not supported filter format');
      }
    });
    return filter;
  }

  static isValueList(buffer) {
    return buffer && buffer.getValue() && Array.isArray(buffer.getValue());
  }
  static isValue(buffer) {
    return buffer && buffer.getValue() && !Array.isArray(buffer.getValue());
  }
  static isDateTimeValue(buffer) {
    return buffer && buffer.getValue() && DataSourceQueryHelper.isDateTime(buffer.getValue());
  }
  static isDateRange(buffer) {
    return buffer && buffer.getValue() && typeof buffer.getValue() === 'object' && buffer.getValue()['startDateTime'] && buffer.getValue()['endDateTime'];
  }

  private static constructDefaultValueFilterProperties(filter: AdvancedFilter, p: BoundParam) {
    filter.filterProperties.push(new FilterProperty(p.name, p.operator || FilterOperator.EQUALS, p ? p.defaultValue : null));
    return filter;
  }

  private static constructValue1FilterProperties(previousObj: FilterProperty, filter: AdvancedFilter,
    p: BoundParam, pBuffer: ReceivedParamBuffer) {
    if (!previousObj && !Array.isArray(pBuffer.getValue())) {
      // REVIEW prem pBuffer.getValue() is returning Some.of(value) so updated like pBuffer.getValue().value
      filter.filterProperties.push(new FilterProperty(p.name, p.operator || FilterOperator.EQUALS, pBuffer.getValue()));
    }
    return filter;
  }

  private static loadPerviousFilterProperties(filter: AdvancedFilter, p: BoundParam) {
    const previousObj = filter.filterProperties.find(filterProp => filterProp.propertyName === p.name);
    return previousObj;
  }

  private static constructValueListFilterProperties(previousObj: FilterProperty, pBuffer: ReceivedParamBuffer,
    filter: AdvancedFilter, p: BoundParam) {
    if (previousObj) {
      if (Array.isArray(pBuffer.getValue())) {
        this.updateFilterPropertyWithAdditionalParamValues(previousObj, pBuffer.getValue());
      } else if (p.operator === FilterOperator.IN && !Array.isArray(pBuffer.getValue())) {
        this.updateFilterPropertyWithAdditionalParamValues(previousObj, [pBuffer.getValue()]);
      }
    }
    else {
      if (Array.isArray(pBuffer.getValue())) {
        // REVIEW prem pBuffer.getValue() is returning Some.of(value) so updated like pBuffer.getValue().value
        filter.filterProperties.push(new FilterProperty(p.name, p.operator || FilterOperator.IN, null, null, pBuffer ? pBuffer.getValue().map(v => v) : null));
      } else if (p.operator === FilterOperator.IN && pBuffer.getValue() && !Array.isArray(pBuffer.getValue())) {
        filter.filterProperties.push(new FilterProperty(p.name, p.operator || FilterOperator.IN, null, null, pBuffer ? [pBuffer.getValue()] : []));
      }
    }

    return filter;
  }

  private static updateFilterPropertyWithAdditionalParamValues(filterProperty: FilterProperty, values: any[]) {
    if (filterProperty.valueList) {
      values.forEach(v => {
        if (!filterProperty.valueList.includes(v)) {
          filterProperty.valueList.push(v);
        }
      });
    }
    else {
      filterProperty.valueList = values.map(v => v);
    }
  }

  private static constructDateRangeFilterProperties(pBuffer: ReceivedParamBuffer, filter: AdvancedFilter, p: BoundParam) {
    if (pBuffer.getValue().startDateTime && pBuffer.getValue().endDateTime) {
      filter.filterProperties.push(new FilterProperty(p.name, FilterOperator.RANGE,
        pBuffer.getValue().startDateTime, pBuffer.getValue().endDateTime));
    }
    return filter;
  }

  private static constructDateTimeFilterProperties(pBuffer: ReceivedParamBuffer, filter: AdvancedFilter, p: BoundParam) {
    const sitezone = OrganizationDate.getSiteTimezone()
    const startTime: string = OrganizationDate.getStartOrEndOfTime('day', true, true, pBuffer.getValue(), sitezone)
    const endTime: string = OrganizationDate.getStartOrEndOfTime('day', false, true, pBuffer.getValue(), sitezone)
    filter.filterProperties.push(new FilterProperty(p.name, FilterOperator.RANGE, startTime, endTime));
    return filter;
  }

  static isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  static loadSortingProperties(filter: AdvancedFilter): AdvancedFilter {
    filter.sortingProperties = new SortingProperty([new SortingField('createDate', 1)]);
    filter.sortingProperties.sortingOperator = SortingOperator.DESCENDING_ORDER;
    return filter;
  }

  static groupByAndReturnObject(objectArray: any[], property: string): object {
    const groups = objectArray.reduce((acc: { [x: string]: any[]; }, obj: { [x: string]: any; }) => {
      const key = obj[property] || "NA";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    return groups;
  }

  static isDate(str: string): boolean {
    const res = (/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(str));
    return res;
  }

  private static isIsoDate(str: string): boolean {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) { return false; }
    return OrganizationDate.convertDateToISOString(str) === str;
  }

  static sortArrayByPropertyName(key: string | number, order = 'asc') {
    const result = (a, b) => {
      if (!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      let varA = this.isIsoDate(a[key]) || this.isDate((a[key])) ?
        new Date(a[key]) : a[key];
      let varB = this.isIsoDate(b[key]) || this.isDate((b[key])) ?
        new Date(b[key]) : b[key];

      varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ?
          (comparison * -1) : comparison
      );
    };
    return result;
  }
}

