import { BorderType } from "../../constants/property-panel-models";

export class BorderStyleConstant {
    public static readonly STYLES = ['none', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset'];
    public static readonly WIDTH_LIST = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    public static readonly FONT_FAMILIES = ['Arial, sans-serif', 'Helvetica, sans-serif',
        'Gill Sans, sans-serif', 'Lucida, sans-serif',
        'Helvetica Narrow, sans-serif', 'sans-serif',
        'Times, serif', 'Times New Roman, serif', 'Palatino, serif',
        'Bookman, serif', 'New Century Schoolbook, serif', 'serif',
        'Andale Mono, monospace', 'Courier New, monospace',
        'Courier, monospace', 'Lucidatypewriter, monospace',
        'Fixed, monospace', 'monospace', 'Comic Sans, Comic Sans MS, cursive',
        'Zapf Chancery, cursive', 'Coronetscript, cursive',
        'Florence, cursive', 'Parkavenue, cursive', 'cursive',
        'Impact, fantasy', 'Arnoldboecklin, fantasy', 'Oldtown, fantasy',
        'Blippo, fantasy', 'Brushstroke, fantasy', 'fantasy', 'Roboto, sans-serif'];
    public static readonly FONT_STYLES = ['normal', 'italic', 'oblique', 'initial', 'inherit'];
    public static readonly FONT_SIZE_UNITS = ['px', 'em', 'rem', 'vw', 'vh'];
    public static readonly TEXT_TRANSFORM_LIST = ['capitalize', 'inherit', 'initial', 'lowercase', 'none', 'uppercase'];
    public static readonly FONT_WEIGHT = ['normal', 'bold', 'inherit'];
    public static readonly WRITING_MODE = ['horizontal-tb', 'vertical-rl'];
    public static readonly BORDER = 'border';
    public static readonly BORDER_SIDES: BorderType[] = [
        { displayName: 'Left', borderValue: null, name: 'border-left', colorValue: null },
        { displayName: 'Right', borderValue: null, name: 'border-right', colorValue: null },
        { displayName: 'Top', borderValue: null, name: 'border-top', colorValue: null },
        { displayName: 'Bottom', borderValue: null, name: 'border-bottom', colorValue: null },
        { displayName: 'ALL', borderValue: null, name: BorderStyleConstant.BORDER, colorValue: null }
    ];
    public static readonly WORD_BREAK_LIST = ['normal', 'break-all', 'break-word', 'inherit', 'initial'];
}
export enum BORDER_CONSTANTS {
    BORDER_RIGHT = 'border-right',
    BORDER_LEFT = 'border-left',
    BORDER_TOP = 'border-top',
    BORDER_BOTTOM = 'border-bottom',
    BORDER = 'border',
}
export class BackgroundStyleConstant {
    public static readonly BG_SIZE = ['custom', 'initial', 'cover', 'contain'];
    public static readonly BG_ATTACHMENT = ['scroll', 'fixed', 'local', 'initial', 'inherit'];
    public static readonly BG_CLIP = ['border-box', 'padding-box', 'content-box', 'initial', 'inherit'];
    public static readonly BG_BLEND_MODE = ['normal', 'multiply', 'screen', 'overlay', 'darken', 'lighten', 'color-dodge', 'saturation', 'color', 'luminosity'];
    public static readonly BG_POSITION = ['left top', 'left center', 'left bottom', 'right top', 'right center', 'right bottom', 'center top', 'center center', 'center bottom', 'initial', 'inherit'];
}
