import { addDays, addMilliseconds, endOfDay, endOfMonth, endOfQuarter, endOfWeek, endOfYear, endOfYesterday, parse, startOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear, startOfYesterday } from "date-fns";
import { format, toDate, toZonedTime, fromZonedTime } from "date-fns-tz";

export class OrganizationDate {
    private static orgTimezone: string;
    private static siteTimezone: string;

    /**
     * Initialize OrgDate with timezone
     */
    static init(timezone: string) {
        this.orgTimezone = timezone;
    }
    static initSiteTimezone(siteTimezone: string) {
        this.siteTimezone = siteTimezone;
    }

    /*
    * To get the organization time zone.
    */
    static getOrgTimeZone() {
        return this.orgTimezone || null;
    }
    static getSiteTimezone() {
        return this.siteTimezone || null
    }

    /**
     * Get org current date time in org timezone in ISO format with offset e.g.: 2021-01-29T00:00:00+05:30
     * @return current time in org timezone
     */
    static now(): string {
        const date = toZonedTime(Date.parse(new Date().toISOString()), this.orgTimezone)
        return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { timeZone: this.orgTimezone })
    }
    /*
    * To get the current ISO Date in string.
    */
    static currentDateToISOString() {
        const now = OrganizationDate.now();
        return parse(now, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", new Date()).toISOString()
    }
    static addMilliSecondsToDate(date: string, milliSeconds: number) {
        const adjustedDate = addMilliseconds(new Date(date), milliSeconds);
        const adjustedParsedDate = parse(adjustedDate.toISOString(), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", new Date()).toISOString()
        return parse(adjustedParsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", new Date()).toISOString()
    }
    /**
     * Convert given dateTime/date in UTC.
     * Ex: If the today's date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-28T18:30:01.000Z
     * @param date in string format with or without offset. Ex: 2021-01-29T00:00:00+05:30 / 2021-01-29, date must be in yyyy-MM-dd format
     * @returns UTC date time in string fromat
     */
    static convertDateToISOString(date: string, timezone?: string): string {
        const dateTimeWithSec = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
        const dateTime = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})/
        const regex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/ // "yyyy-MM-dd"
        const utcregex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/ //"yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
        const ddMMyyyyregex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/

        const localTimezone = timezone || this.orgTimezone;

        const datemilli = Date.parse(date)
        if ((date as any) instanceof Date && datemilli) {
            return fromZonedTime(datemilli, localTimezone).toISOString()
        }
        if (!Number.isNaN(datemilli) && date?.length == 25) {
            // return parse(date, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date()).toISOString()
            return fromZonedTime(date, localTimezone).toISOString()
        }
        else if (!Number.isNaN(datemilli) && date?.length == 19) {
            return fromZonedTime(date, localTimezone).toISOString()
        }
        else if (!Number.isNaN(datemilli) && date?.length == 29) {
            const index = date.indexOf('+')
            date = date.slice(0, index)// NOTE: temp fix : as Date object always taking local offset, so removing the offset before conversion
            // return parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", new Date()).toISOString()
            return fromZonedTime(date, localTimezone).toISOString()
        }
        else if (regex.test(date)) {
            return fromZonedTime(date, localTimezone).toISOString()
        }
        else if (ddMMyyyyregex.test(date) && date.length == 10) {
            const parsedDate = parse(date, "dd-MM-yyyy", new Date())
            return fromZonedTime(parsedDate, localTimezone).toISOString()
        }
        else if (dateTime.test(date) && date.length == 16) {
            const parsedDate = parse(date, "yyyy-MM-dd HH:mm", new Date())
            return fromZonedTime(parsedDate, localTimezone).toISOString()
        }
        else if (dateTimeWithSec.test(date) && date.length == 19) {
            const parsedDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date())
            return fromZonedTime(parsedDate, localTimezone).toISOString()
        }
        else if (date.length == 23) {
            const parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS", new Date())
            return fromZonedTime(parsedDate, localTimezone).toISOString()
        }
        else if (utcregex.test(date) && date.length == 24) {
            // return parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()).toISOString()
            // throw new Error('trying to convert date to ISO, which is already in ISOformat')
            return date
        }
        else {
            throw new Error(`Invalid date/ check date format, date: ${date}`)
        }
    }
    /**
     * Return StartOr End of time based on input.
     * Ex: If the today's date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-28T18:30:01.000Z
     * @param dur in defined string of type 'day' | 'month' | 'year' | 'week' | 'quarter'
     * @param startOrEndOf default is true, with true return start, with false returns end
     * @param toISOString default is false, with true return ISOString else localtime
     * @param date optional paramater, when passed it considers w.r.t that time else org current time.
     * @param timezone optional paramater, when passed it considers that timezone otherwise orgTimeZone.
     * @returns UTC date time/or local based on argument toISOString
     */
    static getStartOrEndOfTime(dur: 'day' | 'month' | 'year' | 'week' | 'quarter' | 'yesterday' | 'lastWeek' | 'last7Days' | 'last30Days' | 'lastYear' | 'lastMonth' | 'custom',
        startOrEndOf = true, toISOString = false, date?: string | Date, timezone?: string, customDays?: number) {
        let dateValue: Date
        let changeDate: Date
        const localTimezone = timezone || this.orgTimezone;
        //TODO: should be tested fochange in local timezone
        dateValue = toDate(date ? new Date(date) : OrganizationDate.now(), { timeZone: localTimezone })

        switch (dur) {
            case 'day':
                changeDate = startOrEndOf ? startOfDay(dateValue) : endOfDay(dateValue)
                break;
            case 'month':
                changeDate = startOrEndOf ? startOfMonth(dateValue) : endOfMonth(dateValue);
                break;
            case 'year':
                changeDate = startOrEndOf ? startOfYear(dateValue) : endOfYear(dateValue);
                break;
            case 'week':
                changeDate = startOrEndOf ? startOfWeek(dateValue) : endOfWeek(dateValue);
                break;
            case 'quarter':
                changeDate = startOrEndOf ? startOfQuarter(dateValue) : endOfQuarter(dateValue);
                break;
            case 'yesterday':
                changeDate = startOrEndOf ? startOfYesterday() : endOfYesterday();
                break;
            case 'lastWeek':
                changeDate = startOrEndOf ? startOfWeek(addDays(startOfWeek(dateValue), -1)) : endOfWeek(addDays(startOfWeek(dateValue), -1));
                break;
            case 'last7Days':
                changeDate = startOrEndOf ? startOfDay(addDays(dateValue, -7)) : endOfDay(dateValue);
                break;
            case 'last30Days':
                changeDate = startOrEndOf ? startOfDay(addDays(dateValue, -30)) : endOfDay(dateValue);
                break;
            case 'lastYear':
                changeDate = startOrEndOf ? startOfYear(addDays(startOfYear(dateValue), -1)) : endOfYear(addDays(startOfYear(dateValue), -1));
                break;
            case 'lastMonth':
                changeDate = startOrEndOf ? startOfMonth(addDays(startOfMonth(dateValue), -1)) : endOfMonth(addDays(startOfMonth(dateValue), -1));
                break;
            case 'custom':
                changeDate = startOrEndOf ? startOfDay(addDays(dateValue, -(customDays))) : endOfDay(dateValue);
                break;    
            default:
                null;
        }
        return toISOString ? fromZonedTime(changeDate, localTimezone).toISOString() : format(changeDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
    }

    /**
     * Get org current time in org timezone
     * @return current time in org timezone
     */
    static timeNow() {
        return this.now().slice(11, 19) || null;
    }

    /**
     * Get beginning of today in UTC.
     * Ex: If the today's date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-28T18:30:01.000Z
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @returns UTC date time at the beginning of today in string fromat
     */
    static getBeginningOfTodayInUTC() {
        var now = OrganizationDate.now();
        return this.getBeginningOfDayInUTC(now);
    }

    /**
     * Get beginning of day in UTC for given dateTimeWithOffset.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-28T18:30:01.000Z
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @returns UTC date time at the beginning of day for given date in string fromat
     */
    static getBeginningOfDayInUTC(dateTimeWithOffset: string) {
        var startOfDay = dateTimeWithOffset.slice(0, 11) + "00:00:01";
        // return new Date(startOfDay).toISOString();
        return fromZonedTime(startOfDay, this.orgTimezone).toISOString();
    }

    /**
     * Get end of today in UTC.
     * Ex: If the today's date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-28T18:30:01.000Z
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @returns UTC date time at the end of today in string fromat
     */
    static getEndOfTodayDayInUTC() {
        var now = OrganizationDate.now();
        return this.getEndOfDayInUTC(now);
    }

    /**
     * Get end of day in UTC for given dateTimeWithOffset.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-29T18:29:59.000Z
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @returns uTC date time at the end of day for given date in string fromat
     */
    static getEndOfDayInUTC(dateTimeWithOffset: string) {
        var endOfDay = dateTimeWithOffset.slice(0, 11) + "23:59:59";
        // return new Date(endOfDay).toISOString();
        return fromZonedTime(endOfDay, this.orgTimezone).toISOString();
    }

    /**
     * Get time in UTC for given time.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 and time is 13:00:00 then the return value will be 07:30:00
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @param time in string format. Ex: 13:00:00
     * @returns UTC time for the org time
     */
    static getTimeInUTCForOrgTime(time: string) {
        var now = OrganizationDate.now();
        return this.getTimeInUTCForTimeZoneTime(now, time);
    }

    /**
     * Get time in UTC for given dateTimeWithOffset and time.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 and time is 13:00:00 then the return value will be 07:30:00
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00.000+05:30
     * @param time in string format. Ex: 13:00:00
     * @returns UTC time for the org time
     */
    static getTimeInUTCForTimeZoneTime(dateTimeWithOffset: string, time: string) {
        var endOfDay = dateTimeWithOffset.slice(0, 11) + time + dateTimeWithOffset.slice(19, 29);
        return new Date(endOfDay).toISOString().slice(11, 19);
        // return zonedTimeToUtc(endOfDay, this.orgTimezone).toISOString().slice(11, 19);
    }
    /**
     * Converts the given date to the start/end of the day
     * @returns UTC time for the give datetime
     */
    static convertOrganizationDateTimeToUTCDateTime(dateTimeWithOffset: string) {
        // return new Date(dateTimeWithOffset).toISOString();
        return fromZonedTime(dateTimeWithOffset, this.orgTimezone).toISOString()
    }

    /**
     * Converts UTC time to org time.
     * Ex: If the passed in UTC time is 13:00:00 then the return value will be 07:30:00
     * @param time in string format. Ex: 13:00:00
     * @returns org time for the UTC time
     */
    static convertUTCTimeToOrgTime(utcTime: string) {
        // var now = OrganizationDate.now();
        return this.convertUTCTimeToTimezoneTime(utcTime, this.orgTimezone);
    }

    /**
     * Converts UTC time to org time.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 and UTC time is 13:00:00 then the return value will be 07:30:00
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @param time in string format. Ex: 13:00:00
     * @returns org time for the UTC time
     */
    private static convertUTCTimeToTimezoneTime(utcTime: string, orgTimezone: string) {
        const zonedTime = fromZonedTime(Date.parse(utcTime), orgTimezone)
        return format(zonedTime, "HH:mm:ss", { timeZone: orgTimezone })
    }

    /**
     * Converts ISO UTC date time to org date time.
     * Ex: If the passed date is 2017-01-02T10:31:39.716Z and the org time zone is Africa/Johannesburg then
     * the return value will be 2017-01-02T12:31:39+02:00
     * @param isoDateTime in string format. Ex: 2015-02-23T04:02:03.000Z
     * @returns org date time for the UTC date time
     */
    static convertIsoUtcDateTimeToOrgDateTime(isoDateTime: string): string {
        const formatedDate = format(toZonedTime(isoDateTime, this.orgTimezone), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { timeZone: this.orgTimezone });
        if (this.orgTimezone == 'UTC' && formatedDate.slice(-1) == 'Z') {
            return formatedDate.replace('Z', '+00:00')
        }
        return formatedDate;
    }
}
