import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { LoaderServiceProvider } from '../providers/abstract/loader-service-provider';
import { SessionStorageProvider } from '../providers/abstract/session-storage-provider';
import { HttpRequestInterceptor } from './request/http.request.interceptor';
import { HttpResponseInterceptor } from './response/http.response.interceptor';
import { ToastServiceProvider } from '../providers/abstract/toast-service-provider';
import { HttpStatusCode } from '../constants/http-constants';
import { signOut } from 'aws-amplify/auth';
import { IdentifierType } from 'projects/den-mobile/src/app/shared/mob-constants';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    private baseUrl: string;
    private loadCounterSubscription: Subscription;

    constructor(@Inject(LoaderServiceProvider) private loaderService: LoaderServiceProvider,
        @Inject(SessionStorageProvider) private sessionStorageService: SessionStorageProvider, private toastService: ToastServiceProvider) {
        this.loadCounter();
    }
    // Ref: https://stackoverflow.com/questions/45735655/how-to-setup-baseurl-for-angular-httpclient
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const apiReq = HttpRequestInterceptor.setHttpHeaders(req, this.baseUrl, this.sessionStorageService);

        return next
            .handle(apiReq)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        HttpResponseInterceptor.intercept(event);
                    }
                }),
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.status === HttpStatusCode.UNAUTHORIZED) {
                        this.handleSessionExpiration();
                    }
                    else if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;    
                    }else {
                        // server-side error
                        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                    }
                    if (error.status === HttpStatusCode.TOO_MANY_REQUESTS){
                        this.toastService.showInfo('Reading files from S3 in progress, please wait !!');
                        return throwError(null);
                    }else{
                        return throwError(error);
                    }
                }));
    };

    loadCounter() {
        if (this.loaderService) {
            this.loaderService.loaderSubjectCounter.asObservable()
                .subscribe((res: number) => {
                    this.loaderService.loadCounter = res;
                    if (res > 0) {
                        this.loaderService.showLoader();
                    } else {
                        this.loaderService.hideLoader();
                    }
                });
        }
    }

    private handleSessionExpiration() {
        const preLoginInfo = this.sessionStorageService.getPreLoginInfo();
        if (preLoginInfo.securityIdentifierType === IdentifierType.EXTERNAL) {
            signOut({ global: true })
                .then(() => {
                    this.clearSessionAndReloadApp();
                })
                .catch(() => this.clearSessionAndReloadApp());
        } else {
            this.clearSessionAndReloadApp();
        }
    }
    private clearSessionAndReloadApp() {
        this.sessionStorageService.clearSession();
        this.toastService.showError('Session expired, please login again');
        setTimeout(() => {
            window.location.reload();
        }, 200);
    }

    ngOnDestroy(): void {
        this.loadCounterSubscription?.unsubscribe();
    }
}