import { Injectable } from "@angular/core";
import { isEqual, isValid, isWithinInterval } from 'date-fns';
import { FilterOperator, FilterProperty } from "../../../../../models/base.models";
import { SharedHelper } from "../../../../../page-builder/helpers/shared-helper";
import { BoundParam, ReturnMethodParamsWrapper } from "../../../../data-source/data-source";
import { DsResultArray, DsResultValue } from "../../../../data-source/data-source-result-entities";
import { ParamWithBufferValue } from "../shared/models/list-view.model";
import { OrganizationDate } from "../../../../../utils/org-date-helper";
import { SchemaControlType } from "../../../../../models/schema/schema-control-type";
import { DateTimeHelper } from "../../../../../utils/date-time-helper";


export class RtPrimitiveTypes {

}

abstract class DataSourceFilterRule {
    abstract condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean;
    abstract buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean;
    getDsResultByParamName(dsResultValues: DsResultValue[], paramName: string) {
        const dsResultValue = dsResultValues.find(dsrValue => dsrValue.fieldName === paramName);
        return dsResultValue ? dsResultValue.originalValue : null;
    }
}
class RecordNotContainParamValueRule extends DataSourceFilterRule {
    condition(param: BoundParam, _paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return (!record || value == null || value == undefined)
    }
    buildResult(_param: BoundParam, _paramBufferValue: any, record: DsResultValue[]): boolean {
        if (!record || record.length === 0) {
            return false;
        } else {
            return true;
        }
    }
}

class ParamOfTypeDateTimeWithRangeOperatorRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.RANGE && value && paramBufferValue && typeof paramBufferValue === 'object' && 'startDateTime' in paramBufferValue && 'endDateTime' in paramBufferValue;
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const startTime = OrganizationDate.getStartOrEndOfTime('day', true, false, paramBufferValue['startDateTime'])
        const endTime = OrganizationDate.getStartOrEndOfTime('day', false, false, paramBufferValue['endDateTime'])
        const value = this.getDsResultByParamName(record, param.name);
        const start = new Date(startTime)
        const end = new Date(endTime)
        return isWithinInterval(new Date(value), { start, end });
    }
}

class ParamOfTypeDateWithEqualsOperatorRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);

        return paramBufferValue && param.operator === FilterOperator.EQUALS && SharedHelper.isDate(value) && SharedHelper.isDate(paramBufferValue);
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);

        return isEqual(new Date(paramBufferValue), new Date(value))
    }
}

class RecordValueAndParamOfTypeisArrayRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.isArray && Array.isArray(paramBufferValue) && Array.isArray(value);
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return paramBufferValue?.some(pbValue => value.includes(pbValue));
    }
}

class ParamOfTypeisArrayRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, _record: DsResultValue[]): boolean {
        return paramBufferValue && Array.isArray(paramBufferValue) && param.operator === FilterOperator.IN;
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: any): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return paramBufferValue?.some(pbValue => pbValue === value);
    }
}

class ParamOfTypeStringWithContainsRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.CONTAINS && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value?.includes(paramBufferValue);
    }
}
class ParamOfTypeStringOrNumberWithEqualsRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.EQUALS && paramBufferValue && value && (typeof paramBufferValue === 'string' || typeof paramBufferValue === 'number');
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value == paramBufferValue;
    }
}
class ParamOfTypeStringOrNumberWithNotEqualsRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.NOT_EQUAL && paramBufferValue && value;
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value != paramBufferValue;
    }
}
class ParamOfTypeStringWithEqualsIgnoreCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.EQUALS_IGNORE_CASE && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value?.toLowerCase() === paramBufferValue.toLowerCase();
    }
}
class ParamOfTypeStringWithNotContainsRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.NOT_CONTAINS && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return !value.includes(paramBufferValue);
    }
}
class ParamOfTypeStringWithNotContainsIgnoreCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.NOT_CONTAINS_IGNORE_CASE && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return !value.toLowerCase().includes(paramBufferValue.toLowerCase());
    }
}
class ParamOfTypeStringWithStartWithRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.STARTS_WITH && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value.startsWith(paramBufferValue);
    }
}
class ParamOfTypeStringWithStartWithIgnoreCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.STARTS_WITH_IGNORE_CASE && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value.toLowerCase().startsWith(paramBufferValue.toLowerCase());
    }
}
class ParamOfTypeStringWithEndsWithRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.ENDS_WITH && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value.endsWith(paramBufferValue);
    }
}
class ParamOfTypeStringWithEndsWithIgnoreCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.ENDS_WITH_IGNORE_CASE && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value.toLowerCase().endsWith(paramBufferValue.toLowerCase());
    }
}

class ParamOfTypeStringWithContainsIgnoreCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.CONTAINS_IGNORE_CASE && paramBufferValue && typeof paramBufferValue === 'string' && value && typeof value === 'string';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value?.toLowerCase().includes(paramBufferValue.toLowerCase());
    }
}
class ParamOfTypeNumberWithGraterThanCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.GREATER_THAN && paramBufferValue && typeof paramBufferValue === 'number' && value && typeof value === 'number';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value > paramBufferValue;
    }
}
class ParamOfTypeNumberWithGraterThanEqualsCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.GREATER_THAN_EQUALS && paramBufferValue && typeof paramBufferValue === 'number' && value && typeof value === 'number';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value >= paramBufferValue;
    }
}
class ParamOfTypeNumberWithLessThanEqualsCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.LESS_THAN_EQUALS && paramBufferValue && typeof paramBufferValue === 'number' && value && typeof value === 'number';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value <= paramBufferValue;
    }
}
class ParamOfTypeNumberWithLessThanCaseRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.LESS_THAN && paramBufferValue && typeof paramBufferValue === 'number' && value && typeof value === 'number';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value < paramBufferValue;
    }
}


class ParamOfTypeDateTimeOperatorRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return param.operator === FilterOperator.RANGE && value && paramBufferValue && SharedHelper.isDateTime(paramBufferValue);
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const startTime = OrganizationDate.getStartOrEndOfTime('day', true, true, paramBufferValue)
        const endTime = OrganizationDate.getStartOrEndOfTime('day', false, true, paramBufferValue)
        const value = this.getDsResultByParamName(record, param.name);
        return isWithinInterval(new Date(value), {
            start: new Date(startTime),
            end: new Date(endTime)
        })
    }
}

class ParamOfTypeBooleanRule extends DataSourceFilterRule {
    condition(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return paramBufferValue && typeof value === 'boolean' && typeof JSON.parse(paramBufferValue) === 'boolean';
    }
    buildResult(param: BoundParam, paramBufferValue: any, record: DsResultValue[]): boolean {
        const value = this.getDsResultByParamName(record, param.name);
        return value === JSON.parse(paramBufferValue);
    }
}

@Injectable()
export class FilteringManager {

    //TODO: nag: Refactor the below using https://github.com/gvergnaud/ts-pattern
    private filterRules: Array<DataSourceFilterRule> = new Array();

    //private recordNotContainParamValueRule = new RecordNotContainParamValueRule()
    //private paramOfTypeDateTimeWithRangeOperatorRule = new ParamOfTypeDateTimeWithRangeOperatorRule()

    constructor() {
        this.initRules();
    }

    initRules(): void {
        // The below order should not be changed.
        this.filterRules.push(new RecordNotContainParamValueRule());
        this.filterRules.push(new ParamOfTypeDateTimeWithRangeOperatorRule());
        this.filterRules.push(new ParamOfTypeDateTimeOperatorRule());
        this.filterRules.push(new ParamOfTypeDateWithEqualsOperatorRule());
        this.filterRules.push(new RecordValueAndParamOfTypeisArrayRule());
        this.filterRules.push(new ParamOfTypeisArrayRule());
        this.filterRules.push(new ParamOfTypeStringWithContainsRule());
        this.filterRules.push(new ParamOfTypeBooleanRule());
        this.filterRules.push(new ParamOfTypeStringOrNumberWithEqualsRule());
        this.filterRules.push(new ParamOfTypeStringOrNumberWithNotEqualsRule());
        this.filterRules.push(new ParamOfTypeStringWithContainsIgnoreCaseRule());
        this.filterRules.push(new ParamOfTypeStringWithEqualsIgnoreCaseRule());
        this.filterRules.push(new ParamOfTypeStringWithNotContainsRule());
        this.filterRules.push(new ParamOfTypeStringWithNotContainsIgnoreCaseRule());
        this.filterRules.push(new ParamOfTypeStringWithStartWithRule());
        this.filterRules.push(new ParamOfTypeStringWithStartWithIgnoreCaseRule());
        this.filterRules.push(new ParamOfTypeStringWithEndsWithRule());
        this.filterRules.push(new ParamOfTypeStringWithEndsWithIgnoreCaseRule());
        this.filterRules.push(new ParamOfTypeNumberWithGraterThanCaseRule());
        this.filterRules.push(new ParamOfTypeNumberWithGraterThanEqualsCaseRule());
        this.filterRules.push(new ParamOfTypeNumberWithLessThanEqualsCaseRule());
        this.filterRules.push(new ParamOfTypeNumberWithLessThanCaseRule());
    }

    applyDataSourceFilter(paramBuffer: ParamWithBufferValue[], dsResultArray: DsResultArray): DsResultArray {
        // moved from dataSource-executor-service
        if (!dsResultArray) {
            return null;
        }
        dsResultArray.results = dsResultArray.results.map(dsResult => {
            if (!dsResult.isDeleted()) {
                let isValueExists = true;
                const record = dsResult.data;

                for (let index = 0; index < paramBuffer.length; index++) {
                    // const param = paramBuffer[index];
                    const paramBufferValue = paramBuffer[index].value;
                    if (paramBufferValue) {
                        let rule = this.filterRules.find(r => r.condition(paramBuffer[index].param, paramBufferValue, record));
                        if (rule) {
                            isValueExists = rule.buildResult(paramBuffer[index]?.param, paramBufferValue, record);
                        }
                    }
                    if (!isValueExists) {
                        break;
                    }
                }
                if (!isValueExists) {
                    dsResult = dsResult.markAsDeleted();
                }
            }
            return dsResult;
        })
        return dsResultArray;
    }


    // ClientSide filter
    applyControlFilter(filterProperties: Record<string, string>, dataSource: DsResultArray): DsResultArray {
        if (!filterProperties || !dataSource) {
            throw new Error('Invalid input for applyControlFilter() method');
        }
        let clonedDataSource = this.copyDataSrc(dataSource);
        const filterData = this.removeEmptyObjects(filterProperties);

        clonedDataSource = this.getRemovableItemFromClonedDataSource(filterData, clonedDataSource);
        return clonedDataSource;
    }

    private getRemovableItemFromClonedDataSource(filterData: Record<string, string>, clonedDataSource: DsResultArray): DsResultArray {
        Object.keys(filterData).forEach(fd => {
            if (filterData[fd]) {
                clonedDataSource.results = clonedDataSource?.results
                    .map(ds => {
                        if (!(typeof ds.data[fd] === 'string' && typeof filterData[fd] === 'string' &&
                            ds.data[fd]?.toLowerCase()?.includes((filterData[fd] as string)?.toLowerCase()))) {
                            ds = ds.markAsDeleted();
                        }
                        return ds;
                    })
            }
        });
        return clonedDataSource;
    }

    private copyDataSrc(ds: DsResultArray): DsResultArray {
        let optDsResultArray: DsResultArray;
        optDsResultArray = new DsResultArray(ds.dsName, ds.results, ds.totalResults, ds.fks, ds.isWsResult);
        return optDsResultArray;
    }

    private removeEmptyObjects(obj: Record<string, string>): Record<string, string> {
        if (obj) {
            for (const key in obj) {
                if (obj[key] === null || obj[key] === undefined || typeof obj[key] == 'string' && (obj[key] as string)?.trim() === "") {
                    delete obj[key];
                }
            }
        }
        return obj;
    }


    // server-side filter functionalities
    //TODO: Revisit
    // @ts-ignore
    addOrUpdateOnChangeOfFilterProps(filterProperties: Record<string, any>, advancedFilterProperty: FilterProperty[], schemaFields: ReturnMethodParamsWrapper[]): FilterProperty[] {
        if (filterProperties && Object.keys(filterProperties)?.length) {
            Object.entries(filterProperties).forEach(([key, value]) => {
                const keyExists = advancedFilterProperty.find(attr => attr.propertyName === key);
                if (keyExists) {
                    this.updateFilterPropertiesBasedOnFieldType(schemaFields, key, keyExists, value);
                } else {
                    this.constructFilterPropBasedOnFieldType(key, value, advancedFilterProperty, schemaFields);
                }
            });
            advancedFilterProperty = advancedFilterProperty.filter(fp => fp.value1 !== undefined && fp.value1 !== null && fp.value1 !== '');
            return advancedFilterProperty;
        }
    }

    private updateFilterPropertiesBasedOnFieldType(schemaFields: ReturnMethodParamsWrapper[], key: string, keyExists: FilterProperty, value: any) {
        const schemaObj = schemaFields.find(sf => sf.name === key);
        if (schemaObj?.type === SchemaControlType.DATE_PICKER) {
            keyExists.value1 = DateTimeHelper.getFormattedDateString('yyyy-MM-dd', value);
        }
        else if (schemaObj?.type === SchemaControlType.DATE_TIME_PICKER) {
            const endTime = OrganizationDate.getStartOrEndOfTime('day', false, true, value)
            keyExists.value1 = value;
            keyExists.value2 = endTime;
        }
        else if (schemaObj?.type === SchemaControlType.CHECK_BOX) {
            keyExists.value1 = JSON.stringify(value);
        }
        else {
            keyExists.value1 = value;
        }
    }

    private constructFilterPropBasedOnFieldType(key: string, value: any, advancedFilterProperty: FilterProperty[], schemaFields: ReturnMethodParamsWrapper[]): FilterProperty[] {
        const schemaObj = schemaFields.find(sf => sf?.name === key);

        if (isValid(new Date(value)) && schemaObj?.type === SchemaControlType.DATE_PICKER) {
            advancedFilterProperty.push(new FilterProperty(key, FilterOperator.EQUALS, DateTimeHelper.getFormattedDateString('yyyy-MM-dd', value)));
        }
        else if (SharedHelper.isDateTime(value as unknown as string) && schemaObj?.type === SchemaControlType.DATE_TIME_PICKER) {
            const endTime = OrganizationDate.getStartOrEndOfTime('day', false, true, value)
            advancedFilterProperty.push(new FilterProperty(key, FilterOperator.RANGE, value, endTime));
        }
        else if (typeof (value) === 'number') {
            advancedFilterProperty.push(new FilterProperty(key, FilterOperator.EQUALS, value));
        }
        else if (typeof (value) === 'boolean' && schemaObj?.type === SchemaControlType.CHECK_BOX) {
            advancedFilterProperty.push(new FilterProperty(key, FilterOperator.EQUALS, JSON.stringify(value)));
        }
        else if (value) {
            // supports both string as well as fkey types
            advancedFilterProperty.push(new FilterProperty(key, FilterOperator.CONTAINS_IGNORE_CASE, (value as unknown as string)?.trim()));
        }
        return advancedFilterProperty;
    }


}


