export class StringHelper {

    static METHOD_REGEX = /([a-zA-Z][a-zA-Z0-9_$]+)\s*\(\s*([a-zA-Z0-9_$:.?=[\]'|"\s*]+(?:\s*,\s*[a-zA-Z0-9_$:.?=[\]'|"\s*]+)*)\s*\)/;
    static RETURN_SCHEMA_REGEX = /\(\s*([a-zA-Z0-9_$:.?=[\]'|"\s*]+(?:\s*,\s*[a-zA-Z0-9_$:.?=[\]'|"\s*]+)*)\s*\)/;

    static getMethodName(property: string) {
        const methodName = property.match(this.METHOD_REGEX)[1];
        return methodName;
    }

    static getMethodParameters(property: string): string[] {
        const methodParams = property.match(this.METHOD_REGEX)[2].split(",");
        return methodParams;
    }

    static getReturnSchemaParameters(property: string): string[] {
        const returnSchemaParams = property.match(this.RETURN_SCHEMA_REGEX)[1].split(",");
        return returnSchemaParams;
    }

    static isMethodIsValid(properties: string) {
        const isMethodValid = this.METHOD_REGEX.test(properties);
        return isMethodValid;
    }

    static isReturnSchemaIsValid(properties: string) {
        const isReturnSchemaValid = this.RETURN_SCHEMA_REGEX.test(properties);
        return isReturnSchemaValid;
    }

    static getKeyFromMethodParameter(param: string) {
        let key = param.split(":")[0]
        if (key.includes("?")) {
            key = key.split("?")[0];
        }
        return key;
    }

    static getDefaultValueFromMethodParameter(param: string) {
        const defaulValue = param.split("=")[1];
        return defaulValue;
    }

    static getDataTypeFromMethodParameter(param: string) {
        let dataType = param.split(":")[1]
        if (dataType.includes("[]")) {
            dataType = dataType.split("[]")[0]
        }
        if (dataType.includes("=")) {
            dataType = dataType.split("=")[0]
        }
        return dataType.trim();
    }

    static getSchemaNameFromUserTaskDsName(dsName: string) {
        const computedString = dsName.split("-")[0];
        return computedString;
    }


    static isMethodParameterOptional(param: string) {
        const isOptional = param.includes("?");
        return isOptional;
    }

    static isMethodParameterArray(param: string) {
        const isArray = param.includes("[]");
        return isArray;
    }

}