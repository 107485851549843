import { Attribute } from "../../../attributes/core/attribute";

export class ControlAttributesHelper {
    private static attributeRegisterMap: Map<string, Attribute<unknown, unknown>[]> = new Map<string, Attribute<unknown, unknown>[]>();

    static setAttributeRegister(controlName: string, allAttributes: Attribute<unknown, unknown>[]) {
        this.attributeRegisterMap.set(controlName, allAttributes);

    }
    static getAttributeRegister(controlName: string) {
        const attributesValue = this.attributeRegisterMap.get(controlName);
        return attributesValue
    }

}