import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import {
  ThemeColorType,
  ThemePalleteKeys,
} from '../../../constants/app-constants';
import { PersonalisationAndLocalizationService } from '../../../providers/personalisation-and-localization/personalisation-and-localization-service';
import { IdentityGenerator } from '../../../utils/identity-generator';
import { JsonHelper } from '../../../utils/json-helper';
import { RtNone, RtOption, RtSome } from '../../../utils/option-helper';
import { CSSPropertyNameConstants } from '../../constants/css-property-name-constants';
import {
  Attribute,
  AttributeThemeType,
  AttributeType,
  MobiAttribute,
} from '../core/attribute';
import { UsableAttributeValue } from '../core/attribute-instance';
import { GradientSliderHelper } from './gradient-slider-heper';
import { AttributeGroup } from '../../constants/attribute-group-constant';

export class SelectedThemeColor {
  constructor(
    public colorType: ThemeColorType,
    public paletteKey: ThemePalleteKeys
  ) { }
}

export type RGB = { r: number; g: number; b: number }; // capture A seperately

export type RGBA = { r: number; g: number; b: number; a: number };

export enum ColorType {
  SOLID = 'solid',
  LINEAR = 'linear',
  RADIAL = 'radial',
}

export interface ColorDefinition {
  cssPropertyName: string;

  addOrUpdateColor(id: string, color: ThemeOrCustomColor): ColorDefinition;

  usableCSSValue(): string;
}

export interface GradientDefinition {
  colors: Map<string, ThemeOrCustomColor>;
  //use for slider
  sliderCSSValue(): string;
  removeColorStop(id: string): ColorDefinition;
}

class StyleBuilder {
  static buildStyle(rgba: RGBA): string {
    return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
  }

  static buildAllGradients(colors: Map<string, ThemeOrCustomColor>): string {
    const stopAndColors: ThemeOrCustomColor[] = Array.from(
      colors.values()
    ); /* .map(c => {
            return { stop: c.stop, gradientPart: c.buildGradientPart };
        }) */
    const sorted = StyleBuilder.sort(stopAndColors);
    return sorted.map((s) => s.buildGradientPart).join(',');
  }

  static sort(stopAndColors: ThemeOrCustomColor[]): ThemeOrCustomColor[] {
    const sortedData = stopAndColors.sort((a, b) => a.stop - b.stop);
    return sortedData;
  }
}

export class SolidColor implements ColorDefinition {
  constructor(public color: ThemeOrCustomColor) { }
  cssPropertyName = CSSPropertyNameConstants.BACKGROUND_COLOR;

  addOrUpdateColor(_: string, color: ThemeOrCustomColor): ColorDefinition {
    return new SolidColor(color);
  }

  usableCSSValue(): string {
    const rgba = this.color.getRGBAColor;
    return StyleBuilder.buildStyle(rgba);
  }

  static parse(solidColor: SolidColor): SolidColor {
    const color = ThemeOrCustomColor.parse(solidColor.color);
    const reConstructedSolidColor = new SolidColor(color);
    return reConstructedSolidColor;
  }
}

//export class  StopAndColor = { colorMode:ColorMode, stop: number, rgbaCss: string }

export class LinearGradient implements ColorDefinition, GradientDefinition {
  /**
   * @param deg min 0, max 360
   */
  constructor(
    public deg: number,
    public colors: Map<string, ThemeOrCustomColor>
  ) { }

  cssPropertyName = CSSPropertyNameConstants.BACKGROUND_IMAGE;

  updateDeg(deg: number) {
    return new LinearGradient(deg, this.colors);
  }

  addOrUpdateColor(id: string, color: ThemeOrCustomColor): ColorDefinition {
    this.colors.set(id, color);
    return new LinearGradient(this.deg, this.colors);
  }

  removeColorStop(id: string): ColorDefinition {
    this.colors.delete(id);
    return new LinearGradient(this.deg, this.colors);
  }

  usableCSSValue(): string {
    const gradientParts = StyleBuilder.buildAllGradients(this.colors);
    const styleValue = `linear-gradient(${this.deg}deg, ${gradientParts})`;
    return styleValue;
  }

  sliderCSSValue(): string {
    const gradientParts = StyleBuilder.buildAllGradients(this.colors);
    const styleValue = `linear-gradient(to right, ${gradientParts})`;
    return styleValue;
  }

  static parse(linearGradient: LinearGradient): LinearGradient {
    const reConstructedMap = new Map();
    linearGradient.colors.forEach((value: ThemeOrCustomColor, key: string) => {
      const themeOrCustomColor = ThemeOrCustomColor.parse(value);
      reConstructedMap.set(key, themeOrCustomColor);
    });
    const reConstructedLinearGradient = new LinearGradient(
      linearGradient.deg,
      reConstructedMap
    );
    return reConstructedLinearGradient;
  }
}

export class RadialGradient implements ColorDefinition, GradientDefinition {
  constructor(public colors: Map<string, ThemeOrCustomColor>) { }

  cssPropertyName = CSSPropertyNameConstants.BACKGROUND_IMAGE;

  addOrUpdateColor(id: string, color: ThemeOrCustomColor): ColorDefinition {
    this.colors.set(id, color);
    return new RadialGradient(this.colors);
  }

  removeColorStop(id: string): ColorDefinition {
    this.colors.delete(id);
    return new RadialGradient(this.colors);
  }

  usableCSSValue(): string {
    const gradientParts = StyleBuilder.buildAllGradients(this.colors);
    const styleValue = `radial-gradient(circle, ${gradientParts})`;
    return styleValue;
  }
  sliderCSSValue(): string {
    const gradientParts = StyleBuilder.buildAllGradients(this.colors);
    const styleValue = `linear-gradient(to right, ${gradientParts})`;
    return styleValue;
  }
  static parse(radialGradient: RadialGradient): RadialGradient {
    const reConstructedMap = new Map();
    radialGradient.colors.forEach((value: ThemeOrCustomColor, key: string) => {
      const themeOrCustomColor = ThemeOrCustomColor.parse(value);
      reConstructedMap.set(key, themeOrCustomColor);
    });
    const reConstructedRadialGradient = new RadialGradient(reConstructedMap);
    return reConstructedRadialGradient;
  }
}

export enum ColorMode {
  THEME_COLOR = 'theme_color',
  CUSTOM_COLOR = 'custom_color',
}

export class ThemeOrCustomColor {
  constructor(
    public themeColorOpt: RtOption<SelectedThemeColor>,
    public customColorOpt: RtOption<RGB>,
    public stop: number, //only matters if it is gradient
    public alpha: number = 100
  ) { }

  get colorMode() {
    if (this.themeColorOpt.isDefined) return ColorMode.THEME_COLOR;
    else if (this.customColorOpt) return ColorMode.CUSTOM_COLOR;
    else throw new Error('Color is not defined');
  }

  get getRGBAColor(): RGBA {
    const A = this.alpha;
    if (this.customColorOpt.isDefined) {
      const customColor = this.customColorOpt.get;
      return { r: customColor.r, g: customColor.g, b: customColor.b, a: A };
    } else if (this.themeColorOpt.isDefined) {
      const themeColorOpt = this.themeColorOpt.map((tc) =>
        PersonalisationAndLocalizationService.getColor(
          tc.colorType as ThemeColorType,
          tc.paletteKey as ThemePalleteKeys
        )
      );
      if (themeColorOpt.isDefined) {
        //const rgba = this.hexToRGBA(themeColorOpt.get)
        const rgb = this.getRGBValues(themeColorOpt.get);
        return { r: rgb.r, g: rgb.g, b: rgb.b, a: A };
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  //used to compose gradient css
  get buildGradientPart(): string {
    return `${StyleBuilder.buildStyle(this.getRGBAColor)} ${this.stop}%`;
  }

  get rgbaCss(): string {
    return `${StyleBuilder.buildStyle(this.getRGBAColor)}`;
  }

  getRGBValues(str: string): RGB {
    var vals = str.substring(str.indexOf('(') + 1, str.length - 1).split(', ');
    const r = parseInt(vals[0]);
    const g = parseInt(vals[1]);
    const b = parseInt(vals[2]);
    // const a = vals.length > 3 ? parseInt(vals[3]) : undefined;
    return { r: r, g: g, b: b };
  }
  static getRGBAValues(str: string): RGBA {
    var vals = str.substring(str.indexOf('(') + 1, str.length - 1).split(',');
    const r = parseInt(vals[0]);
    const g = parseInt(vals[1]);
    const b = parseInt(vals[2]);
    const a = parseFloat(vals[3]);
    return { r: r, g: g, b: b, a: a };
  }

  static rgbaTohex(rgba: RGBA): string {
    //refer: https://stackoverflow.com/a/49974627/1459516
    // or this https://github.com/sindresorhus/rgb-hex
    return rgbHex(rgba.r, rgba.g, rgba.b, rgba.a);
  }

  static hexToRGBA(hex: string): RGBA {
    //refer: https://stackoverflow.com/a/56464092/1459516
    // or this https://github.com/sindresorhus/hex-rgb
    return hexRgb(hex) as unknown as RGBA;
  }

  static parse(themeOrCustomColor: ThemeOrCustomColor): ThemeOrCustomColor {
    let reConstructedThemeOrCustomColor;
    if (!themeOrCustomColor) {
      return reConstructedThemeOrCustomColor = new ThemeOrCustomColor(RtNone(), RtNone(), 0, 100);
    } else {
      const themeColorOpt = RtOption.parse(themeOrCustomColor?.themeColorOpt);
      const customColorOpt = RtOption.parse(themeOrCustomColor?.customColorOpt);
      reConstructedThemeOrCustomColor = new ThemeOrCustomColor(
        themeColorOpt,
        customColorOpt,
        themeOrCustomColor?.stop,
        themeOrCustomColor?.alpha
      );
      return reConstructedThemeOrCustomColor;
    }
  }

  static themeColor(
    colorType: ThemeColorType,
    paletteKey: ThemePalleteKeys,
    stop: number = 0
  ): ThemeOrCustomColor {
    const selectedThemeColor = new SelectedThemeColor(colorType, paletteKey);
    const themeOrCustomColor = new ThemeOrCustomColor(
      RtSome(selectedThemeColor),
      RtNone(),
      stop
    );
    return themeOrCustomColor;
  }
  static customColor(rgba: RGBA, stop: number = 0): ThemeOrCustomColor {
    const rgb = { r: rgba.r, g: rgba.g, b: rgba.b };
    const themeOrCustomColor = new ThemeOrCustomColor(
      RtNone(),
      RtSome(rgb),
      stop,
      rgba.a
    );
    return themeOrCustomColor;
  }
}

export class ColorContainerV3 {
  constructor(
    public colorType: ColorType,
    public colorDefinition: ColorDefinition
  ) { }

  get usableCSSValue() {
    return this.colorDefinition.usableCSSValue();
  }

  addOrUpdateColor(id: string, color: ThemeOrCustomColor): ColorContainerV3 {
    const updatedColorDefinition = this.colorDefinition.addOrUpdateColor(
      id,
      color
    );
    return new ColorContainerV3(this.colorType, updatedColorDefinition);
  }

  updateColorDefinition(colorDefinition: ColorDefinition): ColorContainerV3 {
    return new ColorContainerV3(this.colorType, colorDefinition);
  }

  static solidColorTheme(
    colorType: ThemeColorType,
    paletteKey: ThemePalleteKeys
  ): ColorContainerV3 {
    const themeOrCustomColor = ThemeOrCustomColor.themeColor(
      colorType,
      paletteKey
    );
    const colorContainerV3 = new ColorContainerV3(
      ColorType.SOLID,
      new SolidColor(themeOrCustomColor)
    );
    return colorContainerV3;
  }
  static solidCustomColor(rgba: RGBA): ColorContainerV3 {
    const themeOrCustomColor = ThemeOrCustomColor.customColor(rgba);
    const colorContainerV3 = new ColorContainerV3(
      ColorType.SOLID,
      new SolidColor(themeOrCustomColor)
    );
    return colorContainerV3;
  }
  static newSolidColor(): ColorContainerV3 {
    const themeOrCustomColor = new ThemeOrCustomColor(
      RtNone(),
      RtSome(GradientSliderHelper.getWhiteColorInRGBA()),
      0
    );
    const colorContainerV3 = new ColorContainerV3(
      ColorType.SOLID,
      new SolidColor(themeOrCustomColor)
    );
    return colorContainerV3;
  }

  static linearGradient(
    deg: number,
    themeColors: Array<{
      colorType: ThemeColorType;
      paletteKey: ThemePalleteKeys;
      stop: number;
    }>
  ): ColorContainerV3 {
    const colorsMap = new Map();
    themeColors.forEach((themeColor) => {
      const themeOrCustomColor = ThemeOrCustomColor.themeColor(
        themeColor.colorType,
        themeColor.paletteKey,
        themeColor.stop
      );
      const id = IdentityGenerator.guid();
      colorsMap.set(id, themeOrCustomColor);
    });
    const colorContainerV3 = new ColorContainerV3(
      ColorType.LINEAR,
      new LinearGradient(deg, colorsMap)
    );
    return colorContainerV3;
  }

  static newLinearColor(
    selectedColors: RtOption<Map<string, ThemeOrCustomColor>> = RtNone()
  ): ColorContainerV3 {
    let colors;
    if (selectedColors.isDefined) {
      colors = selectedColors.get;
    } else {
      colors = new Map();
      const defaultStartColor = new ThemeOrCustomColor(
        RtNone(),
        RtSome(GradientSliderHelper.getWhiteColorInRGBA()),
        0
      );
      const defaultEndColor = new ThemeOrCustomColor(
        RtNone(),
        RtSome(GradientSliderHelper.getWhiteColorInRGBA()),
        100
      );
      colors.set(IdentityGenerator.guid(), defaultStartColor);
      colors.set(IdentityGenerator.guid(), defaultEndColor);
    }
    const colorContainerV3 = new ColorContainerV3(
      ColorType.LINEAR,
      new LinearGradient(90, colors)
    );
    return colorContainerV3;
  }

  static newRadialColor(
    selectedColors: RtOption<Map<string, ThemeOrCustomColor>> = RtNone()
  ): ColorContainerV3 {
    let colors;
    if (selectedColors.isDefined) {
      colors = selectedColors.get;
    } else {
      colors = new Map();
      const defaultStartColor = new ThemeOrCustomColor(
        RtNone(),
        RtSome(GradientSliderHelper.getWhiteColorInRGBA()),
        0
      );
      const defaultEndColor = new ThemeOrCustomColor(
        RtNone(),
        RtSome(GradientSliderHelper.getWhiteColorInRGBA()),
        100
      );
      colors.set(IdentityGenerator.guid(), defaultStartColor);
      colors.set(IdentityGenerator.guid(), defaultEndColor);
    }
    const colorContainerV3 = new ColorContainerV3(
      ColorType.RADIAL,
      new RadialGradient(colors)
    );
    return colorContainerV3;
  }

  static parse(jsonValue: string): ColorContainerV3 {
    const colorContainerParsed: ColorContainerV3 = JSON.parse(
      jsonValue,
      JsonHelper.reviver
    );
    let colorDefinition;
    switch (colorContainerParsed.colorType) {
      case ColorType.SOLID:
        colorDefinition = SolidColor.parse(
          colorContainerParsed.colorDefinition as SolidColor
        );
        break;
      case ColorType.LINEAR:
        colorDefinition = LinearGradient.parse(
          colorContainerParsed.colorDefinition as LinearGradient
        );
        break;
      case ColorType.RADIAL:
        colorDefinition = RadialGradient.parse(
          colorContainerParsed.colorDefinition as RadialGradient
        );
        break;
      default:
        throw new Error('ColorType did not match');
    }
    const reConstructedColorContainer = new ColorContainerV3(
      colorContainerParsed.colorType,
      colorDefinition
    );
    return reConstructedColorContainer;
  }
}

export class ColorAttributeV3
  extends Attribute<ColorContainerV3, string>
  implements MobiAttribute<ColorContainerV3, string> {
  constructor(
    public name: string,
    public valueOpt: RtOption<ColorContainerV3> = RtNone(),
    public cssSolidColorAttributeName: RtOption<string> = RtNone(),
    public cssGradientColorAttributeName: RtOption<string> = RtNone()
  ) {
    super(
      name,
      AttributeGroup.APPERANCE,
      'object',
      AttributeType.CSS,
      valueOpt.map((v) => () => v)
    );
  }
  buildMobiUsableValue(
    resolvedValue: RtOption<ColorContainerV3>
  ): UsableAttributeValue<string>[] {
    if (resolvedValue.isDefined) {
      const container = resolvedValue.get;
      const usableCSSValue = container.usableCSSValue;
      const cssAttributeName = this.getCssAttributeName(container);
      const usableAttributeValue = new UsableAttributeValue(
        cssAttributeName,
        usableCSSValue,
        this.attributeType
      );
      return [usableAttributeValue];
    } else {
      const usableAttributeValue = new UsableAttributeValue(
        this.name,
        'transparent',
        this.attributeType
      );
      return [usableAttributeValue];
    }
  }

  generateTSCode(): string {
    return '';
  }

  parseModel(jsonValue: string): ColorContainerV3 {
    return ColorContainerV3.parse(jsonValue);
  }

  buildUsableValue(
    resolvedValue: RtOption<ColorContainerV3>
  ): UsableAttributeValue<string>[] {
    if (resolvedValue.isDefined) {
      const container = resolvedValue.get;
      const usableCSSValue = container.usableCSSValue;
      const cssAttributeName = this.getCssAttributeName(container);
      const usableAttributeValue = new UsableAttributeValue(
        cssAttributeName,
        usableCSSValue,
        this.attributeType
      );
      return [usableAttributeValue];
    } else {
      const usableAttributeValue = new UsableAttributeValue(
        this.name,
        'transparent',
        this.attributeType
      );
      return [usableAttributeValue];
    }
  }

  private getCssAttributeName(colorContainer: ColorContainerV3) {
    if (colorContainer.colorType === ColorType.SOLID) {
      return this.cssSolidColorAttributeName
        .map((p) => p)
        .getOrElse(() => this.name);
    } else {
      return this.cssGradientColorAttributeName
        .map((p) => p)
        .getOrElse(() => this.name);
    }
  }

  public attributeThemeType() {
    return AttributeThemeType.THEMEABLE_ATTRIBUTE;
  }

  clone(): Attribute<unknown, unknown> {
    return new ColorAttributeV3(
      this.name,
      this.valueOpt,
      this.cssSolidColorAttributeName,
      this.cssGradientColorAttributeName
    );
  }

  /*
    color
    background-color
    background-image
    border-color
    border-image
  */
}
