import { ConnectionStatus } from "@capacitor/network";
import { RtNone } from '../../../../../utils/option-helper';
import { GeneralHelper } from "../../../../../utils/general-helper";
import { BehaviorSubject, Subscription } from "rxjs";
import { DsResult } from "../../../../public_api";
import { DataStateType } from "../../child-rendered-event";
import { AppEvent } from "../../event/event-models";
import { ProducerType } from "../../event/event-schema-provider";
import { StandardEvents } from "../../event/event-types";

export abstract class ConnectivityStatusProvider {

    subscriptions: Subscription[] = [];

    public netWorkChangeSubs = new BehaviorSubject<boolean>(true);

    protected isConnected: boolean;
    constructor() {
        this.isConnectionAvailable().then((status: ConnectionStatus) => {
            if (GeneralHelper.isWifiOrCellularrNetwork(status)) {
                this.isConnected = status.connected;
            } else {
                this.isConnected = false;
            }
            this.netWorkChangeSubs.next(this.isConnected);
        })
        this.handleNetworkConnections();
    }

    abstract isConnectionAvailable(): Promise<ConnectionStatus>;

    abstract handleNetworkConnections();

     getAppEventPayload(eventType: StandardEvents): AppEvent {
        const event: AppEvent = {
            eventType: eventType,
            eventProducerName: 'App load',
            dsResults: [new DsResult('', '', [], [], false, false, DataStateType.NEW)],
            subEventType: RtNone(),
            producerType: ProducerType.GLOBAL_EVENT_DATA
        };
        return event;
    }

    abstract isOnline(): boolean;
}