import { AdvancedFilter, SortingField, SortingOperator, SortingProperty } from '../../models/base.models';
import { OrganizationDate } from '../../utils/org-date-helper';

export class SharedHelper {
  static isDateTime(dateTime: string) {
    const _regExp = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/;
    return _regExp.test(dateTime);
  }

  static isValueList(buffer) {
    return buffer && buffer.getValue() && Array.isArray(buffer.getValue());
  }
  static isValue(buffer) {
    return buffer && buffer.getValue() && !Array.isArray(buffer.getValue());
  }
  static isDateTimeValue(buffer) {
    return buffer && buffer.getValue() && SharedHelper.isDateTime(buffer.getValue());
  }
  static isDateRange(buffer) {
    return buffer && buffer.getValue() && typeof buffer.getValue() === 'object' && buffer.getValue()['startDateTime'] && buffer.getValue()['endDateTime'];
  }


  static isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  static loadSortingProperties(filter: AdvancedFilter): AdvancedFilter {
    filter.sortingProperties = new SortingProperty([new SortingField('createDate', 1)]);
    filter.sortingProperties.sortingOperator = SortingOperator.DESCENDING_ORDER;
    return filter;
  }

  static groupByAndReturnObject(objectArray: any[], property: string): object {
    const groups = objectArray.reduce((acc: { [x: string]: any[]; }, obj: { [x: string]: any; }) => {
      const key = obj[property] || "NA";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    return groups;
  }

  static isDate(str: string): boolean {
    const res = (/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(str));
    return res;
  }

  static isIsoDate(str: string): boolean {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) { return false; }
    return OrganizationDate.convertDateToISOString(str) === str;
  }

  static sortArrayByPropertyName(key: string | number, order = 'asc') {
    const result = (a, b) => {
      if (!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      let varA = this.isIsoDate(a[key]) || this.isDate((a[key])) ?
        new Date(a[key]) : a[key];
      let varB = this.isIsoDate(b[key]) || this.isDate((b[key])) ?
        new Date(b[key]) : b[key];

      varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ?
          (comparison * -1) : comparison
      );
    };
    return result;
  }


  static isISOString(datetime: string) {
    const isoRegx = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/;
    return isoRegx.test(datetime);
  }

  static roundUpToMultipleofEight(x: number) { return ((x + 7) & (-8)); }


}
