import { SchemaControlType } from "../../../../models/schema/schema-control-type";

import { FormFieldType } from "./form-field-type";

export abstract class FormControlRegistyProvider {

    formControls: Map<string, () => Promise<FormFieldType<any>>> = new Map();

    abstract registerFormControls(): void;

    constructor() {
        this.registerFormControls();
    }

    getFieldType(controlType: SchemaControlType): () => Promise<FormFieldType<unknown>> {
        return this.formControls.get(controlType);
    }

}