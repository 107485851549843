import { ExpressionFunction } from "../ExpressionRegistry";

export enum UtilityEnum{
    ALL_FUNCTIONS_WITH_DEF_PARAMS = 'getFunctionListWithDefaultParams'
}

export class UtilityHelper {
    static readonly functionList : ExpressionFunction[] = [
        {
            functionName : "resolveExpression(expression)",
            toolTipMessage : "Returns the evaluated value, in this we can perform arithmetic operations.",
            returnType : 'string'
        }
    ];

    static resolveExpression(expression: string, options? : { params : any , isUpdateMode: boolean }) {
        try {
            return eval(expression);
        } catch (error) {
            console.log(error?.message);
            return null
        }
    }
   
    static getFunctionListWithDefaultParams() : ExpressionFunction[]{
        return UtilityHelper.functionList;
    }
}


