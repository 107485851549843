import { ChartAttributesEnum, ChartDataSourceEnum, ChartLegendValuesEnum, ChartTypes, ChartDateXAxisFormat, DefaultConfig } from 'projects/den-core/src/lib/models/chart/chart-models';
import { ChartDataConstructionHelper } from './chart-data-construction-helper';
import { BarChartDefaultAttributeBindingHelper, BubbleChartDefaultAttributeBindingHelper, CombinationChartDefaultAttributeBindingHelper, DefaultAttributeBindingHelper, GuageChartDefaultAttributeBindingHelper, NumberCardChartDefaultAttributeBindingHelper, PieChartDefaultAttributeBindingHelper, PolarChartDefaultAttributeBindingHelper, ScaleDefaultAttributeBindingHelper } from './chart-attribute-binding-helper';
import { ChartColorBindingHelper } from './chart-color-binding-helper';
import { ListChartDataConstructionHelper } from './list-chart-data-construction-helper';
import { VerticalBarChartEnum } from '../constants/core-chart-attribute-constant';
import { DsResult, DsResultValue } from '../../../../data-source/data-source-result-entities';
import { ControlPropertyDefinitionValue } from '../../../core/control-instance';
import { UsableAttributeValue, WebControlRegistryConstant } from 'projects/den-core/page-builder';

export class ChartHelper {
    // TODO nag- update the reference type
    static addOrUpdateData(reference: any, newKpiObj: DsResult): any {
        let existedObj = (reference.dataSource || []).find(ds => ds.id === newKpiObj.id);
        if (existedObj) {
            existedObj = Object.assign(existedObj, newKpiObj);
        } else {
            this.addItem(reference, newKpiObj);
        }
        return reference.dataSource;
    }

    static updateGridWithDataSource(reference: any) {
        reference.chartData = [];
        reference.multiSeries = [];
        if (reference.xAxisProperty && (reference.yAxisPropertyList.length || reference.yAxisProperty) && reference.dataSource?.length) {
            setTimeout(() => {
                if (reference.isStaticDataSource) {
                    this.createStaticChartData(reference);
                } else if (reference.isArrayResponse) {
                    this.createDynamicListChartData(reference, reference.dataSource);
                    this.setWidthForVerticalBarChart(reference);
                } else {
                    this.createDynamicChartData(reference, reference.dataSource);
                }
                this.intializeChartAttributes(reference);
            });
        }
    }

    static updateGridColumnsDefinitionValue(reference: any) {
        reference.controlInstance.propertyDefinitions.forEach((attrConfiguration: ControlPropertyDefinitionValue) => {
            if (attrConfiguration && attrConfiguration.controlAttributeName === ChartDataSourceEnum.XAXIS_PROPERTY && typeof attrConfiguration.dsPropertyName === 'string') {
                reference.xAxisProperty = attrConfiguration.dsPropertyName;
            }
            if (attrConfiguration && attrConfiguration.controlAttributeName === ChartDataSourceEnum.YAXIS_PROPERTY_LIST && Array.isArray(attrConfiguration.dsPropertyName)) {
                reference.yAxisPropertyList = attrConfiguration.dsPropertyName;
            }
            if (attrConfiguration && attrConfiguration.controlAttributeName === ChartDataSourceEnum.LINE_YAXIS_PROPERTY_LIST
                && (Array.isArray(attrConfiguration.dsPropertyName) || typeof attrConfiguration.dsPropertyName === 'string')) {
                reference.yAxisLinePropertyList = attrConfiguration.dsPropertyName;
            }
            if (attrConfiguration && attrConfiguration.controlAttributeName === ChartDataSourceEnum.COLOR_CODE && typeof attrConfiguration.dsPropertyName === 'string') {
                reference.extraColorProp = attrConfiguration.dsPropertyName;
            }
            if (attrConfiguration && attrConfiguration.controlAttributeName === ChartDataSourceEnum.GROUP_BY_PROPERTY && typeof attrConfiguration.dsPropertyName === 'string') {
                reference.groupByProperty = attrConfiguration.dsPropertyName;
            }
            if (attrConfiguration && attrConfiguration.controlAttributeName === ChartDataSourceEnum.YAXIS_PROPERTY && typeof attrConfiguration.dsPropertyName === 'string') {
                reference.yAxisProperty = attrConfiguration.dsPropertyName;
            }
            if (attrConfiguration && attrConfiguration.controlAttributeName === ChartDataSourceEnum.PARENT_FIELD && typeof attrConfiguration.dsPropertyName === 'string') {
                reference.parentField = attrConfiguration.dsPropertyName;
            }
            if (attrConfiguration && attrConfiguration.controlAttributeName === ChartDataSourceEnum.LEGEND_PROPERTY) {
                reference.legendPropertyDefinition = attrConfiguration;
            }

        });
    }

    static applyDefaultConfigurationAttributeValues(reference: any) {
        reference = DefaultAttributeBindingHelper.applyCommonChartAttributes(reference);
        reference = PieChartDefaultAttributeBindingHelper.applyPieChartAttributeValues(reference);
        reference = GuageChartDefaultAttributeBindingHelper.applyDefaultGaugeAttributeValues(reference);
        reference = PolarChartDefaultAttributeBindingHelper.applyDefaultPolarChartAttributeValues(reference);
        reference = BarChartDefaultAttributeBindingHelper.applyDefaultBarChartAttributeValues(reference);
        reference = BubbleChartDefaultAttributeBindingHelper.applyDefaultBubbleChartAttributeValues(reference);
        reference = ScaleDefaultAttributeBindingHelper.applyDefaultScaleAttributeValues(reference);
        reference = CombinationChartDefaultAttributeBindingHelper.applyDefaultCombinationChartAttributeValues(reference);
        reference = NumberCardChartDefaultAttributeBindingHelper.applyDefaultNumberCardChartAttributeValues(reference);
    }

    static applyDefaultColorAttributeValues(reference: any) {
        reference = ChartColorBindingHelper.applyDefaultColorConfigurationAttributes(reference);
    }

    private static createStaticChartData(reference: any) {
        const layerPadding = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LAYER_PADDING] || 10;
        const layerHeight = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LAYER_HEIGHT] || 50;
        const chartData = reference.dataSource;
        const xAxisProperty = reference.xAxisProperty;
        reference.chartData = ChartDataConstructionHelper.constructStaticChartData(chartData, layerPadding, layerHeight, xAxisProperty);
    }

    private static createDynamicChartData(reference: any, chartDsRes: DsResultValue[]) {
        const chartType = this.getChartType(reference);
        let xAxisDateFormat: ChartDateXAxisFormat = ChartHelper.constructXAxisDateFormat(reference);
        if (reference.isGroupedChartType) {
            reference.chartData = ChartDataConstructionHelper.constructGroupChartDataForSingle(chartDsRes, reference.xAxisProperty, reference.yAxisPropertyList, xAxisDateFormat);
        } else if (chartType === ChartTypes.pie) {
            reference.chartData = ChartDataConstructionHelper.constructPieChartSeries(chartDsRes, reference.xAxisProperty, reference.yAxisPropertyList);
        } else if (this.isBubbleChartType(reference)) {
            reference.chartData = ChartDataConstructionHelper.constructBubbleChartData(chartDsRes, reference.xAxisProperty, reference.yAxisPropertyList, xAxisDateFormat);
        } else if (this.isBarLineChartType(reference)) {
            reference.multiSeries = ChartDataConstructionHelper.constructComboChartGroupData(chartDsRes, reference.xAxisProperty, reference.yAxisLinePropertyList, xAxisDateFormat);
            reference.chartData = ChartDataConstructionHelper.constructChartSeries(chartDsRes, reference.xAxisProperty, reference.yAxisPropertyList, xAxisDateFormat);
        } else if (this.isStackedVerticalBarLineChartType(reference)) {
            reference.multiSeries = ChartDataConstructionHelper.constructStackedComboChartLineData(reference.dataSource, reference.xAxisProperty, reference.yAxisLinePropertyList, xAxisDateFormat);
            reference.chartData = ChartDataConstructionHelper.constructStackedComboChartGroupData(reference.dataSource, reference.xAxisProperty, reference.yAxisProperty, reference.groupByProperty, xAxisDateFormat);
        } else {
            reference.chartData = ChartDataConstructionHelper.constructChartSeries(chartDsRes, reference.xAxisProperty, reference.yAxisPropertyList, xAxisDateFormat);
        }
    }

    private static createDynamicListChartData(reference: any, chartDsRes: DsResultValue[][]) {
        let xAxisDateFormat: ChartDateXAxisFormat = this.constructXAxisDateFormat(reference);
        if (this.isLineAreaChartType(reference)) {
            reference.chartData = ListChartDataConstructionHelper.handleLineAreaChart(chartDsRes,
                reference.xAxisProperty, reference.yAxisPropertyList, xAxisDateFormat);
        } else if (this.isBarLineChartType(reference) || this.isStackedVerticalBarLineChartType(reference)) {
            reference.multiSeries = ListChartDataConstructionHelper.handleLineAreaChart(chartDsRes,
                reference.xAxisProperty, reference.yAxisLinePropertyList, xAxisDateFormat);
            reference.chartData = ListChartDataConstructionHelper.handleChartDsResultArray(chartDsRes,
                reference.xAxisProperty, reference.yAxisPropertyList,
                reference.yAxisProperty, xAxisDateFormat);
        } else if (this.isGroupedChartType(reference)) {
            reference.chartData = ListChartDataConstructionHelper.handleGroupBarChart(
                chartDsRes, reference.xAxisProperty,
                reference.yAxisPropertyList, xAxisDateFormat);
        } else {
            reference.chartData = ListChartDataConstructionHelper.handleChartDsResultArray(chartDsRes,
                reference.xAxisProperty, reference.yAxisPropertyList,
                reference.yAxisProperty, xAxisDateFormat, reference.parentField);
            if (reference.controlInstance.controlName === WebControlRegistryConstant.TREE_MAP) {
                reference.allChartData = reference.chartData;
            }
        }
    }


    private static constructXAxisDateFormat(reference: any) {
        let xAxisDateFormat: ChartDateXAxisFormat;
        if (this.canApplyDateFormatOnXAxis(reference)) {
            xAxisDateFormat = this.getXAxisFormat(reference);
        }
        return xAxisDateFormat;
    }

    private static addItem(reference: any, newKpiObj: DsResult) {
        if (reference.dataSource) {
            reference.dataSource.push(newKpiObj);
        } else {
            reference.dataSource = [newKpiObj];
        }
    }

    private static intializeChartAttributes(reference: any) {
        const chartType = this.getChartType(reference);
        this.legendValuesForChart(chartType, reference);
        this.applyDefaultColorAttributeValues(reference);
        this.applyDefaultConfigurationAttributeValues(reference);
        this.dimensionsForCharts(reference);
    }
    private static legendValuesForChart(chartType: any, reference: any) {
        if (this.isGroupedBarOrPieChart(chartType, reference) || this.xaxisLegendOption && Array.isArray(reference.dataSource)) {
            reference[ChartAttributesEnum.LEGEND_VALUES] = ChartDataConstructionHelper.getPieAndGroupedBarChartLegendValues(reference.dataSource, reference.yAxisPropertyList[0], reference?.legendPropertyDefinition);
        } else if (this.isBarLineChartType(reference)) {
            reference[ChartAttributesEnum.LEGEND_VALUES] = [...reference.yAxisPropertyList, ...reference.yAxisLinePropertyList || []];
        } else if (this.isStackedVerticalBarLineChartType(reference)) {
            reference[ChartAttributesEnum.LEGEND_VALUES] = ChartDataConstructionHelper.getStackedBarLineChartLegendValues(reference.dataSource, reference.groupByProperty);
        } else {
            reference[ChartAttributesEnum.LEGEND_VALUES] = reference.yAxisPropertyList;
        }
    }

    private static dimensionsForCharts(reference: any) {
        reference.barPadding = reference.barPadding || reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BAR_PADDING];
        reference.dimensions = [reference.CONFIG_BINDINGS[reference.BINDING_PROPS.WIDTH], reference.CONFIG_BINDINGS[reference.BINDING_PROPS.HEIGHT]];
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.CHART_HEIGHT] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.HEIGHT] || 300;
    }

    private static isGroupedBarOrPieChart(chartType: any, reference: any) {
        return (chartType === ChartTypes.pie ||
            this.isGroupedChartType(reference));
    }
    private static xaxisLegendOption(reference: any) {
        return (reference[ChartAttributesEnum.LEGEND_OPTION] === ChartLegendValuesEnum.xAxis);

    }

    private static getChartType(reference: any) {
        if (reference.CONFIG_BINDINGS[reference.BINDING_PROPS.PIE_CHART_TYPES] && reference.CONFIG_BINDINGS[reference.BINDING_PROPS.PIE_CHART_TYPES].type) {
            return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.PIE_CHART_TYPES].type
        } else {
            return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.PIE_CHART_TYPES]
        }
    }

    private static isBarLineChartType(reference: any) {
        return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BAR_LINE_CHART_TYPES] && reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BAR_LINE_CHART_TYPES].type === ChartTypes.barLineChart;
    }

    private static isStackedVerticalBarLineChartType(reference: any) {
        return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.STACKED_VERTICAL_BAR_LINE_CHART_TYPES] && reference.CONFIG_BINDINGS[reference.BINDING_PROPS.STACKED_VERTICAL_BAR_LINE_CHART_TYPES].type === ChartTypes.stackedBarLineChart;
    }

    private static isBubbleChartType(reference: any) {
        return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BUBBLE_CHART_TYPES] && reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BUBBLE_CHART_TYPES].type === ChartTypes.bubbleChart
    }

    private static isLineAreaChartType(reference: any) {
        return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LINE_AREA_CHART_TYPE];
    }

    private static isGroupedChartType(reference: any) {
        return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.GROUP_BAR_CHART_TYPE];
    }



    private static getXAxisFormat(reference: any): ChartDateXAxisFormat {
        const inputDateFormat = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.X_AXIS_DATE_INPUT_FORMAT];
        const outputFormat = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.X_AXIS_DATE_OUTPUT_FORMAT];
        return inputDateFormat && outputFormat ? { inputFormat: inputDateFormat, outputFormat: outputFormat } : null;
    }

    private static canApplyDateFormatOnXAxis(reference): boolean {
        return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.APPLY_X_AXIS_DATE_FORMAT];
    }

    /** Set spacing for bar charts with less no of data as per ds length */
    static setWidthForVerticalBarChart(reference: any) {
        if (reference.CONFIG_BINDINGS[ChartAttributesEnum.BAR_PADDING]) {
            reference.barPadding = reference.CONFIG_BINDINGS[ChartAttributesEnum.BAR_PADDING];
            return
        }
        if (reference.CONFIG_BINDINGS[reference.BINDING_PROPS.GROUP_BAR_CHART_TYPE] == ChartTypes.stackedVertivalBarChart || reference.CONFIG_BINDINGS[reference.BINDING_PROPS.GROUP_BAR_CHART_TYPE] == ChartTypes.normalisedVerticalBarChart) {
            reference.barPadding = null;
            const dsLength = reference.chartData.length;
            const defaultBarPaddingValue = VerticalBarChartEnum.DEFAULT_BAR_PADDING_VALUE;
            if (dsLength && dsLength < 6) {
                const isFitContainer = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.FIT_CONTAINER];
                const parentWidth = reference.parentElementRef.nativeElement.getBoundingClientRect()?.width;
                const configuredWidth = isFitContainer ? parentWidth : reference.CONFIG_BINDINGS[reference.BINDING_PROPS.WIDTH];
                let barPadding = configuredWidth / (dsLength * dsLength);
                if (isFitContainer) {
                    const fitContainerPaddingAdjustmentConstant = parentWidth * VerticalBarChartEnum.PARENT_WIDTH_ADJUSTMENT_PERCENTAGE;
                    barPadding += fitContainerPaddingAdjustmentConstant - (dsLength * 60);
                } else {
                    barPadding += dsLength * 10;
                }
                barPadding = Math.ceil(barPadding);
                reference.barPadding = (barPadding < defaultBarPaddingValue) ? defaultBarPaddingValue : barPadding;
            }
        }
    }
    static extractScaleValues(configurationAttributeValues: UsableAttributeValue<unknown>[]) {
        const scaleDetails = { xScaleMin: null, xScaleMax: null, yScaleMin: null, yScaleMax: null, interval: null }
        const xScaleMin = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.X_SCALE_MIN);
        const xScaleMax = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.X_SCALE_MAX);
        const yScaleMin = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.Y_SCALE_MIN);
        const yScaleMax = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.Y_SCALE_MAX);
        scaleDetails.xScaleMin = this.setValue(xScaleMin?.value || null);
        scaleDetails.xScaleMax = this.setValue(xScaleMax?.value || null);
        scaleDetails.yScaleMin = this.setValue(yScaleMin?.value || null);
        scaleDetails.yScaleMax = this.setValue(yScaleMax?.value || null);
        return scaleDetails;
    }


    private static setValue(scale) {
        if (scale === DefaultConfig.AUTO) {
            return null;
        }
        if (scale === DefaultConfig.DYNAMIC) {
            return DefaultConfig.DYNAMIC;
        }

        const parsedScale = JSON.parse(scale);
        return typeof parsedScale === "number" ? parsedScale : null;
    }


    static setDynamicValue(scale: string, value: number) {
        if (scale === DefaultConfig.DYNAMIC) {
            return value ?? null;
        }
        return scale;
    }

    static setValueToScaleIfDynamic(extendedDSResultList, yProperty: string, xProperty: string, scaleDetails) {
        if (!extendedDSResultList.length) return;
        const yMin = this.findMinAndMaxNumber(extendedDSResultList, yProperty, "min");
        const yMax = this.findMinAndMaxNumber(extendedDSResultList, yProperty, "max");
        const xMin = this.findMinAndMaxNumber(extendedDSResultList, xProperty, "min");
        const xMax = this.findMinAndMaxNumber(extendedDSResultList, xProperty, "max");
        scaleDetails.yScaleMin = ChartHelper.setDynamicValue(scaleDetails.yScaleMin, yMin);
        scaleDetails.yScaleMax = ChartHelper.setDynamicValue(scaleDetails.yScaleMax, yMax);
        scaleDetails.xScaleMin = ChartHelper.setDynamicValue(scaleDetails.xScaleMin, xMin);
        scaleDetails.xScaleMax = ChartHelper.setDynamicValue(scaleDetails.xScaleMax, xMax);
    }


    static findMinAndMaxNumber(extendedDSResultList, axisLabel: string, minMax: string) {
        const values = extendedDSResultList.map(x => x.getValueByFieldName(axisLabel)).filter(value => typeof value === "number");
        if (values.length === 0) return null;
        return minMax === "min" ? Math.min(...values) - 100 : Math.max(...values) + 100;
    }

}
