import { ButtonConfirmationModel } from "../../../../../../../../src/lib/page-builder/attributes/button-confirmation/button-confirmation-attribute";
import { EventAction } from "../../event-store";

export type NavigateAction = {
  navigateType: NavigateType,
  navigateTo: string,
  queryParams: Map<string, NavigationParamMap>,
  actionConfirmationDialog:ButtonConfirmationModel
} & EventAction

export enum NavigateType {
  PAGE = "page",
  EXTERNAL_LINK = "external_link"
}

export type NavigationParamMap = { isRequired: boolean };
