import { RtNone, RtOption } from "../../../utils/option-helper";
import { AttributeGroup } from "../../constants/attribute-group-constant";
import { AttributeRegistryConstant } from "../../constants/attribute-registry-constants";
import { Attribute, AttributeType, MobiAttribute } from "../core/attribute";
import { UsableAttributeValue } from "../core/attribute-instance";

export type FormEventDataMapper = { producerName: string, actionId: string, eventName: string, fieldMapper: { producerFieldName: string, controlFieldName: string, canAssignValueInUpdateMode: boolean, canRetainSubcription: boolean }[] };
export class FormEventDataMapperAttribute extends Attribute<FormEventDataMapper[], FormEventDataMapper[]> implements MobiAttribute<FormEventDataMapper[], FormEventDataMapper[]> {

  constructor(public eventNames: string[]) {
    super(AttributeRegistryConstant.FORM_EVENT_DATA_MAPPER, AttributeGroup.EVENT_DATA_MAPPER, "object", AttributeType.CONFIGURATION, RtNone());
  }

  buildMobiUsableValue(resolvedValue: RtOption<FormEventDataMapper[]>): UsableAttributeValue<FormEventDataMapper[]>[] {
    return this.buildUsableValue(resolvedValue);
  }
  generateTSCode(): string {
    return "";
  }
  clone(): Attribute<unknown, unknown> {
    return new FormEventDataMapperAttribute(this.eventNames);
  }
  parseModel(jsonValue: string): FormEventDataMapper[] {
    const eventDataBinder: FormEventDataMapper[] = JSON.parse(jsonValue);
    return eventDataBinder;
  }
  buildUsableValue(resolvedValue: RtOption<FormEventDataMapper[]>): UsableAttributeValue<FormEventDataMapper[]>[] {
    if (resolvedValue.isDefined) {
      const usableAttributeValue = new UsableAttributeValue(this.name, resolvedValue.get, this.attributeType);
      return [usableAttributeValue];
    } else {
      return [];
    }
  }

}
