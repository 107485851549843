import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RtNone, RtOption } from '../../utils/option-helper';


type CallbackFunction<T = any> = (event: T) => void;

interface EventSubject<T> {
  topic: string;
  data: T;
}


// A Message Bus Using An RxJS Subject
// credits: https://www.bennadel.com/blog/3518-trying-to-create-a-message-bus-using-an-rxjs-subject-in-angular-6-1-10.html

// @Injectable({
//   providedIn: 'root'
// })
@Injectable({ providedIn: 'root' })
export class EventService {

  private eventStream = new Subject();
  private subscriptions: { topic: string, dataStream: BehaviorSubject<any>, pageName: string }[] = [];
  private initiatorSubscriptions: { topic: string, initiatorId: string }[] = [];
  private globaltopicRegistry = new Map<string, string[]>();
  // Initialize the event service.
  constructor() { 
   
  }

  getSubscriptions() {
    return this.subscriptions;
  }

  public setGlobalTopics(pageName: string, topic: string){
    const exists = this.globaltopicRegistry.has(pageName);
    if(exists){
      let topics = this.globaltopicRegistry.get(pageName)
      topics.push(topic);
      this.globaltopicRegistry.set(pageName, topics);
    }else{
      this.globaltopicRegistry.set(pageName, new Array(topic));
    }
  }

  public clearGlobalTopics(pageName: string){
    const topics = this.globaltopicRegistry.get(pageName);
    if(topics){
      topics.forEach(topic => this.unsubscribe(topic));
      this.globaltopicRegistry.delete(pageName);
    }
    
  }
  // Push the given event onto the eventStream.
  public publish(topic: string, data: any, initiatorIdOpt: RtOption<string> = RtNone(), pageName: string = null): void {
    
    const payload = { topic: topic, data: data };
    if (initiatorIdOpt.isDefined) {
      this.initiatorSubscriptions.push({ topic: topic, initiatorId: initiatorIdOpt.get });
    }

    this.eventStream.next(payload);
    let subscription = this.subscriptions.find(s => s.topic === topic);
    if (!subscription) {
      subscription = this.createDataStreamForTopic(topic, pageName);
    }
    subscription.dataStream.next(payload);
  }

  public clear(pageName: string) {
    const subscriptions = this.subscriptions.filter(sub => !sub.pageName || sub.pageName == pageName);
    subscriptions.forEach(s => s.dataStream.unsubscribe());
    this.subscriptions = this.subscriptions.filter(sub => sub.pageName && sub.pageName != pageName);
  }

  private createDataStreamForTopic(topic: string, pageName: string): { topic: string, dataStream: BehaviorSubject<any>, pageName: string } {
    const subscriptionStream: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    const subscription = { topic: topic, dataStream: subscriptionStream, pageName: pageName};
    this.subscriptions.push(subscription);
    return subscription;
  }
  // Subscribe to the event, but only invoke the callback when the event is
  // of the given newable type (ie, it's a Class definition, not an instance).
  // --
  public subscribe<U>(topic: string, callback: CallbackFunction<U>, canSendOnePreviousValue: boolean = false, pageName: string = null): Subscription {
    let subscription: Subscription;
    if (canSendOnePreviousValue) {
      let sub = this.subscriptions.find(s => s.topic === topic);
      if (!sub) {
        sub = this.createDataStreamForTopic(topic, pageName);
      }
      subscription = sub.dataStream
        .pipe(
          filter((event: EventSubject<U>): boolean => {
            return (event && event.topic === topic);
          })
        )
        .subscribe((event: EventSubject<U>): void => {
          try {
            callback(event.data);
          } catch (error) {
            // this.errorHandler.handleError(error);
          }
        });
    } else {
      subscription = this.eventStream
        .pipe(filter((event: any) => event && event.topic === topic))
        .subscribe((event: EventSubject<U>): void => {
          try {
            callback(event.data);
          } catch (error) {
            // this.errorHandler.handleError(error);
          }
        });
    }
    return subscription;
  }

  unsubscribe(topic: string, initiatorIdOpt: RtOption<string> = RtNone()) {
    if (initiatorIdOpt.isDefined) {
      this.initiatorSubscriptions = this.initiatorSubscriptions.filter(sub => {
        if (sub.topic === topic && sub.initiatorId === initiatorIdOpt.get) {
          this.unsubscribeDataStreamSubject(topic);
          this.subscriptions = this.subscriptions.filter(s => s.topic !== topic);
          return false;
        } else {
          // do nothing
          return true;
        }
      });
    } else {
      this.unsubscribeDataStreamSubject(topic);
    }
    // this.unsubscribeDataStreamSubject(topic);
  }
  unsubscribeDataStreamSubject(topic: string) {
    const subscription = this.subscriptions.find(s => s.topic === topic);
    if (subscription) {
      this.subscriptions = this.subscriptions.filter(s => s.topic !== topic);
      subscription.dataStream.unsubscribe();
    }
  }
  resetDataStreamSubject(topic: string) {
    const subscription = this.subscriptions.find(s => s.topic === topic);
    if (subscription) {
      subscription.dataStream.next(null);
    }
  }
}
