import { DsResultValue } from "../data-source/data-source-result-entities";
import { ExtendedDsResultValue } from "./extended-ds";

export class FKResolverHelper {

    static getInstanceId(instanceId: string) {
        if (instanceId.indexOf('~') >= 0) {
            return instanceId.split('~')[0];
        }
        return instanceId;
    }
    static getValueByPropertyNameFromDsResultValueList(data: DsResultValue[], propertyName: string) {
        const dsResultValue = data.find(dsrv=> dsrv.fieldName === propertyName);
        return dsResultValue ? dsResultValue.originalValue :  null;
    }
    static resolveFkeys(value: string, dataSource: ExtendedDsResultValue, fkeys: string[] = []) {
        const matches = value ? value.match(/\{([^{}]+)\}/g) : [];
        if (matches && matches.length) {
            matches.forEach(match => {
                    const propertyName = match.replace(/[{}]/g, '');
                    const isPropertyIsApi = fkeys?.some(k => k === propertyName);
                    let ds: string;
                    if (isPropertyIsApi) {

                        const dsvalue = dataSource.getValueByFieldName(propertyName);
                            ds = Array.isArray(dsvalue) ? dsvalue?.join(',') : dsvalue || 'NA';
                        

                    } else {
                        ds = FKResolverHelper.resolveFKEYCustomPropAndDirectValue(propertyName, ds, dataSource, fkeys);

                    }
                    // const ds = dataSource[match.replace(/[{}]/g, '')];
                    value = value ? value.replace(match, (typeof ds === 'number') ? ds : ds || 'NA') : 'NA';
                
            });
        }
        return value;
    }

    private static resolveFKEYCustomPropAndDirectValue(propertyName: string, ds: string, dataSource: ExtendedDsResultValue, fkeys: string[]) {

        ds = dataSource.getValueByFieldName(propertyName);
        return ds;
    }

}