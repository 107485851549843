import { AttributeInstance } from "../../../attributes/core/attribute-instance";
import { StandardControlDrop, StandardDropChildData } from "../../../models/model";
import { ControlInstanceDraftHelper } from "../control-instance-drafts-models";

export class ControlCompanionHelper {


  public static constructControlInstance(data: StandardControlDrop) {
    let { parentInstanceId, parentSectionIndex } = { ...data.parentData };

    //TODO: Review the below. Type casting ...data.childData as ControlDropChildData to avoid compilation issues
    let { ctrlType, controlName, attributes, propertyDefinitions, displayName } = { ...data.childData as StandardDropChildData };

    if (data.isTheControlDroppedFromNewDataSourceMode) {
      //TODO: Save temp data source instances with controlInstanceId
      //dataSourceServiceInstanceName = this.pageService.getTempDataSourceInstances()?.id;
    }

    const cssAttrs: AttributeInstance[] = attributes || [];
    const instanceId = ControlCompanionHelper.generateInstanceId();

    let controlInstance = ControlInstanceDraftHelper.createNewInstance("", instanceId,
      parentInstanceId, parentSectionIndex.isDefined ? parentSectionIndex.get : -1,
      ctrlType, controlName, null, data.parentData.pageName, cssAttrs, null, propertyDefinitions || [], [],null,displayName);
    return controlInstance;
  }

  public static generateInstanceId() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  }
}
