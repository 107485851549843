import { HttpContext, HttpContextToken, HttpHeaders } from "@angular/common/http";

export class TemplateDownloadHelper {
    static SKIP_HEADER_MODIFICATION = new HttpContextToken<string>(() => null);;

    static getOptionsForTemplateDownload(headers: HttpHeaders) {
        return {
            headers,
            responseType: 'blob' as 'json',
            context: new HttpContext().set(TemplateDownloadHelper.SKIP_HEADER_MODIFICATION, 'true')
        }
    }
}
