import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ModalService } from 'projects/den-core';
import { ModalDataContainer } from 'projects/den-core/contants';
import { ConfirmationDialogComponent } from 'projects/den-core/src/lib/components-library/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs/internal/Subscription';

// Credits: https://blog.innoventestech.com/angular-pwa-and-app-update-1cfbf9c78da0
@Injectable({ providedIn: 'root' })
export class AppUpdateService {

    isNewVersionAvailable: boolean = false;
    // intervalSource = interval(15 * 60 * 1000); // every 15 mins
    intervalSubscription: Subscription;

    constructor(private swUpdate: SwUpdate, private zone: NgZone,
        private modalService: ModalService,
    ) {
    }

    checkForUpdate() {
        this.intervalSubscription?.unsubscribe();
        if (!this.swUpdate.isEnabled) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            try {
                setTimeout(() => {
                    this.swUpdate.checkForUpdate().then((available: boolean) => {
                        this.isNewVersionAvailable = available;
                        if (this.isNewVersionAvailable) {
                            this.zone.run(() => {
                                const data: ModalDataContainer = { headerMessage: `New update is available`, message: `A new version of the application is released, please update to get new features.`, confirmBtnLabel: `INSTALL NOW`, cancelBtnLabel: `SKIP` };
                                this.modalService.invoke(ConfirmationDialogComponent, data).subscribe((isConfirm: boolean) => {
                                    if (isConfirm) {
                                        this.applyUpdate();
                                    }
                                });
                            });
                        }
                    });
                }, 60 * 1000);
            } catch (error) {
                console.error('Failed to check for updates:', error);
            }
        });
        // this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe(evt => {
        //     switch (evt.type) {
        //         case 'VERSION_DETECTED':
        //             console.log(`Downloading new app version: ${evt.version.hash}`);
        //             break;
        //         case 'VERSION_READY':
        //             console.log(`Current app version: ${evt.currentVersion.hash}`);
        //             console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
        //             this.isNewVersionAvailable = true;
        //             break;
        //         case 'VERSION_INSTALLATION_FAILED':
        //             console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
        //             break;
        //     }
        // });
    }

    applyUpdate(): void {
        // Reload the page to update to the latest version after the new version is activated
        this.swUpdate.activateUpdate()
            .then(() => document.location.reload())
            .catch(error => console.error('Failed to apply updates:', error));
    }
}