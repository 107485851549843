export class HttpStatusCode {
    static CONNECTION_FAILED = 101;
    static ACCEPTED = 202;
    static NO_CONTENT = 204;
    static BAD_REQUEST = 400;
    static UNAUTHORIZED = 401;
    static FORBIDDEN = 403;
    static NOT_FOUND = 404;
    static METHOD_NOT_ALLOWED = 405;
    static NOT_ACCEPTABLE = 406;
    static REQUEST_TIMEOUT = 408;
    static CONFLICT = 409;
    static PRECONDITION_FAILED = 412;
    static LOCKED = 423;
    static INTERNAL_SERVER_ERROR = 500;
    static BAD_GATEWAY = 502;
    static SERVICE_UNAVAILABLE = 503;
    static FAILED_DEPENDENCY = 424;
    static EXPECTATION_FAILED = 417;
    static TOO_MANY_REQUESTS = 429;
}

export class HttpStatusMessages {
    static CONFLICT = 'An entity with the same name exists.';
    static PRECONDITION_FAILED = 'Some of the fields failed to validate.';
    static NO_CONTENT = 'No entity found.';
    static FORBIDDEN = 'You are not logged in to perform this action. Please login.';
    static UNAUTHORIZED = 'You are unauthorized to perform this action. Please contact system administrator.';
    static INTERNAL_SERVER_ERROR = 'There is internal server error. Please try again after sometime, if the error still exists please contact system admin.';
    static NOT_FOUND = 'The resource you are trying to access can not be found.';
    static REQUEST_TIMEOUT = 'The request to the resource has been timeout.';
    static CONNECTION_FAILED = 'The connection to the server is failed.';
    static METHOD_NOT_ALLOWED = 'The request URL is not allowed.';
    static BAD_REQUEST = 'The data sent with the request is invalid.';
    static NOT_ACCEPTABLE = 'The content not acceptable according to the Accept headers sent in the request.';
    static LOCKED = 'The resource that is being accessed is locked.';
    static BAD_GATEWAY = 'The server received an invalid response from the server.';
    static SERVICE_UNAVAILABLE = 'The server is currently unavailable (because it is overloaded or down for maintenance).';
    static FAILED_DEPENDENCY = 'The request failed because it depended on another request and that request failed';
}

export class HttpRequestTypes {
    static GET = "GET";
    static POST = "POST";
    static PUT = "PUT";
    static DELETE = "DELETE";
    static REMOVE = "REMOVE";
}