import { SchemaControlType } from "projects/den-core/base-models";
import { DateHelper } from "./expression-helpers/date-helper";
import { FileHelper } from "./expression-helpers/file-helper";
import { LocationHelper } from "./expression-helpers/location-helper";
import { UnqiueCodeHelper } from "./expression-helpers/unique-code-helper";
import { UtilityHelper } from "./expression-helpers/utility-helper";

export type ExpressionFunction = {
    functionName : string;
    toolTipMessage : string;
    returnType : string
}

export enum HelperClassConstants {
    UTILS = 'Utils',
    FILE = 'File',
    DATE = 'Date',
    LOCATION = 'Location',
    UNIQUECODE = 'UniqueCode'
}

export class ExpressionHelperClassRegistry{

    expressions : Map<string,any> = new Map();

    constructor(){
        this.setExpressions();
    }

    setExpressions(){
        this.expressions.set(HelperClassConstants.UTILS, UtilityHelper);
        this.expressions.set(HelperClassConstants.FILE , FileHelper);
        this.expressions.set(HelperClassConstants.DATE, DateHelper);
        this.expressions.set(HelperClassConstants.LOCATION, LocationHelper);
        this.expressions.set(HelperClassConstants.UNIQUECODE, UnqiueCodeHelper);
    }

    getExpression(type){
        return this.expressions.get(type);
    }

    getExpressionBySchemaFieldType(schemaFieldControlType : string) {
        if(schemaFieldControlType !== SchemaControlType.FILE_UPLOAD) {
            return new Map([...this.expressions].filter(([className,value]) => className != HelperClassConstants.FILE));
        }
        return this.expressions;
    }

}

