import { ChangeDetectorRef, Directive, ElementRef, Injector, ViewContainerRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PageProperties } from '../../../models/base.models';
import { RtOption } from '../../../utils/option-helper';
import { UsableAttributeValue } from '../../attributes/core/attribute-instance';
import { EventDataBinder } from '../../attributes/event-data-binder/event-data-binder-attribute';
import { EventDataMapper } from '../../attributes/event-data-mapper/event-data-mapper-attribute';
import { ControlPropertyDefinitionValue } from '../../controls/core/control-instance';
import { ControlAccessability, ControlVisibility } from '../../controls/core/control-model';
import { CtrlEventRaised } from '../../controls/core/event/control-event';
import { DsResult, DsResultValue, IDsArrayOrGroupedResult } from '../../data-source/data-source-result-entities';
import { ICommonControlRenderService } from '../../ide/services/common-control-render-service';
import { SimpleAngularControlInfo } from '../../ide/services/parent-control';
import { AngularContainerControlHelper } from '../helper/angular-container-control-helper';
import { ListViewControl } from '../list-view-control';


@Directive()
export abstract class AngularListControl<D extends IDsArrayOrGroupedResult, R extends ICommonControlRenderService<SimpleAngularControlInfo>> extends ListViewControl<D, SimpleAngularControlInfo, R> {
  style: any//Verify this with cesium-model-viewer.component.ts;
  disableInfoOnTab = false;
  private elementRef: ElementRef;
  constructor(injector: Injector, resultClass: { new(...args: any[]): D; }) {
    super(injector, resultClass);
    this.elementRef = injector.get(ElementRef);
    this.viewContainerRef = injector.get(ViewContainerRef);
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
  }
  getControlInfo(): SimpleAngularControlInfo {
    return new SimpleAngularControlInfo(this.viewContainerRef, this.controlInstance);
  }
  //dataSource: any;
  CONFIG_BINDINGS: any = {};


  propertyDefinitions: ControlPropertyDefinitionValue[];

  applyPropertyDefinitions(_propertyDefinitions: ControlPropertyDefinitionValue[]): void {
    this.propertyDefinitions = _propertyDefinitions;
    super.applyPropertyDefinitions(_propertyDefinitions);
  }

  onControlEventReceivedFn(_event: CtrlEventRaised): void {
    // throw new Error('Method not implemented.');
    // do nothing;
  }

  tryRemoveChildControlsIfAny(): void {
    // throw new Error("Method not implemented.");
  }

  onChangOfPageProperties(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex + 1;
    this.pageSize = pageEvent.pageSize;
    const pageProperties: PageProperties = new PageProperties(this.pageNumber, this.pageSize);
    this.paginate(pageProperties)
  }
  onRefreshClick() {
    const pageProperties: PageProperties = new PageProperties(this.pageNumber, this.pageSize);
    this.paginate(pageProperties);
  }
  getEventDataMapperValue(eventDataMapper: EventDataMapper, data: RtOption<DsResult>, controlPropertyName: string) {
    const eventDataMapperFieldName = eventDataMapper.fieldMapper.find(fm => fm.controlPropertyName === controlPropertyName)?.fieldName;
    const dsResultValue = data?.get?.data.find(d => d.fieldName === eventDataMapperFieldName) as DsResultValue;
    if (eventDataMapperFieldName === dsResultValue?.fieldName) {
      return dsResultValue?.value;
    }
    return null;
  }
  getEventDataBindingValue(eventDataBinder: EventDataBinder, data: RtOption<DsResult>, controlPropertyName: string) {
    const eventDataFieldName = eventDataBinder.fieldMapper.find(fm => fm.controlPropertyName === controlPropertyName)?.fieldName;
    const dsResultValue = data?.get?.data.find(d => d.fieldName === eventDataFieldName) as DsResultValue;
    if (eventDataFieldName === dsResultValue?.fieldName) {
      return dsResultValue?.referenceData.isDefined ? dsResultValue.referenceData?.get : dsResultValue?.value;
    }
    return null;
  }
  reApplyConfigurationAttributes(configurationAttributeValues: UsableAttributeValue<unknown>[]): void {
    this.CONFIG_BINDINGS = {};
    this.applyConfigurationAttributes(configurationAttributeValues);
  }

  reApplyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]): void {
    this.style = undefined;
    this.applyCSSAttributes(cssAttributeValues);
  }

  applyConfigurationAttributes(configurationAttributeValues: UsableAttributeValue<unknown>[]): void {
    // TODO : Fill me; I'm hungry!
    configurationAttributeValues.forEach(i => {
      this.CONFIG_BINDINGS[i.name] = i.value;
      this[i.name] = i.value;
    });
    super.applyConfigurationAttributes(configurationAttributeValues);
  }

  applyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]) {

    if (this.style) {
      this.applyGridAreaAttribute(cssAttributeValues);
      this.updateCssAttributeValue(cssAttributeValues);
    } else {
      this.applyGridAreaAttribute(cssAttributeValues);
      this.style = AngularContainerControlHelper.convertToJsonObj(cssAttributeValues);
    }
  }

  applyVisibility(visibility: ControlVisibility) {
    console.error("applyVisibility not implemented");
  }

  applyAccessability(accessability: ControlAccessability) {
    console.error("accessability not implemented");
  }

  private applyGridAreaAttribute(cssAttributeValues: UsableAttributeValue<unknown>[]) {
    const gridAreaAttribute = cssAttributeValues.find(a => a.name === "grid-area");
    if (gridAreaAttribute && this.elementRef) {
      this.elementRef.nativeElement.style["grid-area"] = gridAreaAttribute.value;
    }
  }

  updateCssAttributeValue(cssAttributeValues: UsableAttributeValue<unknown>[]) {
    cssAttributeValues.forEach(css => {
      this.style[css.name] = css.value;
    });
  }

  disableInfoOnTabDevice() {
    if (window.innerWidth < 740) {
      this.disableInfoOnTab = true;
    }
  }



  ngOnDestroy() {
    super.ngOnDestroy()
  }

}