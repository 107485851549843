import { LogService } from '../../../../log-service/log.service';
import { EventService } from '../../../../providers/event-service/event.service';
import { RtNone, RtOption, RtSome } from '../../../../utils/option-helper';
import { DsResult, DsResultArray, IDsResult } from '../../../data-source/data-source-result-entities';
import { DSBindTypeEnum } from '../../../models/enums';
import { PageService } from '../../../services/page-service';
import { AddChildBaseCommand, AddChildWithDsResultCommand, ChildCommand, DataStateType, DeleteChildByDsResultIdCommand, UpdateChildChildWithDsResultCommand } from '../child-rendered-event';
import { IControl } from '../control-model';
import { FilteringManager } from '../list-view-control/filtering-manager/filtering-manager';
import { FilterConfig } from '../list-view-control/shared/models/list-view.model';


export abstract class AbstractDSStateManager<D extends IDsResult> {

  data: D;

  private _controlProps: Map<string, any>

  /**
   * set any control special props to be used for both control rendering or DS execution
   *  e.g. tab index, pagination, filters, etc
   */
  public setControlProps(key: string, value: any) {
    this._controlProps.set(key, value)
  }

  public get controlProps() {
    return this._controlProps
  }

  parentCmd: AddChildBaseCommand;

  protected abstract dsBindType: DSBindTypeEnum;

  private childCommandsBuffer: ChildCommand[] = [];

  // abstract buildChildCommandsWithDataSource(resOpt: RtOption<D>, control: IControl<D>): void;

  abstract applyData(parentCmd: AddChildBaseCommand, newData: D,
    control: IControl<D>, filterConfig: RtOption<FilterConfig>): void;

  abstract onDestroy();

  abstract rebuildCommands(control: IControl<D>): AddChildWithDsResultCommand[];
  abstract resetDataForAllDataSources()

  constructor(public eventService: EventService, public pageService: PageService,
    public filterManager: FilteringManager, public logService: LogService) {
  }

  isDataReceived() {
    return this.data != null;
  }

  deQueueAllCmds() {
    const commands = this.childCommandsBuffer;
    this.childCommandsBuffer = [];
    return commands;
  }


  protected enQueueAllCmds(childCommands: ChildCommand[]) {
    this.childCommandsBuffer = this.childCommandsBuffer.concat(childCommands);
  }

  protected buildCommand(dsResult: DsResult, control: IControl<D>) {
    const repeaterId = this.getRepeaterId(control);
    switch (dsResult.dataStateType) {
      case DataStateType.NEW:
        return new AddChildWithDsResultCommand(dsResult, control.controlInstance, RtNone(), repeaterId, control.id, this.controlProps);
      case DataStateType.UPDATED:
        return new UpdateChildChildWithDsResultCommand(dsResult);
      case DataStateType.DELETED:
        return new DeleteChildByDsResultIdCommand(dsResult.id);
      default:
        throw Error("Illegal operation exception. Command can't be built for already applied data");
    }
  }

  protected buildAddNewCommand(dsResult: DsResult, control: IControl<D>): AddChildWithDsResultCommand {
    const repeaterId = this.getRepeaterId(control);
    return new AddChildWithDsResultCommand(dsResult, control.controlInstance, RtNone(), repeaterId, control.id, this.controlProps);
  }

  private getRepeaterId(control: IControl<D>): RtOption<string> {
    let repeaterId = this.parentCmd.repeaterControlId;
    if (control.resultClassName === DsResultArray.name) {
      repeaterId = RtSome(control.id);
    }
    return repeaterId;
  }

}
