import { MathUtils, Quaternion, Vector3 } from 'three';
import { ThreeJsConstants } from './den-threejs-constants';

export class DenThreeJSConfiguration {
  width: number;
  height: number;
  rotateSpeed: number;
  zoomSpeed: number;
  enableMousePick: boolean;
  enableSelect: boolean;
  enableSkybox: boolean;
  enableDrag: boolean;
  enableAxes: boolean;
  background: string | any;
  enableTransformControl: boolean;
  enableGridHelper: boolean;

  constructor() { }
}

class DenBaseThreeJSTransform {
  constructor(
    public x?: number,
    public y?: number,
    public z?: number,
  ) { }

  toVector() {
    return new Vector3(this.x, this.y, this.z);
  }
}

export class DenThreeJSScale extends DenBaseThreeJSTransform {
  setWorldScale(scale: Vector3) {
    return new DenThreeJSScale(scale.x, scale.y, scale.z);
  }
}

export class DenThreeJSPosition extends DenBaseThreeJSTransform {
  setWorldPosition(position: Vector3) {
    return new DenThreeJSPosition(position.x, position.y, position.z);
  }
}

export class DenThreeJSOrientation extends DenBaseThreeJSTransform {
  // tslint:disable-next-line: variable-name
  public _x: number;
  // tslint:disable-next-line: variable-name
  public _y: number;
  // tslint:disable-next-line: variable-name
  public _z: number;
  // tslint:disable-next-line: variable-name
  public _w: number;

  constructor(
    public x?: number,
    public y?: number,
    public z?: number,
    public w?: number
  ) {
    super();
    this._x = x;
    this._y = y;
    this._z = z;
    // this._w = w;
  }
  setWorldOrientation(quaternion: Quaternion) {
    return new DenThreeJSOrientation(quaternion.x, quaternion.y, quaternion.z, quaternion.w);
  }

  getQuaternion(): Quaternion {
    return new Quaternion(this.x, this.y, this.z, this.w);
  }

  toDegree() {
    return {
      x: this.x * MathUtils.RAD2DEG,
      y: this.y * MathUtils.RAD2DEG,
      z: this.z * MathUtils.RAD2DEG,
    };
  }

  
  toRadian() {
    return {
      x: this.x * MathUtils.DEG2RAD,
      y: this.y * MathUtils.DEG2RAD,
      z: this.z * MathUtils.DEG2RAD,
    };
  }


}

class BaseThreeJsEntity {

  public id: string;
  public name: string;
  public position?: DenThreeJSPosition;
  public scale?: DenThreeJSScale;
  public orientation?: DenThreeJSOrientation;
  public userData?: any;

  constructor() { }

  isNew(): boolean {
    if (!this.position) {
      return true;
    }
    return false;
  }

  applyPositionProperties(pos: DenThreeJSPosition) {
    this.position = pos;
  }

  applyOrientationProperties(orient: DenThreeJSOrientation) {
    this.orientation = orient;
  }

  applyScaleProperties(scale: DenThreeJSScale) {
    this.scale = scale;
  }

  applyUserData(data: any) {
    this.userData = data;
  }
}

export class DenThreeJS3DEntity extends BaseThreeJsEntity {

  originalEntity: any;

  constructor(
    public id: string,
    public name: string,
    public filePath: string,
    public position?: DenThreeJSPosition,
    public scale?: DenThreeJSScale,
    public orientation?: DenThreeJSOrientation,
    public direction?: any) { super(); }

  setOriginalEntity(entity: any) {
    this.originalEntity = entity;
  }

  getOrignalEntity() {
    return this.originalEntity;
  }

  static parse(objectFromJson: DenThreeJS3DEntity): DenThreeJS3DEntity {
    const position = objectFromJson.position ? new DenThreeJSPosition(objectFromJson.position.x, objectFromJson.position.y, objectFromJson.position.z) : undefined;
    const scale = objectFromJson.scale ? new DenThreeJSScale(objectFromJson.scale.x, objectFromJson.scale.y, objectFromJson.scale.z) : undefined;
    const orientation = objectFromJson.orientation ? new DenThreeJSOrientation(objectFromJson.orientation.x, objectFromJson.orientation.y, objectFromJson.orientation.z, objectFromJson.orientation.w) : undefined;
    const denThreeJS3DEntity = new DenThreeJS3DEntity(objectFromJson.id, objectFromJson.name, objectFromJson.filePath, position, scale, orientation, objectFromJson.direction);
    return denThreeJS3DEntity;
  }
}

export class DenThreeJSMarker extends BaseThreeJsEntity {
  public readonly originalId: string;

  constructor(
    public id: string,
    public name: string,
    public iconPath: string,
    public position?: DenThreeJSPosition,
    public scale?: DenThreeJSScale,
    public orientation?: DenThreeJSOrientation) {
    super();
    this.originalId = id;
    this.id = `${ThreeJsConstants.MARKER_PREFIX}_${this.id}`;
  }
}

export class ThreeJSModelHighlight {
  constructor(public id: string,
    public color: string,
  ) { }
}

export class ThreeJSModelHighlightWithOpacity {
  constructor(public id: string,
    public color: string,
    public show: boolean,
    public opacity: number) { }
}

export class DenThreeJSBillboard extends BaseThreeJsEntity {

  public readonly originalId: string;

  constructor(
    public id: string,
    public template?: string,
    public width?: number,
    public height?: number,
    public position?: DenThreeJSPosition,
  ) {
    super();
    this.originalId = id;
    this.id = `${ThreeJsConstants.POP_UP_PREFIX}_${this.id}`;
  }
}

export enum ThreeJsEntityLayerModelEnum {

  SCHEMA_NAME = 'SCHEMA_NAME',

  ENABLE_DEFAULT_RENDER = 'ENABLE_DEFAULT_RENDER',

  SHOW = 'SHOW',

  HIDE = 'HIDE',

  SCHEMA_PROPERTY_DISPLAY_NAME = 'SCHEMA_PROPERTY_DISPLAY_NAME',

}
