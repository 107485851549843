import { AdvancedFilter, DependencyProperty, DynamicDependencyProperty, FilterOperator, FilterProperty, ImageConfig, SortingProperty } from "../../../base-models";
import { Observable } from "rxjs";

export class FilterConstants {

    DATE_TIME_FILTER_TYPE = 'datetimepicker';
    DATE_FILTER_TYPE = 'datepicker';
    TIME_FILTER_TYPE = 'timepicker';
    SELECT_FILTER_TYPE = 'select';
    INPUT_FILTER_TYPE = 'input';
    DYNAMIC_FIELD_SINGLE_SELECT = 'attributes';
    NUMBER_FILTER_TYPE = 'number';
    SEARCHABLE_SELECT = 'searchableSelect';
    YEAR_MONTH_PICKER = 'yearMonthPicker';
}

export enum PdfExcelConstants {
    SELECT = "select",
    ACTION = "actions",
    createDate = "createDate",
    modifiedDate = "modifiedDate"
}

export class ConfirmationDialogConfig {
    static DIALOG_CONFIG = {
        width: '500px',
        panelClass: 'modal-dialog-wrapper'
    };

}

export class TableAction<T> {
    name: string;
    type?: 'icon' | 'slide-toggle';
    propertyName?: string;
    icon?: string;
    cssClass?: string;
    rtAuthorize?: string;
    rtAuthorizeId?: string;
    mouseoverFn?: (element: T) => void;
    callbackFn: (element: T) => void;
    cssCallbackFn?: (element: T) => string;
}

export class TableInput<READER_API, U, WRITER_API> {
    guid: string;
    enableSelect: boolean = true;
    enableDelete: boolean = true;
    disableSelectedRowDelete?: boolean = false;
    columnDetails: Array<ColumnDetails>;
    actionList: Array<TableAction<U>>;
    api: READER_API;
    writerApi?: WRITER_API;
    entityName: string;
    dependencyData?: DependencyProperty[] | DynamicDependencyProperty[];
    apiName?: string;
    parentPropertyName?: string;
    filterProperties?: Array<FilterProperty>;
    sortingProperties?: SortingProperty;
    isDefaultSelectedRow?: boolean;
    apiParams?: string;
    deletePermission?: string;
    isReusable?: boolean = false;
    isExtensibilitySettingsRequired?: boolean = true;
    entitySchemaName?: string;
    overrideResponseBeforeResolveDependencyFn?: (data: any[]) => Observable<any>;
    overrideResponseAfterResolveDependencyFn?: (data: any[]) => Observable<any>;
    overrideRequestCallback?: (filter: AdvancedFilter) => AdvancedFilter;
    constructor() { }
}

export class LabelColor {
    backGroundColor: string;
    labelClass?: string;
}

export class DateFormat {
    outputFormat: string;
}


export class ColumnDetails {
    displayName: string;
    propertyName: string;
    filterType?: 'input' | 'select' | 'datepicker' | 'datetimepicker' | 'timepicker' | 'number' | 'searchableSelect';
    defaultFilterOperator?: FilterOperator;
    objPropertyName?: string;
    hasObject?: boolean;
    isFilterable?: boolean;
    showColumnMain?: boolean;
    showColumnFlex?: boolean;
    labelColor?: LabelColor;
    labelName?: string;
    id?: number;
    isHyperLink?: boolean;
    dateFormat?: DateFormat;
    isProgressBar?: boolean;
    imageConfig?: ImageConfig;
    dynamicFieldSelectedOptions?: string[];
    referenceablePropertyName?: string;
}

export class PdfConstants {
    static FOOTER_LEFT_MARGIN_FOR_TOTAL_PAGES: number = 700;
    static FOOTER_TOP_MARGIN: number = 580;
    static FOOTER_LEFT_MARGIN_FOR_GENERATED_DATE: number = 40;
    static PDF_COLOR_COLUMN_TEXT_IN_WHITE: number[] = [234, 242, 235];
    static PDF_COLOR_COLUMN_TEXT_IN_BLACK: string = '#000000';
}