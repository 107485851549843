import { EntitySchemaFieldContainer } from "../../../../../../schema-models";
import { DsResultValue } from "../../../data-source/data-source-result-entities";
import { ControlInstanceWrapper } from "../control-instance-drafts-models";
import { ParamBinding } from "./event-store";
import { EventActionGroupUniqueKey } from "./event-types";
import { RtOption } from "../../../../utils/option-helper";

export class AppEventTopics {

  private static readonly ON_CONTROL_ADDED_TOPIC = 'ON_CONTROL_ADDED_TOPIC'
  static buildOnControlAddedTopic(): string {
    return this.ON_CONTROL_ADDED_TOPIC;
  }

  private static readonly PARAM_READY_FOR_MAPPING = 'PARAM_READY_FOR_MAPPING';
  //dsName, param, param type, isListParams
  static buildReadyForParamMappingTopic(): string {
    return this.PARAM_READY_FOR_MAPPING;
  }

  private static readonly PARAM_MAPPED = 'PARAM_MAPPED';
  //producerName, ds.fieldName, uniqueEventId
  static buildParamMappedTopic(dsName: string): string {
    return `${this.PARAM_MAPPED}_${dsName}`;
  }

  private static readonly PARAM_UNMAPPED = 'PARAM_UNMAPPED';
  //producerName, ds.fieldName, uniqueEventId
  static buildParamUnmappedTopic(dsName: string): string {
    return `${this.PARAM_UNMAPPED}_${dsName}`;
  }

  private static readonly DATA_SOURCE_PARAM_AVAILABILITY_EVENT = 'DATA_SOURCE_PARAM_AVAILABILITY_EVENT';
  //producerName, ds.fieldName, uniqueEventId
  static buildDataSourceParamTopic(dsName: string, paramName: string): string {
    return `${this.DATA_SOURCE_PARAM_AVAILABILITY_EVENT}_${dsName}_${paramName}`;
  }

  private static readonly CONTROL_BINDING = 'CONTROL_BINDING';
  static buildControlBindingTopic(actionId: string, controlInstanceId: string): string {
    return `${this.CONTROL_BINDING}_${actionId}_${controlInstanceId}`;
  }
  private static readonly PAGE_VARIABLE = 'PAGE_VARIABLE';
  static buildPageVariableTopic(pageName: string, variableName: string): string {
    return `${this.PAGE_VARIABLE}_${pageName}_${variableName}`;
  }
  static buildControlBindingTopicForUniqueControl(actionId: string, controlInstanceId: string, idFieldValue: string): string {
    return `${this.CONTROL_BINDING}_${actionId}_${controlInstanceId}_${idFieldValue}`;
  }
  static buildControlBindingUnApplyTopicForUniqueControl(actionId: string, controlInstanceId: string, idFieldValue: string): string {
    return `UN_APPLY_${this.buildControlBindingTopicForUniqueControl(actionId, controlInstanceId, idFieldValue)}`;
  }

  private static readonly OPEN_DATA_BINDING = 'OPEN_DATA_BINDING';
  static buildOpenDataBindingTopic(): string {
    return this.OPEN_DATA_BINDING;
  }

  private static readonly OPEN_DATA_BINDING_PANEL_FROM_OUTLINE = 'OPEN_DATA_BINDING_PANEL_FROM_OUTLINE';
  static buildOpenDataBindingPanelFromOutlineTopic(controlInstanceId: string): string {
    return this.OPEN_DATA_BINDING_PANEL_FROM_OUTLINE + '_' + controlInstanceId;
  }

  private static readonly DELETE_CONTROL = 'DELETE_CONTROL';
  static buildDeleteControlTopic(): string {
    return this.DELETE_CONTROL;
  }

  private static readonly OPEN_RECOMMENDED_CONTROLS = 'OPEN_RECOMMENDED_CONTROLS';
  static buildOpenRecommendedControlsTopic(): string {
    return this.OPEN_RECOMMENDED_CONTROLS;
  }

  private static readonly OPEN_CONTROL_EVENT_STORE = 'OPEN_CONTROL_EVENT_STORE';
  static buildOpenControlEventStoreTopic(): string {
    return this.OPEN_CONTROL_EVENT_STORE;
  }

  private static readonly OPEN_CUSTOM_STYLES = 'OPEN_CUSTOM_STYLES';
  static buildOpenCustomStylesTopic(): string {
    return this.OPEN_CUSTOM_STYLES;
  }


  private static readonly OPEN_CONTROL_EVENT_STORE_PANEL = 'OPEN_CONTROL_EVENT_STORE_PANEL';
  static buildOpenControlEventStorePanelTopic(): string {
    return this.OPEN_CONTROL_EVENT_STORE_PANEL;
  }

  private static readonly OPEN_CONTROL_PROPERTIES_PANEL = 'OPEN_CONTROL_PROPERTIES_PANEL';
  static buildOpenControlPropertyStoreTopic() {
    return this.OPEN_CONTROL_PROPERTIES_PANEL;
  }

  static readonly OPEN_ONCOMPLETE_FORM = 'OPEN_ONCOMPLETE_FORM';
  static readonly OPEN_FORM_FIELDS_CONFIG_PANEL = 'OPEN_FORM_FIELDS_CONFIG_PANEL';

  static readonly DATA_RECEIVED = 'DATA_RECEIVED';
  static buildDataReceivedTopicId(controlId: string) {
    return this.DATA_RECEIVED + '_' + controlId;
  }

  static readonly NEW_POPUP_CONTROL_ADDED = "NEW_POPUP_CONTROL_ADDED";
  static readonly OPEN_MOBILE_POPUP_UP = "OPEN_MOBILE_POPUP_UP";
  static readonly MOBILE_POP_UP = "MOBILE_POP_UP";
}

export type ParamMappingRequest = { dsName: string, param: string, paramType: string, isListParams: boolean, canRetainPreviousDialog?: boolean };
export type ParamMapped = { producerName: string, dsFieldName: string, uniqueEventId: EventActionGroupUniqueKey, paramMappingRequest: ParamMappingRequest; };
export type ParamUnMapped = { uniqueEventId: EventActionGroupUniqueKey, paramBinding: ParamBinding; };
export type DataSourceParamEvent = { uniqueEventId: EventActionGroupUniqueKey, binding: ParamBinding, value: DsResultValue[], paramSelectorOpt: RtOption<boolean> };
export type ControlAssistantOptionSelectedEvent = { controlInstanceId: string, controlId: string, controlName: string, event_ClientX: number, event_ClientY: number; };
export type DataSourceReceivedCommand = { controlInstance: ControlInstanceWrapper, pageId: string, isParentDsChanged: boolean; };
export type FormFieldsControlInstance = { controlInstance: ControlInstanceWrapper, entitySchemaFieldContainers: EntitySchemaFieldContainer[] };
