import { AttributeInstance } from "../../attributes/core/attribute-instance";
import { ControlInstanceWrapper } from "../../controls/core/control-instance-drafts-models";

export type SelectedControlData = {
  controlInstanceId: string,
  controlId: string,
  controlName?: string,
  pointerEvent?: { domRect: DOMRect, pageX: number, pageY: number },
} & MediatorMessage;

export enum ATTRIBUTE_PANEL_CONFIG {
  CONTROL = "control",
  CONTROL_CONDITIONAL_BEHAVIOR = "control_conditional_behavior",
  CONTROL_QUICK_STYLES = "control_quick_styles",
}

export type PropertyChanged = {
  controlInstance: ControlInstanceWrapper;
  pageId: string;
  updatedAttributeInstance: AttributeInstance;
  panelConfig: ATTRIBUTE_PANEL_CONFIG;
} & MediatorMessage;


export type AppToEditorMessageCarrier<M extends MediatorMessage> = {
  topic: string,
  payload: M;
};

export type EditorToAppMessageCarrier<M extends MediatorMessage> = {
  topic: string,
  payload: M,
  changes: any[];
};

export interface MediatorMessage { }

export abstract class MediatorMessagingService {
  readonly APP_TO_EDITOR_MESSAGE_CARRIER = "APP_TO_EDITOR_MESSAGE_CARRIER";
  readonly EDITOR_TO_APP_MESSAGE_CARRIER = "EDITOR_TO_APP_MESSAGE_CARRIER";
}

//App to communicate with editor (From app to editor)
export abstract class AppToEditorMessagingService extends MediatorMessagingService {
  abstract publishToEditor<M extends MediatorMessage>(topic: string, msg: M);

}

//To communicate with the APP from the editor (From editor to app)
export abstract class EditorToAppMessagingService extends MediatorMessagingService {
  abstract init();
  abstract publishToApp<M extends MediatorMessage>(topic: string, msg: M);
  abstract isSubscriptionInitialized(): boolean;
}


