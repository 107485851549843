export class DOMIdConstant {
    public static readonly PAGE_BUILDER_DESIGNER = 'PAGE_BUILDER_DESIGNER';
    public static readonly PAGE_BUILDER_CONTAINER = 'PAGE_BUILDER_CONTAINER';

    public static readonly getPageBuilderDOMIds = {
        PB_DESIGNER: DOMIdConstant.PAGE_BUILDER_DESIGNER,
        PB_CONTAINER: DOMIdConstant.PAGE_BUILDER_CONTAINER,
    };

    public static readonly LEAFLET_MAP_OVERLAY_LAYER_CLASS_NAME = 'leaflet-map-overlay-layer-wrapper';
}
