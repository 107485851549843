

export class ControlRegistryMock {


    static EXTERNAL_CONTROL_REGISTRY = "Control_Registry";

    static readonly CONTROL_DESCRIPTOR = [
        {
            inputOutput: "controlLibPath",
            properties: "controlDescriptor"
        }
    ]

    static readonly MOCK_CONTROL_REGISTRY = [
        {
            id: "1234",
            controlType: "controlType",
            controlName: "controlName",
            component: "component",
            controlLibPath: "controlLibPath",
            controlDescriptor: "controlDescriptor"
        }
    ]


    static readonly TABLE_COLUMNS = ["controlType", "controlName", "componentName", "controlLibraryPath", "controlDescriptor", "actions"]
    static readonly IO_TYPES = ["Input", "Output"]
    static readonly DATA_TYPES = ["number", "string", "boolean"]
    static readonly PROPERTY_TYPES = ["CSS", "CONFIGURATION", "PROPERTY_DEFINITION",]
    static readonly CONTROL_TYPES = ["ANGULAR", "LEAFLET", "THREEJS", "REPORT"]
}

export enum AttributeNames {
    COLOR = "color",
    PADDING_MARGIN = "paddingMargin",
    DISPLAY = "display",
    BORDER = "border",
    BACKGROUND_COLOR = "backgroundColor",
    FORE_COLOR = "foreColor",
    TYPOGRAPHY = "typography",
    DIMENSION = "dimension",
}
