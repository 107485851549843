
export class ChartAttributesEnum {
    static readonly SHOW_XAXIS = 'Show X-Axis';
    static readonly SHOW_Y_AXIS_LEFT = 'Show Y-Axis';
    static readonly SHOW_Y_AXIS_RIGHT = 'Show Y-Axis Right';
    static readonly XAXIS_LABEL = 'X-Axis Label';
    static readonly YAXIS_LEFT_LABEL = 'Y-Axis Label';
    static readonly YAXIS_RIGHT_LABEL = 'Y-Axis Right Label';
    static readonly SHOW_GRID_LINES = 'Show GRID Lines';
    static readonly SHOW_LEGEND = 'Show Legend';
    static readonly LEGEND_POSITION = 'Legend Position';
    static readonly LEGEND_OPTION = 'Legend Option';
    static readonly LEGEND_ALIGNMENT = 'Legend Alignment';
    static readonly LEGEND_FONT = 'Legend Font';
    static readonly LEGEND_COLOR = 'Legend Color';
    static readonly LEGEND_FONT_WIEGHT = 'Legend Font Weight';
    static readonly LEGEND_FONT_STYLE = 'Legend Font Style';
    static readonly LINE_COLOR = 'Line Color';
    static readonly WIDTH = 'Width';
    static readonly HEIGHT = 'Height';
    static readonly FIT_CONTAINER = 'Fit Container';
    static readonly SHOW_TOTAL = 'Show Total';
    static readonly MIN_GAUGE_SCALE = 'Min Scale';
    static readonly MAX_GAUGE_SCALE = 'Max Scale';
    static readonly GAUGE_INTERVAL = 'Interval';
    static readonly CHART_HEIGHT = 'Chart Height';
    static readonly APPLY_X_AXIS_DATE_FORMAT = 'Apply X-Axis Date Format';
    static readonly X_AXIS_DATE_INPUT_FORMAT = 'X-Axis Date Input Format';
    static readonly X_AXIS_DATE_OUTPUT_FORMAT = 'X-Axis Date Output Format';
    static readonly SCHEME_TYPE = 'Scheme Type';
    static readonly ANIMATIONS = 'Animations';
    static readonly BUFFER_SPACE = 'Buffer Space';
    static readonly LEGEND_H_SPACE = 'Legend H Space';
    static readonly LEGEND_V_SPACE = 'Legend V Space';
    static readonly AXIS_INTERVAL = 'Axis Interval';



    static readonly BAR_CHART_TYPES = 'Bar Chart Type';
    static readonly GROUP_BAR_CHART_TYPE = 'Group Bar Chart Type';
    static readonly LINE_AREA_CHART_TYPE = 'Line Area Chart Type';
    static readonly PIE_CHART_TYPES = 'Pie Chart Type';
    static readonly BAR_LINE_CHART_TYPES = 'Bar Line Chart Type';
    static readonly BUBBLE_CHART_TYPES = 'Bubble Chart Type';
    static readonly STACKED_VERTICAL_BAR_LINE_CHART_TYPES = 'Stacked Vertical Bar Line Chart Type';
    static readonly CHART_TITLE_POSITION = 'Title Position';
    static readonly LEGEND_TITLE = 'Legend Title';
    static readonly DISABLE_TOOLTIP = 'Disable Tooltip';
    static readonly ENABLE_GRADIENT = 'Enable Gradient';
    static readonly ENABLE_FILTER = 'Enable Filter';

    static readonly SHOW_DATA_LABEL = 'Show Data Label';
    static readonly SHOW_X_AXIS_LABEL = 'Show X-axis Label';
    static readonly SHOW_Y_AXIS_LABEL = 'Show Y-axis Label';
    static readonly SHOW_Y_AXIS_RIGHT_LABEL = 'Show Y-axis Right Label';
    static readonly X_Y_AXIS_COLOR = 'Chart X-Y Axis Color';
    static readonly WRAP_TICKS = 'Wrap Ticks';


    //Chart color input for legened component
    static readonly COLORS = 'colors';
    static readonly LEGEND_VALUES = 'legendValues';

    //Combination chart component inputs
    static readonly LEGEND_COLORS = 'legendColors';
    static readonly LEGEND_COLOR_SCHEME = 'legendColorScheme';

    //Number Card chart
    static readonly CARD_COLOR = 'Card Color';
    static readonly TEXT_COLOR = 'Text Color';
    static readonly EMPTY_COLOR = 'Empty Color';
    static readonly INNER_PADDING = 'Inner Padding';
    static readonly ROUND_DOMAINS = 'Round Domains';
    static readonly TRIM_XAXIS_TICKS = 'Trim X-Axis Ticks';
    static readonly TRIM_YAXIS_TICKS = 'Trim Y-Axis Ticks';
    static readonly ROTATE_XAXIS_TICKS = 'Rotate X-Axis Ticks';
    static readonly MAX_XAXIS_TICK_LENGTH = 'Max X-Axis Tick Length';
    static readonly MAX_YAXIS_TICK_LENGTH = 'Max Y-Axis Tick Length';
    static readonly AUTO_SCALE = 'Auto Scale';
    static readonly TIME_LINE = 'Time Line';

    //Gaguge chart
    static readonly START_ANGLE = 'Start Angle';
    static readonly ANGLE_SPAN = 'Angle Span';
    static readonly SMALL_SEGMENTS = 'Small Segments';
    static readonly BIG_SEGMENTS = 'Big Segments';
    static readonly SHOW_AXIS = 'Show Axis';
    static readonly UNITS_VALUE = 'Units Value';

    //Pie GRID
    static readonly MIN_GRID_WIDTH = 'Min Grid Width';
    static readonly COMPARABLE_TOTAL_VALUE = 'Comparable Total Value';
    static readonly LABEL = 'Label';

    //Bar Chart
    static readonly ROUND_EDGES = 'Round Edges';
    static readonly Y_SCALE_MIN = 'Y-scale Min';
    static readonly Y_SCALE_MAX = 'Y-scale Max';
    static readonly X_SCALE_MIN = 'X-scale Min';
    static readonly X_SCALE_MAX = 'X-scale Max';
    static readonly BAR_PADDING = 'Bar Padding';
    static readonly GROUP_PADDING = 'Group Padding';
    static readonly NO_BAR_WHEN_ZERO_VALUE = 'No Bar When Zero Value';
    static readonly BAR_COLOR = 'Bar color';

    //Line Area Chart
    static readonly SHOW_REF_LINES = 'Show Ref Lines';
    static readonly SHOW_REF_LABLES = 'Show Ref Labels';
    static readonly RANGE_FILL_OPACITY = 'Range Fill Opacity';

    //Polar Chart
    static readonly Y_AXIS_MIN_SCALE = 'Y-Axis Min Scale';
    static readonly SHOW_SERIES_ON_HOVER = 'Show Series On Hover';
    static readonly LABEL_TRIM = 'Label Trim';
    static readonly LABEL_TRIM_SIZE = 'Label Trim Size';
    static readonly CURVE_TYPE = 'Curve Type';

    //Polar Chart
    static readonly MAX_RADIUS = 'Max Radius';
    static readonly MIN_RADIUS = 'Min Radius';

    //Pie Chart
    static readonly TRIM_LABELS = 'Trim Labels';
    static readonly MAX_LABEL_LENGTH = 'Max Label Length';
    static readonly EXPLODE_SLICES = 'Explode Slices';
    static readonly DOUGHNUT = 'Doughnut';
    static readonly ARC_WIDTH = 'Arc Width';
    static readonly LABELS = 'Labels';
    static readonly LABEL_FORMATTING = 'Label Formatting';

    //Stacked triangle chart
    static readonly LAYER_PADDING = 'Layer Padding';
    static readonly LAYER_HEIGHT = 'Layer Height';

    //Tree Map
    static readonly PARENT_FIELD = "Parent Field"
}
export class ChartDataSourceEnum {
    static readonly XAXIS_PROPERTY = 'X-Axis Property';
    static readonly YAXIS_PROPERTY_LIST = 'Y-Axis Property List';
    static readonly LINE_YAXIS_PROPERTY_LIST = 'Line Y-Axis Property List';
    static readonly COLOR_CODE = 'Color Code';
    //Stacked vertical bar line chart
    static readonly GROUP_BY_PROPERTY = 'Group By';
    static readonly YAXIS_PROPERTY = 'Y-Axis Property';
    static readonly PARENT_FIELD = 'Parent Field Property';
    static readonly  LEGEND_PROPERTY = 'Legend Value'
}
export enum ChartLegendValuesEnum {
    xAxis = 'X-Axis',
    yAxis = 'Y-Axis',
}
export class ChartTypeValue {
    constructor(public type: string | number) { }
}
export class ChartDsResult {
    dsName: string;
    dsList: any[];
}

export class BubbleChart {
    name: any;
    series: BubbleChartSeries[];
    tooltip?: string;
    constructor(name: any, series: BubbleChartSeries[], tooltip?: string) {
        this.name = name;
        this.series = series;
        this.tooltip = tooltip;
    }
}

export class BubbleChartSeries {
    name: any;
    x: any;
    y: any;
    r: any;
    constructor() {

    }
}
export class AxisPoint {
    constructor(public value: number, public unit: string) {
  
    }
  }

export enum ChartTypes {
    line = '2',
    verticalBar = 3,
    horizontalBar = 4,
    gaugeChart = 5,
    areaChart = '6',
    groupedHorizontalBar = '7',
    groupedVerticalBar = '8',
    barLineChart = '9',
    stackedVertivalBarChart = '10',
    stackedHorizontalBarChart = '13',
    stackedBarLineChart = '12',
    normalisedHorizontalBarChart = '14',
    normalisedVerticalBarChart = '15',
    pieGrid = '16',
    pie = '1',
    donutChart = '11',
    stackedAreaChart = '17',
    normalisedAreaChart = '18',
    bubbleChart = 19,
    stackedTriangle = '20'
}
export class ChartDateXAxisFormat {
    inputFormat: string;
    outputFormat: string;
}

export class BaseChart {
    name: any;
    series: BaseChartSeries[];
    tooltip?: string;
    constructor(name: any, series: BaseChartSeries[], tooltip?: string) {
        this.name = name;
        this.series = series;
        this.tooltip = tooltip;
    }
}

export class BaseChartSeries {
    name: any;
    value: any;
    tooltip?: string;
    parent?:string;
    constructor(name: any, value: any, tooltip?: string, parent?:string){
        this.name = name;
        this.value = value;
        this.tooltip = tooltip;
        this.parent = parent;
    }
}

export enum MinMaxChartDimension {
    MIN_WIDTH = 50,
    MIN_HEIGHT = 50,
    MAX_WIDTH = 1000,
    MAX_HEIGHT = 1500
}
export enum DefaultConfig {
    AUTO = "Auto",
    Manual = "Manual",
    DYNAMIC="Dynamic"
  }
  

  export type ScaleAxis = {
    xScaleMin;
    xScaleMax;
    yScaleMin;
    yScaleMax;
  };
  