import { format } from "date-fns";
import { ExpressionFunction } from "../ExpressionRegistry";

export class DateHelper {

    static readonly functionList : ExpressionFunction[] = [
        {
            functionName : "getDateTime(yyyy-MM-dd HH:mm:ss)",
            toolTipMessage : "return the current DateTime.",
            returnType : 'string'
        },
        {
            functionName : "getDynamicDateTime(date, yyyy-MM-dd HH:mm:ss)",
            toolTipMessage : "return the customized date format",
            returnType : 'string'
        }
    ];

    static getDateTime(dateFormat: string,options? : { params : any , isUpdateMode: boolean }) {
        return format(new Date(), dateFormat);
    }
    static getDynamicDateTime(date:string, _format: string,options? : { params : any , isUpdateMode: boolean }) {
        if (date) {
            return format(new Date(date), _format);
        } else {
            return format(new Date(), _format);
        }
    }
        
    static getFunctionListWithDefaultParams() : ExpressionFunction[] {
        return DateHelper.functionList;
    }
}