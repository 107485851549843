import { Injectable } from "@angular/core";
import { isWithinInterval } from "date-fns";
import { OrganizationDate } from "../../../../../utils/org-date-helper";
import { Observable, ObservableInput, PartialObserver } from "rxjs";
import { DataSourceServiceInstanceWithFilter, ReceivedParamBuffer } from "../../../../data-source/data-source";
import { DsResult, DsResultArray, IDsResult, RawDataSourceResult } from "../../../../data-source/data-source-result-entities";
import { OfflineDataModel } from "../model/offline-model";
import { OfflineManagerService } from "./offline-manager.service";
import { FilterOperator } from "../../../../../../../base-models";
import { RtOption } from "../../../../../utils/option-helper";

@Injectable()
export class OfflineDataSourceService {
    constructor(private offlineManagerService: OfflineManagerService) { }

    invokeDataSourceService(pageName: string, ds: DataSourceServiceInstanceWithFilter, paramBuffer: ReceivedParamBuffer[], controlInstanceId: string): Observable<RawDataSourceResult> {
        return new Observable((observer: PartialObserver<RawDataSourceResult>) => {

            this.offlineManagerService.getOffline(pageName, controlInstanceId, ds.datasourceInstance.id)
                .subscribe((value: OfflineDataModel) => {
                    observer.next(value?.rawResult);
                });
        });
    }

    applyFilter(result: RtOption<IDsResult>, paramBuffer: ReceivedParamBuffer[]): RtOption<IDsResult> {
        const dsResultArray: DsResultArray = result.get as unknown as DsResultArray
        paramBuffer?.forEach(pb => {
            //Implement EQUALS, IN, DATE, DATERANGE
            this.filterByOperatorType(pb, dsResultArray);
        });
        return new RtOption(dsResultArray);
    }


    private filterByOperatorType(pb: ReceivedParamBuffer, dsResultArray: DsResultArray) {
        if (pb.param.operator === FilterOperator.RANGE) {
            this.filterByRange(pb, dsResultArray);
        } else if (pb.param.operator === FilterOperator.EQUALS) {
            this.filterByEquals(dsResultArray, pb);
        } else if (pb.param.operator === FilterOperator.IN) {
            this.filterByIn(dsResultArray, pb);
        }
    }

    private filterByIn(dsResultArray: DsResultArray, pb: ReceivedParamBuffer) {
        dsResultArray.results = dsResultArray.results?.filter(dsResult =>
            pb.getValue()?.some(pbValue => pbValue === dsResult.data.find(dsValue => dsValue.fieldName == pb.param.name)?.value));
    }

    private filterByEquals(dsResultArray: DsResultArray, pb: ReceivedParamBuffer) {
        let pbValue;
        if (pb.getValue() == "true" || pb.getValue() == "false") {
            pbValue = JSON.parse(pb.getValue());
        } else {
            pbValue = pb.getValue();
        }
        if (pbValue) {
            dsResultArray.results = dsResultArray.results?.filter(dsResult =>
                dsResult.data.find(dsValue => dsValue.fieldName == pb.param.name)?.value == pbValue);
        }

    }

    private filterByRange(pb: ReceivedParamBuffer, dsResultArray: DsResultArray) {
        const startTime = OrganizationDate.getStartOrEndOfTime('day', true, true, pb.getValue()?.['startDateTime']);
        const endTime = OrganizationDate.getStartOrEndOfTime('day', false, true, pb.getValue()?.['endDateTime']);
        dsResultArray.results = dsResultArray.results?.filter(dsResult => this.findResults(dsResult, pb, startTime, endTime));
    }

    private findResults(dsResult: DsResult, pb: ReceivedParamBuffer, startTime: string, endTime: string): unknown {
        const value = dsResult.data.find(dsrValue => dsrValue.fieldName === pb.param.name)?.value
        return isWithinInterval(new Date(value), {
            start: new Date(startTime),
            end: new Date(endTime)
        });
    }

    getOfflineData(entityName: string): ObservableInput<unknown> {
        return this.offlineManagerService.getOfflineFormDataByName(entityName);
    }
}
