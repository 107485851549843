import { ElementRef } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

export abstract class LoaderServiceProvider {

  public loaderSubject = new Subject();
  public loaderSubjectCounter = new BehaviorSubject(0);
  public loadCounter: number = 0;
  public hasOverlayCreated: boolean = false;

  public apiLoaderSubject = new Subject();
  public apiLoaderCounter = 0;

  abstract showOverlay(element: ElementRef): void;
  abstract updateOverlayPosition(element: ElementRef): void;
  abstract closeOverlay(): void;

  constructor() { }

  incrementLoaderCount() {
    this.loadCounter++;
    this.loaderSubjectCounter.next(this.loadCounter);
  }

  decrementLoaderCount() {
    this.loadCounter--;
    this.loaderSubjectCounter.next(this.loadCounter);
  }

  showLoader() {
    this.loaderSubject.next(true);
  }

  hideLoader() {
    this.loaderSubject.next(false);
  }

  incrementApiLoaderCount() {
    this.apiLoaderCounter++;
    this.updateApiLoaderState();
  }

  decrementApiLoaderCount() {
    this.apiLoaderCounter--;
    this.updateApiLoaderState();
  }

  updateApiLoaderState() {
    const showApiLoader = this.apiLoaderCounter > 0;
    this.apiLoaderSubject.next(showApiLoader);
  }
}