import { Easing } from "@tweenjs/tween.js";
import { RtSome } from "../../../utils/option-helper";
import { CSSPropertyNameConstants } from "../../constants/css-property-name-constants";

export enum AnimationProperty {
  OPACITY = 'opacity',
  COLOR = 'color',
  BACKGROUND_COLOR = 'background-color',
  DROP_SHADOW = "drop_shadow", //.shadow.blur
  POSITION = "position",
  SCALE = "scale",
  ROTATION = "rotation",
  THREED_POSITION = "threed_position",
  // THREED_SCALE = "threed_scale",
  // THREED_ROTATION = "threed_rotation",
  THREED_BLOOM = "threed_bloom",
  // CAMERA = "threed_bloom"
  TRANSLATE_X = "translateX",
  TRANSLATE_Y = "translateY",
}

export enum AnimationMode {
  PARALLEL = "parallel",
  SEQUENTIAL = "sequential"
}

export enum FrequencyAnimationMode {
  CONTINUOUS = "continuous",
  FINITETIMES = "finiteTimes"
}

export enum EasingSelection {

  LINEAR = "Linear",

  QUADRATIC_IN = "Quadratic.In",
  QUADRATIC_OUT = "Quadratic.Out",
  QUADRATIC_IN_OUT = "Quadratic.InOut",

  CUBIC_IN = "Cubic.In",
  CUBIC_OUT = "Cubic.Out",
  CUBIC_IN_OUT = "Cubic.InOut",

  QUARTIC_IN = "Quartic.In",
  QUARTIC_OUT = "Quartic.Out",
  QUARTIC_IN_OUT = "Quartic.InOut",

  QUINTIC_IN = "Quintic.In",
  QUINTIC_OUT = "Quintic.Out",
  QUINTIC_IN_OUT = "Quintic.InOut",

  SINUSOIDAL_IN = "Sinusoidal.In",
  SINUSOIDAL_OUT = "Sinusoidal.Out",
  SINUSOIDAL_IN_OUT = "Sinusoidal.InOut",

  EXPONENTIAL_IN = "Exponential.In",
  EXPONENTIAL_OUT = "Exponential.Out",
  EXPONENTIAL_IN_OUT = "Exponential.InOut",

  CIRCULAR_IN = "Circular.In",
  CIRCULAR_OUT = "Circular.Out",
  CIRCULAR_IN_OUT = "Circular.InOut",

  ELASTIC_IN = "Elastic.In",
  ELASTIC_OUT = "Elastic.Out",
  ELASTIC_IN_OUT = "Elastic.InOut",

  BACK_IN = "Back.In",
  BACK_OUT = "Back.Out",
  BACK_IN_OUT = "Back.InOut",

  BOUNCE_IN = "Bounce.In",
  BOUNCE_OUT = "Bounce.Out",
  BOUNCE_IN_OUT = "Bounce.InOut",
}

// export enum EasingType {
//   In = "In",
//   Out = "Out",
//   InOut = "InOut"
// }

// export type SelectedEasing = { option: EasingSelection, type: EasingType }

export class EasingResolver {
  private static _easingMap: Map<EasingSelection, (amount: number) => number>;
  static get easingMap() {
    if (RtSome(this._easingMap).isEmpty) {
      this.init();
    }
    return this._easingMap;
  }

  static resolve(easingOption: EasingSelection) {
    if (!this.easingMap.has(easingOption)) {
      throw new Error(`No mapping found for EasingSelection: ${easingOption}`);
    }
    return this.easingMap.get(easingOption);
  }

  private static init() {
    this._easingMap = new Map();
    this._easingMap.set(EasingSelection.LINEAR, Easing.Linear.None);

    this._easingMap.set(EasingSelection.QUADRATIC_IN, Easing.Quadratic.In);
    this._easingMap.set(EasingSelection.QUADRATIC_OUT, Easing.Quadratic.Out);
    this._easingMap.set(EasingSelection.QUADRATIC_IN_OUT, Easing.Quadratic.InOut);

    this._easingMap.set(EasingSelection.CUBIC_IN, Easing.Cubic.In);
    this._easingMap.set(EasingSelection.CUBIC_OUT, Easing.Cubic.Out);
    this._easingMap.set(EasingSelection.CUBIC_IN_OUT, Easing.Cubic.InOut);

    this._easingMap.set(EasingSelection.QUARTIC_IN, Easing.Quartic.In);
    this._easingMap.set(EasingSelection.QUARTIC_OUT, Easing.Quartic.Out);
    this._easingMap.set(EasingSelection.QUARTIC_IN_OUT, Easing.Quartic.InOut);

    this._easingMap.set(EasingSelection.QUINTIC_IN, Easing.Quintic.In);
    this._easingMap.set(EasingSelection.QUINTIC_OUT, Easing.Quintic.Out);
    this._easingMap.set(EasingSelection.QUINTIC_IN_OUT, Easing.Quintic.InOut);

    this._easingMap.set(EasingSelection.SINUSOIDAL_IN, Easing.Sinusoidal.In);
    this._easingMap.set(EasingSelection.SINUSOIDAL_OUT, Easing.Sinusoidal.Out);
    this._easingMap.set(EasingSelection.SINUSOIDAL_IN_OUT, Easing.Sinusoidal.InOut);

    this._easingMap.set(EasingSelection.EXPONENTIAL_IN, Easing.Exponential.In);
    this._easingMap.set(EasingSelection.EXPONENTIAL_OUT, Easing.Exponential.Out);
    this._easingMap.set(EasingSelection.EXPONENTIAL_IN_OUT, Easing.Exponential.InOut);

    this._easingMap.set(EasingSelection.CIRCULAR_IN, Easing.Circular.In);
    this._easingMap.set(EasingSelection.CIRCULAR_OUT, Easing.Circular.Out);
    this._easingMap.set(EasingSelection.CIRCULAR_IN_OUT, Easing.Circular.InOut);

    this._easingMap.set(EasingSelection.ELASTIC_IN, Easing.Elastic.In);
    this._easingMap.set(EasingSelection.ELASTIC_OUT, Easing.Elastic.Out);
    this._easingMap.set(EasingSelection.ELASTIC_IN_OUT, Easing.Elastic.InOut);

    this._easingMap.set(EasingSelection.BACK_IN, Easing.Back.In);
    this._easingMap.set(EasingSelection.BACK_OUT, Easing.Back.Out);
    this._easingMap.set(EasingSelection.BACK_IN_OUT, Easing.Back.InOut);

    this._easingMap.set(EasingSelection.BOUNCE_IN, Easing.Bounce.In);
    this._easingMap.set(EasingSelection.BOUNCE_OUT, Easing.Bounce.Out);
    this._easingMap.set(EasingSelection.BOUNCE_IN_OUT, Easing.Bounce.InOut);

  }

}

export class AnimationPropertyAttributeregistry {

  private static _registry:Map<AnimationProperty,string>

 private static get registry() {
    if (RtSome(this._registry).isEmpty) {
      this.init();
    }
    return this._registry;
  }

  static resolve(property: AnimationProperty) {
    if (!this.registry.has(property)) {
      throw new Error(`No mapping found for AnimationProperty: ${property}`);
    }
    return this.registry.get(property);
  }

  private static init() {
    this._registry = new Map();
    this._registry.set(AnimationProperty.OPACITY, CSSPropertyNameConstants.OPACITY);
    this._registry.set(AnimationProperty.COLOR, CSSPropertyNameConstants.COLOR);
    this._registry.set(AnimationProperty.DROP_SHADOW, CSSPropertyNameConstants.BORDER_RADIUS);
    this._registry.set(AnimationProperty.ROTATION, CSSPropertyNameConstants.TRANSFORM);
    this._registry.set(AnimationProperty.TRANSLATE_X, CSSPropertyNameConstants.TRANSFORM);
    this._registry.set(AnimationProperty.TRANSLATE_Y, CSSPropertyNameConstants.TRANSFORM);
  }
}
