export class JsonHelper {

  public static replacer(key, value) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  public static reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  public static isValidJSON(jsonString: string) {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        return false;
    }
}

   public static isValidJsonObject(jsonObj : Object) {
  try {
    JSON.stringify(jsonObj);
    return true;
  } catch (error) {
    return false;
  } 
 }

}
