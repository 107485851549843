import { AttributeInstance } from "../../page-builder/attributes/core/attribute-instance";

export class FormFieldInstance {
  constructor(public formField: string, //TextBox, Dropdown, DatePicker etc
    public entitySchemaFieldName: string, // Employee --> name
    public droppedControlInstanceId: string,  // AccordianId --> Parents controlInstanceId
    public attributes: AttributeInstance[],
    public parentSectionIndex?: number) { } // attributes --> additional data required to render field e.g. GridArea

  static parse(formFieldInstance: FormFieldInstance) {
    const reconstructedModel = new FormFieldInstance(formFieldInstance.formField, formFieldInstance.entitySchemaFieldName,
      formFieldInstance.droppedControlInstanceId, formFieldInstance.attributes, formFieldInstance.parentSectionIndex);
    return reconstructedModel;
  }
}

export class FormFieldInstanceContainer {
  constructor(public schemaId: string,   //Employee
    public fields: FormFieldInstance[],
    public isDefault: boolean = false) { }  //List of  fields

  static parse(jsonString: string): FormFieldInstanceContainer {
    const parsedModel: FormFieldInstanceContainer = JSON.parse(jsonString);
    const reconstructedFormFieldInstances = parsedModel.fields.map(formField => FormFieldInstance.parse(formField));
    const formFieldInstanceContainer = new FormFieldInstanceContainer(parsedModel.schemaId, reconstructedFormFieldInstances, parsedModel.isDefault);
    return formFieldInstanceContainer;
  }
}


//https://adminlte.io/themes/v3/pages/forms/general.html
//https://mdbootstrap.com/docs/b4/jquery/admin/forms/templates/
