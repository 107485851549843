import { IdentityGenerator } from "projects/den-core";
import { ExpressionFunction } from "../ExpressionRegistry";


export class UnqiueCodeHelper{

    // return [ "getNumeric(5)", "getAlphabeticUpperCase(5)",
    //     "getAlphabeticLowerCase(5)","getAlphaNumericUpperCase(5)","getAlphaNumericLowerCase(5)"];

    
    static readonly functionList : ExpressionFunction[] = [
        {
            functionName : "getNumeric(5)",
            toolTipMessage : "Returns Unique code consist of numbers.",
            returnType : 'string'
        },
        {
            functionName : "getAlphabeticUpperCase(5)",
            toolTipMessage : "Returns Unique code consist of Uppercase Alphabets.",
            returnType : 'string'
        },
        {
            functionName : "getAlphabeticLowerCase(5)",
            toolTipMessage : "Returns Unique code consist of Lowercase Alphabets.",
            returnType : 'string'
        },
        {
            functionName : "getAlphaNumericUpperCase(5)",
            toolTipMessage : "Returns Unique code with the mix of Uppercase Alphabets and numbers.",
            returnType : 'string'
        },
        {
            functionName : "getAlphaNumericLowerCase(5)",
            toolTipMessage : "Returns Unique code with the mix of Lowercase Alphabets and numbers.",
            returnType : 'string'
        },
    ];

    static getNumeric(noOfChars: string, options? : { params : any , isUpdateMode: boolean , expression : string, funcName : string}){
        let numberOfCharacters : number = parseInt(noOfChars);
        let uniqueCodeValue = IdentityGenerator.getNumericCode(numberOfCharacters);
        return this.isUpdateMode(numberOfCharacters,uniqueCodeValue,options);
    }

    static getAlphabeticUpperCase(noOfChars: string, options? : { params : any , isUpdateMode: boolean , expression : string, funcName : string}){
        let numberOfCharacters : number = parseInt(noOfChars);
        let uniqueCodeValue = IdentityGenerator.getAlaphabeticCodeUpperCase(numberOfCharacters);
        return this.isUpdateMode(numberOfCharacters,uniqueCodeValue,options);
    }

    static getAlphabeticLowerCase(noOfChars: string, options? : { params : any , isUpdateMode: boolean , expression : string, funcName : string}){
        let numberOfCharacters : number = parseInt(noOfChars);
        let uniqueCodeValue = IdentityGenerator.getAlaphabeticCodeLowerCase(numberOfCharacters);
        return this.isUpdateMode(numberOfCharacters,uniqueCodeValue,options);
    }

    static getAlphaNumericUpperCase(noOfChars: string, options? : { params : any , isUpdateMode: boolean , expression : string, funcName : string}){
        let uniqueCodeValue : string;
        let numberOfCharacters : number = parseInt(noOfChars);
        while (!(/\d/.test(uniqueCodeValue))) { //generating code until getting a alpha numeric characters
            uniqueCodeValue = IdentityGenerator.getAlaphaNumericCodeUpperCase(numberOfCharacters);
        }
        return this.isUpdateMode(numberOfCharacters,uniqueCodeValue,options);
    }

    static getAlphaNumericLowerCase(noOfChars: string, options? : { params : any , isUpdateMode: boolean , expression : string, funcName : string}){
        let uniqueCodeValue : string;
        let numberOfCharacters : number = parseInt(noOfChars);
        while (!(/\d/.test(uniqueCodeValue))) { //generating code until getting a alpha numeric characters
            uniqueCodeValue = IdentityGenerator.getAlaphaNumericCodeLowerCase(numberOfCharacters);
        }
        return this.isUpdateMode(numberOfCharacters,uniqueCodeValue,options);
    }

    static isUpdateMode(numberOfCharacters : number, uniqueCodeValue :string, options:any) {
        if(options.isUpdateMode) {
            const startPos = options?.expression.indexOf(`{${options.funcName}}`);
            uniqueCodeValue = options.params.slice(startPos, startPos + numberOfCharacters);
        }
        return uniqueCodeValue;
    }

    static getFunctionListWithDefaultParams() : ExpressionFunction[] {
        return UnqiueCodeHelper.functionList;
    }
}