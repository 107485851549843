
export class PubSubTopic {
  static readonly CONTROL_RENDERED = 'CONTROL_RENDERED';
  static readonly CHILD_PAGE_CONTROL_RENDERED = 'CHILD_PAGE_CONTROL_RENDERED';
  static readonly CHILD_CONTROL_RENDERED = 'CHILD_CONTROL_RENDERED';
  static readonly CONTROL_DATA_RECEIVED = 'CONTROL_DATA_RECEIVED';
  static readonly CONTROL_INSTANCE_SELECTED = 'CONTROL_INSTANCE_SELECTED';
  static readonly CONTROL_INSTANCE_SELECTED_FROM_OUTLINE = 'CONTROL_INSTANCE_SELECTED_FROM_OUTLINE';
  static readonly CONTROL_INSTANCE_DELETED = 'CONTROL_INSTANCE_DELETED';
  static readonly CONTROL_SELECTED = 'CONTROL_SELECTED';
  private static readonly CONTEXT_MENU = 'CONTEXT_MENU';
  private static readonly MOUSE_OVER = 'MOUSE_OVER';
  private static readonly MOUSE_OUT = 'MOUSE_OUT';
  static readonly CONTROL_UN_SELECTED = 'CONTROL_UN_SELECTED';
  static readonly THREE_JS_CONTROL_SELECTED = 'THREE_JS_CONTROL_SELECTED';
  static readonly ON_ESC_KEY_PRESSED = 'ON_ESC_KEY_PRESSED';
  static readonly CLEAR_ELEMENT_SELECTION = 'CLEAR_ELEMENT_SELECTION';
  static readonly DELETE_ELEMENT = 'DELETE_ELEMENT';
  static readonly CREATE_ELEMENT = 'CREATE_ELEMENT';
  static readonly PAGE_BUILDER_MODE = 'PAGE_BUILDER_MODE';
  // static readonly CESIUM_HEATMAP = 'CESIUM_HEATMAP';
  static readonly SELECTED_TREE_DATA = 'SELECTED_TREE_DATA';
  static readonly REARRANGE_OUTLINE = 'REARRANGE_OUTLINE';
  static readonly SAVE_CONTROL_INSTANCE_SUCCESS = 'SAVE_CONTROL_INSTANCE_SUCCESS';
  static readonly RENDER_COMPONENT_ONDROP = 'RENDER_COMPONENT_ONDROP';
  static readonly ON_CONTROL_DROPPED = 'ON_CONTROL_DROPPED';
  static readonly CONTROL_REARRANGED_FROM_OUTLINE = 'CONTROL_REARRANGED_FROM_OUTLINE';
  static readonly CONTROL_COPY_PASTE_FROM_OUTLINE = 'CONTROL_COPY_PASTE_FROM_OUTLINE';
  static readonly CONTROL_RERENDERED = 'CONTROL_RERENDERED';
  static readonly ON_CHILD_CONTROL_DELETE = 'ON_CHILD_CONTROL_DELETE';
  private static readonly RELOAD_REPEATER = 'RELOAD_REPEATER';
  static readonly ON_FORM_FIELD_DROPPED = 'ON_FORM_FIELD_DROPPED';

  static readonly CANVAS_INSTANCE_CHANGES = 'CANVAS_INSTANCE_CHANGES';
  static readonly CSS_PROPERTIES_CHANGED = 'CSS_PROPERTIES_CHANGED';
  private static readonly DATA_RECEIVED = 'DATA_RECEIVED';
  private static readonly VISIBLE_EVENT_MAPPED_TOPIC = 'VISIBLE_EVENT_MAPPED_TOPIC';
  private static readonly VISIBLE_EVENT_UNMAPPED_TOPIC = 'VISIBLE_EVENT_UNMAPPED_TOPIC';
  private static readonly DATA_SOURCE_UPDATE__DATA_RECEIVED = 'DATA_SOURCE_UPDATE__DATA_RECEIVED';

  private static readonly PARAM_READY_FOR_MAPPING = 'PARAM_READY_FOR_MAPPING';
  private static readonly REMOVE_EVENT_MENU = 'REMOVE_EVENT_MENU';
  private static readonly VISIBLE_EVENT_READY_FOR_MAPPING = 'INTERACTION_EVENT_READY_FOR_MAPPING';
  private static readonly EMPTY_VALUE_EVENT_READY_FOR_MAPPING = 'EMPTY_VALUE_EVENT_READY_FOR_MAPPING';
  private static readonly PARAM_MAPPED = 'PARAM_MAPPED';
  private static readonly PARENT_PARAM_MAPPED = 'PARAM_MAPPED';
  private static readonly PARAM_UN_MAPPED = 'PARAM_UN_MAPPED';

  static readonly CREATE_NEW_DATA_SOURCE = 'CREATE_NEW_DATA_SOURCE';
  static readonly QUICK_CONFIG_CHANGED = 'QUICK_CONFIG_CHANGED';

  static readonly THREE_JS_ENTITY_ICON_CLICK = 'THREE_JS_ENTITY_ICON_CLICK';
  static readonly ON_THREE_D_MODEL_DROP = 'ON_THREE_D_MODEL_DROP';
  static readonly MODEL_UN_MAP = 'MODEL_UN_MAP';
  static readonly FORM_MIXIN_INITIALIZED = 'FORM MIXIN';
  static readonly THEME_APPLIED = 'THEME_APPLIED';
  static readonly APP_SETTINGS_CONFIGURED = 'APP_SETTINGS_CONFIGURED';
  static readonly SELECTED_THEME = 'SELECTED_THEME';
  static readonly ON_NEW_CONTROL_ADDED_FROM_OUTLINE = 'NEW_CONTROL_ADDED_FROM_OUTLINE';
  static readonly CSS_GRID_AREA_SELECTED = 'CSS_GRID_AREA_SELECTED';
  static readonly ACTION_EVENT_SUCCESS = 'ACTION_EVENT_SUCCESS';
  static readonly NAV_MENU_SELECT = 'NAV_MENU_SELECT';
  static readonly OFFLINE_FORM = 'OFFLINE_FORM';
  static readonly REDUCERS = 'reducers';
  static readonly FORM_REFRESH = 'form_refresh';
  static readonly COMPLETED_API_EXECUTION_TOPIC = 'Completed API Execution Topic';
  static readonly RELOAD_PAGE_ON_PUBLISH_OR_DISCARD = 'Reload Page On Publish Or Discard';
  static readonly RELOAD_PAGE_ON_CLEARING_PAGE = 'Reload Page On Clear page';
  static readonly RELOAD_OUTLINE_ON_PASTE_CONTROLS = 'Reload Outline on Paste Controls';

  //Mobile page builder specific subscrption topics
  static readonly CONTROL_INSTANCE_WRAPPER = 'CONTROL_INSTANCE_WRAPPER';
  static readonly CLEAR_DB_ON_PUBLISH = 'CLEAR_DB_ON_PUBLISH';
  static readonly EVENT_STORE_ACTION_MESSENGER = "EVENT_STORE_ACTION_MESSENGER";
  static readonly OPEN_POPUP_FROM_OUTLINE_TOPIC = 'OPEN_POPUP_FROM_OUTLINE_TOPIC';
  static readonly DELETE_EVENT_ACTION = 'DELETE_EVENT_ACTION';

  static readonly UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE'
  static readonly UPDATE_PAGE_EVENT_CONTAINERS = 'UPDATE_PAGE_EVENT_CONTAINERS'
  static readonly FORM_FIELD_CONTROL_RERENDERED = ' FORM_FIELD_CONTROL_RERENDERED';
  static readonly OFFLINE_MAP_DOWNLOADED = 'OFFLINE_MAP_DOWNLOADED';
  static readonly OFFLINE_MAP_DOWNLOADED_PROGRESS = 'OFFLINE_MAP_DOWNLOADED_PROGRESS';
  static readonly STATIC_TAB_CONFIG_CHANGES = 'STATIC_TAB_CONFIG_CHANGES ';
  static readonly STEPPER_NAME_CONFIG_CHANGES = 'STEPPER_NAME_CONFIG_CHANGES'
  static readonly ACCORDION_CONFIG_CHANGES = 'ACCORDION_CONFIG_CHANGES ';
  static readonly ENTITY_CACHE_UPDATE = 'ENTITY_CACHE_UPDATE ';
  static readonly BEHAVIOUR_ATRRIBUTE_DELETE = 'BEHAVIOUR_ATRRIBUTE_DELETE';
  //fluent service specific subscriptions
  static readonly UPDATE_FUNCTIONS_ON_DELETE = "UPDATE_FUNCTIONS_ON_DELETE"
  static readonly ERROR_LOG = "ERROR_LOG"
  static readonly CURSOR_POINTER_TOPIC = "CURSOR_POINTER_TOPIC"

  static readonly FORM_FIELD_SESSION_DEFAULTS = "FORM_FIELD_SESSION_DEFAULTS";
  static readonly BULK_UPDATE_BATCH_TOPIC = "BULK_UPDATE_BATCH_TOPIC";
  static readonly BULK_UPDATE_SUCCESS_TOPIC = "BULK_UPDATE_SUCCESS_TOPIC"
  static readonly COPY_PASTE_CONTROL_MOBILE = "COPY_PASTE_CONTROL_MOBILE"

  static buildNewBulkUpdateSucessTopicId(){
    return `${this.BULK_UPDATE_SUCCESS_TOPIC}`;
  }  

  static buildNewControlAddedTopicId(instanceId: string) {
    return this.ON_NEW_CONTROL_ADDED_FROM_OUTLINE + '_' + instanceId;
  }

  static buildThreeDModelDropTopicId(schemaName: string) {
    return this.ON_THREE_D_MODEL_DROP + '_' + schemaName;
  }
  static buildCssPropertiesTopicId(instanceId: string) {
    return this.CSS_PROPERTIES_CHANGED + '_' + instanceId;
  }

  static buildDataReceivedTopicId(controlId: string) {
    return this.DATA_RECEIVED + '_' + controlId;
  }
  static buildDataSourceUpdateDataRecievedTopicId(instanceId: string) {
    return this.DATA_SOURCE_UPDATE__DATA_RECEIVED + '_' + instanceId;
  }

  static buildReArrangeOutlineTopicId(instanceId: string) {
    return this.REARRANGE_OUTLINE + '_' + instanceId;
  }
  static buildControlRenderedTopicId(instanceId: string) {
    return this.CONTROL_RENDERED + '_' + instanceId;
  }
  static buildChildControlRenderedTopicId(instanceId: string) {
    return this.CHILD_CONTROL_RENDERED + '_' + instanceId;
  }

  static buildRenderCompOnDropTopicId(instanceId: string) {
    return this.RENDER_COMPONENT_ONDROP + '_' + instanceId;
  }

  //Development time topics
  static buildReadyForParamMappingTopic(): string {
    return this.PARAM_READY_FOR_MAPPING;
  }
  static buildRemoveEventMenuTopic(): string {
    return this.REMOVE_EVENT_MENU;
  }
  static buildReadyForVisibleEventMappingTopic(): string {
    return this.VISIBLE_EVENT_READY_FOR_MAPPING;
  }
  static buildReadyForEmptyValueEventMappingTopic(): string {
    return this.EMPTY_VALUE_EVENT_READY_FOR_MAPPING;
  }
  static buildParamMappedTopic(): string {
    return this.PARAM_MAPPED;
  }
  static buildParentParamMappedTopic(): string {
    return this.PARENT_PARAM_MAPPED;
  }
  static buildParamUnMappedTopic(targetInstanceId: string): string {
    return this.PARAM_UN_MAPPED + "" + targetInstanceId;
  }
  // static buildControlEventTopic(): string {
  //   return this.CONTROL_EVENT_PUBLISH_TOPIC;
  // }
  static buildVisibleEventMappedTopic(): string {
    return this.VISIBLE_EVENT_MAPPED_TOPIC;
  }
  static buildVisibleEventUnMappedTopic(targetInstanceId: string): string {
    return this.VISIBLE_EVENT_UNMAPPED_TOPIC + "" + targetInstanceId;
  }
  static buildModelUnMapTopic(instanceId): string {
    return this.MODEL_UN_MAP + instanceId;
  }
  static buildDeleteElementTopic(instanceId): string {
    return this.DELETE_ELEMENT + instanceId;
  }
  static buildControlSelectTopic(controlId): string { //controlId is the id property from the control class
    return `${this.CONTROL_SELECTED}_${controlId}`;
  }
  static buildContextMenuTopic(controlId): string { //controlId is the id property from the control class
    return `${this.CONTEXT_MENU}_${controlId}`;
  }
  static buildMouseEnterTopic(controlId): string { //controlId is the id property from the control class
    return `${this.MOUSE_OVER}_${controlId}`;
  }
  static buildMouseLeaveTopic(controlId): string { //controlId is the id property from the control class
    return `${this.MOUSE_OUT}_${controlId}`;
  }
  static buildElementSelectedFromOutlineTopic(instanceId): string { //instanceId is the controlInstanceId
    return `${this.CONTROL_INSTANCE_SELECTED_FROM_OUTLINE}_${instanceId}`;
  }
  static buildControlUnSelectTopic(controlId): string { //controlId is the id property from the control class
    return `${this.CONTROL_UN_SELECTED}_${controlId}`;
  }
  static buildControlInstanceDeletedTopic(instanceId): string { //instanceId is the controlInstanceId
    return `${this.CONTROL_INSTANCE_DELETED}_${instanceId}`;
  }

  static readonly LAYER_SELECTED = 'LAYER_SELECTED';
  static buildLayerSelectTopic(layerInstanceId): string {
    return `${this.LAYER_SELECTED}_${layerInstanceId}`;
  }


  static buildSpecialControlDropTopicId(instanceId: string) {
    return this.ON_FORM_FIELD_DROPPED + '_' + instanceId;
  }

  static buildOnChildControlDeleteTopicId(parentId) {
    return this.ON_CHILD_CONTROL_DELETE + "_" + parentId;
  }

  static buildReloadRepeaterTopic(repeaterId) {
    return this.RELOAD_REPEATER + "_" + repeaterId;
  }

  static readonly OBJECT_3D_PROPERTY_CHANGE = 'OBJECT_3D_PROPERTY_CHANGE';
  static buildObject3DPropertyChange(controlId: string) {
    return `${this.OBJECT_3D_PROPERTY_CHANGE}_${controlId}`;
  }

  private static readonly ON_CONTROL_DATA_SOURCE_UPDATED = 'ON_CONTROL_DATA_SOURCE_UPDATED';
  static buildControlDataSourceChangeTopic(controlId: string) {
    return `${this.ON_CONTROL_DATA_SOURCE_UPDATED}_${controlId}`;
  }

  private static readonly CONTROL_RE_ARRANGED = 'CONTROL_RE_ARRANGED';
  static buildControlReArrangedTopicId(instanceId: string) {
    return this.CONTROL_RE_ARRANGED + '_' + instanceId;
  }

  private static readonly DEFAULT_PAGINATION_SETTINGS = 'DEFAULT_PAGINATION_SETTINGS';
  static buildDefaultPaginationSettingsTopic(instanceId: string): string {
    return this.DEFAULT_PAGINATION_SETTINGS + '_' + instanceId;

  }
  private static readonly RESET_PREVIOUS_DATA = 'RESET_PREVIOUS_DATA';
  static buildResetPreviousDataTopic(controlInstanceId: string): string {
    return this.RESET_PREVIOUS_DATA + '_' + controlInstanceId;

  }

  static buildControlDropTopicId(instanceId: string) {
    return `${PubSubTopic.ON_CONTROL_DROPPED}_${instanceId}`;
  }

  static buildActionEventSuccessTopicId(instanceId: string) {
    return `${PubSubTopic.ACTION_EVENT_SUCCESS}_${instanceId}`;
  }
  static buildControlRearrangeFromOutlineTopicId(instanceId: string) {
    return `${PubSubTopic.CONTROL_REARRANGED_FROM_OUTLINE}_${instanceId}`;
  }
  static buildControlCopyPasteFromOutlineTopicId(instanceId: string) {
    return `${PubSubTopic.CONTROL_COPY_PASTE_FROM_OUTLINE}_${instanceId}`;
  }
  static buildControlRerenderTopicId(instanceId: string) {
    return `${PubSubTopic.CONTROL_RERENDERED}_${instanceId}`;
  }
  static buildOfflineFormTopicId(name: string) {
    return `${PubSubTopic.OFFLINE_FORM}_${name}`;
  }

  static readonly THREEJS_ENTITY_LIST = 'threeJsEntityList_';
  static buildThreeJSEntityListTopicId(instanceId: string) {
    return `${PubSubTopic.THREEJS_ENTITY_LIST}_${instanceId}`;
  }

  static formRefreshTopicId(instanceId: string) {
    return this.FORM_REFRESH + '_' + instanceId;
  }

  static buildCompletedAPIExecutionTopicId(controlId: string) {
    return this.COMPLETED_API_EXECUTION_TOPIC + '_' + controlId;
  }

  static buildControlInstanceWrapperTopicId(instanceId): string { //instanceId is the controlInstanceId
    return `${this.CONTROL_INSTANCE_WRAPPER}_${instanceId}`;
  }
  static readonly FORM_CONTAINER_VALUE_CHANGE = 'FORM_CONTAINER_VALUE_CHANGE';
  static buildFormContainerValueChangeTopicId(instanceId: string) {
    return `${this.FORM_CONTAINER_VALUE_CHANGE}_${instanceId}`;
  }

  static readonly FORM_CONTAINER_INSTANCE_SAVE = 'FORM_CONTAINER_INSTANCE_SAVE';
  static buildFormContainerInstanceSaveTopicId(id: string) {
    return `${this.FORM_CONTAINER_INSTANCE_SAVE}_${id}`;
  }

  static readonly FORM_CONTAINER_DESTROY = 'FORM_CONTAINER_DESTROY';
  static buildFormContainerDestroyTopicId(instanceId: string) {
    return `${this.FORM_CONTAINER_DESTROY}_${instanceId}`;
  }

  static readonly FORM_ACTION_COMPLETED = 'FORM_ACTION_COMPLETED';
  static buildFormActionCompletedTopicId(instanceId: string) {
    return `${this.FORM_ACTION_COMPLETED}_${instanceId}`;
  }

  static readonly FORM_BACKEND_ACTION_COMPLETED = 'FORM_BACKEND_ACTION_COMPLETED';
  static buildFormBackendActionCompletedTopicId() {
    return `${this.FORM_BACKEND_ACTION_COMPLETED}`;
  }

  static readonly FORM_FIELD_CONTROL_SELECTED = 'FORM_FIELD_CONTROL_SELECTED';
  static buildFormFieldSelectedTopicId(instanceId: string) {
    return `${this.FORM_FIELD_CONTROL_SELECTED}_${instanceId}`;
  }

  static buildFormFieldControlRerenderTopicId(instanceId: string) {
    return `${PubSubTopic.FORM_FIELD_CONTROL_RERENDERED}_${instanceId}`;
  }

  static buildStaticTabConfigChangesTopicId(instanceId: string) {
    return `${PubSubTopic.STATIC_TAB_CONFIG_CHANGES}_${instanceId}`;
  }

  static buildStepperConfigChangesTopicId(instanceId: string) {
    return `${PubSubTopic.STEPPER_NAME_CONFIG_CHANGES}_${instanceId}`
  }

  static buildAccordionConfigChangesTopicId(instanceId: string) {
    return `${PubSubTopic.ACCORDION_CONFIG_CHANGES}_${instanceId}`;
  }
  static buildDeleteBehaviourAttributeTopic(instanceId: string) {
    return `${PubSubTopic.BEHAVIOUR_ATRRIBUTE_DELETE}_${instanceId}`;
  }

  static buildControlErrorLogTopic(instanceId): string {
    return `${PubSubTopic.ERROR_LOG}_${instanceId}`;
  }

}


export enum ThreeDSelectEventType {
  OBJECT_3D = "object_3d",
  LAYER = "layer"
}
export abstract class ThreeDSelectEvent {
  abstract selectEventType: ThreeDSelectEventType;
  constructor(public controlId: string) {
  }
}
export class Object3DSelectEvent extends ThreeDSelectEvent {
  selectEventType: ThreeDSelectEventType = ThreeDSelectEventType.OBJECT_3D;
  constructor(public controlId: string) {
    super(controlId);
  }
}
export class LayerSelectEvent extends ThreeDSelectEvent {
  selectEventType: ThreeDSelectEventType = ThreeDSelectEventType.LAYER;
  constructor(public controlId: string, public shouldSelectGroup: boolean = false) {
    super(controlId);
  }
}
