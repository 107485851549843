import { Injectable } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { PageService } from "../../../../../../../../src/lib/page-builder/services/page-service";
import { EventService } from "../../../../../../providers/event-service/event.service";
import { RtNone } from "../../../../../../utils/option-helper";
import { GlobalEventDataSchema } from "../../event-schema-provider";
import { StandardEvents } from "../../event-types";
import { ProducerProps, GlobalEventProducerInfo, GlobalEventProducer, GlobalEventData } from "../global-event-producer";

//Page params provider. e.g. query params.
export class PageParamsEventProducerProps extends ProducerProps {

  constructor(private pageService: PageService) {
    super();
    this.resolveParamMap();
  }

  produceParams: GlobalEventDataSchema[]

  private resolveParamMap() {
    const pageParams = this.pageService.pageParams as any;
    this.produceParams = pageParams.map(key => {
      return { property: key.paramName }
    })
  }

  ON_PAGE_LOAD = StandardEvents.ON_PAGE_LOAD
  ON_PAGE_LEAVE = StandardEvents.ON_PAGE_LEAVE

  get props(): GlobalEventProducerInfo {
    return {
      producerName: PageParamsEventProducerProps.PRODUCER_NAME,
      description: "query params recieved when page is navigated",
      iconName: "",
      eventTypes: [this.ON_PAGE_LOAD, this.ON_PAGE_LEAVE],
      eventParams: [],
      produceParams: this.produceParams,
    }
  }
  static PRODUCER_NAME = "Page load";
}

@Injectable()
export class PageParamsEventProducer extends GlobalEventProducer<PageParamsEventProducerProps> {
  paramMap: ParamMap;
  produceParams: GlobalEventDataSchema[]
  constructor(public eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private pageService: PageService) {
    super(eventService)
  }

  get props(): PageParamsEventProducerProps {
    return new PageParamsEventProducerProps(this.pageService);
  }

  init() {
    this.resolveParamMap()
  }

  buildAndProduce(schema: GlobalEventDataSchema, value: any) {
    const data: GlobalEventData = { schema: schema, value: value }
    this.produce(this.props.ON_PAGE_LOAD, RtNone(), [data])
  }

  dispose() {
    //nothing to dispose
  }
  pulishPageParams(queryparamsMap: Map<string, string>) {
    if (queryparamsMap) {
      queryparamsMap.forEach((value, key) => {
        this.buildAndProduce({ property: key }, value);
      })
    }
  }
  private resolveParamMap() {
    this.activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
      const keys = paramMap.keys;
      keys.map(key => {
        const value = paramMap.get(key)
        this.buildAndProduce({ property: key }, value)
      }) 

    });
  }

}
