//converters

import { DsResultArray } from "../../../../data-source/data-source-result-entities";

export enum ConvertibleType {
    DATA_CONVERTER = "data_converter",
    lOCAL_COORDINATES_CONVERTER = "local_coordinates_converter",
}

export interface ConvertibleDefinition {
    id:string
    type: ConvertibleType,
    setToFieldName: string, //Used for DATA_CONVERTER
    inputFieldName: string, //Used for lOCAL_COORDINATES_CONVERTER
    outputFieldName: string, //Used for lOCAL_COORDINATES_CONVERTER
}

export interface IDsResultConverter<D extends ConvertibleDefinition> {

    convert(definition: D, data: DsResultArray): DsResultArray;
}
