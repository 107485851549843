export class PasswordRecoveryConstants {
    static EMAIL = 'devum-Email';
    static PHONE = 'devum-Phone';
    static RECOVERY_PASSWORD = 'devum-recoverPassword';
    static UPDATE_PASSWORD = 'devum-updatePassword';
    static LOGIN_FORM = 'devum-login';
}
export class IdentifierType {
    static INTERNAL = 'internal';
    static EXTERNAL = 'external';
}
export class Route {
    static SECURITY = 'security';
    static INTERNAL = 'internal-login';
    static LOGIN = 'login';
    static MANAGE_ROLE = 'manage_role';
    static SITES_APPS = 'sites-apps';
    static SECURITY_INTERNAL = `${Route.SECURITY}/${Route.INTERNAL}`;
    static SECURITY_EXTERNAL = `${Route.SECURITY}/${Route.LOGIN}`;
    static SECURITY_SITES_APPS = `${Route.SECURITY}/${Route.SITES_APPS}`;
    static HOME = 'home';
    static APP = 'app';
    static FORMS = 'forms';
    static RESET_PASSWORD = 'reset-password';
    static APP_DESIGNER = 'app_designer';
    static OFFILINE_SYNC = 'offline';
    static OFFILINE_SYNC_DETAILS = 'details';
    static SCHEDULE_DOWNLOAD = 'schedule-download';
    static OFFLINE_DOWNLOAD_TAB = 'offline-download-tab';
    static OFFLINE_UPLOAD_TAB = 'offline-upload-tab';
    static OFFILINE_MAPS = 'offline-maps';
    static BLUETOOTH_MAC = 'bluetooth-mac';
}

export class AuthenticationExceptionCodes {
    static NO_APPS = 70002; //TODO: Update with actual
}

export enum AppConstants {
    BACK_BUTTON = 'backbutton'
}

export enum EntityConstants {
    ENTITY_SCHEMA_FIELD_FILTERS = "entitySchemaFieldFilters",
    ENTITY_FIELD_CONDITIONS = "entityFieldConditions",
    ENTITY_FIELD_CONDITION_OPERATORS = "entityFieldConditionOperators",
    ENTITY_PASSWORD = "Password",
    AUTH_CREDENTIALS = "AuthCredentials",
    ENTITY_FIELD_DISPLAY_CONDITIONS = "EntityFieldDisplayConditions",
    ADVANCED_ENTITY_SCHEMA_SETTINGS = "AdvancedEntitySchemaSettings"
}

export enum TileLayerOfflineEvents {
    SAVE_START_EVENT = "savestart",
    LOAD_TILE_END_EVENT = "loadtileend",
}