import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


export enum APICallResponseConstant {
    EXECUTED = 'EXECUTED',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

@Injectable()
export class APIRegistryService {

    public httpCallList: Map<string, APICallResponseConstant> = new Map();
    #apiRegistryObs = new Subject<Map<string, APICallResponseConstant>>();

    constructor(){
        
    }

    getRegistryObserverable() {
        return this.#apiRegistryObs.asObservable();
    }

    setItemToRegistry(controlInstanceId: string, status: APICallResponseConstant) {
        this.httpCallList.set(controlInstanceId, status);
        this.#apiRegistryObs.next(this.httpCallList);
    }

    isRequestSuccess(controlInstanceId: string) {
        return this.httpCallList.get(controlInstanceId) == APICallResponseConstant.SUCCESS;
    }

    clear() {
        this.httpCallList.clear();
    }



}