import { HttpErrorResponse } from "@angular/common/http";
import { BaseEnumType } from "./base.models";

export class EnumValues extends BaseEnumType { }

export enum FormlyControlTypes {
    INPUT = 'input',
    CHECK_BOX = 'checkbox',
    RADIO_BUTTON = 'radio',
    TEXT_AREA = 'textarea',
    SINGLE_SELECT = 'select',
    MULTI_SELECT = 'multiselect',
    TEXT = 'input',
    DATE = 'datepicker',
    DATE_TIME = 'datetime',
    TIME = 'timepicker',
    BUTTON = 'button',
    INPUT_WITH_NUMBER_TYPE = 'number',
    FILE_UPLOAD = 'file',
    COLOR_PICKER = 'rt-color-picker',
    RICH_TEXT = 'rich-text',
    QUESTIONNAIRE = 'questionnaire',
    MAP = 'map',
    FILE_UPLOAD_3D = 'file3D',
    DOCUMENT_UPLOAD = 'document',
    LOCATION2D = 'geoLocation',
    LOCATION3D = 'location3D',
    CHARVAKA_USER_TASK_ACTION_COMPONENT = 'rt-user-actions-complex-property-property',
    ENTITY_MAPPING = 'ENTITY_MAPPING',
    ENTITY_MAPPING_COMPONENT = 'rt-usertask-entity-field-mapping-complex-property.component',
    COLLECTION_AND_USER_TASKS = 'COLLECTION_AND_USER_TASKS',
    COLLECTION_AND_USER_TASKS_COMPONENT = 'rt-collection-usertask-complex-property',
    CHARVAKA_USER_TASK_ACTION = 'USER_ACTIONS',
    CHARVAKA_SERVICE_DATA_MAPPING = 'DATA_MAPPING_INPUT',
    REPEAT_SECTION = 'repeat',
    USERS_AND_ROLES_CONFIG = 'SITE_USER_MAPPING_COMPLEX_PROPERTY',
    TEST_CASE_AND_SIMULATION = 'test_case_and_simulation',
    VARIABLES_DEFINTION_COMPLEX_PROPERTY = 'VARIABLES_DEFINTION_COMPLEX_PROPERTY',
    REPORT_PARAMS_COMPLEX_PROPERTY = 'REPORT_CONFIGURATION_COMPLEX_PROPERTY',
    TASK_KPI = 'TASK_KPI',
    CHARVAKA_SERVICE_TASK_REPORT_COMPONENT = 'rt-report-params-complex-property.component',
    VARIABLES_DEFINTION_COMPLEX_PROPERTY_COMPONENT = 'rt-variable-definition-complex-property-property',
    CHARVAKA_SERVICE_DATA_MAPPING_COMPONENT = 'rt-data-mappings-complex-property.component',
    REPORT_TASK_USERS_CONFIG = 'REPORT_TASK_USERS_CONFIG',
    SEARCHABLE_CONTROL = 'searchable-control',
    LIST_COMPLEX_PROPERTY = 'LIST_COMPLEX_PROPERTY',
    LIST_COMPLEX_PROPERTY_COMPONENT = 'rt-list-complex-property',
    DATE_TIME_MILLIS = 'dateTimeMillis',
    API = 'api',
    ICON = 'icon',
    SEARCHABLE_SELECT = 'searchable-select',
    AUTO_COMPLETE_SELECT = 'auto-complete-select',
    TEXT_WITH_ACTION_BUTTON = 'text-with-action-button',
    MULTI_CHECKBOX = 'multicheckbox',
    RESOURCE_BROWSER = 'resourcefile',
    RESOURCE_BROWSER_3D = 'resource3dfile',
    CHARVAKA_FLUENT_SERVICE_COMPLEX = "CHARVAKA_FLUENT_SERVICE_COMPLEX_COMPONENT",
    CHARVAKA_FLUENT_SERVICE_COMPLEX_COMPONENT = "rt-fluent-service-task",

}

export enum CharvakaMessageType {
    EMAIL = 1,
    WHATS_APP = 2,
    SMS = 3,
    HF_APPs = 4
}

export enum OperationTypeEnum {
    INSERT_MANY = 2,
    DELETE_MANY_WITH_LIMIT_AND_SORT = 3,
    SET_MANY = 4,
    DELETE_MANY = 6,
    UPDATE_MANY = 8
};


export enum FieldType {
    NUMBER = 'number',
    STRING = 'string',
    CATEGORY = 'category',
    BOOLEAN = 'boolean',
    DATE = 'date',
    TEXT_AREA = 'textarea',
    MULTI_SELECT = 'multiselect',
}

export enum ErrorMessageConstant {
    ALERT = 'alert',
    ERROR = 'error',
    WARN = 'warn',
    INFO = 'info'
}

export class UploadImage {
    static readonly IMAGE_UPLOAD_PATTERN = (/\.(jpg|jpeg|png|gif|tif|tiff)$/);
    static readonly DOCUMENT_UPLOAD_PATTERN = (/\.(pdf|docx|xlsx|pptx|txt|csv)$/);
    static readonly IMAGE_3D_UPLOAD_PATTERN = (/\.(gltf|glb)$/);
    static readonly IMAGE_UPLOAD_LENGTH = 50;
    static readonly MAX_FILE_SIZE = 10000000;
}
export type ButtonErrorHandler = boolean | ErrorEvent | HttpErrorResponse | Error;
