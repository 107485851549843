
export class DateTimeFormatConstants {
    static DATE_FORMAT = 'yyyy-MM-dd';
    static DDMMYYYY = 'dd/MM/yyyy';
    static TIME_FORMAT = 'HH:mm:ss';
    static DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
    static DATE_TIME_FORMAT_HHMM = 'dd/MM/yyyy HH:mm';
    static TIME_FORMAT_HHMM = 'HH:mm';
    static ISO_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
    static SHOW_RELATIVE_TIME: string;
    static REFRESH_INTERVAL: string;
    static RELATIVE_TIME_FORMAT: string;
    static FROM_NOW_WITH_SUFFIX: any;
    static FROM_NOW_WITHOUT_SUFFIX: any;
    static CALENDER_TIME: any;
    static CUSTOM_DATE_FORMAT: string;
    static DATE_TIME: string;
}

export class OperationType {
    static INSERT = 'insert';
    static DELETE = 'delete';
    static UPDATE = 'update';
    static ADD = 'add';
    static READ = 'read';
    static CREATE = 'create';
}

export class ActionName {
    static DELETE = 'Delete';
    static CLEAR = 'clear';
}

export class PasswordRecoveryConstants {
    static EMAIL = 'Email';
    static PHONE = 'Phone';
    static RECOVERY_PASSWORD = 'recoverPassword';
    static UPDATE_PASSWORD = 'updatePassword';
    static LOGIN_FORM = 'login';
}

export class HttpHeaderConstants {
    static SITE_ID = 'siteCode';
    static API_KEY = 'apiKey';
    static IP_ADDRESS = 'ipAddress';
    static USER_AGENT = 'User-Agent';
    static GEO_LOCATION = 'geoLocation';
    static APP_CODE = 'appCode';
    static IDENTIFIER_TYPE = "identifierType";
    static DOMAIN_URL = "domainURL";
    static ORG_CODE = 'orgCode';
    static Authorization = "Authorization";
}

export class FormStatus {
    static INVALID = 'INVALID';
    static DEFAULT_LABEL = 'save';
}

export enum URLConstants {
    DYNAMIC_FORMS = 'dynamicForms/'
}

export class CrudType {
    static INSERT = 1;
    static UPDATE = 2;
    static DELETE = 3;
    static REFRESH = 4;
}
export class Entity {
    static CharvakaFluentService = "CharvakaFluentService"
    static Page = "Page"
    static EntitySchema = "EntitySchema"
    static MaterializedViewDefinition = "MaterializedViewDefinition"
    static CharvakaProcess = "CharvakaProcess"
}

export enum OrgCodeConstants {
    REACTORE = 'reactore',
}

export enum AssetPathEnum {
    ASSET_PATH = 'AssetPath',
}

export enum DateFormatEnum {
    VIEW_DATE_FORMAT = 'dd/MM/yyyy',
    DATE_FORMAT = 'yyyy-MM-dd',
    VIEW_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm',
    DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss',
    TIME_FORMAT = 'HH:mm'
};
export class DateRegex {
    static ddMMyyyy = /^(0[1-9]|1\d|2\d|3[01])\-(0[1-9]|1[0-2])\-(19|20)\d{2}$/;
    static yyyyMMdd = /^(\d{4})([\/-])(\d{1,2})\2(\d{1,2})$/;
    static ddMMyyyyHHmmss = /^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-\d\d\d\d (20|21|22|23|[0-1]?\d):[0-5]?\d:[0-5]?\d$/
    static ddMMyyyyHHmm = /^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-\d\d\d\d (20|21|22|23|[0-1]?\d):[0-5]?\d$/
    static yyyyMMddHHmmss = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/
    static yyyyMMddHHmm = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/
    static HHmm = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
    static yyyyMMddTHHmmssSSSZ = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)$/
    static yyyyMMddTHHmmssZ = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)$/
    static yyyyMMddTHHmmssSSSXXX = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}([+-]?\d\d):(\d\d))$/
    static yyyyMMddTHHmmssXXX = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([+-]?\d\d):(\d\d))$/
    static yyyyMM = /^(\d{4})([\/-])(\d{1,2})$/;
    static yyyyMMddTHHmmss = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})$/
}

export class ModalDataContainer {
    headerMessage?: string = 'Confirm Delete';
    message?: string;
    customMessage?: string;
    confirmBtnLabel?: string = 'Yes';
    cancelBtnLabel?: string = 'No';
}
export class ConfirmDialogData {
    data?: ModalDataContainer;
    height? = 'auto';
    width?: string;
    panelClass?: string;
    disableClose? = true
}
export enum FixedRoleEnumConstants {

    App_Admin = "App_Admin",
    App_Committer = "App_Committer",
    App_Developer = "App_Developer",
    App_Ops_Manager = "App_Ops_Manager",
    Authenticated_User = "Authenticated_User",
    Super_Admin = "Super_Admin",
    Trial_Admin = "Trial_Admin"

}

export enum PbUrlConstants {
    Localhost = "localhost",
    Den_Mobi = "denmobi"
}

export enum PageApiMethodTypeConstants {
    delete = "DELETE"
}

export enum DateTimeFieldConstants {
    START_DATE_TIME = "startDateTime",
    END_DATE_TIME = "endDateTime"
}

export enum DataypeEnum{
    UNDEFINED = 'undefined'
}

export class WindowConstant {
    static DATA = 'name';
    static APP_CODE = 'appCode';
    static SITE_CODE = 'siteCode';
    static POPUP = 'popup';

    static setAppCode(appCode: string) {
        if (window[this.DATA]) {
            const codes = window[this.DATA] != WindowConstant.POPUP ? window[this.DATA].split('_') : undefined;
            window[this.DATA] = `${appCode}_${codes[1]}`;
        } else {
            window[this.DATA] = `${appCode}_`;
        }
    }

    static setSiteCode(siteCode: string) {
        if (window[this.DATA]) {
            const codes = window[this.DATA].split('_');
            window[this.DATA] = `${codes[0]}_${siteCode}`;
        } else {
            window[this.DATA] = `_${siteCode}`;
        }
    }


    /**
     * Sets the app code and site code in the window object.
     * @param appCode - The app code to set.
     * @param siteCode - The site code to set.
     */
    static setAppAndSiteCode(appCode: string, siteCode: string) {
        window[this.DATA] = `${appCode}_${siteCode}`;
    }

    static getAppAndSiteCode() {
        const codes = window[this.DATA].split('_');
        return {
            appCode: codes[0],
            siteCode: codes[1]
        }
    }

    static getAppCode() {
        const appCode = window[this.DATA]?.split('_')[0];
        if(appCode === DataypeEnum.UNDEFINED || !appCode || window[this.DATA] == WindowConstant.POPUP) {
            return undefined;
        }else{
            return appCode;
        }
    }

    static getSiteCode() {
        return window[this.DATA]?.split('_')[1] || undefined;
    }
}