import { Renderer2 } from "@angular/core";
import { Observable } from "rxjs";
import { ControlInstanceWrapper } from "../../controls/core/control-instance-drafts-models";

export const CreateElement = (container: HTMLElement, name: string): void => {
  // dynamically insert element
  const element: HTMLElement = document.createElement(name);
  container.appendChild(element);
};

export const RegisterScript = (name: string, src: string, controlInstance: ControlInstanceWrapper): Observable<boolean> => {

  return new Observable(observer => {
    let id = name + controlInstance.id;
    let scriptById = document.getElementById(id);
    // Refactore below removecChild
    if (scriptById) {
      document.body.removeChild(scriptById)
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.onload = () => {
      observer.next(true);
    }
    document.body.appendChild(script);
  
  });
};

