<div class="rt-mx-15 rt-mt-15">
  <h3 mat-dialog-title class="rt-mb-20">{{entity?.headerMessage || "Confirm Delete"}} </h3>

  <div mat-dialog-content>
    <div class="row rt-mb-10" [innerHTML]="entity?.message"></div>
    <div class="row rt-mb-5 rt-orange-color" *ngIf="entity?.customMessage">
      <span classs="rt-font-12" [innerHTML]="entity.customMessage"></span>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button class="btn cancel-btn rt-mr-10" [mat-dialog-close]="false" cdkFocusInitial>
      {{entity?.cancelBtnLabel || "No"}}
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">
      {{entity?.confirmBtnLabel || "Yes"}}
    </button>
  </div>
</div>