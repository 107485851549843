import { RtNone, RtOption, RtSome } from '../../../utils/option-helper';
import { DsResult } from '../../data-source/data-source-result-entities';
import { IChildControlInfo } from '../../ide/services/parent-control';
import { ControlInstanceWrapper } from './control-instance-drafts-models';
import { RenderedControlInstance } from './control-model';

//**************** Towards rendered controls to Parent ***************/

export class FirstChild {
  constructor(public canRaiseClickEvent: boolean) { }
}

export enum DataStateType {
  APPLIED = "APPLIED",
  NEW = "INSERT",
  UPDATED = "UPDATE",
  DELETED = "DELETE"
}

export enum ChildCommandType {
  ROOT = "root",
  STATIC = "static",
  NEW_WITH_DS_RESULT = "new_with_ds_result",
  UPDATED_WITH_DS_RESULT = "updated_with_ds_result",
  DELETED_DS_RESULT = "deleted_ds_result"
}

export interface ChildCommand {
  commandType: ChildCommandType
}

//e.g. A Divlist should send across 'DsProviderInfo' to its children,
// which should be carried forward till next DS is found
// export class DsProviderInfo {
//   constructor(public id: string, public dsResult: DsResult) { }

//   get dataStateType(): DataStateType {
//     return this.dsResult.dataStateType
//   }
// }

//to use for mixin rendering
//export type ControlInstanceInfo = { id: string, controlName: String }

export interface RepeaterControlInitiated {
  repeaterControlId: RtOption<string>
}

export interface ControlInstanceProvider {
  parentInstanceInfo: ControlInstanceWrapper
}


export interface AddChildBaseCommand extends ChildCommand {

  id(instanceId: string): string;
  repeaterControlId: RtOption<string>;
  props: Map<string, any>
  dsResult?: DsResult;
  parentInstanceInfo?: ControlInstanceWrapper;

  toControlInstance(controlRef: IChildControlInfo): RenderedControlInstance
}

export class AddRootControlCommand implements AddChildBaseCommand {
  id(instanceId: string) {
    return instanceId;
  }
  commandType: ChildCommandType = ChildCommandType.ROOT;
  repeaterControlId: RtOption<string> = RtNone();

  toControlInstance(controlRef: IChildControlInfo): RenderedControlInstance {
    return new RenderedControlInstance(RtNone(), controlRef);
  }

  constructor(public props: Map<string, any>) {
  }
}

export class AddStaticChildCommand implements AddChildBaseCommand, ControlInstanceProvider {
  commandType: ChildCommandType = ChildCommandType.STATIC;

  constructor(
    //public parentInstanceId: string
    public parentInstanceInfo: ControlInstanceWrapper,
    public repeaterControlId: RtOption<string>,
    public parentId: string,
    public props: Map<string, any>
  ) {
  }

  id(instanceId: string) {
    return `${this.parentInstanceInfo.id}_${instanceId}`;
  }

  toControlInstance(controlRef: IChildControlInfo): RenderedControlInstance {
    return new RenderedControlInstance(RtNone(), controlRef);
  }

}
//**************** Towards rendering ***************/
export class AddChildWithDsResultCommand implements AddChildBaseCommand, ControlInstanceProvider, RepeaterControlInitiated {

  id(instanceId: string) {
    return `${this.dsResult.id}_${instanceId}`;
  }

  commandType: ChildCommandType = ChildCommandType.NEW_WITH_DS_RESULT;

  constructor(
    public dsResult: DsResult,
    // public parentInstanceId: string,
    //  public dsProviderInfo: DsProviderInfo,
    public parentInstanceInfo: ControlInstanceWrapper,
    public firstChild: RtOption<FirstChild> = RtNone(),
    public repeaterControlId: RtOption<string>,
    public parentId: string,
    public props: Map<string, any>
  ) {
  }

  public markAsFirstChild(canRaiseClickEvent: boolean) {
    return new AddChildWithDsResultCommand(this.dsResult,
      this.parentInstanceInfo,
      RtSome(new FirstChild(canRaiseClickEvent)),
      this.repeaterControlId,
      this.parentId,
      this.props);
  }

  public unMarkAsFirstChild() {
    return new AddChildWithDsResultCommand(this.dsResult,
      this.parentInstanceInfo,
      RtNone(),
      this.repeaterControlId,
      this.parentId,
      this.props);
  }

  toControlInstance(control: IChildControlInfo): RenderedControlInstance {
    return new RenderedControlInstance(RtSome(this.dsResult.id), control);
  }
}

export class UpdateChildChildWithDsResultCommand implements ChildCommand {

  commandType: ChildCommandType = ChildCommandType.UPDATED_WITH_DS_RESULT;

  constructor(public dsResult: DsResult) {
  }
}

export class DeleteChildByDsResultIdCommand implements ChildCommand {

  commandType: ChildCommandType = ChildCommandType.DELETED_DS_RESULT;

  constructor(public dsResultId: string) {
  }
}

export class ChildCommandResolver {

  static resolveCmds<C extends ChildCommand>(cmds: ChildCommand[], cmdType: ChildCommandType): C[] {
    const filteredCmds = cmds.filter(c => c.commandType == cmdType)
    return filteredCmds as unknown as C[]
  }

  static as<C extends ChildCommand>(cmd: ChildCommand) {
    return cmd as unknown as C
  }

  static getDsResult(cmd: ChildCommand): RtOption<DsResult> {
    if (cmd.commandType == ChildCommandType.NEW_WITH_DS_RESULT) {
      const addCmd = ChildCommandResolver.as<AddChildWithDsResultCommand>(cmd)
      return RtSome(addCmd.dsResult)
    } else if (cmd.commandType == ChildCommandType.UPDATED_WITH_DS_RESULT) {
      const updatedCmd = ChildCommandResolver.as<UpdateChildChildWithDsResultCommand>(cmd)
      return RtSome(updatedCmd.dsResult)
    } else {
      return RtNone()
    }
  }

  static resolveParentInstance(cmd: ChildCommand): RtOption<ControlInstanceProvider> {
    if (cmd.commandType == ChildCommandType.NEW_WITH_DS_RESULT || cmd.commandType == ChildCommandType.STATIC) {
      const instanceProvider = cmd as unknown as ControlInstanceProvider;
      return RtSome(instanceProvider)
    } else {
      return RtNone()
    }
  }
}

export type ChildControlDeletedCommand = { childControlId: string };

// type ChildItemChanged = { dsResult: DsResult }

