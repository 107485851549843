import { format } from "date-fns";
import { FormFieldFileUpload } from "projects/den-core";
import { ExpressionFunction } from "../ExpressionRegistry";

export enum sizeConstant{
    KB= 'KB',
    MB = 'MB',
    KB_SIZE = 1024,
    MB_SIZE = 1024*1024
}
export class FileHelper {

    static readonly functionList : ExpressionFunction[] = [
        {
            functionName : "getModifiedDate(yyyy-MM-dd HH:mm)",
            toolTipMessage : "Returns the uploaded file's created time and we can customise the DateTime Format.",
            returnType : 'string'
        },
        {
            functionName : `getFileSize(${sizeConstant.MB})`,
            toolTipMessage : "Returns the uploaded file's size in KB and MB format.",
            returnType : 'string'
        },
    ];

    static getModifiedDate(dateFormat :string, options? : { params: { fileInfo :FormFieldFileUpload} , isUpdateMode: boolean } ) {
        if(options?.params?.fileInfo) {
            return format(new Date(options?.params?.fileInfo?.file.lastModified), dateFormat);
        }else{
            return format(new Date(), dateFormat);
        }
    }

    static getFileSize(sizeType :string, options? : { params: { fileInfo :FormFieldFileUpload} , isUpdateMode: boolean } ) {
        const sizeInBytes = options.params.fileInfo.file.size;
        let actualSize : number;
        switch(sizeType) {
            case sizeConstant.KB : actualSize = sizeInBytes/sizeConstant.KB_SIZE; break;
            case sizeConstant.MB : actualSize = sizeInBytes/sizeConstant.MB_SIZE; break;
        }
        return `${actualSize.toFixed(2)}${sizeType}`;
    }

    static getFunctionListWithDefaultParams() : ExpressionFunction[] {
        return FileHelper.functionList;
    }
}
