import { CommonModule } from "@angular/common";
import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ModalDataContainer } from '../../constants/constant';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'rt-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  entity: ModalDataContainer;;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalDataContainer, private sanitizer: DomSanitizer) {
    this.entity = this.data;
    if (this.entity?.message) {
      this.entity.message = (this.sanitizer.bypassSecurityTrustHtml(this.entity?.message) as any);
    }
    if (this.entity?.customMessage) {
      this.entity.customMessage = (this.sanitizer.bypassSecurityTrustHtml(this.entity?.customMessage) as any);
    }
  }

  ngOnInit(): void { }

}

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [
    ConfirmationDialogComponent, MatDialogModule
  ]
})
export class ConfirmationDialogModule { }