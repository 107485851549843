import { ThemeColorType, ThemePalleteKeys } from "../../../constants/app-constants";
import { PersonalisationAndLocalizationService } from "../../../providers/personalisation-and-localization/personalisation-and-localization-service";
import { ColorContainer } from "../color-v2/color-attribute";

export class ColorHelper {

    static resolveColor(container: ColorContainer) {
        if (container.customColor.isDefined) {
            return container.customColor.get;
        } else if (container.themeColor.isDefined) {
            const themeColor = container.themeColor.map(tc => PersonalisationAndLocalizationService.getColor(tc.colorType as ThemeColorType, tc.paletteKey as ThemePalleteKeys));
            if (themeColor.isDefined) {
                return themeColor.get;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    static setCorrectColorCodes(colorCodes: string[], colorCoesArray: string[]): any {
        const baseColor = colorCodes.find(code => code != null && code != undefined)
        // some fields may not have color code provided by user in that case we'll use user provided colors in properties panel
        let index = 0;
        colorCodes = colorCodes.map((code) => code = code ? code : colorCoesArray[index++]);
        // if user has not provided colors in property panel, we'll generate shades of an existing color and use it
        colorCodes = colorCodes.map((code, index) => {
            if (!code) {
                if (baseColor.includes('rgb')) code = this.shadeOfRGBColor(baseColor, (index + 1) * 5);
                else code = this.convertHexToRGBAndReturnShadeColor(baseColor, (index + 1) * 5);
            }
            return code;
        });
        return colorCodes;
    }

    static convertHexToRGBAndReturnShadeColor(hex: string, percent: number) {
        let g;
        let r;
        let b;
        ({ g, r, b, hex } = ColorHelper.convertHexToRGB(hex));
        g = Math.min(255, Math.max(0, Math.round(g * (1 - percent / 100))));
        return 'rgb(' + r + "," + g + "," + b + ")";
    }

    static convertHexToRGB(hex: string) {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return { g, r, b, hex };
    }

    static shadeOfRGBColor(rgb: string, percent: number) {
        const r = rgb.match(/\(([^)]+)\)/)[1].split(',')[0];
        let g = parseInt(rgb.match(/\(([^)]+)\)/)[1].split(',')[1]);
        const b = rgb.match(/\(([^)]+)\)/)[1].split(',')[2];
        g = Math.min(255, Math.max(0, Math.round(g * (1 + percent / 100))));
        return 'rgb(' + r + "," + g + "," + b + ")";
    }
}
