import { Injectable } from "@angular/core";
import { EventService } from "../../../../../../providers/event-service/event.service";
import { Observable, lastValueFrom } from "rxjs";
import { AppEventTopics } from "../../app-event-topics";
import { PageVariableData } from "../../event-store";
import { AppEvent, ApplyActionCommand, PageEventActionStrategy } from "../../event-models";
import { RtNone, RtSome } from "../../../../../../../../src/lib/utils/option-helper";
import { BindingType, PageVariables, PageVariablesContainer } from "./global-variables-action-models";
import { DataStateType, DsResult, DsResultValue, PageService } from "projects/den-core/page-builder";

@Injectable()
export class PageVariableActionStrategy implements PageEventActionStrategy<PageVariables> {

  constructor(private eventService: EventService, private pageService: PageService) {

  }
  applyActions(cmds: ApplyActionCommand<PageVariables>[], event: AppEvent): Promise<void> {
    const cmd$ = new Observable<void>(() => {
      cmds.map((cmd) => {
        this.publishActionData(cmd, event);
      })
    })
    return lastValueFrom(cmd$);
  }

  private publishActionData(cmd: ApplyActionCommand<PageVariables>, event: AppEvent) {
    cmd.action.containers.forEach((binding: PageVariablesContainer) => {
      this.publishEventData(binding, cmd);
    })
  }

  private publishEventData(binding: PageVariablesContainer, cmd: ApplyActionCommand<PageVariables>) {
    const topic = AppEventTopics.buildPageVariableTopic(this.pageService.pageName, binding.variableName);
        let value;
    if (binding.dataBindingType == BindingType.DATA_BINDING) {
      value = cmd.data.data.find(ds => ds.fieldName == binding.bindingProperty)?.value;
    } else {

      const resolvedExpression = this.replaceExpressionInSideValueWithData(cmd.data, binding.expression)
      try {
        value = resolvedExpression ? eval(resolvedExpression) : resolvedExpression;
      } catch (error) {
           console.warn(error);
           value = resolvedExpression;
      }
    }
    const dsResultValue = new DsResultValue(binding.variableName, value, value, RtNone(), RtNone());
    const result = new DsResult(null, "", [dsResultValue], [], false, true, DataStateType.NEW);
    const data = RtSome(result);
    const eventData = new PageVariableData({ variableName: binding.variableName, value: data });
    this.eventService.publish(topic, eventData);
  }


  private replaceExpressionInSideValueWithData(dsResult: DsResult, valueString: string) {
    const regex = /\$\{(.*?)\}/g;
    const regexMatches = [...valueString?.matchAll(regex)];
    regexMatches.forEach((regexMatchArray: RegExpMatchArray) => {
      const dsPropertyName = regexMatchArray[1].trim();
      const propertyValue = dsResult?.data?.find(dsResVal => dsResVal.fieldName === dsPropertyName)?.value;
      valueString = valueString?.replace(regexMatchArray[0], typeof propertyValue == 'string' ? `'${propertyValue.toString()}'` : propertyValue);
    });
    return valueString;
  }
}
