<div class="rt-mt-10 site-app-selector-dialog-wrapper">
    <h3 class="rt-px-20">Select the Site & App</h3>

    <div mat-dialog-content>
        <div class="row rt-mx-10 site-app-selector-container">
            <div class="col-md-5 rt-pl-5 rt-pr-5">
                <div class="row col-md-12  rt-px-0 rt-py-5  rt-mt-10 rt-mb-5 search-wrapper">
                    <mat-icon class="rt-flex-items-auto rt-cursor-default rt-font-22 rt-dimension-22 rt-mr-5 rt-search-icon">
                      search
                    </mat-icon>
                    <input class="search-label rt-no-border rt-flex-items" matInput placeholder="Search Sites" autocomplete="off" [(ngModel)]="searchInput"
                      #nameSearchInput  (input)="applyFilterForSites()">
                    <mat-icon class="rt-flex-items-auto rt-pull-right rt-search-icon"
                      (click)="searchInput=''; nameSearchInput.value=''; applyFilterForSites()"> close</mat-icon>
                  </div>

                <ul *ngIf="sites.length" class="list-wrapper rt-mt-10" style="height: 425px;overflow: scroll;">
                    <li class="list rt-border rt-cursor-pointer"
                        *ngFor="let site of sites ; let i = index" (click)="onSelectingSite(site)">
                        <div class="rt-clear list-item">
                            <div class="row rt-items-center rt-justify-between">
                                <div class="rt-p-5">
                                    <label>{{ site?.name || "NA" | titlecase }}</label>
                                </div>
                                 <mat-icon class="rt-green-color rt-height-20" *ngIf="site?.siteCode === selectedSite?.siteCode">check_circle</mat-icon>
                            </div>
                            <span *ngIf="site.isDefault" class="default-label">Default</span>
                        </div>
                    </li>
                </ul>
                <div class="rt-red-color" *ngIf="sites.length === 0">No Sites Mapped to this User. Please contact
                    Administrator!
                </div>
            </div>
            <div class="col-md-7 rt-pl-0 rt-pr-5">
                <div class="row col-md-12 rt-py-5 rt-px-0 rt-mt-10 rt-mb-5 search-wrapper">
                    <mat-icon class="rt-flex-items-auto rt-cursor-default rt-font-22 rt-dimension-22 rt-mr-5 rt-search-icon">
                      search
                    </mat-icon>
                    <input class="search-label rt-no-border rt-flex-items" matInput placeholder="Search Apps" autocomplete="off" [(ngModel)]="appSearchInput" #appSearchInputRef (input)="applyAppFilterForApps()">
                    <mat-icon class="rt-flex-items-auto rt-pull-right rt-search-icon" (click)="appSearchInput=''; appSearchInputRef.value=''; applyAppFilterForApps()">close</mat-icon>
                  </div>
                <ul *ngIf="apps?.length" class="list-wrapper rt-mt-10">
                    <li class="list rt-border rt-cursor-pointer  "
                        *ngFor="let app of apps ; let j = index" (click)="onSelectingApp(app)">
                        <div class="rt-clear list-item">
                            <div class="row rt-items-center rt-p-5  rt-justify-between">
                                <img class="rt-dimension-30 rt-flex-items-auto"
                                    [src]=" app?.clientLogo || './assets/img/no_image.png'">
                                <div class="rt-pl-10 rt-flex-items rt-text-ellipsis">
                                    <label>{{ app?.name || 'NA' | titlecase}}</label>
                                </div>
                                <mat-icon class="rt-green-color rt-height-20" *ngIf="app?.appCode === selectedApp?.appCode">check_circle</mat-icon>
                            </div>
                            <span *ngIf="app.isDefault" class="default-label">Default</span>
                        </div>
                    </li>
                </ul>
                <div class="rt-red-color" *ngIf="apps.length === 0">No Apps Mapped to this User. Please contact
                    Administrator!
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="row col-md-12">

            <div class="col-md-4 rt-text-left">
                <mat-checkbox [disabled] ="disableButton"  [(ngModel)]="isDefault">Set as Default</mat-checkbox>
            </div>
            <div class="col-md-8 rt-buttons rt-text-right">
                <button mat-raised-button class="rt-mr-5" [mat-dialog-close]="false">Cancel</button>

                <button mat-raised-button color="primary" (click)="saveAndProceed()" [disabled]="disableButton">SWITCH</button>

            </div>
        </div>
    </div>
</div>