import { ExpressionFunction } from "../ExpressionRegistry";

export class LocationHelper {

    
    static readonly functionList : ExpressionFunction[] = [
        {
            functionName : "getLocation()",
            toolTipMessage : "Returns the current Latitude and Longitude position, i.e. _LAT_12.837490_LNG_18.4320617. Make sure location permission is enabled to use this feature. ",
            returnType : 'string'
        }
    ];
    static getLocation(options? : {coords :any}) {
        return options?.coords?.latitude ? `_LAT_${options?.coords?.latitude}_LNG_${options?.coords?.longitude}` : '';
    }

    static getFunctionListWithDefaultParams() : ExpressionFunction[] {
        return LocationHelper.functionList;
    }
}
