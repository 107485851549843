import { AttributeInstance, AttributeRegistryConstant, ColorAttributeV3 } from "projects/den-core/page-builder";
import { PaddingMarginControlAttribute } from "../../../../attributes/paddingOrMargin/padding-margin-control-attribute";
import { RtSome } from "projects/den-core/src/lib/utils/option-helper";
import { TypographyAttributes } from "../../../../attributes/typography/typography-attributes";
import { DimensionNoAutoHeightAttribute } from "../../../../attributes/dimensionsWithoutAutoHeight/dimension-without-autoheight-attributes";

export class FormFieldCustomAttributeRegistry {
    static attributeModels: Map<string, any> = new Map();

    static setAttributeModel() {
        this.attributeModels.set(AttributeRegistryConstant.DIMENSIONS, DimensionNoAutoHeightAttribute);
        this.attributeModels.set(AttributeRegistryConstant.PADDING, PaddingMarginControlAttribute);
        this.attributeModels.set(AttributeRegistryConstant.COLOR, ColorAttributeV3);
        this.attributeModels.set(AttributeRegistryConstant.BACKGROUND_COLOR, ColorAttributeV3);
        this.attributeModels.set(AttributeRegistryConstant.TYPOGRAPHY, TypographyAttributes);
    }

    static getAttributeModel(modelName: string) {
        this.handleAttributeRegistration();
        return this.attributeModels.get(modelName);
    }

    static getUsableBuildValues(attributeInstance: AttributeInstance) {
        this.handleAttributeRegistration();
        const attributeClass = this.attributeModels.get(attributeInstance.name);
        let usableAttributeValue: AttributeInstance[] = [];
        if (attributeInstance.name == AttributeRegistryConstant.COLOR || attributeInstance.name == AttributeRegistryConstant.BACKGROUND_COLOR) {
            const AttributeModel: ColorAttributeV3 = new attributeClass(attributeInstance.name);
            const parsedModel = AttributeModel.parseModel(attributeInstance.value);
            usableAttributeValue = AttributeModel.buildUsableValue(RtSome(parsedModel)) as any;
        } else {
            const attributeClass = this.attributeModels.get(attributeInstance.name);
            const AttributeModel = new attributeClass().parseModel(attributeInstance.value);
            usableAttributeValue = AttributeModel.buildUsableValues();
        }
        return usableAttributeValue;
    }

    private static handleAttributeRegistration() {
        if (this.attributeModels.size == 0) {
            this.setAttributeModel();
        }
    }
}