import { AttributeRegistryConstant } from "../../constants/attribute-registry-constants";
import { AttributeGroup } from "../../constants/attribute-group-constant";
import { Attribute, AttributeType, MobiAttribute } from "../core/attribute";
import { UsableAttributeValue } from "../core/attribute-instance";
import { IClassInstanceHelper } from "../core/class-instance-helper";
import { RtNone, RtOption, RtSome } from "../../../utils/option-helper";

export class PaddingMarginContainer implements IClassInstanceHelper {
  constructor(public left: number, public right: number, public top: number, public bottom: number) { }
  generateTSCode(): string {
    return `new PaddingMarginContainer(${this.left}, ${this.right}, ${this.top}, ${this.bottom})`;
  }

  buildUsableValues(prefix: string): UsableAttributeValue<string>[] {
    return [
      new UsableAttributeValue(`${prefix}-left`, `${this.left}px`, AttributeType.CSS),
      new UsableAttributeValue(`${prefix}-top`, `${this.top}px`, AttributeType.CSS),
      new UsableAttributeValue(`${prefix}-right`, `${this.right}px`, AttributeType.CSS),
      new UsableAttributeValue(`${prefix}-bottom`, `${this.bottom}px`, AttributeType.CSS),
    ];
  }
}

export class PaddingMarginControlModel implements IClassInstanceHelper {
  constructor(public padding: PaddingMarginContainer, public margin: PaddingMarginContainer) { }
  generateTSCode(): string {
    const paddingValues = this.padding.generateTSCode()
    const marginValues = this.margin.generateTSCode()
    return `new PaddingMarginControlModel(${paddingValues}, ${marginValues})`;
  }

  static parse(jsonString: string): PaddingMarginControlModel {
    const configValue: PaddingMarginControlModel = JSON.parse(jsonString);
    const marginUnParsed = configValue.margin;
    const paddingUnParsed = configValue.padding;
    const marginContainer = new PaddingMarginContainer(marginUnParsed.left, marginUnParsed.right, marginUnParsed.top, marginUnParsed.bottom);
    const paddingContainer = new PaddingMarginContainer(paddingUnParsed.left, paddingUnParsed.right, paddingUnParsed.top, paddingUnParsed.bottom);
    return new PaddingMarginControlModel(paddingContainer, marginContainer);
  }

  buildUsableValues() {
    return [
      ...this.padding.buildUsableValues('padding'),
      ...this.margin.buildUsableValues('margin'),
    ];
  }
  buildMobileUsableValues() {
    return [
      ...this.padding.buildUsableValues('padding'),
      ...this.margin.buildUsableValues('margin'),
    ];
  }
}

export class PaddingMarginControlAttribute extends Attribute<PaddingMarginControlModel, string> implements MobiAttribute<PaddingMarginControlModel, string>{

  generateTSCode(): string {
    return `
      const paddingMarginModel = ${this.model.generateTSCode()};
      new PaddingMarginControlAttribute(RtSome(paddingMarginModel));
      `;
  }

  constructor(private defaultPaddingAndMargin: RtOption<PaddingMarginControlModel> = RtNone()) {
    super(AttributeRegistryConstant.PADDING, AttributeGroup.APPERANCE, 'string', AttributeType.CSS, RtSome(() => this.getDefaultPaddingMargin()));
  }

  buildMobiUsableValue(resolvedValue: RtOption<PaddingMarginControlModel>): UsableAttributeValue<string>[] {
    if (resolvedValue.isDefined) {
      const container = resolvedValue.get;
      return container.buildMobileUsableValues();
    } else {
      return [];
    }
  }

  clone(): Attribute<unknown, unknown> {
    return new PaddingMarginControlAttribute(this.defaultPaddingAndMargin);
  }

  parseModel(jsonValue: string): PaddingMarginControlModel {
    return PaddingMarginControlModel.parse(jsonValue);
  }

  getDefaultPaddingMargin() {
    if (this.defaultPaddingAndMargin.isDefined) {
      return this.defaultPaddingAndMargin.get;
    }
    const marginContainer = new PaddingMarginContainer(0, 0, 0, 0);
    const paddingContainer = new PaddingMarginContainer(0, 0, 0, 0);
    return new PaddingMarginControlModel(paddingContainer, marginContainer);
  }

  buildUsableValue(resolvedValueOpt: RtOption<PaddingMarginControlModel>): UsableAttributeValue<string>[] {
    if (resolvedValueOpt.isDefined) {
      const container = resolvedValueOpt.get;
      return container.buildUsableValues();
    } else {
      return [];
    }
  }

}
