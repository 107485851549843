import { Injectable, Injector } from '@angular/core';
import { RtOption } from '../../../../utils/option-helper';
import { AddChildBaseCommand } from '../../../controls/core/child-rendered-event';
import { ControlInstanceWrapper } from '../../../controls/core/control-instance-drafts-models';
import { SimpleAngularControlInfo } from '../../../ide/services/parent-control';
import { AngularControlRenderService } from '../angular-control-render-service';


@Injectable()
export class AngularDefaultControlRenderService extends AngularControlRenderService<SimpleAngularControlInfo> {
  constructor(injector: Injector) {
    super(injector);
  }
  renderAndAppendChildControl(_childInstance: ControlInstanceWrapper, _parentNode: Node, _parentControlName: string, _currentNode: Node, _cmd: AddChildBaseCommand,
    _parentInstanceId: string, _parentSectionIndex: RtOption<number>): boolean {
    return false;
  }
  destroyControls() {
    throw new Error('Method not implemented.');
  }
}
