import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'rt-grid-overlay',
  templateUrl: './grid-overlay.component.html',
  styleUrls: ['./grid-overlay.component.scss'],
})

export class GridOverlayComponent { }

@NgModule({
  declarations: [GridOverlayComponent],
  exports: [GridOverlayComponent],
})

export class GridOverlayModule { }