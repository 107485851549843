import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GridOverlayComponent } from '../../components-library/grid-overlay/grid-overlay.component';
import { LoaderServiceProvider } from '../abstract/loader-service-provider';


@Injectable()
export class LoaderService extends LoaderServiceProvider {

  constructor(private overlay: Overlay, rendererFactory: RendererFactory2) {
    super();
    // https://stackoverflow.com/questions/44989666/service-no-provider-for-renderer2
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // TODO Members should be private and should be accessible through getter & setters

  public loaderSubject = new Subject();
  public loaderSubjectCounter = new BehaviorSubject(0);
  public loadCounter: number = 0;
  public hasOverlayCreated: boolean = false;
  private overlayRef: OverlayRef | undefined;
  private overlayElement: ElementRef | undefined;
  private renderer: Renderer2;
  showLoader() {
    this.loaderSubject.next(true);
  }
  hideLoader() {
    this.loaderSubject.next(false);
  }


  showOverlay(element: ElementRef) {
    if (!element) {
      throw new Error('Element is invalid for GRID loader');
    }
    // overlay element in table-component
    this.overlayElement = element;

    // position of the overlay in application level
    const position: ConnectedPosition[] = [{ originX: 'center', originY: 'center', overlayX: 'center', overlayY: 'center' }];
    const positionStrategy = this.overlay.position().flexibleConnectedTo(element).withPositions(position);

    // configuration to be set when overlay displayed
    const config: OverlayConfig = { hasBackdrop: false, scrollStrategy: this.overlay.scrollStrategies.block(), positionStrategy, disposeOnNavigation: true };
    const overlayConfig = new OverlayConfig(config);

    // Returns an OverlayRef which is a PortalHost
    this.overlayRef = this.overlay.create(overlayConfig);

    // Create ComponentPortal that can be attached to a PortalHost
    const filePreviewPortal = new ComponentPortal(GridOverlayComponent);

    // Attach ComponentPortal to PortalHost
    this.overlayRef.attach(filePreviewPortal);

    // apply backdrop class on the overlayed element
    this.renderer.addClass(this.overlayElement.nativeElement, 'backdrop-panel');
  }
  // update the overlay position on flex (right side panel)
  updateOverlayPosition(element: ElementRef) {
    if (this.hasOverlayCreated) {
      this.overlayElement = element;
      const position: ConnectedPosition[] = [{ originX: 'center', originY: 'center', overlayX: 'center', overlayY: 'center' }];
      const positionStrategy = this.overlay.position().flexibleConnectedTo(this.overlayElement).withPositions(position);
      this.overlayRef.updatePositionStrategy(positionStrategy);
      this.overlayRef.updatePosition();
    }
  }
  // dispose the overlay and remove the overlay class
  closeOverlay(): void {
    if (this.overlayRef && this.overlayElement && this.overlayElement.nativeElement) {
      this.hasOverlayCreated = false;
      this.overlayRef.detach();
      this.renderer.removeClass(this.overlayElement.nativeElement, 'backdrop-panel');
    }
  }
}
