import { Inject, Injectable, OnInit } from '@angular/core';
import { Observable, PartialObserver } from 'rxjs';
import { LocalStorageProvider } from '../../providers/abstract/local-storage-provider';
import { SessionStorageProvider } from '../../providers/abstract/session-storage-provider';
import { RtSome } from '../../utils/option-helper';
import { LogService } from '../../log-service/log.service';

@Injectable()
export class OfflineIndexDBManagerService extends LocalStorageProvider implements OnInit {
  deleteByName(_dbName: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  private databaseName!: string;
  private tableName!: string;
  private database: any;
  private indexedDB: any = window.indexedDB;
  private version: any = 1;
  private dbOpenRequest: any;
  entityCacheMap: Map<string, any> = new Map();
  constructor(@Inject(SessionStorageProvider) private sessionStorageService: SessionStorageProvider, private logService: LogService) {
    super();
  }

  ngOnInit(): void {
  }

  init(databaseName: string, tableName: string): Observable<string> {
    if (!databaseName) {
      return new Observable(observer => {
        this.indexedDB.databases().then((indexDbs: any[]) => {
          const indexedDb = indexDbs.find(db => db.name === this.sessionStorageService.getDbKey());
          if (indexedDb) {
            databaseName = indexedDb.name;
            this.initDatabase(databaseName, tableName, observer);
          } else {
            observer.error("indexedDb name is not provided");
            this.logService.error("indexedDb name is not provided",null);
          }
        }, (error: ErrorEvent) => {
          observer.error(error);
          this.logService.error(error.error.message, error.error.message);
        });
      });
    } else {
      return new Observable(observer => {
        this.initDatabase(databaseName, tableName, observer);
      });
    }
  }
  initDatabase(databaseName: string, tableName: string, observer: PartialObserver<any>) {
    // this.deleteOldDbInstances(databaseName, observer);
    this.databaseName = databaseName;
    this.tableName = tableName || 'OfflineCache';
    const request = this.indexedDB.open(databaseName, this.version);
    request.onupgradeneeded = (event: any) => {
      this.tryCreateObjectStore(event, this.tableName);
    };
    request.onsuccess = (event: any) => {
      this.tryCreateObjectStore(event, this.tableName);
      this.database = event.target.result;
      this.dbOpenRequest = event.results;
      observer.next('Database initialized');
    };

    request.onerror = (error) => {
      observer.error('Database initialization failed');
      this.logService.error('Database initialization failed', error)
    };
  }
  private deleteOldDbInstances(databaseName: string, observer: PartialObserver<any>) {
    this.indexedDB.databases().then((indexDbs: any[]) => {
      indexDbs.forEach(db => {
        if (db.name !== databaseName && db.name.startsWith('Offline-rt-db-')) {
          window.indexedDB.deleteDatabase(db.name);
        }
      });

    }, (error: ErrorEvent) => {
      observer.error(error);
    });
  }

  private tryCreateObjectStore(event: any, tableName: string) {
    const db = event.target.result;
    if (db.objectStoreNames.contains(tableName) === false) {
      db.createObjectStore(tableName);
    }
  }
  private getValuesFromStore(database: any, tableName: string, observer: PartialObserver<any>) {
    //https://googlechrome.github.io/samples/idb-getall/
    const transaction = database.transaction([tableName], 'readwrite');
    const objectStore = transaction.objectStore(tableName);
    if ('getAll' in objectStore) {
      // IDBObjectStore.getAll() will return the full set of items in our store.
      objectStore.getAll().onsuccess = (event: any) => {
        observer.next(event.target.result);
      };
    } else {
      // Fallback to the traditional cursor approach if getAll isn't supported.
      const data: any[] = [];
      objectStore.openCursor().onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          data.push(cursor.value);
          cursor.continue();
        } else {
          observer.next(data);
        }
      };
    }
  }
  // getAll() {
  getAll<T>(): Observable<T[]> {
    return new Observable<T[]>(observer => {
      if (!this.database) {
        this.init(this.databaseName, this.tableName).subscribe(() => {
          this.getValuesFromStore(this.database, this.tableName, observer);
        });
      } else {
        this.getValuesFromStore(this.database, this.tableName, observer);
      }
    });
  }

  // getAllDataWithKeys() 
  getAllDataWithKeys<T>(): Observable<T[]> {
    return new Observable<T[]>(observer => {
      if (!this.database) {
        this.init(this.databaseName, this.tableName).subscribe(() => {
          this.getKeyValuesFromStore(this.database, this.tableName, observer);
        });
      } else {
        this.getKeyValuesFromStore(this.database, this.tableName, observer);
      }
    });
  }

  private getKeyValuesFromStore(database: any, tableName: string, observer: PartialObserver<any>) {
    const transaction = database.transaction([tableName], 'readwrite');
    const objectStore = transaction.objectStore(tableName);
    const data: any[] = [];
    let request = objectStore.openCursor();
    request.onerror =  (event)=> {
      this.logService.error("error fetching data",event)
    };
    request.onsuccess = function (event) {
      let cursor = event.target.result;
      if (cursor) {
        let key = cursor.primaryKey;
        let value = cursor.value;
        data.push({ key, value });
        cursor.continue();
      }
      else {
        observer.next(data);
      }
    };
  }
  private getFromInMemoryOrIndexedDb(database: any, tableName: string, key: string, observer: PartialObserver<any>) {
    //Check in memory for the entity by key, if not found check in indexeddb and update cache with the same
    const entityInMemory = this.entityCacheMap.get(key);
    if (entityInMemory) {
      observer.next(entityInMemory);
    } else {
      const transaction = database.transaction([tableName], 'readwrite');
      const objectStore = transaction.objectStore(tableName);
      const request = objectStore.get(key);
      request.onsuccess = (event: any) => {
        //Update cache with latest entity data
        if (event?.target?.result) {
          this.entityCacheMap.set(key, event.target.result);
          observer.next(event.target.result);
        } else {
          observer.next(undefined);
        }
      };
      request.onerror = (error: any) => {
        observer.error(error);
      };
    }
  }
  get<T>(key: string, tableName: string): Observable<T> {
    return new Observable<T>(observer => {
      const storeName = RtSome(tableName).isDefined ? tableName : this.tableName;
      if (!this.database) {
        this.init(this.databaseName, storeName).subscribe(() => {
          this.getFromInMemoryOrIndexedDb(this.database, storeName, key, observer);
        }, (error) => {
          observer.error(error);
          this.logService.error("Error while set data to IndexedDb", error);
        });
      } else {
        this.getFromInMemoryOrIndexedDb(this.database, storeName, key, observer);
      }
    });
  }

  set<T>(key: string, value: T, tableName: string): Observable<string> {
    return new Observable(observer => {
      if (this.database) {
        const storeName = RtSome(tableName).isDefined ? tableName : this.tableName;
        const transaction = this.database.transaction([storeName], 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.put(value, key);
        //Set latest entity data to entity cache map
        this.entityCacheMap.set(key, value);
        request.onsuccess = () => {
          observer.next('Successfully set data to IndexedDb');
        };
        request.onerror = (error) => {
          observer.error('Error while set data to IndexedDb');
          this.logService.error("Error while set data to IndexedDb", error);
        };
      } else {
        console.warn('database not found to set ' + ' in indexdb');
        this.logService.warn('database not found to set ' + ' in indexdb', null)
      }
    });
  }

  remove(key: string, tableName?: string): Observable<string> {
    return new Observable(observer => {
      const storeName = RtSome(tableName).isDefined ? tableName : this.tableName;
      const transaction = this.database.transaction([storeName], 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.delete(key);
      //Delete entity list from cache by key
      this.entityCacheMap.delete(key);
      request.onsuccess = () => {
        observer.next('Successfully deleted from IndexedDb');
      };
      request.onerror = (error) => {
        observer.error('Error while deleting data from IndexedDb');
        this.logService.error("Error while deleting data from IndexedDb", error);
      };
    });
  }

  clear(): Observable<string> {
    return new Observable(observer => {
      const transaction = this.database.transaction([this.tableName], 'readwrite');
      const objectStore = transaction.objectStore(this.tableName);
      const request = objectStore.clear();
      //Clear entire entity cache map
      this.entityCacheMap.clear();
      request.onsuccess = () => {
        observer.next('Successfully cleared data from table :' + this.tableName + ' in IndexedDb');
      };
      request.onerror = (error) => {
        observer.error('Error while clear data from table :' + this.tableName + ' in IndexedDb');
        this.logService.error('Error while clear data from table :' + this.tableName + ' in IndexedDb', error as string);
      };
    });
  }

  delete(): Observable<string> {
    return new Observable(observer => {
      const request = window.indexedDB.deleteDatabase(this.databaseName);
      request.onsuccess = (_event) => {
        //Clear entire entity cache map
        this.entityCacheMap.clear();
        observer.next('Successfully deleted database ' + this.databaseName);
      };
      request.onerror = (error) => {
        observer.error('Error deleting database ' + this.databaseName + ' from IndexedDb');
        this.logService.error('Error deleting database ' + this.databaseName + ' from IndexedDb', error as unknown as string);
      };
    });
  }

  close(): void {
    if (this.dbOpenRequest) {
      this.dbOpenRequest.close();
    }
  }

}
