
import { Type } from '@angular/core';
import { RtNone, RtOption, RtSome } from '../../../utils/option-helper';
import { FormControlType } from '../../constants/common-constants';
import { AttributeInstance, UsableAttributeValue } from './attribute-instance';
import { IClassInstanceHelper } from './class-instance-helper';
import { AttributeMenu } from 'projects/den-web/src/app/page-builder/models/model';

export enum AttributeType {
  CSS = "CSS",
  CONFIGURATION = "CONFIGURATION",
  LOCAL_ACTION = "LOCAL_ACTIONS", //e.g. make sound, lit light, vibrate
  // DATASOURCE = "DATASOURCE",
  PROPERTY_DEFINITION = "PROPERTY_DEFINITION" //e.g. make sound, lit light, vibrate

}

export enum AttributeThemeType {
  THEMEABLE_ATTRIBUTE = 'themable_attribute',
  NON_THEMEABLE_ATTRIBUTE = 'non_themable_attribute',
}


export abstract class UsableValueResolver {
  abstract buildUsableValue<M, U>(attribute: Attribute<M, U>, resolvedValue: RtOption<M>): UsableAttributeValue<U>[];
}

export interface MobiAttribute<M, U> {
  buildMobiUsableValue(resolvedValue: RtOption<M>): UsableAttributeValue<U>[];
}

export abstract class Attribute<M, U> implements IClassInstanceHelper {

  protected _model: RtOption<M> = RtNone();

  //TODO: Move to chnage tracker
  //private _changedModel: RtOption<M>;
  public attributeThemeType() {
    return AttributeThemeType.NON_THEMEABLE_ATTRIBUTE;
  }

  constructor(public name: string,
    public group: string = null,
    public type: keyof FormControlType,
    public attributeType: AttributeType,
    public defaultValueOpt: RtOption<() => M>,
    public canShowOnRightPanel: boolean = true) {
    // public attributeType: AttributeType, public value: RtOption<() => M>, public canShowOnRightPanel: boolean = true) {
  }

  abstract generateTSCode(): string;
  abstract clone(): Attribute<unknown, unknown>;

  get model(): M {
    const modelOpt = this.modelOption;
    if (modelOpt.isDefined) {
      return modelOpt.get;
    } else {
      throw new Error(`Model is not defined for ${this.name} .`);
    }
  }

  get isChanged(): boolean {
    return this._model.isDefined;
  }

  get modelOption(): RtOption<M> {
    // if(this._changedModel.isDefined) {
    //   return this._changedModel.get
    // }
    if (this._model.isDefined) {
      return this._model;
    } else {
      return this.defaultValueOpt.map(fn => fn());
    }
  }

  initModelUsingJson(jsonString: string) {
    const model = this.parseModel(jsonString);
    this.applyModel(model);
  }

  toAttributeInstance() {
    return new AttributeInstance(this.name, JSON.stringify(this.model), this.attributeType);
  }



  // abstract applyModel(value: M): void;

  abstract parseModel(jsonValue: string): M;

  //1) capture whenever values change
  //1) apply value from companion mixins & attribites. e.g. grid definition
  applyModel(value: M) {
    // this.overridenValue = RtSome(value)
    this._model = RtSome(value);
    //TODO: MOve to change tracker
    //this._changedModel = RtSome(value);
  }

  //persist the attribute only if it is changed by user input.
  // if the persit failed, then call captureChanges() and put back the changed value
  //TODO: MOve to Change tracker
  // extractChangedValue(): RtOption<M> {
  //   if (this._changedModel.isDefined) {
  //     this.overriddenValue = this._changedModel;
  //     this._changedModel = RtNone();
  //     return this._changedModel;
  //   } else {
  //     return RtNone();
  //   }
  // }

  //use this while saving layouts/'quick styles'
  //TODO: Move to change tracker
  // getOverridenValue(): RtOption<M> {
  //   if (this._changedModel.isDefined) {
  //     //consider changed value as priority. In otherwords,
  //     // if user attempts to create a layout/quickstyle before persisting the actual control attributes
  //     return this._changedModel;
  //   } else {
  //     return this.overriddenValue;
  //   }
  // }

  // get toUsableInstance() {
  //   const usableValue = this.getUsableValue();
  //   return new AttributeInstance(this.name, usableValue, this.attributeType);
  // }

  //todo:
  // 1) call from control during instance creation, or reload.
  // 2) provide if the overriden value is available or use default.
  // applyValueOrUseDefault(value: RtOption<M>) {
  //   if (value.isDefined) {
  //     this.overriddenValue = value;
  //     this.applyValue(value.get);
  //   } else {
  //     this.applyValue(this.defaultValue());
  //   }
  // }

  getUsableValues(): UsableAttributeValue<U>[] {
    if (this._model.isDefined)
      return this.buildUsableValue(this._model);
    else
      return this.buildUsableValue(this.defaultValueOpt.map(fn => fn()));
  }

  // Should always have only one e.g. grid configurations.
  // Where as in case of some other css attributes there might be more than one.
  getOneUsableValue(): UsableAttributeValue<U> {
    const usableValues = this.getUsableValues();
    if (usableValues.length === 0) {
      throw new Error("No usable value found");
    }
    if (usableValues.length > 1) {
      throw new Error("More than one usable value found");
    }
    return usableValues[0];
  }



  //e.g. css values
  abstract buildUsableValue(resolvedValue: RtOption<M>): UsableAttributeValue<U>[];



  //abstract getUsableValue(resolvedValue: RtOption<M>): U;
}

export abstract class AbstractCssAttribute<M, U> extends Attribute<M, U> {

  constructor(public name: string,
    public group: string = null,
    public type: keyof FormControlType = 'string',
    public defaultValueOpt: RtOption<() => M>,
    public canShowOnRightPanel: boolean = true) {
    super(name, group, type, AttributeType.CSS, defaultValueOpt, canShowOnRightPanel);
  }

  // withValue(defaultValue?: any) {
  //   new CssAttribute(this.name, this.group, this.type, defaultValue);
  // }

}

export class SimpleCssAttribute<U> extends AbstractCssAttribute<U, U> implements IClassInstanceHelper, MobiAttribute<U, U> {


  parseModel(jsonValue: any): U {
    try {
      return JSON.parse(jsonValue)
    } catch {
      return jsonValue;
    }
  }

  applyModel(value: U) {
    this._model = RtSome(value);
    return this;
  }

  buildUsableValue(resolvedValue: RtOption<U>): UsableAttributeValue<U>[] {
    return resolvedValue.toArray.map(v => {
      return new UsableAttributeValue(this.name, v, this.attributeType);
    });
  }

  buildMobiUsableValue(resolvedValue: RtOption<U>): UsableAttributeValue<U>[] {
    return resolvedValue.toArray.map(v => {
      return new UsableAttributeValue(this.name, v, this.attributeType);
    });
  }

  constructor(public name: string,
    public group: string = AttributeType.CSS,
    public type: keyof FormControlType = 'string',
    private simpleDefaultValue: any = undefined,
    public canShowOnRightPanel: boolean = true) {

    super(name, group, type, RtSome(() => simpleDefaultValue), canShowOnRightPanel);
  }

  clone(): Attribute<unknown, unknown> {
    return new SimpleCssAttribute(this.name, this.group, this.type, this.simpleDefaultValue, this.canShowOnRightPanel);
  }
  generateTSCode(): string {
    return `new SimpleCssAttribute(${this.name}, ${this.group}, ${this.type}, ${this.model}, ${this.canShowOnRightPanel} )`;
  }

  withValue(defaultValue?: any) {
    new SimpleCssAttribute(this.name, this.group, this.type, defaultValue);
  }

}

export class SimpleConfigurationAttribute<U> extends Attribute<U, U> implements MobiAttribute<U, U> {

  generateTSCode(): string {
    return `new SimpleConfigurationAttribute(${this.name}, ${this.group}, ${this.type}, ${this.model}, ${this.canShowOnRightPanel} )`;
  }

  parseModel(jsonValue: any): U {
    // Extend attribute for most of the attributes (e.g. border, margin, etc).
    // Remove and refactor all configuration to use the same
    // from both during runtime (rendering) and during right-side panel angular-component population
    try {
      return JSON.parse(jsonValue)
    } catch {
      return jsonValue;
    }
  }

  applyModel(value: U) {
    this._model = RtSome(value);
    return this;
  }

  buildUsableValue(resolvedValue: RtOption<U>): UsableAttributeValue<U>[] {
    return resolvedValue.toArray.map(v => {
      return new UsableAttributeValue(this.name, v, this.attributeType);
    });
  }

  buildMobiUsableValue(resolvedValue: RtOption<U>): UsableAttributeValue<U>[] {
    return resolvedValue.toArray.map(v => {
      return new UsableAttributeValue(this.name, v, this.attributeType);
    });
  }

  constructor(public name: string,
    public group: string = AttributeType.CONFIGURATION,
    public type: keyof FormControlType = 'string',
    private defaultConfigValue: any = undefined,
    public canShowOnRightPanel: boolean = true) {
    super(name, group, type, AttributeType.CONFIGURATION, RtSome(() => defaultConfigValue), canShowOnRightPanel);
  }

  clone(): Attribute<unknown, unknown> {
    return new SimpleConfigurationAttribute(this.name, this.group, this.type, this.defaultConfigValue, this.canShowOnRightPanel);
  }
}

export class CssProperty {
  constructor(public propertyName: string,
    public type: keyof FormControlType = 'string',
    public prefix?: string[],
    public sufix?: string[]) { }
}

export class CssAttributeRegistry<T> {
  constructor(public name: string, public type: Type<T>) {
  }
}

export class CssAttributeRegistryA<T> {
  constructor(public elementType: Type<T>, public cssProperties: CssProperty[]) {
  }
}

export abstract class AttributePallete<M, U, A extends Attribute<M, U>> {

  private _attribute: RtOption<A>;

  get attribute(): A {
    if (this._attribute.isDefined)
      return this._attribute.get;
    else
      throw new Error("Attribute is not initialized.");
  }

  set attribute(attribute: A) {
    this._attribute = RtSome(attribute);
  }

  // controlInstance: ControlInstanceWrapper;
  controlInstanceId: string;
  name: string;
  type: keyof FormControlType;
  // defaultValues: any;
  attributeType: string;
  menus: Array<AttributeMenu>;

  onPropertyChange: ($event: AttributeInstance) => void;
  canShowOnRightPanel: boolean;

  constructor() { }

  setInputs(name: string,
    // controlInstance: ControlInstanceWrapper,
    controlInstanceId: string,
    type: keyof FormControlType,
    attributeType: string,
    //defaultValues: any,
    canShowOnRightPanel: boolean,
    persistedJsonValue: RtOption<string>,
    menus: Array<AttributeMenu> = [AttributeMenu.SAVE_AS_BEHAVIOUR]
  ) {

    this.name = name;
    //  this.controlInstance = controlInstance;
    this.controlInstanceId = controlInstanceId;
    this.type = type;
    this.attributeType = attributeType;
    // this.defaultValues = defaultValues;
    this.canShowOnRightPanel = canShowOnRightPanel;
    this.menus = menus;
    //TODO: use attribute name, resolve respective attribute Json value from control InstanceWrapper and pass as 'persistedJsonValue'
    persistedJsonValue.map(JsonValue => this.initModelUsingJson(JsonValue));
  }

  //step-1
  initModelUsingJson(jsonModel: string) {
    this.attribute.initModelUsingJson(jsonModel);
  }

  //NOTE: do not have a copy of the model on individual 'pallete angular components', instead bind the model
  get model() {
    return this.attribute.model;
  }

  get modelOption() {
    return this.attribute.modelOption;
  }

  //usage example: Add row to GridDefinition
  //const gridDefinition:GridDefinition = this.model
  //const modefiedDefinition = gridDefinition.addRow(new Row())
  //applyModel(modifiedDefinition)
  applyModel(model: M) {
    this.attribute.applyModel(model);
  }


  // abstract applyStyle(defaultValue: string): void;
}
