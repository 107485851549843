import { ComponentRef, Inject, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { ApplicationService } from '../../../../src/lib/providers/application/application.service';
import { LoaderService } from '../../../../src/lib/providers/loader/loader.service';
import { Observable, Subject } from 'rxjs';
import { DynamicLoaderComponent } from './dynamic-loader/dynamic-loader.component';

@Injectable()
export class DCService {

  @ViewChild(DynamicLoaderComponent, { read: ViewContainerRef, static: true }) flexRef;
  private pageTitlePublisher: Subject<string> = new Subject();
  rootViewContainer: ViewContainerRef;

  constructor( private appService: ApplicationService, private loaderService: LoaderService) {
    this.appService.containerSubject.subscribe((ref: ViewContainerRef) => {
      this.rootViewContainer = ref;
    });
  }

  public getPageTitleAsObservable(): Observable<string> {
    return this.pageTitlePublisher.asObservable();
  }

  load<T, U>(component: any, title: string, inputs?: T, outputs?: U) {
    if (!component || !title) {
      throw 'invalid input for addDynamicComponent()';
    }
    this.pageTitlePublisher.next(title);
    this.loaderService.hasOverlayCreated = true;
    this.appService.addFlexContent();
    this.rootViewContainer.clear(); // clears existing views
    const componentRef = this.rootViewContainer.createComponent(component); // initializes component for factory generated
    if (inputs) {
      const keys = Object.keys(inputs);
      const values = (<any>Object).values(inputs);
      this.inititalizeProperties(keys, values, componentRef);
    }
    if (outputs) {
      this.handleOutputEvents<U>(outputs, componentRef);
    }
  }

  private handleOutputEvents<U>(outputs: U, componentRef: ComponentRef<any>) {
    const keys = Object.keys(outputs);
    keys.forEach(key => {
      componentRef.instance[key].subscribe((data) => {
        outputs[key](data);
      });
    });
  }

  private inititalizeProperties(keys: Array<string>, values: Array<any>, ref: ComponentRef<any>) {
    if (this.isInputExists(keys, values)) {
      for (let index = 0; index < keys.length; index++) {
        ref.instance[keys[index]] = values[index]; // inititalizes input or output properties here
      }
    }
  }

  private isInputExists(keys: string[], values: any[]) {
    return (keys && keys.length > 0) && (values && values.length > 0);
  }

}
