export class ControlTypeConstant {

  public static readonly COMMON = 'Basic Elements';
  public static readonly ADVANCE = 'Advanced Elements';
  public static readonly LEAFLET = 'Leaflet';
  public static readonly TWO_D = '2D';
  public static readonly THREEJS = 'ThreeJS';
  public static readonly CONTAINER = 'Container';
  public static readonly LAYOUT = 'Page Layout';
  public static readonly NAVIGATION = 'Navigation';
  public static readonly CHART_MULTIPLE = 'Charts(Multiple)';
  public static readonly CHART_SINGLE = 'Charts(Single)';
  public static readonly STATIC_ASSETS = 'Static Asset';
  public static readonly FORM = 'Form';
  public static readonly THREEJS_LAYERS = 'ThreeJs Layer';
  public static readonly THREE_JS_GEOMETRY = 'ThreeJs Geometry';
  public static readonly THREE_D_GEOMETRY = '3D Geometry';
  public static readonly SECTIONS = "Sections";
  public static readonly LEAFLET_LAYERS = "Leaflet Layer";
  public static readonly TWO_D_ELEMENTS = "2D Elements";
  public static readonly THREE_D_ELEMENTS = "3D Elements";
  public static readonly REPORT = "Report";
}

export class ControlTypeDisplayNameConstant {
  public static readonly CHILD_PAGE = 'Child Page';
  public static readonly COLLAPSIBLE_CONTAINER = 'Collapsible Container';
  public static readonly UNPAGINATED_LIST = 'Unpaginated List';
  public static readonly PAGINATED_LIST = 'Paginated List';
  public static readonly GROUPED_LIST = 'Grouped List';
  public static readonly UNPAGINATED_SLIDER = 'Unpaginated Slider';
  public static readonly PAGINATED_SLIDER = 'Paginated Slider';
  public static readonly PROGRESS_BAR = 'Progress Bar';
  public static readonly TOGGLE_ICON = 'Toggling Icon';
  public static readonly FORM_ACTION_BUTTON = 'Form Action Button';
  public static readonly FORM_ACTION_SAVE_ALL_BUTTON = 'Form Action Save All Button';
  public static readonly DROP_DOWN_LIST = 'Drop-down List';
  public static readonly BULLETED_LIST = 'Bulleted List';
  public static readonly TEXT_BOX = 'Text Box';
  public static readonly SITE_OR_APP_SELECTOR = 'Site Or App Selector';
  public static readonly STEPPER = 'Stepper';
  public static readonly PROGRESS_STEPPER = 'Progress Stepper'
  public static readonly RADIO_BUTTON = 'Radio Button';
  public static readonly YEAR_MONTH_PICKER = 'Year and Month picker';
  public static readonly CHECK_BOX = 'Check Box';
  public static readonly CHECK_BOX_LIST = 'CheckBox List'

  public static readonly UNIQUE_CODE_GENERATOR = 'Unique Code Generator';
  public static readonly PYRAMID_LIST = 'Pyramid List';
  public static readonly GRID_LAYOUT = 'Grid Layout';
  public static readonly MULTI_SELECT = 'Multi select';
  public static readonly DATE_RANGE = 'Date Range';
  public static readonly DOWNLOAD_FILE = 'Download File';
  public static readonly TREE_VIEW = 'Tree View';
  public static readonly TIME_LINE_LIST = 'Time Line List';
  public static readonly TEXT_AREA = 'Text Area';
  public static readonly USER_PROFILE = 'User Profile';
  public static readonly USER_AND_ROLES = 'User and Roles';
  public static readonly API_INCIDENT_MONITOR = 'Api Incident Monitor';

  public static readonly STATIC_TAB = 'Static Tab';
  public static readonly STATIC_ACCORDION = 'Accordion';
  public static readonly LOG_DETAILS = 'log Details';
  public static readonly HEALTH_CHECK = 'Health Check';
  public static readonly USER_PROFILE_PAGE = 'User Profile Page';
  public static readonly SIGNATURE_PAD = 'Signature Pad'

}

export class MobileControlTypeDisplayNameConstant {
  public static readonly CHILD_PAGE = 'Child Page';
  public static readonly CARD = 'Card';
  public static readonly CONTAINER = 'Container';
  public static readonly FORM_WRAPPER = 'Form wrapper';
  public static readonly GRID = 'Grid';
  public static readonly FORM_ACTION_SAVE_ALL_BUTTON = 'Form Action Save All Button';
  public static readonly LABEL = 'Label';
  public static readonly BUTTON = 'Button';
  public static readonly CHECK_BOX = 'Checkbox';
  public static readonly CHECK_BOX_LIST = 'Checkbox List';
  public static readonly INPUT = 'Input';
  public static readonly TOGGLE = 'Toggle';
  public static readonly DATE_TIME_PICKER = 'Date Time Picker';
  public static readonly ICONS = 'Icons';
  public static readonly IMAGE = 'Image';
  public static readonly SELECT = 'Select';
  public static readonly MULTI_SELECT = 'Multi select';
  public static readonly SEARCHABLE_SELECT = 'Searchable Select';
  public static readonly SPINNER = 'Spinner';
  public static readonly PROGRESS_BAR = 'Progress Bar';
  public static readonly BADGE = 'Badge';
  public static readonly RANGE = 'Range';
  public static readonly MENU = 'Menu';
  public static readonly STANDARD_MENU = 'Standard Menu';
  public static readonly DATE_RANGE_PICKER = 'Date Range Picker';
  public static readonly QR_CODE_GENERATOR = 'QR Code Generator';
  public static readonly QR_CODE_SCANNER = 'QR Code Scanner';
  public static readonly BLUETOOTH_PRINTER = 'Bluetooth Printer';
  public static readonly MOBI_USER_PROFILE_PAGE = 'User Profile Page'


}


export class ReportControlTypeDisplayNameConstant {
  public static readonly LAYOUT_CONTAINER = 'Layout Container';
  public static readonly CONTAINER = 'Container';
  public static readonly LABEL = 'Label';
  public static readonly PAGE_HEADER = 'Page Header';
  public static readonly PAGE_FOOTER = 'Page Footer';
  public static readonly UNPAGINATED_LIST = 'Unpaginated List';
  public static readonly GROUPED_LIST = 'Grouped List';
  public static readonly ICONS = 'Icons';
  public static readonly DATE = 'Date';

  public static readonly DATA_GRID = 'Data Grid';
  public static readonly HEADING = 'Heading';
  public static readonly IMAGE = 'Image';
  public static readonly BUTTON = 'Button';
  public static readonly DROP_DOWN_LIST = 'Drop-down List';
  public static readonly MULTI_SELECT = 'Multi select';
  public static readonly YEAR_MONTH_PICKER = 'Year and Month picker';
  public static readonly DATE_RANGE = 'Date Range Picker';
  public static readonly EXPORT_PDF = 'Export PDF';

  public static readonly BAR_CHARTS = 'Horizontal/Vertical Bar';
  public static readonly LINE_AREA_CHARTS = 'Line/Area';
  public static readonly PIE_CHART_LIST = 'Pie';
  public static readonly BAR_LINE_CHART = 'Bar Line';
  public static readonly GROUPED_BAR_CHARTS = 'Grouped Bar';
  public static readonly GROUPED_LIST_BAR_CHARTS = 'Grouped List Bar';
  public static readonly STACKED_TRIANGLE_CHART = 'Stacked Triangle';
  public static readonly STACKED_VERTICAL_BAR_LINE_CHART = 'Stacked Vertical Line';
  public static readonly GAUGE_CHART = 'Gauge'
}