import { CategoryEnum, LogData, LogService } from '../../../../../../../src/lib/log-service/log.service';
import { EventService } from '../../../../../providers/event-service/event.service';
import { RtNone, RtOption, RtSome } from '../../../../../utils/option-helper';
import { DataSourceJoinStrategy } from '../../../../constants/common-constants';
import { BoundParam } from '../../../../data-source/data-source';
import { DsResult, DsResultArray, DsResultWithDsName } from '../../../../data-source/data-source-result-entities';
import { DSBindTypeEnum, PropertyDefinitionEnum } from '../../../../models/enums';
import { PageService } from '../../../../services/page-service';
import { AddChildBaseCommand, AddChildWithDsResultCommand, ChildCommand } from '../../child-rendered-event';
import { ControlInstanceWrapper } from '../../control-instance-drafts-models';
import { IControl } from '../../control-model';
import { DataSourceServiceInstanceWrapper } from '../../data-source-draft-models';
import { FilteringManager } from '../../list-view-control/filtering-manager/filtering-manager';
import { FilterConfig, ParamWithBufferValue } from '../../list-view-control/shared/models/list-view.model';
import { AbstractDSStateManager } from '../abstract-ds-state-manager';
import { DataJoinHelper } from './utils/data-join-helper';

export class DsResultArrayStateManager extends AbstractDSStateManager<DsResultArray> {

  dsBindType: DSBindTypeEnum = DSBindTypeEnum.MULTIPLE;

  private joinStrategy: DataSourceJoinStrategy;

  private configuredDataSources: DataSourceServiceInstanceWrapper[] = [];

  //pk to match and join with other datasources
  masterDataSource: RtOption<DataSourceServiceInstanceWrapper> = RtNone();

  //uncombined
  private allDataSources: Map<string, DsResultArray> = new Map();

  constructor(public eventService: EventService, public pageService: PageService,
    public filterManager: FilteringManager, public logService: LogService) {
    super(eventService, pageService, filterManager, logService);

  }

  private init(controlInstance: ControlInstanceWrapper) {
    this.configuredDataSources = this.pageService.getControlInstanceDataSourceServiceInstances(controlInstance.instanceId);
    const masterDS = this.configuredDataSources.find(ds => ds.isMaster);
    this.masterDataSource = masterDS ? RtSome(masterDS) : RtNone();
    this.joinStrategy = controlInstance.joinStrategy;
    this.validateJoinKeys(controlInstance.instanceId);
  }

  private validateJoinKeys(instanceId: string) {
    const hasMultipleDataSources = this.configuredDataSources.length > 1;

    if (hasMultipleDataSources) {

      const hasNoMaster = this.masterDataSource.isEmpty;
      if (hasNoMaster) {
        this.logService.warn("No master data source is configured", null, CategoryEnum.PageBuilder, { pageName: this.pageService.pageName, instanceId: instanceId })
        throw Error("No master data source is configured");
      }

      const hasNoJoinKeys = this.configuredDataSources.every(dataSource => dataSource.joinKey == null || dataSource.joinKey == "");
      if (hasNoJoinKeys) {
        this.logService.warn("Join keys are missing", null, CategoryEnum.PageBuilder, { pageName: this.pageService.pageName, instanceId: instanceId })
        throw new Error("Join keys are missing");
      }
    }
  }

  applyData(parentCmd: AddChildBaseCommand, newData: DsResultArray, control: IControl<DsResultArray>, filterConfig: RtOption<FilterConfig>): void {
    this.parentCmd = parentCmd;
    this.init(control.controlInstance);

    //step 1 : Apply New Results
    this.applyNewResults(newData, control)

    //If data is received for all data sources then only apply joins, filtering and setting data state manager data
    if (this.isTheDataReceivedForAllDataSources()) {
      //step 2 : join/rejoin
      let dataAfterApplyingJoin: DsResultArray;
      if (this.masterDataSource.isDefined && this.masterDataSource.get.joinKey) {
        dataAfterApplyingJoin = this.join(this.masterDataSource.get);
      } else {
        const data = this.allDataSources.get(newData.dsName);
        const propertyDefinition = control.controlInstance?.propertyDefinitions?.find(definition => definition.controlAttributeName == PropertyDefinitionEnum.nestedProperty);
        if (propertyDefinition?.dsPropertyName) {
          data.dsName = propertyDefinition?.dsPropertyName as string;
        }
        if (!(data instanceof DsResultArray)) {
          this.logService.error(`Single Data is not supported for List Cobtrol`, null, CategoryEnum.PageBuilder, new LogData(control.controlInstance.instanceId, this.pageService.pageName, newData.dsName));
        }
        dataAfterApplyingJoin = data.asFullyQualifiedDsResultArray();
      }
      // TODO add console warn for delete fail
      this.showConsoleWarnIfDeleteRecordNotFound(newData, control);
      //step 3: apply filtering
      this.data = this.applyFilters(dataAfterApplyingJoin, filterConfig);

      //step 4: Build commands for all rest data and WS if Operation Type is add
      const dataTobeApplied = this.data.toUnApplied();
      const commands = this.buildChildCommands(dataTobeApplied, control);

      // Enqueue commands
      this.enQueueAllCmds(commands);
      // DELETE the records which are marked for delete from local data and also from all data sources only if it is master or has single data source
      this.removeMarkedAsDeletedRecordsAndUpdateTotalResults();
      //Mark all records as applied
      this.data = this.data.markAllAsApplied();
      this.markAllDataSourcesAsApplied();

      //step 5: publish to child controls if the received data is from WS
      //this.publishToChildrenIfWSMessage(newData);
    }else if(this.isMasterDataIsEmpty(newData)){
      this.data = newData;
    }

  }
  isMasterDataIsEmpty(newData: DsResultArray) {
    return this.masterDataSource.isDefined && this.masterDataSource.get.dsName === newData.dsName && newData.results.length === 0;
  }

  private showConsoleWarnIfDeleteRecordNotFound(newData: DsResultArray, control: IControl<DsResultArray>) {
    if (newData.isWsResult && this.data) {
      newData.results.forEach(res => {
        const resultFound = this.data.results.find(result => result.id == res.id);
        if (!resultFound && res.isDeleted()) {
          console.warn(`${res.id} did not find in available records for delete. DsName -> ${newData.dsName}, InstanceId -> ${control.controlInstance.instanceId}`);
        }
      });
    }
  }

  private removeMarkedAsDeletedRecordsAndUpdateTotalResults() {
    const resultsExcludingDeleted = this.data.results.filter(res => !res.isDeleted());
    const deletedResults = this.data.results.filter(res => res.isDeleted())
    // const removedRecordsCount = this.data.results.length - resultsExcludingDeleted.length;
    // this.data.totalResults = this.data.totalResults - removedRecordsCount;
    this.data.results = resultsExcludingDeleted;
    this.configuredDataSources.forEach(ds => {
      if (ds.isMaster || this.configuredDataSources?.length === 1) {
        const datasource = this.allDataSources.get(ds.dsName);
        
        datasource.results = datasource.results.filter(result => !deletedResults.some(res => res.id === result.id));
        this.allDataSources.set(ds.dsName, datasource);
      }
    });
  }

  rebuildCommands(control: IControl<DsResultArray>): AddChildWithDsResultCommand[] {
    if (this.data) {
      const results = this.data.results.map(result => result.markAsNew());
      //Child commands should be built only for the dsResults which are not deleted and not updated
      let commands = results
        .map(dsResult => {
          return this.buildAddNewCommand(dsResult, control);
        });
      return commands;
    } else {
      return [];
    }
  }
  resetDataForAllDataSources() {
    this.configuredDataSources.forEach(ds => {
      this.allDataSources.delete(ds.dsName)
    })
  }

  private buildChildCommands(resOpt: DsResultArray, control: IControl<DsResultArray>): ChildCommand[] {
    const results = resOpt.results;
    //Child commands should be built only for the dsResults which are not deleted and not updated
    let commands: ChildCommand[] = results
      .map(dsResult => {
        return this.buildCommand(dsResult, control);
      });
    return commands;
  }

  private isTheDataReceivedForAllDataSources(): boolean {
    return this.configuredDataSources.every(ds => this.allDataSources.has(ds.dsName));
  }

  private applyNewResults(newData: DsResultArray, control: IControl<DsResultArray>) {
    if (this.isTheDataReceivedForAllDataSources()) {
      this.applyWhenAllDataSourcesDataIsReceived(newData, control);
    } else {
      this.applyWhenAllDataSourcesDataIsNotReceived(newData, control);
    }
  }

  private applyWhenAllDataSourcesDataIsNotReceived(newData: DsResultArray, control: IControl<DsResultArray> & { pageSize?: number }) {
    const existing = this.allDataSources.get(newData.dsName);
    if (existing) {
      const updatedResults = this.getUpdatedDsResults(existing, newData, control);
      this.allDataSources.set(newData.dsName, updatedResults);
    }
    else if (newData.dsName) {
        this.allDataSources.set(newData.dsName, newData);
    }
  }

  private applyWhenAllDataSourcesDataIsReceived(newData: DsResultArray, control: IControl<DsResultArray> ) {
    // if (this.masterDataSource.isDefined) {
    if (this.isTheDataDoesNotBelongToMaster(this.masterDataSource, newData)) {
      //Always change the status to updated if the newly arrived data is not master.
      newData = newData.markAsUpdated();
    }
    const existing = this.allDataSources.get(newData.dsName);
    const updatedResults = this.getUpdatedDsResults(existing, newData, control);
    this.allDataSources.set(newData.dsName, updatedResults);
  }

  private getUpdatedDsResults(existing: DsResultArray, newData: DsResultArray, control: IControl<DsResultArray> & { pageSize?: number }) {
    if(existing) {
      const updatedResults = existing.applyDsResults(newData, control.pageSize);
      if(updatedResults.deletedDsResults) {
        const cmds = updatedResults.deletedDsResults.map(result => this.buildCommand(result, control));
        this.enQueueAllCmds(cmds);
      }
      return updatedResults.updatedDs;
    } else {
      return newData;
    }
  }

  private isTheDataDoesNotBelongToMaster(masterDs: RtOption<DataSourceServiceInstanceWrapper>, newData: DsResultArray) {
    return (masterDs.isDefined && masterDs.get.dsName != newData.dsName);
  }


  private markAllDataSourcesAsApplied() {
    this.allDataSources.forEach((value: DsResultArray, key: string) => {
      this.allDataSources.set(key, value.markAllAsApplied());
    })
  }

  private join(masterDs: DataSourceServiceInstanceWrapper): DsResultArray {
    const masterData = this.allDataSources.get(masterDs.dsName);
    const mergedData = this.mergeData(masterDs, masterData);
    if (this.joinStrategy) {
      this.applyJoins();
    }

    //todo: nag resolve total results & fks
    const totalResults = masterData.totalResults;
    const combinedFks = []
    return new DsResultArray("combined", mergedData, totalResults, combinedFks);
  }


  private mergeData(master: DataSourceServiceInstanceWrapper, masterData: DsResultArray) {
    const masterKey = master.joinKey;
    const otherDatasets = DataJoinHelper.nonMasterDatasets(master.dsName, this.allDataSources);
    const joinedDSResults = masterData.results
      .filter(result => result.getValueByKey(masterKey) !== undefined)  //When master field name is deleted from Schema, remove the record from the result; 
      .map((masterItem: DsResult) => {

        const masterKeyValue = masterItem.getValueByKey(masterKey);
        const dsFKResults: DsResultWithDsName[] = otherDatasets.map((otherData: DsResultArray) => {

          const otherDataSourceServiceInstance = this.configuredDataSources.find(ds => ds.dsName === otherData.dsName);

          //Populate with schema field names by using DataSourceServiceInstance
          const schemaFieldNames = otherDataSourceServiceInstance.method.returningResult.map(param => param.name);
          if(!otherDataSourceServiceInstance.joinKey){
            console.warn(`Join key is not selected for Datasource Instance : ${otherDataSourceServiceInstance.dsName}`);
          }
          const dsResult = otherData.getValueByMasterJoinKey(masterKeyValue.value, otherDataSourceServiceInstance.joinKey, schemaFieldNames);
          return new DsResultWithDsName(otherData.dsName, dsResult);
        });
        const fullyQualifiedMasterItem = masterItem.asFullyQualifiedDsResult(master.dsName);
        return fullyQualifiedMasterItem.applyDsResults(dsFKResults);
      });
    return joinedDSResults;
  }

  private applyJoins() {

  }

  private applyFilters(data: DsResultArray, filterConfig: RtOption<FilterConfig>): DsResultArray {
    let dsResultArray = data;
    if (filterConfig.isDefined) {
      const filterData = filterConfig.get;
      const paramBuferWithValue: ParamWithBufferValue[] = [];
      if (filterData.paramBuffer?.size) {
        filterData.paramBuffer.forEach((buffeArray, key) => {
          buffeArray.forEach(buffer => {
            const param: BoundParam = {
              name: `${key}.${buffer.param.name}`, type: buffer.param.type, isOptional: buffer.param.isOptional,
              bindingType: buffer.param.bindingType, isArray: buffer.param.isArray, isRequired: buffer.param.isRequired,
              canAccumalate: buffer.param.canAccumalate, isAttributeProperty: buffer.param.isAttributeProperty, operator: buffer.param.operator
            };
            paramBuferWithValue.push({
              param: param, value: buffer.getValue()
            })
          })
        })

    
        dsResultArray = this.filterManager.applyDataSourceFilter(paramBuferWithValue, dsResultArray);
      }
      if (filterData.filterProperties && typeof filterData.filterProperties === 'object' && Object.keys(filterData.filterProperties).length) {
        dsResultArray = this.filterManager.applyControlFilter(filterData.filterProperties, dsResultArray);
      }
    }
    return dsResultArray;
  }




  onDestroy() {

  }
}


