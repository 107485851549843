import { AllMeasuresUnits } from 'convert-units/definitions/all';
import { FilterOperator, FilterProperty, PageProperties, SortingProperty } from '../../models/base.models';
import { RtNone, RtOption, RtSome } from '../../utils/option-helper';
import { ControlCondition } from '../controls/core/condition-builder/models/control-conditions';
import { DataSourceServiceInstanceWrapper } from '../controls/core/data-source-draft-models';
import { EventActionGroupUniqueKey } from '../controls/core/event/event-types';
import { DsEnum } from '../models/enums';

export class DatasourceTypes {
  public static readonly STRING: string = 'string';
  public static readonly NUMBER: string = 'number';
  public static readonly ARRAY: string = 'array';
}

export class Method {

  /**
   *Creates an instance of Method.
   * @param {string} methodName
   * @param {Map<String, String>} params
   * @param {number} supportedType
   * @param {number} methodType
   * @param {Map<String, String>} returningResult
   * @memberof Method
   */
  constructor(public methodName: string,
    public params: Map<String, String>,
    public supportedType: string,
    public methodType: string,
    public returningResult: Map<String, String>,
    public returningSchemaName: RtOption<string> = RtNone()
  ) {

  }
}

export enum BindingTypeEnum {
  GLOBAL = 1,
  PARENT = 2,
}

export enum BoundParamDefaultValueType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  STRING_ARRAY = 'array (string)',
  NUMBER_ARRAY = 'array (number)',
  DATE = 'date',
  DATE_RANGE = 'date range',
}

export type BoundParam = {

  name: string,
  type: string,
  boundPropertyName?: string,
  defaultValue?: string | number | boolean,
  defaultValueType?: BoundParamDefaultValueType,
  isOptional: boolean,
  bindingType: BindingTypeEnum,
  isArray: boolean,
  isRequired: boolean,
  eventType?: string,
  ctrlInstanceId?: string,
  canAccumalate: boolean,
  isAttributeProperty: boolean,
  operator: FilterOperator,
  isListParam?: boolean,
  isJoinKeyParam?: boolean,
  dsFieldName?: string,
  uniqueEventId?: EventActionGroupUniqueKey
}

export class ParameterTopicMapping {
  public bindingType: number;
  public topicName: string;
  public eventState: boolean;
  public defaultValue: string | number | boolean;
  public optional: boolean;
  public ctrlInstanceName: string;
  public ctrlInstanceId: string;
  public param: MethodParams;
  public eventType: string;

}

export class ReceivedParamBuffer {

  private value: any | any[];

  constructor(public param: BoundParam) {

  }

  isOptional() {
    return this.param.isOptional;
  }

  isFulfilled() {
    if (this.isOptional()) {
      return true;
    } else {
      const value = this.getValue();
      return (value != null && value != "") ? true : false;
    }
  }

  getValue() {
    if (this.value != null) {
      return this.value;
    } else if (RtSome(this.param.defaultValue).isDefined && this.param.defaultValue != '') {
      return this.param.defaultValue;
    } else {
      return null;
    }
  }

  applyValue(datasource: RtOption<any>, selectedStatus: RtOption<boolean> = RtNone()) {
    // if (!datasource.isDefined) { return; }
    //TODO : Nagaraju : REVIEW selectedStatus param usage , remove if its not required
    if (selectedStatus.isDefined && selectedStatus.get === false) {
      this.removeValue(datasource.get);
    } else {
      this.setValue(datasource.isDefined ? datasource.get : null, selectedStatus);
    }
  }

  private setValue(value: any, selectedStatus: RtOption<boolean>) {
    if (this.param.isArray) {
      if (selectedStatus.isDefined && selectedStatus.get === true) {
        this.processArrayValue(value);
      } else {
        if (Array.isArray(value)) {
          this.value = value;
        } else {
          this.value = value ? [value] : undefined;
        }
      }
    } else {
      this.value = value;
    }

  }
  private processArrayValue(value: any) {
    if (Array.isArray(this.value) && Array.isArray(value)) {
      this.value = [...this.value, ...value];
    } else if (Array.isArray(this.value) && !Array.isArray(value)) {
      this.value.push(value);
    } else if (Array.isArray(value)) {
      this.value = value;
    } else {
      this.value = value ? [value] : undefined;
    }
  }

  private removeValue(value: any) {
    if (this.param.isArray) {
      if (Array.isArray(this.value) && Array.isArray(value)) {
        this.value = this.value.filter(v => !value.includes(v));
      } else if (Array.isArray(this.value) && !Array.isArray(value)) {
        this.value = this.value.filter(v => v !== value);
      } else {
        console.error('No data available to remove');
      }
    } else {
      this.value = null;
    }
  }
}


export class ReceivedConditionEventBuffer {

  private value: any | any[];

  constructor(public controlCondition: ControlCondition) {
  }

  isFulfilled() {
    return (this.getValue() != null) ? true : false;
  }

  getValue() {
    if (this.value != null) {
      return this.value;
    } else {
      return null;
    }
  }

  applyValue(datasource: RtOption<any>, selectedStatus: RtOption<boolean> = RtNone()) {
    if (!datasource.isDefined) { return; }
    if (selectedStatus.isDefined && selectedStatus.get === false) {
      this.removeValue();
    } else {
      this.setValue(datasource.get);
    }
  }

  private setValue(value: any) {
    this.value = value;
  }

  private removeValue() {
    this.value = null;
  }
}



//TODO: Remove MethodInstance once the new DS setup is completed
export class DataSourceMethodInstance {

  /**
   *Creates an instance of Method.
   * @param {string} methodName
   * @param {Map<String,String>} params
   * @param {number} supportedTypeId
   * @param {Map<String,String>} returningResult returnValueName and its type
   * @memberof Method
   */
  constructor(public methodName: string,
    public params: BoundParam[],
    public methodType: string,
    public returningResult: ReturnMethodParams[],
    public groupField1?: string,
    public groupField2?: string,
    public aggregateField?: string,
    public aggregateFunction?: string,
    public isStartDateIncluded?: boolean,
    public isEndDateIncluded?: boolean,
    public dateField?: string,
    public canAggregateTime: boolean = false,
    public canAggregateSize: boolean = false,
    public sortOptions?: SortingProperty,
    public defaultPaginationSettings?: PageProperties,
    public returningSchemaName?: string,
    public wsLogTopic?: string,
    public isOnRequestMview?: boolean
  ) {
  }
  clone() {
    const newDsMethodIstance = JSON.parse(JSON.stringify(this));
    newDsMethodIstance.sortOptions = this.sortOptions ? new SortingProperty(this.sortOptions.sortingFields, this.sortOptions.sortingOperator) : null;
    newDsMethodIstance.clone = this.clone;
    return newDsMethodIstance;
  }
}

export interface IKpiResponse {
  snapshotId: string;
  payLoad: { kpiResponse: string; };
  transactionDate: string;
}





export class Datasource {
  constructor(public type: string, public group: string, public name: string, public methods: Method[], public wsTopicResult: { name: string, template: string; }[]) {
    // constructor(public name: string, public type: String, innerType: String = null) {
  }
}

export class DataSourceServiceInstanceWithFilter {
  constructor(
    public datasourceInstance: DataSourceServiceInstanceWrapper,
    public pagination?: PageProperties,
    public additionalFilters?: FilterProperty[]
  ) { }


  isKpi() {
    return this.datasourceInstance.type && this.datasourceInstance.type.toUpperCase() === DsEnum.KPI;
  }

  isFormOrView() {
    return this.datasourceInstance.type && this.datasourceInstance.type.toUpperCase() !== DsEnum.KPI;
  }
}

export class MethodParams {
  public name: string;
  public type: string;
  public isOptional: boolean;
  public isArray: boolean;
  constructor() {
  }
}

export class ReturnMethodParams {
  public name: string;
  public type: string;
  public props?: Object;
  public valueOpt?: ReturnMethodParams[];
  constructor() {
  }
}

export class ReturnMethodParamsWrapper {
  constructor(private dsName: string, private returnMethodParams: ReturnMethodParams) {

  }
  //Fully qualified param name
  get name() {
    return DataSourcePropertyKeyBuilder.buildKey(this.dsName, this.returnMethodParams.name);
  }
  get paramName() {
    return this.returnMethodParams.name;
  }

  get type() {
    return this.returnMethodParams.type;
  }
  get hasUOM(): boolean {
    //Resolve from returnMethodParams
    if (RtSome(this.returnMethodParams.props).isDefined) {
      return RtSome(this.returnMethodParams.props["uom"]).isDefined;
    }
    return false;
  }
  get uom(): AllMeasuresUnits {
    //Resolve from returnMethodParams
    if (this.hasUOM) {
      return this.returnMethodParams.props["uom"];
    }
    return undefined;
  }

}

export class EntityData {
  fieldName: string;
  value: any;
  schemaType: number | string | boolean;
  isForeignKey: boolean;
}

export interface DatasourceFilterModel extends DataSourceServiceInstanceWrapper {
  groupField: string;
  groupPeriod: string;
  realTimeTimeout: number;
  kpiPeriod: string;
}
export interface DatasourceSelectionModel extends DataSourceServiceInstanceWrapper {

}
export interface DSFilterOutputModel {
  datasource: DataSourceServiceInstanceWrapper;
  bindableParams: MethodParams[];
}

export class DataSourcePropertyKeyBuilder {
  static buildKey(dsName: string, fieldName: string) {
    return `${dsName}.${fieldName}`;
  }
}

export class TFDatasource {
  constructor(public pauseTime: RtOption<number>,
    public displayMode: DisplayMode,
  ) {

  }
}

export enum DisplayMode {
  ACCUMULATE = "accumulate",
  ONE_AT_A_TIME = "one_at_a_time"
}


