import { Injectable } from "@angular/core";
import { Observable, lastValueFrom } from "rxjs";
import { ControlRegistryProvider } from "../../../../../ide/services/control-registry-provider";
import { AbstractWebPopupControlRenderServiceRegistry, WebPopupControlRenderService } from "../../../../../ide/services/popup-control-render-service";
import { ApplyActionCommand } from "../../event-models";
import { DevumBottomSheet, DevumDialog, DevumSidePanel, DevumSnackbar, PopupAction, PopupType } from "./popup-action-models";
import { PopupProviderStrategyProvider } from "./popup-provider-strategy";



@Injectable()
// export class WebPopupProviderStrategy implements PageEventActionStrategy<PopupAction> {
export class WebPopupProviderStrategy extends PopupProviderStrategyProvider<PopupAction> {

  popupProviderRegistry: Map<string, { renderService: WebPopupControlRenderService, cmd: ApplyActionCommand<PopupAction>, ctrlInstanceId: string }> = new Map();
  popupProviderRegistryForDesignMode: Map<string, { renderService: WebPopupControlRenderService, ctrlInstanceId: string }> = new Map();

  constructor(
    public controlRegistry: ControlRegistryProvider,
    public popupControlRenderServiceRegistry: AbstractWebPopupControlRenderServiceRegistry) {
    super();
  }

  applyActions(cmds: ApplyActionCommand<PopupAction>[]): Promise<void> {
    const cmd$ = new Observable<void>(() => {
      this.handleRetrieveClose();
      cmds.map((cmd) => {
        if (cmd.action.popupType === PopupType.DIALOG) {
          const ctrlInstanceId = this.#createDialogComponent(cmd);
          this.popupProviderRegistry.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.dialogControlRenderService, cmd, ctrlInstanceId });
        } else if (cmd.action.popupType === PopupType.SIDE_PANEL) {
          const ctrlInstanceId = this.#createSidePanelComponent(cmd);
          this.popupProviderRegistry.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.sidePanelControlRenderService, cmd, ctrlInstanceId });
        } else if (cmd.action.popupType === PopupType.BOTTOM_SHEET) {
          const ctrlInstanceId = this.#createBottomSheetComponent(cmd);
          this.popupProviderRegistry.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.bottomSheetControlRenderService, cmd, ctrlInstanceId });
        } else if (cmd.action.popupType === PopupType.SNACK_BAR) {
          const ctrlInstanceId = this.#createSnackBarComponent(cmd);
          this.popupProviderRegistry.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.snackbarControlRenderService, cmd, ctrlInstanceId });
        } else if (cmd.action.popupType === PopupType.TOAST) {
          const ctrlInstanceId = this.#createToastComponent(cmd);
          this.popupProviderRegistry.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.toastControlRenderService, cmd, ctrlInstanceId });
        }
      })
    })
    
    return lastValueFrom(cmd$);
  }

  handleClose(controlInstanceId: string) {
    this.popupProviderRegistry.get(controlInstanceId)?.renderService?.close(controlInstanceId);
  }

  private handleRetrieveClose() {
    const popupControlRenderServices = this.popupProviderRegistry.values();
    Array.from(popupControlRenderServices).forEach(popupCtrlService => {
      const retrieveClose = popupCtrlService.cmd.action.popupSettings.retrieveClose;
      if (retrieveClose == false) {
        this.popupProviderRegistry.get(popupCtrlService.ctrlInstanceId)?.renderService?.close(popupCtrlService.ctrlInstanceId);
      }
    });
  }

  openPopupForComposing(popupAction: PopupAction) {
    this.closeExistingPopupsInDesignerMode();
    const clonedPopup: PopupAction = JSON.parse(JSON.stringify(popupAction));
    if (popupAction.popupType === PopupType.DIALOG) {
      this.openDialogForComposing(clonedPopup);
    } else if (popupAction.popupType === PopupType.SIDE_PANEL) {
      this.openSidePanelForComposing(clonedPopup);
    } else if (popupAction.popupType === PopupType.BOTTOM_SHEET) {
      this.opnBottomSheetForComposing(clonedPopup);
    } else if (popupAction.popupType === PopupType.SNACK_BAR) {
      this.openSnackbarForComposing(clonedPopup);
    } else if (popupAction.popupType === PopupType.TOAST) {
      this.openToastbarForComposing(clonedPopup);
    }
  }

  private closeExistingPopupsInDesignerMode() {
    const popupControlRenderServices = this.popupProviderRegistryForDesignMode.values();
    Array.from(popupControlRenderServices)?.forEach(popupCtrlService => {
      this.popupProviderRegistryForDesignMode?.get(popupCtrlService.ctrlInstanceId)?.renderService?.close(popupCtrlService.ctrlInstanceId);
    });
  }

  private openToastbarForComposing(clonedPopup: PopupAction) {
    const dialogSettings = clonedPopup.popupSettings as DevumSnackbar;
    clonedPopup.popupSettings = dialogSettings;
    const ctrlInstanceId = this.popupControlRenderServiceRegistry.toastControlRenderService.renderPopup(clonedPopup);
    this.popupProviderRegistryForDesignMode.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.toastControlRenderService, ctrlInstanceId });
  }

  private openSnackbarForComposing(clonedPopup: PopupAction) {
    const dialogSettings = clonedPopup.popupSettings as DevumSnackbar;
    dialogSettings.hasAction = true;
    clonedPopup.popupSettings = dialogSettings;
    const ctrlInstanceId = this.popupControlRenderServiceRegistry.snackbarControlRenderService.renderPopup(clonedPopup);
    this.popupProviderRegistryForDesignMode.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.snackbarControlRenderService, ctrlInstanceId });
  }

  private opnBottomSheetForComposing(clonedPopup: PopupAction) {
    const dialogSettings = clonedPopup.popupSettings as DevumBottomSheet;
    dialogSettings.hasBackdrop = false;
    dialogSettings.disableClose = true;
    clonedPopup.popupSettings = dialogSettings;
    const ctrlInstanceId = this.popupControlRenderServiceRegistry.bottomSheetControlRenderService.renderPopup(clonedPopup);
    this.popupProviderRegistryForDesignMode.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.bottomSheetControlRenderService, ctrlInstanceId });
  }

  private openSidePanelForComposing(clonedPopup: PopupAction) {
    const dialogSettings = clonedPopup.popupSettings as DevumSidePanel;
    dialogSettings.hasBackdrop = false;
    dialogSettings.disableClose = true;
    clonedPopup.popupSettings = dialogSettings;
    const ctrlInstanceId = this.popupControlRenderServiceRegistry.sidePanelControlRenderService.renderPopup(clonedPopup);
    this.popupProviderRegistryForDesignMode.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.sidePanelControlRenderService, ctrlInstanceId });
  }

  private openDialogForComposing(eventAction: PopupAction) {
    const clonedPopup: PopupAction = JSON.parse(JSON.stringify(eventAction));
    const dialogSettings = clonedPopup.popupSettings as DevumDialog;
    dialogSettings.hasBackdrop = false;
    dialogSettings.disableClose = true;
    clonedPopup.popupSettings = dialogSettings;
    const ctrlInstanceId = this.popupControlRenderServiceRegistry.dialogControlRenderService.renderPopup(clonedPopup);
    this.popupProviderRegistryForDesignMode.set(ctrlInstanceId, { renderService: this.popupControlRenderServiceRegistry.dialogControlRenderService, ctrlInstanceId });
  }

  #createSnackBarComponent(cmd: ApplyActionCommand<PopupAction>) {
    return this.popupControlRenderServiceRegistry.snackbarControlRenderService.renderPopup(cmd.action);
  }

  #createBottomSheetComponent(cmd: ApplyActionCommand<PopupAction>) {
    return this.popupControlRenderServiceRegistry.bottomSheetControlRenderService.renderPopup(cmd.action);
  }

  #createDialogComponent(cmd: ApplyActionCommand<PopupAction>) {
    return this.popupControlRenderServiceRegistry.dialogControlRenderService.renderPopup(cmd.action);
  }

  #createSidePanelComponent(cmd: ApplyActionCommand<PopupAction>) {
    return this.popupControlRenderServiceRegistry.sidePanelControlRenderService.renderPopup(cmd.action);
  }
  #createToastComponent(cmd: ApplyActionCommand<PopupAction>) {
    return this.popupControlRenderServiceRegistry.toastControlRenderService.renderPopup(cmd.action);
  }
}
