import { Attribute, ControlPropertyDefinition, SimpleConfigurationAttribute } from 'projects/den-core/page-builder';
import { ChartAttributesEnum, ChartDataSourceEnum, ChartTypes } from 'projects/den-core/src/lib/models/chart/chart-models';
import { AxisChartAttribute } from 'projects/den-core/src/lib/page-builder/attributes/charts/axis-chart-properities/axis-chart-attribute';
import { ChartAxisDateFormatAttribute } from 'projects/den-core/src/lib/page-builder/attributes/charts/date-format-properties/date-format-properties';
import { SimpleSelectorOptions } from 'projects/den-core/src/lib/page-builder/attributes/simple-selector/simple-selector-attribute';
import { AttributeGroup } from 'projects/den-core/src/lib/page-builder/constants/attribute-group-constant';


export class CoreChartAttributesConstants {

    static readonly LINE_AREA_CHART_TYPE = [
        { id: ChartTypes.line, name: 'Line' },
        { id: ChartTypes.areaChart, name: 'Area' },
        { id: ChartTypes.stackedAreaChart, name: 'Stacked area' },
        { id: ChartTypes.normalisedAreaChart, name: 'Normalised area' }
    ];

    static readonly BAR_CHART_TYPES = [
        { id: ChartTypes.verticalBar, name: 'Vertical bar' },
        { id: ChartTypes.horizontalBar, name: 'Horizontal bar' }
    ];

    static readonly PIE_CHART_TYPES = [
        { id: ChartTypes.pie, name: 'Pie' },
        { id: ChartTypes.pieGrid, name: 'Pie grid' },
        { id: ChartTypes.donutChart, name: 'Donut chart' }
    ];

    static readonly GROUPED_BAR_CHART_TYPES = [
        { id: ChartTypes.groupedHorizontalBar, name: 'Grouped horizontal' },
        { id: ChartTypes.groupedVerticalBar, name: 'Grouped vertical' },
        { id: ChartTypes.stackedVertivalBarChart, name: 'Stacked vertical bar' },
        { id: ChartTypes.stackedHorizontalBarChart, name: 'Stacked horizontal bar' },
        { id: ChartTypes.normalisedVerticalBarChart, name: 'Normalized vertical bar' },
        { id: ChartTypes.normalisedHorizontalBarChart, name: 'Normalized horizontal bar' }
    ];

    static readonly SCHEME_TYPE_ATTRIBUTES = [
        { name: "linear", label: 'linear' },
        { name: 'ordinal', label: 'ordinal' }
    ];

    static lineAreaChartTypeSelectorOptions: SimpleSelectorOptions = new SimpleSelectorOptions("Select chart type",
        CoreChartAttributesConstants.LINE_AREA_CHART_TYPE);

    static pieAreaChartTypeSelectorOptions: SimpleSelectorOptions = new SimpleSelectorOptions("Select chart type",
        CoreChartAttributesConstants.PIE_CHART_TYPES);

    static groupBarChartTypeSelectorOptions: SimpleSelectorOptions = new SimpleSelectorOptions("Select chart type",
        CoreChartAttributesConstants.GROUPED_BAR_CHART_TYPES);

    static readonly BASE_PROP_DEFINITIONS = [
        new ControlPropertyDefinition(ChartDataSourceEnum.XAXIS_PROPERTY),
        new ControlPropertyDefinition(ChartDataSourceEnum.YAXIS_PROPERTY_LIST, 'array'),
        new ControlPropertyDefinition(ChartDataSourceEnum.COLOR_CODE)
    ];

    static readonly BAR_LINE_PROPS = [
        new ControlPropertyDefinition(ChartDataSourceEnum.XAXIS_PROPERTY),
        new ControlPropertyDefinition(ChartDataSourceEnum.YAXIS_PROPERTY),
        new ControlPropertyDefinition(ChartDataSourceEnum.LINE_YAXIS_PROPERTY_LIST, 'array')
    ];

    static readonly STACKED_VERTICAL_PROPS = [
        new ControlPropertyDefinition(ChartDataSourceEnum.XAXIS_PROPERTY),
        new ControlPropertyDefinition(ChartDataSourceEnum.YAXIS_PROPERTY_LIST, 'array'),
        new ControlPropertyDefinition(ChartDataSourceEnum.GROUP_BY_PROPERTY),
        new ControlPropertyDefinition(ChartDataSourceEnum.LINE_YAXIS_PROPERTY_LIST, 'array'),
        new ControlPropertyDefinition(ChartDataSourceEnum.COLOR_CODE)
    ];

    static readonly TREE_MAP_PROPS = [
        new ControlPropertyDefinition(ChartDataSourceEnum.XAXIS_PROPERTY),
        new ControlPropertyDefinition(ChartDataSourceEnum.YAXIS_PROPERTY_LIST, 'array'),
        new ControlPropertyDefinition(ChartDataSourceEnum.COLOR_CODE),
        new ControlPropertyDefinition(ChartDataSourceEnum.PARENT_FIELD),
    ];

    static readonly BASE_CONFIG_ATTRIBUTES: SimpleConfigurationAttribute<unknown>[] = [
        new SimpleConfigurationAttribute<string>(ChartAttributesEnum.LEGEND_TITLE, AttributeGroup.CONFIGURATION, 'string'),
        new SimpleConfigurationAttribute<boolean>(ChartAttributesEnum.ANIMATIONS, AttributeGroup.CONFIGURATION, 'boolean', true),
    ];

    static readonly AXIS_CHART_CONFIG_ATTRIBUTES: Attribute<unknown, unknown>[] = [
        ...CoreChartAttributesConstants.BASE_CONFIG_ATTRIBUTES,
        new ChartAxisDateFormatAttribute(),
        new AxisChartAttribute(),
    ];
    
    static DEFAULT_CURVE_TYPE = 'Default';
    static CURVE_TYPES = ['Default', 'Basis Closed', 'Cardinal Closed', 'Catmull Rom Closed', 'Linear Closed'];

    static readonly POLAR_CURVE_TYPES: string[] = CoreChartAttributesConstants.CURVE_TYPES;



    static readonly PROGRESS_CHART_CONFIG_ATTRIBUTES: Attribute<unknown, unknown>[] = [
        new SimpleConfigurationAttribute('width', AttributeGroup.APPERANCE, 'number', 50), new SimpleConfigurationAttribute('height', AttributeGroup.APPERANCE, 'number', 20),
        new SimpleConfigurationAttribute('label', AttributeGroup.CONFIGURATION, 'string', 'label'), new SimpleConfigurationAttribute('filledLabel', AttributeGroup.CONFIGURATION, 'string', 'actual'), new SimpleConfigurationAttribute('totalLabel', AttributeGroup.CONFIGURATION, 'string', 'total')];

    static CHART_TITLE_POSITIONS = [{ name: 'Below', label: 'below' }, { name: 'Right', label: 'right' }];

    static CHART_LEGEND_POSITION = [{ id: 1, name: 'Top' }, { id: 2, name: 'Bottom' }, { id: 3, name: 'Left' }, { id: 4, name: 'Right' }];
    static CHART_LEGEND_ALIGN = ['Horizonal' ,'Vertical'];

}

export enum VerticalBarChartEnum{
    DEFAULT_BAR_PADDING_VALUE = 15,
    PARENT_WIDTH_ADJUSTMENT_PERCENTAGE = 0.30,
}