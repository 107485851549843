import { Component, ViewEncapsulation } from '@angular/core';
import { AbstractSiteAppSelector } from '../abstract-site-app-selector/abstract-site-app-selector.component';

@Component({
  selector: 'den-w-site-app-selector-dialog',
  templateUrl: './site-app-selector-dialog.component.html',
  styleUrls: ['./site-app-selector-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SiteAppSelectorDialogComponent extends AbstractSiteAppSelector {
  
}
