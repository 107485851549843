import { RtSome } from "../../utils/option-helper";
import { WebControlRegistryConstant } from "../constants/web-control-registry-constants"
import { ControlCompanionHelper } from "../controls/core/control-companion/control-companion-helper";
import { ControlDropParentData, StandardControlDrop, StandardDropChildData } from "../models/model";
import { ControlType, RenderOwnership } from "../public_api";

export class TwoDThreeDSuggestionHelper {

    static threeDEnvironmentControls() {
        return [
            { isSelected: false, controlName: WebControlRegistryConstant.THREE_D_SPATIAL_ENVIRONMENT, icon: '3d-spatial-environment' },
            { isSelected: false, controlName: WebControlRegistryConstant.THREE_D_ENVIRONMENT, icon: '3d-environment' }
        ]
    }
    static threeDLayerControls() {
        return [
            { isSelected: false, controlName: WebControlRegistryConstant.THREE_D_STATIC_LIST, icon: '3d-static-list' },
            { isSelected: false, controlName: WebControlRegistryConstant.THREE_D_ENTITY_LIST, icon: '3d-list' },
            { isSelected: false, controlName: WebControlRegistryConstant.THREE_D_ENTITY_REAL_TIME_LIST, icon: '3d-list' },
            { isSelected: false, controlName: WebControlRegistryConstant.THREE_D_ENTITY_LIST_ITEM, icon: '3d-list' }
        ]
    }

    static threeDSingleChildControls() {
        return [
            WebControlRegistryConstant.THREE_D_VIEWER,
            WebControlRegistryConstant.THREEJS_VIEWER,
            WebControlRegistryConstant.THREE_D_BILL_BOARD,
            WebControlRegistryConstant.THREE_D_ELEMENTS,
            WebControlRegistryConstant.THREE_D_FONT_CONTROL,
            WebControlRegistryConstant.THREE_D_GROUP_CONTROL,
            WebControlRegistryConstant.THREE_D_CUBOID_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_CIRCLE_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_CONE_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_CYLINDER_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_DODECAHEDRON_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_ICOSAHEDRON_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_LATHE_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_OCTAHEDRON_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_PLANE_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_RING_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_SPHERE_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_TETRAHEDRON_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_TORUS_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_TORUS_KNOT_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_TUBE_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_EXTRUDE_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_SHAPE_GEOMETRY_CONTROl,
            WebControlRegistryConstant.THREE_D_LINE_GEOMETRY_CONTROl
        ]
    }

    static is3DSingleControl(controlDropData: StandardControlDrop) {
        return TwoDThreeDSuggestionHelper.threeDSingleChildControls().includes(controlDropData.childData.controlName);
    }
    static is3DLayerControl(controlDropData: StandardControlDrop) {
        return TwoDThreeDSuggestionHelper.threeDLayerControls().some(c => c.controlName === controlDropData.childData.controlName);
    }
    static is3DLayerControlParentInvalid(controlDropData: StandardControlDrop) {
        return TwoDThreeDSuggestionHelper.threeDEnvironmentControls().some(c => c.controlName === controlDropData.parentData.parentControlName);
    }
    static enable3DEnvironmentControl(controlDropData: StandardControlDrop) {
        if (TwoDThreeDSuggestionHelper.threeDEnvironmentControls().some(c => c.controlName === controlDropData.parentData.parentControlName)) {
            return false;
        }
        return true;
    }
    static get3DSingleChildControlName(controlName: string) {
        return TwoDThreeDSuggestionHelper.threeDSingleChildControls().find(c => c == controlName);
    }
    static twoDSingleChildControls() {
        return [
            WebControlRegistryConstant.SINGLE_LEAFLET_POLYGON,
            WebControlRegistryConstant.LEAFLET_SINGLE_MARKER,
            WebControlRegistryConstant.SINGLE_LEAFLET_CIRCLE,
            WebControlRegistryConstant.SINGLE_LEAFLET_HEAT_MAP,
            WebControlRegistryConstant.LEAFLET_SINGLE_POPUP,
            WebControlRegistryConstant.ROUTE_DETAILS,
            WebControlRegistryConstant.SINGLE_LEAFLET_LABEL,
            WebControlRegistryConstant.LEAFLET_GEOJSON_FILE_CONTROL,
        ]
    }
    static twoDLayerControls() {
        return [
            WebControlRegistryConstant.LEAFLET_STATIC_LAYER,
            WebControlRegistryConstant.LEAFLET_DYNAMIC_LAYER
        ]
    }
    static is2DChildSingleControl(controlDropData: StandardControlDrop) {
        return TwoDThreeDSuggestionHelper.twoDSingleChildControls().includes(controlDropData.childData.controlName);
    }
    static is2DChildLayerControl(controlDropData: StandardControlDrop) {
        return TwoDThreeDSuggestionHelper.twoDLayerControls().includes(controlDropData.childData.controlName);
    }
    static show2DControlView(controlDropData: StandardControlDrop): boolean {
        const parentIsLeafletMap = controlDropData.parentData.parentControlName === WebControlRegistryConstant.LEAFLET_MAP;
        const isSingleControl = this.is2DChildSingleControl(controlDropData);
        const isLayerControl = this.is2DChildLayerControl(controlDropData);

        if (!parentIsLeafletMap && (isLayerControl || isSingleControl)) {
            return true;
        }
        return false;

    }
    static show3DControlView(controlDropData: StandardControlDrop): boolean {
        const parentIs3DMap = this.threeDEnvironmentControls().some(c => c.controlName == controlDropData.parentData.parentControlName);
        const isLayerControl = this.is3DLayerControl(controlDropData);
        if (!parentIs3DMap && (isLayerControl || this.is3DSingleControl(controlDropData))) {
            return true;
        }
        return false;
    }

    static getStandardControlDropData(controlDropData: StandardControlDrop): StandardControlDrop[] {
        let standardControlArray = [];

        const environmentChildData = this.getEnvironmentStandardDropChildData(controlDropData);
        const environmentParentData = this.getEnvironmentParentData(controlDropData.parentData.pageName);

        if (controlDropData.parentData.parentControlName !== WebControlRegistryConstant.LEAFLET_MAP && TwoDThreeDSuggestionHelper.is2DChildSingleControl(controlDropData as StandardControlDrop)) {
            standardControlArray = this.getSingleLayerStandardControlDropData(controlDropData, environmentParentData, environmentChildData);
        } else if (controlDropData.parentData.parentControlName !== WebControlRegistryConstant.LEAFLET_MAP && TwoDThreeDSuggestionHelper.is2DChildLayerControl(controlDropData as StandardControlDrop)) {
            standardControlArray = this.getLayerStandardControlDropData(environmentParentData, environmentChildData);
        }

        return standardControlArray;
    }
    private static getSingleLayerStandardControlDropData(controlDropData: StandardControlDrop, environmentParentData: ControlDropParentData, environmentChildData: StandardControlDrop) {
        const selectedLayerDataControlName: string = WebControlRegistryConstant.LEAFLET_DYNAMIC_LAYER;

        const layerChildData = new StandardDropChildData(ControlType.LEAFLET, WebControlRegistryConstant.LEAFLET_DYNAMIC_LAYER, [], [], RenderOwnership.DEFAULT_MECHANISM);

        const layerStandardDropData = new StandardControlDrop(layerChildData, environmentParentData, false);

        const parentLayerControl = { parentInstanceId: ControlCompanionHelper.generateInstanceId(), parentControlName: selectedLayerDataControlName, parentSectionIndex: RtSome(-1), pageName: controlDropData.parentData.pageName };

        const singleLayerStandardControlDropData = new StandardControlDrop(controlDropData.childData, parentLayerControl, false);

        return [environmentChildData, layerStandardDropData, singleLayerStandardControlDropData];

    }
    private static getLayerStandardControlDropData(environmentParentData: ControlDropParentData, environmentChildData: StandardControlDrop) {

        const layerChildData = new StandardDropChildData(ControlType.LEAFLET, WebControlRegistryConstant.LEAFLET_DYNAMIC_LAYER, [], [], RenderOwnership.DEFAULT_MECHANISM);

        const layerStandardDropData = new StandardControlDrop(layerChildData, environmentParentData, false);

        return [environmentChildData, layerStandardDropData];

    }
    private static getEnvironmentStandardDropChildData(controlDropData: StandardControlDrop) {
        const environmentStandardDropChildData: StandardDropChildData = new StandardDropChildData(ControlType.LEAFLET, WebControlRegistryConstant.LEAFLET_MAP, [], [], RenderOwnership.DEFAULT_MECHANISM);
        const environmentChildData = new StandardControlDrop(environmentStandardDropChildData, controlDropData.parentData, false);
        return environmentChildData;
    }

    private static getEnvironmentParentData(pageName: string) {
        return { parentInstanceId: ControlCompanionHelper.generateInstanceId(), parentControlName: WebControlRegistryConstant.LEAFLET_MAP, parentSectionIndex: RtSome(-1), pageName: pageName };
    }
}