import { PeriodConstant } from "../../../constants/period.constants";
import { BindingTypeEnum, DataSourceMethodInstance, DataSourceServiceInstanceWithFilter } from "../../../data-source/data-source";
import { DataSourceServiceInstanceWrapper } from "../data-source-draft-models";
import { CtrlEventRaised } from "../event/control-event";
import { EventAttributeType } from "../event/control-event-attribute";
import { DsResultValue } from "../../../data-source/data-source-result-entities";
import { RtNone, RtOption, RtSome } from "../../../../utils/option-helper";
import { OrganizationDate } from "../../../../../../src/lib/utils/org-date-helper";

export class DataSourceExecutorServiceHelper {

  static getDataSourceServiceInstanceWithFilter(selDS: DataSourceServiceInstanceWrapper): DataSourceServiceInstanceWithFilter {
    const methodInstance = selDS.method;
    const method = new DataSourceMethodInstance(methodInstance.methodName, methodInstance.params,
      methodInstance.methodType, methodInstance.returningResult, methodInstance.groupField1, methodInstance.groupField2,
      methodInstance.aggregateField, methodInstance.aggregateFunction, methodInstance.isStartDateIncluded, methodInstance.isEndDateIncluded,
      methodInstance.dateField, methodInstance.canAggregateTime, methodInstance.canAggregateSize, methodInstance.sortOptions,
      methodInstance.defaultPaginationSettings, methodInstance.returningSchemaName, methodInstance.wsLogTopic, methodInstance.isOnRequestMview);
    const datasource = new DataSourceServiceInstanceWrapper();
    datasource.type = selDS.type;
    datasource.group = selDS.group;
    datasource.id = selDS.id;
    datasource.joinKey = selDS.joinKey;
    datasource.isMaster = selDS.isMaster;
    datasource.finalResponseType = selDS.finalResponseType;
    datasource.method = method;
    datasource.dsName = selDS.dsName;
    datasource.wsTopicResult = selDS.wsTopicResult;
    datasource.serviceName = selDS.serviceName;
    datasource.pageName = selDS.pageName;
    datasource.label = selDS.label || selDS.dsName;
    const dsr = new DataSourceServiceInstanceWithFilter(datasource);
    return dsr;
  }

  static applyParamsIfSharedByParent(parentData: RtOption<any>, selDS: DataSourceServiceInstanceWrapper): void {
    if (selDS.method?.params) {
      selDS.method.params.forEach((param) => {
        const boundPropertyName = param.boundPropertyName;
        if (parentData && parentData.isDefined && param.bindingType === BindingTypeEnum.PARENT) {
          const value = this.getValueFromDsResultValue(parentData.get.data, boundPropertyName);
          if (parentData && parentData.isDefined && value) {
            param.defaultValue = value;
          }
        }
      });
    }
  }
  private static getValueFromDsResultValue(data: DsResultValue[], boundPropertyName: string) {
    const dsResultValue = data.find(dsResultVal => dsResultVal.fieldName === boundPropertyName);
    return dsResultValue ? dsResultValue.value : null

  }

  static getTheDateRangeForPeriod(mode: string): RtOption<StartAndEndTs> {
    switch (mode) {
      case PeriodConstant.DAILY: {
        const startDate = OrganizationDate.getStartOrEndOfTime('day', true, true)
        const endDate = OrganizationDate.getStartOrEndOfTime('day', false, true)
        return RtSome({ startts: startDate, endts: endDate });
      }
      case PeriodConstant.WEEKLY: {
        const startDate = OrganizationDate.getStartOrEndOfTime('week', true, true)
        const endDate = OrganizationDate.getStartOrEndOfTime('week', false, true)
        return RtSome({ startts: startDate, endts: endDate });
      }
      case PeriodConstant.MONTHLY: {
        const startDate = OrganizationDate.getStartOrEndOfTime('month', true, true)
        const endDate = OrganizationDate.getStartOrEndOfTime('month', false, true)
        return RtSome({ startts: startDate, endts: endDate });
      }
      case PeriodConstant.YEARLY: {
        const startDate = OrganizationDate.getStartOrEndOfTime('year', true, true)
        const endDate = OrganizationDate.getStartOrEndOfTime('year', false, true)
        return RtSome({ startts: startDate, endts: endDate });
      }
      case PeriodConstant.QUARTERLY: {
        const startDate = OrganizationDate.getStartOrEndOfTime('quarter', true, true)
        const endDate = OrganizationDate.getStartOrEndOfTime('quarter', false, true)
        return RtSome({ startts: startDate, endts: endDate });
      }
    }
    return RtNone();
  }

  static getSelectedStatus(event: CtrlEventRaised): RtOption<boolean> {
    if (!event) {
      return RtNone();
    }
    const selectedAttribute = event.getAttributeValue(EventAttributeType.SELECTED_STATUS);
    if (selectedAttribute.isDefined) {
      if (selectedAttribute.get.value === false) {
        return RtSome(false);
      } else {
        return RtSome(true);
      }
    } else {
      return RtNone();
    }
  }
}

export type StartAndEndTs = { startts: string, endts: string };


