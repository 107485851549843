import { Injectable } from "@angular/core";
import { RtNone } from "../../../../../../utils/option-helper";
import { SessionStorageProvider } from "../../../../../../providers/abstract/session-storage-provider";
import { AppSettingsProvider } from "../../../../../../providers/app-settings-provider/app-settings-provider";
import { EventService } from "../../../../../../providers/event-service/event.service";
import { GlobalEventDataSchema } from "../../event-schema-provider";
import { StandardEvents } from "../../event-types";
import { ProducerProps, GlobalEventProducerInfo, GlobalEventProducer, GlobalEventData } from "../global-event-producer";
import { WebStorageEnum } from "../../../../../../../../contants";

//App params provider. e.g.: siteCode, appCode, timeZone
export class AppParamsEventProducerProps extends ProducerProps {

    //eventTypes
    ON_APP_LOAD = StandardEvents.ON_APP_LOAD
    IS_ONLINE = StandardEvents.IS_ONLINE
    IS_OFFLINE = StandardEvents.IS_OFFLINE

    //produceParams
    ORG_CODE = { property: WebStorageEnum.ORG_CODE }
    APP_CODE = { property: WebStorageEnum.APP_CODE }
    SITE_CODE = { property: WebStorageEnum.SITE_CODE }
    ROLE_NAMES = { property: WebStorageEnum.USER_ROLE_NAMES }
    ROLE_IDS = { property: WebStorageEnum.USER_ROLE_IDS }
    USER_NAME = { property: WebStorageEnum.USER_NAME }
    USER_ACCOUNT_ID = { property: WebStorageEnum.USER_ACCOUNT_ID }
    SELECTED_TIME_ZONE = { property: "selected_timeZone" }
    SELECTED_LANGUAGE = { property: "selected_language" }
    SELECTED_THEME = { property: "theme" }
    DATE_FORMAT = { property: "dateFormat" }

    IP_ADDRESS = { property: "ip_address" }

    LOCATION_CITY = { property: "locaton_city" }
    LOCATION_REGION = { property: "location_region" }
    LOCATION_REGION_CODE = { property: "location_region_code" }
    LOCATION_REGION_NAME = { property: "location_regionName" }
    LOCATION_COUNTRYCODE = { property: "location_countryCode" }
    LOCATION_COUNTRYNAME = { property: "location_countryName" }
    LOCATION_CONTINENTCODE = { property: "location_continentCode" }
    LOCATION_CONTINETNAME = { property: "location_continentName" }
    LOCATION_LATITUDE = { property: "location_latitude" }
    LOCATION_LONGITUDE = { property: "location_longitude" }
    LOCATION_ACCURACY_RADIUS = { property: "location_AccuracyRadius" }
    LOCATION_TIMEZONE = { property: "location_timezone" }
    LOCATION_CURRENCY_CODE = { property: "location_currencyCode" }
    LOCATION_CURRENCY_SYMBOL = { property: "location_currencySymbol" }
    LOCATION_CURRENCY_CONVERTER = { property: "location_currencyConverter" }

    get props(): GlobalEventProducerInfo {
        return {
            producerName: "App load",
            description: "Params produced when app loads",
            iconName: "",
            eventTypes: [this.ON_APP_LOAD, this.IS_OFFLINE, this.IS_ONLINE],
            eventParams: [],
            produceParams: [
                //app params
                this.ORG_CODE,
                this.APP_CODE,
                this.SITE_CODE,
                this.ROLE_NAMES, this.USER_NAME, this.USER_ACCOUNT_ID,
                //app settings
                this.SELECTED_TIME_ZONE,
                this.SELECTED_LANGUAGE,
                this.SELECTED_THEME,
                this.DATE_FORMAT,
                //geo query
                this.LOCATION_CITY,
                this.LOCATION_REGION,
                this.LOCATION_REGION_CODE,
                this.LOCATION_REGION_NAME,
                this.LOCATION_COUNTRYCODE,
                this.LOCATION_COUNTRYNAME,
                this.LOCATION_CONTINENTCODE,
                this.LOCATION_CONTINETNAME,
                this.LOCATION_LATITUDE,
                this.LOCATION_LONGITUDE,
                this.LOCATION_ACCURACY_RADIUS,
                this.LOCATION_TIMEZONE,
                this.LOCATION_CURRENCY_CODE,
                this.LOCATION_CURRENCY_SYMBOL,
                this.LOCATION_CURRENCY_CONVERTER,
            ],
        }
    }
}

@Injectable()
export class AppParamsReader {

    appParams = new Map<GlobalEventDataSchema, string | string[]>();
    constructor(private sessionStorageService: SessionStorageProvider,) {
        this.#init();
    }

    private get props(): AppParamsEventProducerProps {
        return new AppParamsEventProducerProps();
    }


    private getPrimaryUserRoleNames(): string[] {
        const roles = this.sessionStorageService.getUserRoles();
        if (roles) {
            return roles.map(_ => _.roleName);
        } else {
            return [];
        }
    }
    private getPrimaryUserRoleIds(): string[] {
        const roles = this.sessionStorageService.getUserRoles();
        if (roles) {
            return roles.map(_ => _.id);
        } else {
            return [];
        }
    }

    #init() {
        this.appParams.set(this.props.ORG_CODE, this.sessionStorageService.getOrgCode());
        this.appParams.set(this.props.APP_CODE, this.sessionStorageService.getAppCode());
        this.appParams.set(this.props.SITE_CODE, this.sessionStorageService.getSiteCode());
        this.appParams.set(this.props.ROLE_NAMES, this.getPrimaryUserRoleNames());
        this.appParams.set(this.props.ROLE_IDS, this.getPrimaryUserRoleIds());
        this.appParams.set(this.props.USER_NAME, this.sessionStorageService.getUserName());
        this.appParams.set(this.props.USER_ACCOUNT_ID, this.sessionStorageService.getUserAccountId());
    }

    getAppParam(paramName: string) {
        return [...this.appParams.entries()].find(item => item[0].property === paramName)[1]
        // return this.appParams.get({ property: paramName });
    }

    getAllAppParams(): Map<GlobalEventDataSchema, string | string[]> {
        return this.appParams;
    }

}

@Injectable()
export class AppParamsEventProducer extends GlobalEventProducer<AppParamsEventProducerProps> {

    constructor(public eventService: EventService,
        private appParamsReader: AppParamsReader,
        private appSettingsProvider: AppSettingsProvider) {
        super(eventService);
    }

    get props(): AppParamsEventProducerProps {
        return new AppParamsEventProducerProps();
    }

    //init(pageEventProducer: PageEventProducer) {
    init() {
        this.appParamsReader.getAllAppParams().forEach((value, key) => {
            this.buildAndProduce(key, value);
        });
    }

    produceAppSettings() {
        const appSettings = this.appSettingsProvider.getResolvedAppSettings()
        this.buildAndProduce(this.props.SELECTED_TIME_ZONE, appSettings.appTZ)
        this.buildAndProduce(this.props.SELECTED_LANGUAGE, appSettings.language)
        this.buildAndProduce(this.props.SELECTED_THEME, appSettings.theme)
        this.buildAndProduce(this.props.DATE_FORMAT, appSettings.dateFormat)
    }

    getIpAddress() {

        fetch('http://www.geoplugin.net/json.gp')
            .then(response => response.json())
            .then(data => {
                const geoParams = data as ClientGeoParams
                this.buildAndProduce(this.props.LOCATION_CITY, geoParams.geoplugin_city);
                this.buildAndProduce(this.props.LOCATION_REGION, geoParams.geoplugin_region);
                this.buildAndProduce(this.props.LOCATION_REGION, geoParams.geoplugin_region);
                this.buildAndProduce(this.props.LOCATION_REGION_CODE, geoParams.geoplugin_regionCode);
                this.buildAndProduce(this.props.LOCATION_REGION_NAME, geoParams.geoplugin_regionName);
                this.buildAndProduce(this.props.LOCATION_COUNTRYCODE, geoParams.geoplugin_countryCode);
                this.buildAndProduce(this.props.LOCATION_COUNTRYNAME, geoParams.geoplugin_countryName);
                this.buildAndProduce(this.props.LOCATION_CONTINENTCODE, geoParams.geoplugin_continentCode);
                this.buildAndProduce(this.props.LOCATION_CONTINETNAME, geoParams.geoplugin_continentName);
                this.buildAndProduce(this.props.LOCATION_LATITUDE, geoParams.geoplugin_latitude);
                this.buildAndProduce(this.props.LOCATION_LONGITUDE, geoParams.geoplugin_longitude);
                this.buildAndProduce(this.props.LOCATION_ACCURACY_RADIUS, geoParams.geoplugin_locationAccuracyRadius);
                this.buildAndProduce(this.props.LOCATION_TIMEZONE, geoParams.geoplugin_timezone);
                this.buildAndProduce(this.props.LOCATION_CURRENCY_CODE, geoParams.geoplugin_currencyCode);
                this.buildAndProduce(this.props.LOCATION_CURRENCY_SYMBOL, geoParams.geoplugin_currencySymbol);
                this.buildAndProduce(this.props.LOCATION_CURRENCY_CONVERTER, geoParams.geoplugin_currencyConverter);
            });
    }

    buildAndProduce(schema: GlobalEventDataSchema, value: any) {
        const data: GlobalEventData = { schema: schema, value: value }
        this.produce(this.props.ON_APP_LOAD, RtNone(), [data])
    }


    dispose() {
        //nothing to dispose
    }

}

export interface ClientGeoParams {
    geoplugin_request: string, //"49.37.174.98",
    geoplugin_status: 200, //200,
    geoplugin_delay: string //"2ms",
    geoplugin_city: string, //"Bengaluru",
    geoplugin_region: string, //"Karnataka",
    geoplugin_regionCode: string, //"KA"
    geoplugin_regionName: string, //"Karnataka",
    geoplugin_countryCode: string, //"IN",
    geoplugin_countryName: string, //"India",
    geoplugin_continentCode: string, //"AS",
    geoplugin_continentName: string, //"Asia",
    geoplugin_latitude: number, //"12.9719",
    geoplugin_longitude: number, //"77.5937",
    geoplugin_locationAccuracyRadius: number, //"200",
    geoplugin_timezone: string, //"Asia\/Kolkata",
    geoplugin_currencyCode: string, //"INR",
    geoplugin_currencySymbol: string  //"₹",
    geoplugin_currencySymbol_UTF8: string //"₹",
    geoplugin_currencyConverter: number //:74.8403
}




// @Injectable()
// export class AppStateManager {

//     constructor() { }

// }
